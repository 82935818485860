import { all } from 'redux-saga/effects'

import verificationSaga from './verification/saga'
import paymentSaga from './payment/saga'
import currencySaga from './currency/saga'

export default function* rootSaga() {
  yield all([
    verificationSaga(),
    paymentSaga(),
    currencySaga()
  ])
}