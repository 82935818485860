import React, { useEffect, useState } from "react";
import "./dashboard.css";
import axios from "axios";
import DataTable from "./DataTable";
import { encryptDecript } from "../../helpers/encryptDecrypt.js";
import DatePicker from "react-datepicker";
import "./../../components/Form/DatePicker/index.scss";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../components/Layout/AuthLayout/Header";
import AfipayDesc from "../../components/Layout/AdminLayout/AfipayDesc";
import Spinner from "../../components/Spinner";
import { alert } from "../../utils/alert";

const Charge = (props) => {
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [moduleAccess, setModuleAccess] = useState([]);
  const [totalEntries, setTotalEntries] = useState(10);
  const [fileTemplate, setFileTemplate] = useState("");

  useEffect(() => {
    if (
      typeof localStorage.getItem("AFIPAY02B01") !== "undefined" ||
      localStorage.getItem("AFIPAY02B01") !== ""
    ) {
      setModuleAccess(encryptDecript(localStorage.getItem("AFIPAY02B01"), 2));
    }
  }, []);

  const handleSelectDateFrom = (tempDate) => {
    let minDate = new Date("1/1/1900");
    let bUpdatedDate = true;
    if (tempDate < minDate) {
      bUpdatedDate = false;
    }
    if (bUpdatedDate) {
      setDateFrom(tempDate);
    }
  };

  const handleSelectDateTo = (tempDate) => {
    let minDate = new Date("1/1/1900");
    let bUpdatedDate = true;
    if (tempDate < minDate) {
      bUpdatedDate = false;
    }
    if (bUpdatedDate) {
      setDateTo(tempDate);
    }
  };

  const formatDate = (e) => {
    var date = new Date(e),
      month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    let ret = [year, month, day].join("-");

    return ret.toString();
  };

  const onSearch = (e) => {
    setLoading(true);
    e.preventDefault();

    getListData();
  };

  const getListData = () => {
    let token = "";
    if (
      typeof localStorage.getItem("AFIPAY02B02") !== "undefined" ||
      localStorage.getItem("AFIPAY02B02") !== "" ||
      localStorage.getItem("AFIPAY02B02") !== null
    ) {
      token = encryptDecript(localStorage.getItem("AFIPAY02B02"), 2);
    }

    const headers = {
      Authorization: "Bearer " + token,
    };

    // format date
    let startdate = dateFrom;
    let enddate = dateTo;

    if (
      startdate !== null ||
      enddate !== null ||
      startdate !== "" ||
      enddate !== ""
    ) {
      startdate = formatDate(startdate);
      enddate = formatDate(enddate);

      if (
        startdate === "NaN-NaN-NaN" ||
        enddate === "NaN-NaN-NaN" ||
        startdate === "1970-01-01" ||
        enddate === "1970-01-01"
      ) {
        startdate = "";
        enddate = "";
      }
    }

    const params = {
      dateFrom: startdate,
      dateTo: enddate,
    };

    axios
      .get(
        process.env.REACT_APP_API_DASH +
          "/dashboard/report-transaction/charge-report",
        { params, headers }
      )
      .then(async (response) => {
        if (response?.data?.data?.Message === "Forbidden") {
          setData([]);
          setLoading(false);
          await alert({
            title: "Forbidden",
            html: "You don`t have access to read data or your access has been revoked in this menu",
          });
        } else {
          setData(response?.data?.data);
          setLoading(false);
          localStorage.setItem(
            "AFIPAY01A36",
            encryptDecript(response?.data?.data, 1)
          );
          document.getElementById("tableData").style.display = "block";
        }
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await alert({
            title: "Your session has ended",
            html: "Please login again",
          });

          props.history.push(process.env.REACT_APP_SUBDIR + "/sign-out-admin");
        }
        setLoading(false);
      });
  };

  const onChangeEntries = (val) => {
    setTotalEntries(val);
    getListData();
  };

  const onClickDownload = (e, chargeCode) => {
    e.preventDefault();
    if (fileTemplate === "" || fileTemplate === null) {
      alert({
        title: "Warning",
        html: "Please choose file template.",
      });

      return;
    }

    let token = "";
    if (
      typeof localStorage.getItem("AFIPAY02B02") !== "undefined" ||
      localStorage.getItem("AFIPAY02B02") !== "" ||
      localStorage.getItem("AFIPAY02B02") !== null
    ) {
      token = encryptDecript(localStorage.getItem("AFIPAY02B02"), 2);
    }

    const headers = {
      Authorization: "Bearer " + token,
    };

    const params = {
      chargeCode: chargeCode,
      templateReport: fileTemplate,
    };

    axios
      .get(
        process.env.REACT_APP_API_DASH +
          "/dashboard/report-transaction/download-report-charge",
        { params, headers, responseType: "blob" }
      )
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          const href = URL.createObjectURL(response.data);

          const link = document.createElement("a");
          link.href = href;
          if (response.headers["content-type"] === "application/vnd.ms-excel") {
            link.setAttribute("download", chargeCode + ".xlsx");
          } else if (response.headers["content-type"] === "text/plain") {
            link.setAttribute("download", chargeCode + ".txt");
          } else {
            link.setAttribute("download", "error.txt");
          }

          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Header
        url={process.env.REACT_APP_SUBDIR + "/sign-out-admin"}
        urlLabel="Keluar"
        title={"Dashboard"}
        confirm={true}
        icon={"ic-out"}
        confirmTitle={"Keluar?"}
        confirmDescription={"Apakah anda yakin ingin mengakhiri sesi anda?"}
        right={true}
        isAdmin={true}
        moduleAccess={moduleAccess}
        moduleName={"charge"}
      />
      <AfipayDesc title={"Report / Charge Report"} />

      <div className="container pt-4 mb-5 px-5 mt-4 dashboard-admin">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="dateFrom" className="form-label">
                    From
                  </label>
                  <DatePicker
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={new Date("1/1/1900")}
                    maxDate={new Date()}
                    className="form-control datetimestyle"
                    dateFormat="yyyy-MM-dd"
                    name="dateFrom"
                    id="dateFrom"
                    placeholderText="YYYY-MM-DD"
                    selected={dateFrom}
                    onChange={(newDate) => handleSelectDateFrom(newDate)}
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="dateTo" className="form-label">
                    To
                  </label>
                  <DatePicker
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={new Date("1/1/1900")}
                    maxDate={new Date()}
                    className="form-control datetimestyle"
                    dateFormat="yyyy-MM-dd"
                    name="dateTo"
                    id="dateTo"
                    placeholderText="YYYY-MM-DD"
                    selected={dateTo}
                    onChange={(newDate) => handleSelectDateTo(newDate)}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mx-0 justify-content-end align-items-end">
          <button
            type="submit"
            className="btn btn-block btn-primary"
            id="btnSearch"
            onClick={onSearch}
            style={{
              backgroundColor: "#00008f",
              width: "125px",
              height: "47px",
              padding: "0",
              borderRadius: "7px",
              // left: "calc(50% - 48px/2)",
              // top: "calc(50% - 20px/2 - 0.5px)",
            }}
          >
            Search <Spinner visible={loading === true} bgColor={"#fff"} />
          </button>
        </div>

        <div
          className="dashboard-table table-responsive"
          id="tableData"
          style={{ display: "none" }}
        >
          <div className="row justify-content-between pt-4 pb-1">
            <div
              className="col-lg-3"
              style={{
                paddingTop: "1.75rem",
              }}
            >
              <span>Showing</span>
              <select
                className="form-select form-select-sm ms-2 me-2"
                style={{
                  paddingRight: "2rem",
                  width: "auto",
                  height: "auto",
                  display: "inline-block",
                }}
                value={totalEntries}
                onChange={(e) => onChangeEntries(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span>Entries</span>
            </div>
            <div className="col-lg-5">
              <label className="form-label" htmlFor="fileTemplate">
                File Template
              </label>
              <select
                className="form-control custom-select"
                id="fileTemplate"
                name="fileTemplate"
                onChange={(e) => setFileTemplate(e.target.value)}
                style={{ borderRadius: "7px" }}
              >
                <option value="">Please Choose</option>
                <option value="success">LFPBCAD To K47 (Success)</option>
                <option value="no token">LFPBCAD (No Token)</option>
                <option value="failed">LFPBCAD To K47 (Failed)</option>
                <option value="old token">LFPBCAD (Old Token)</option>
                <option value="mismatch token">LFPBCAD (Mismatch Token)</option>
              </select>
            </div>
          </div>
          <div className="mt-2">
            <DataTable
              data={data}
              tableName={"charge"}
              totalEntries={totalEntries}
              onClickDownload={onClickDownload}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Charge;
