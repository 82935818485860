import React, { Component } from "react";
import clock from "../assets/images/clock-circular.svg";
import mail from "../assets/images/mail-logo.svg";
import phone from "../assets/images/phone-logo.svg";
import fb from "../assets/images/fb-icon.svg";
import ld from "../assets/images/ld-icon.svg";
import yt from "../assets/images/yt-icon.svg";
import ig from "../assets/images/ig-icon.svg";
import twt from "../assets/images/twt-icon.svg";

class Footer extends Component {
  render() {
    return (
      <div className="footer">

        <div className="footer-image-hakcipta">
          <div
            className="form-group-footer-value"
            style={{ textAlign: "center", fontSize: "16px" }}
          >
            ©2022 Hak Cipta Dilindungi.
            <br />
            PT AXA Financial Indonesia berizin dan diawasi oleh Otoritas Jasa Keuangan
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;
