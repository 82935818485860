import React from "react";
import TableUser from "./hook/TableUser.js";
import TableMenu from "./hook/TableMenu.js";
import TableRole from "./hook/TableRole.js";
import TableLog from "./hook/TableLog.js";
import TableTokenapps from "./hook/TableTokenapps.js";
import TableCharge from "./hook/TableCharge.js";
import { encryptDecript } from "../../helpers/encryptDecrypt.js";
import TableExcludeAutoDebit from "./hook/TableExcludeAutoDebit.js";
import TableRefundSplitPayment from "./hook/TableRefundSplitPayment.js";

export default class DataTable extends React.Component {
  constructor(props) {
    super(props);
    localStorage.setItem("AFIPAY01A36", encryptDecript(this.props.data, 1));
  }

  setData() {
    var data = this.props.data;
    localStorage.setItem("AFIPAY01A36", encryptDecript(data, 1));
  }

  getData() {
    var data = encryptDecript(localStorage.getItem("AFIPAY01A36"), 2);
    return this.props.data;
  }

  render() {
    if (this.props.tableName === "user") {
      return (
        <div>
          <TableUser
            data={this.getData()}
            rowsPerPage={this.props.totalEntries}
            onClickEdit={this.props.onClickEdit}
            loading={this.props.loading}
          />
        </div>
      );
    } else if (this.props.tableName === "menu") {
      return (
        <div>
          <TableMenu
            data={this.getData()}
            rowsPerPage={this.props.totalEntries}
            loading={this.props.loading}
          />
        </div>
      );
    } else if (this.props.tableName === "role") {
      return (
        <div>
          <TableRole
            data={this.getData()}
            rowsPerPage={this.props.totalEntries}
            onClickEdit={this.props.onClickEdit}
            onClickAccess={this.props.onClickAccess}
            loading={this.props.loading}
          />
        </div>
      );
    } else if (this.props.tableName === "log") {
      return (
        <div>
          <TableLog
            data={this.getData()}
            rowsPerPage={this.props.totalEntries}
            loading={this.props.loading}
          />
        </div>
      );
    } else if (this.props.tableName === "tokenapps") {
      return (
        <div>
          <TableTokenapps
            data={this.getData()}
            rowsPerPage={this.props.totalEntries}
            loading={this.props.loading}
            onChangeEntries={this.props.onChangeEntries}
            exportExcel={this.props.exportExcel}
          />
        </div>
      );
    } else if (this.props.tableName === "charge") {
      return (
        <div>
          <TableCharge
            data={this.getData()}
            rowsPerPage={this.props.totalEntries}
            loading={this.props.loading}
            onChangeEntries={this.props.onChangeEntries}
            onClickDownload={this.props.onClickDownload}
          />
        </div>
      );
    } else if (this.props.tableName === "exclude") {
      return (
        <div>
          <TableExcludeAutoDebit
            data={this.getData()}
            rowsPerPage={this.props.totalEntries}
            checkedAll={this.props.checkedAll}
            onCheckedAll={this.props.onCheckedAll}
            checkedState={this.props.checkedState}
            onChangeCheckBox={this.props.onChangeCheckBox}
            loading={this.props.loading}
            cbAllState={this.props.cbAllState}
          />
        </div>
      );
    } else if (this.props.tableName === "refund") {
      return (
        <div>
          <TableRefundSplitPayment
            data={this.getData()}
            rowsPerPage={this.props.totalEntries}
            checkedAll={this.props.checkedAll}
            onCheckedAll={this.props.onCheckedAll}
            checkedState={this.props.checkedState}
            onChangeCheckBox={this.props.onChangeCheckBox}
            loading={this.props.loading}
            cbAllState={this.props.cbAllState}
          />
        </div>
      );
    }
  }
}
