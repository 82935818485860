import { takeEvery, put, all, call, select } from "redux-saga/effects";
import { encryptDecript } from "../../helpers/encryptDecrypt";
import {
  acionTransactionFailed,
  acionTransactionSuccess,
  acionTransactionPending,
  actionCreatePaymentEnd,
  actionCreatePaymentStart,
  actionSetPaymentFailed,
  actionSetPaymentSuccess,
  actionSetQrGopay,
  actionSetUrlGopay,
  actionCreateBcaPrivateStart,
  actionCreateBcaPrivateEnd,
  actionCreateBcaPrivateSuccess,
  actionCreateBcaPrivateFailed,
  actionCreateBcaStart,
  actionCreateBcaEnd,
  actionCreateBcaFailed,
  actionCreateBcaSuccess,
} from "./actions";
import actionTypes from "./actionTypes";
import { request } from "../../utils/request";
import history from "../../utils/history";
import {
  generateTransactionId,
  validateEffectiveDt,
} from "../../utils/formatter";
import sha256 from "crypto-js/sha256";
import { setPaymentValidation } from "../auth/actions";
import { accessToken } from "../../helpers/accessToken";

function* sagaCreatePayment(params) {
  yield put(actionCreatePaymentStart());
  const { auth: { emailPembayar } } = yield select()

  const { polis, payment, hubunganPembayar, method, paymentType, kurs } =
    params?.data;
  let hasPayment = !!payment?.payment;

  if (!hasPayment) {
    let generate = !!polis;
    if (generate === true && paymentType) {
      let periode = 1;
      let premium = polis?.premium?.replace("Rp ", "")?.trim();
      // let kurs = localStorage.getItem("AFIPAY01A34") !== null ? encryptDecript(localStorage.getItem("AFIPAY01A34"), 2) : "1"

      let custom_field1 = "";
      if (method === "AutoDebit") {
        if (polis?.isPolisNB) {
          if (hubunganPembayar !== null) {
            if (hubunganPembayar?.toString() === "1") {
              custom_field1 = "renewal";
            } else {
              custom_field1 = "renewalBO";
            }
          } else {
            custom_field1 = "renewal";
          }
        } else {
          if (hubunganPembayar !== null) {
            if (hubunganPembayar?.toString() === "1") {
              custom_field1 = "switching";
            } else {
              custom_field1 = "switchingBO";
            }
          } else {
            custom_field1 = "switching";
          }
        }
      } else {
        if (polis?.isPolisNB) {
          if (paymentType === "credit_card") {
            const { dataCc } = params?.data;
            if (dataCc?.saveCard) {
              if (hubunganPembayar !== null) {
                if (hubunganPembayar?.toString() === "1") {
                  custom_field1 = "tokenization";
                } else {
                  custom_field1 = "tokenizationBO";
                }
              } else {
                custom_field1 = "tokenization";
              }
            } else {
              if (hubunganPembayar !== null) {
                if (hubunganPembayar?.toString() === "1") {
                  custom_field1 = "payment";
                } else {
                  custom_field1 = "paymentBO";
                }
              } else {
                custom_field1 = "payment";
              }
            }
          } else {
            if (hubunganPembayar !== null) {
              if (hubunganPembayar?.toString() === "1") {
                custom_field1 = "payment";
              } else {
                custom_field1 = "paymentBO";
              }
            } else {
              custom_field1 = "payment";
            }
          }
        } else {
          custom_field1 = "ptd";
        }
      }

      let paymentModeCode = 0; // default 0 (tidak ada perubahan payment)
      let custom_field3 =
        polis?.coresystem +
        "_" +
        polis?.polisnumber +
        "_" +
        paymentModeCode +
        "_AFI_" +
        periode;
      let trxRefference = generateTransactionId(polis?.polisnumber);

      let showBO = "false";
      if (
        polis?.coresystem?.toLowerCase() === "rls" &&
        hubunganPembayar != null &&
        hubunganPembayar != 0 &&
        hubunganPembayar != 1
      ) {
        showBO = "true";
      }

      let showPeriode = "true",
        showGrossAmount = "true",
        showPremiAmount = "true";
      if (method === "Pembayaran") {
        if (polis?.isPolisNB && polis?.coresystem?.toLowerCase() === "rls") {
          showPeriode = "false";
          showPremiAmount = "false";
        } else if (polis?.currency === "USD") {
          showPremiAmount = "false";
        }
      } else {
        showPeriode = "false";
        showGrossAmount = "false";
        showPremiAmount = "false";
      }

      let emailAddress = polis?.emailAddress;
      if (
        hubunganPembayar != null &&
        hubunganPembayar != 0 &&
        hubunganPembayar != 1
      ) {
        emailAddress = emailPembayar;
      }

      //Set premium
      let prem = parseFloat(premium);
      let iPremium = Math.round(prem * parseInt(periode)); // ceil premi
      if (method === "AutoDebit") {
        iPremium = 10000;
      } else {
        // set premium if USD
        let premRp = prem; // premi USD
        if (polis?.currency === "USD") {
          // convert kurs
          let floatBuyRate = parseFloat(kurs.replace(",", ""));
          premRp = premRp * floatBuyRate; // premi in Rp (decimal)
          iPremium = Math.round(premRp * parseInt(periode));
        }
      }

      //payment process
      let payload = {
        // trxRefference,
        grossAmount: iPremium,
        // customer: {
        //   firstNM: polis?.policyHolderNm,
        //   lastNM: "",
        //   emailAddress: polis?.emailAddress,
        //   phoneNumber: polis?.mobilePhoneNo,
        // },
        // items: [
        //   {
        //     id: "",
        //     name: "AFIPAY " + polis?.polisnumber,
        //     amount: iPremium,
        //     quantity: 1,
        //   },
        // ],
        paymentType,
        custom_field1,
        // custom_field2: polis?.policyHolderNm,
        // custom_field3,
      };

      let valid = false;

      if (method === "Pembayaran") {
        if (paymentType === "gopay") {
          valid = true;
          payload = {
            ...payload,
            // paymentDetails: {
            //   finish: process.env.REACT_APP_BASEREF + "/failed",
            //   bank: "BNI",
            //   secure: "true",
            //   saveCard: "true",
            //   channel: "migs",
            // },
            data: {
              is_autodebit: "false",
              is_registration: "false",
              is_parsial: "false",
              due_date:
                polis?.policyDueDt === "0" || polis?.policyDueDt === ""
                  ? ""
                  : polis?.policyDueDt,
              showBCA: "false",
              showBO: showBO,
              showGrossAmount: showGrossAmount,
              showPeriode: showPeriode,
              showPremiAmount: showPremiAmount,
              emailTo: emailAddress,
            },
          };
        } else if (paymentType === "credit_card") {
          valid = true;
          const { dataCc } = params?.data;
          payload = {
            ...payload,
            custom_field2:dataCc?.namaPemegangKartu,
            // paymentDetails: {
            //   finish: process.env.REACT_APP_BASEREF + "/failed",
            //   bank: "BNI",
            //   secure: "true",
            //   saveCard: dataCc?.saveCard ? "true" : "false",
            //   channel: "migs",
            // },
            data: {
              card_number: dataCc?.card_number,
              card_exp_month: dataCc?.card_exp_month,
              card_exp_year: dataCc?.card_exp_year,
              card_cvv: dataCc?.card_cvv,
              is_autodebit: dataCc?.saveCard ? "true" : "false",
              is_registration: "false",
              is_parsial: polis?.optSplitPayment ? "true" : "false",
              due_date:
                polis?.policyDueDt === "0" || polis?.policyDueDt === ""
                  ? ""
                  : polis?.policyDueDt,
              showBCA: "false",
              showBO: showBO,
              showGrossAmount: showGrossAmount,
              showPeriode: showPeriode,
              showPremiAmount: showPremiAmount,
              emailTo: emailAddress,
            },
          };
        } else if (["va_bca", "va_permata"].includes(paymentType)) {
          valid = true;
          payload = {
            ...payload,
            // paymentDetails: {
            //   finish: process.env.REACT_APP_BASEREF + "/failed",
            //   bank: "BNI",
            //   secure: "true",
            //   saveCard: "true",
            //   channel: "migs",
            // },
            data: {
              is_autodebit: "false",
              is_registration: "false",
              is_parsial: "false",
              due_date:
                polis?.policyDueDt === "0" || polis?.policyDueDt === ""
                  ? ""
                  : polis?.policyDueDt,
              showBCA: "false",
              showBO: showBO,
              showGrossAmount: showGrossAmount,
              showPeriode: showPeriode,
              showPremiAmount: showPremiAmount,
              emailTo: emailAddress,
            },
          };
        }
      } else if (method === "AutoDebit") {
        if (paymentType === "credit_card") {
          valid = true;
          const { dataCc } = params?.data;
          payload = {
            ...payload,
            custom_field2:dataCc?.card_owner,
            // paymentDetails: {
            //   finish: process.env.REACT_APP_BASEREF + "/failed",
            //   bank: "BNI",
            //   secure: "true",
            //   saveCard: dataCc?.saveCard ? "true" : "false",
            //   channel: "migs",
            // },
            data: {
              card_number: dataCc?.card_number,
              card_exp_month: dataCc?.card_exp_month,
              card_exp_year: dataCc?.card_exp_year,
              card_cvv: dataCc?.card_cvv,
              is_autodebit: "true",
              is_registration: "true",
              is_parsial: "false",
              due_date:
                polis?.policyDueDt === "0" || polis?.policyDueDt === ""
                  ? ""
                  : polis?.policyDueDt,
              showBCA: "false",
              showBO: showBO,
              showGrossAmount: showGrossAmount,
              showPeriode: showPeriode,
              showPremiAmount: showPremiAmount,
              emailTo: emailAddress,
            },
          };
        }
      }

      if (valid === true) {
        let sEntity = "AFI";
        if (polis?.coresystem === "DMTM") {
          sEntity = "ALI";
        }
        let headerPost = {
          "Content-Type": "application/json",
          entity: sEntity,
          target: "MIDTRANS",
          source: "AFIPAY",
          requestid: trxRefference,
          Authorization: "Bearer " + accessToken(),
        };

        try {
          const res = yield call(
            request,
            // process.env.REACT_APP_API_TRANSACTION,
            process.env.REACT_APP_API_FE + "/createTransaction",
            {
              method: "POST",
              headers: headerPost,
              body: JSON.stringify(payload),
            }
          );
          
          if (
            ["pending"].includes(res?.data?.transaction_status.toLowerCase())
          ) {
            payload = {
              ...payload,
              trxRefference: res?.data?.order_id,
            };

            if (method === "Pembayaran" && paymentType === "gopay") {
              let deepLink = null;
              let qr = null;

              let actions = res?.data?.actions?.filter(
                (val) =>
                  val?.name === "generate-qr-code" ||
                  val?.name === "deeplink-redirect"
              );

              actions?.map((val, key) => {
                if (val?.name === "deeplink-redirect") {
                  deepLink = val?.url;
                } else if (val?.name === "generate-qr-code") {
                  qr = val?.url;
                }
              });

              yield put(actionSetQrGopay(qr));
              yield put(actionSetUrlGopay(deepLink));

              let requestedAt = res?.data?.transaction_time;
              let expiredAt = res?.data?.expiry_time;

              yield put(
                actionSetPaymentSuccess({
                  payment: payload,
                  result: res?.data,
                  type: "PembayaranGopay",
                  // transactionId: res?.data?.transaction_id,
                  transactionId: res?.data?.order_id,
                  requestedAt,
                  expiredAt,
                })
              );
            } else if (
              method === "Pembayaran" &&
              paymentType === "credit_card"
            ) {
              let requestedAt = res?.data?.transaction_time;
              let expiredAt = res?.data?.expiry_time;

              yield put(
                actionSetPaymentSuccess({
                  payment: payload,
                  result: res?.data,
                  type: "PembayaranKartuKredit",
                  // transactionId: res?.data?.transaction_id,
                  transactionId: res?.data?.order_id,
                  requestedAt,
                  expiredAt,
                  message:res?.message,
                })
              );
            } else if (method === "Pembayaran" && paymentType === "va_bca") {
              let requestedAt = res?.data?.transaction_time;
              let expiredAt = res?.data?.expiry_time;

              yield put(
                actionSetPaymentSuccess({
                  payment: payload,
                  result: res?.data,
                  type: "PembayaranVaBca",
                  // transactionId: res?.data?.transaction_id,
                  transactionId: res?.data?.order_id,
                  requestedAt,
                  expiredAt,
                })
              );
            } else if (
              method === "Pembayaran" &&
              paymentType === "va_permata"
            ) {
              let requestedAt = res?.data?.transaction_time;
              let expiredAt = res?.data?.expiry_time;

              yield put(
                actionSetPaymentSuccess({
                  payment: payload,
                  result: res?.data,
                  type: "PembayaranVaPermata",
                  // transactionId: res?.data?.transaction_id,
                  transactionId: res?.data?.order_id,
                  requestedAt,
                  expiredAt,
                })
              );
            } else if (
              method === "AutoDebit" &&
              paymentType === "credit_card"
            ) {
              let requestedAt = res?.data?.transaction_time;
              let expiredAt = res?.data?.expiry_time;

              yield put(
                actionSetPaymentSuccess({
                  payment: payload,
                  result: res?.data,
                  type: "AutoDebitKartuKredit",
                  // transactionId: res?.data?.transaction_id,
                  transactionId: res?.data?.order_id,
                  requestedAt,
                  expiredAt,
                  message: res?.message
                })
              );
            }
          } else {
            yield put(actionSetPaymentFailed({message: res?.message}));
          }
        } catch (err) {
          yield put(actionSetPaymentFailed());
        }
      } else {
        yield put(actionSetPaymentFailed());
      }
    }
  }

  yield put(actionCreatePaymentEnd());
}

function* sagaInquiryPaymentStatus(params) {
  try {
    const { polis, payment } = params?.data;
    let sEntity = "AFI";
    if (polis?.coresystem === "DMTM") {
      sEntity = "ALI";
    }
    let header = {
      entity: sEntity,
      target: "MIDTRANS",
      source: "AFIPAY",
      requestid: payment?.trxRefference,
      Authorization: "Bearer " + accessToken(),
    };

    const res = yield call(
      request,
      // `${process.env.REACT_APP_API_TRANSACTION}/${payment?.trxRefference}`,
      `${process.env.REACT_APP_API_FE}/transaction/${payment?.trxRefference}`,
      {
        method: "GET",
        headers: header,
      }
    );

    // console.log({ payment })

    // console.log("status", res?.data?.transaction_status?.toLowerCase())

    if (
      payment?.paymentType === "credit_card" &&
      payment?.data?.is_registration === "true"
    ) {
      if (
        ["settlement", "success", "capture", "cancel"].includes(
          res?.data?.transaction_status?.toLowerCase()
        )
      ) {
        yield put(acionTransactionSuccess());
      } else if (
        ["failure", "deny", "expired", "expire"].includes(
          res?.data?.transaction_status?.toLowerCase()
        )
      ) {
        yield put(acionTransactionFailed({
          errMessage : res?.message,
        }));
      }
    } else {
      if (
        ["settlement", "success", "capture"].includes(
          res?.data?.transaction_status?.toLowerCase()
        )
      ) {
        yield put(acionTransactionSuccess());
      } else if (
        ["failure", "deny", "expired", "expire"].includes(
          res?.data?.transaction_status?.toLowerCase()
        )
      ) {
        
        yield put(acionTransactionFailed({
          errMessage : res?.message,
        }));
      }
    }
  } catch (err) { }
}

function* sagaCheckActivePayment(params) {
  if (params?.data?.payment?.trxRefference) {
    if (params?.data?.type === "PembayaranKartuKredit") {
      yield history.push({
        pathname: process.env.REACT_APP_SUBDIR + "/pembayaran-kartu",
      });
    } else if (
      params?.data?.type === "PembayaranVaBca" ||
      params?.data?.type === "PembayaranVaPermata"
    ) {
      yield history.push({
        pathname: process.env.REACT_APP_SUBDIR + "/pembayaran-va",
      });
    } else if (params?.data?.type === "PembayaranGopay") {
      yield history.push({
        pathname: process.env.REACT_APP_SUBDIR + "/pembayaran-gopay",
      });
    } else if (params?.data?.type === "AutoDebitBca") {
      yield history.push({
        pathname: process.env.REACT_APP_SUBDIR + "/auto-debit-bca",
      });
    } else if (params?.data?.type === "AutoDebitKartuKredit") {
      yield history.push({
        pathname: process.env.REACT_APP_SUBDIR + "/auto-debit-kartu-kredit",
      });
    } else if (params?.data?.type === "AutoDebitBcaPrivateCard") {
      yield history.push({
        pathname: process.env.REACT_APP_SUBDIR + "/auto-debit-bca-private-card",
      });
    }
  }
}

function* sagaCheckPaymentValidation(params) {
  let polis = params?.data;

  if (polis) {
    /** STATUS POLIS */
    let enablePayment = true;
    let notificationDBB = false,
      notificationLapse = false,
      notificationSinglePremi = false,
      notificationBO = false;
    let policyStatus = "";
    if (polis?.coresystem === "RLS") {
      let arrPolicyStatus = polis?.policyStatusCode.split("]");
      let sCodeActive = arrPolicyStatus[0].replace("[", "").trim();
      if (
        sCodeActive === "1" ||
        sCodeActive === "2" ||
        sCodeActive === "5" ||
        sCodeActive === "6" ||
        sCodeActive === "B" ||
        sCodeActive === "F" ||
        sCodeActive.toLowerCase() === "aktif"
      ) {
        policyStatus = "Aktif";
      } else if (
        sCodeActive === "Y" ||
        sCodeActive.toLowerCase() === "polis baru" ||
        polis?.issueDt === "0"
      ) {
        policyStatus = "Polis Baru";
      } else {
        policyStatus = "Tidak Aktif";
      }
    } else if (polis?.coresystem === "DMTM") {
      if (
        polis?.policyStatusCode === "PE" ||
        polis?.policyStatusCode.toLowerCase() === "polis baru"
      ) {
        policyStatus = "Polis Baru";
      } else if (
        polis?.policyStatusCode === "IN" ||
        polis?.policyStatusCode === "PU" ||
        polis?.policyStatusCode.toLowerCase() === "aktif"
      ) {
        policyStatus = "Aktif";
      } else {
        policyStatus = "Tidak Aktif";
      }

      //notification condition DBB
      if (polis?.paymentMethodCode.toLowerCase() === "dbb") {
        notificationDBB = true;
      }
    }

    if (
      policyStatus.trim().toLowerCase() === "tidak aktif" &&
      !polis?.isPolisNB
    ) {
      notificationLapse = true;
      enablePayment = false;
    } else if (polis?.isPaymentDisabled) {
      notificationSinglePremi = true;
      enablePayment = false;
    }

    /** FREKUENSI PEMBAYARAN */
    let frekuensiPembayaran = "";
    switch (polis?.paymentMethodCode) {
      case "A":
        frekuensiPembayaran = "Tahunan";
        break;
      case "Q":
        frekuensiPembayaran = "Triwulan";
        break;
      case "S":
        frekuensiPembayaran = "Semester";
        break;
      case "M":
        frekuensiPembayaran = "Bulanan";
        break;
      case "P":
        frekuensiPembayaran = "Sekali Bayar";
        break;
      default:
        break;
    }

    /** METODE PEMBAYARAN */
    let pembayaranPremi = true,
      pembayaranPremiGopay = true,
      autodebit = true,
      autodebitKartu = true,
      autodebitBca = true,
      autodebitSkdr = true,
      splitPaymentCC = false;
    let opsi = "Pembayaran";
    let selected = null;
    if (polis?.policyStatusCode?.toLowerCase() === "tidak aktif") {
      pembayaranPremi = false;
      opsi = null;
    } else if (polis?.coresystem === "DMTM") {
      if (polis?.isPolisNB) {
        pembayaranPremi = false;

        autodebit = true;
        autodebitKartu = false;
        autodebitBca = false;

        opsi = "AutoDebit";
        selected = "AutoDebitBca";
      } else if (polis?.paymentmethod === "dbb") {
        pembayaranPremi = false;

        autodebit = true;
        autodebitKartu = false;
        autodebitBca = false;

        opsi = "AutoDebit";
        selected = "AutoDebitBca";
      } else {
        pembayaranPremi = false;

        autodebit = true;
        autodebitKartu = false;
        autodebitBca = false;

        opsi = "AutoDebit";
        selected = "AutoDebitBca";
      }
    } else {
      if (polis?.currency === "USD") {
        pembayaranPremiGopay = false;
      }
    }

    if (polis?.isPending === true) {
      pembayaranPremi = false;
      pembayaranPremiGopay = false;
      opsi = "AutoDebit";
    }

    if (polis?.isSplitPayment === true) {
      splitPaymentCC = true;
    }

    /** PERIODE PEMBAYARAN */
    let currdate = new Date();
    let sPaymentduedate = new Date(polis?.paymentduedate);
    let Difference_In_Time = sPaymentduedate.getTime() - currdate.getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    let periode = 0;
    let isNextMonthAnniversary = validateEffectiveDt(
      polis?.paymentduedate,
      polis?.effectiveDt
    );
    let isDueDateMoreSixtyDays = false;

    let periode1 = true;
    if (polis?.isPolisNB) {
      periode = 1;
      periode1 = true;
    } else {
      if (polis?.paymentMethodCode === "M") {
        if (
          polis?.currency !== "USD" &&
          parseInt(polis?.premium.replace("Rp", "").trim()) <
          parseInt(
            polis?.premiumAmount.replace("Rp", "").replace(",", "").trim()
          )
        ) {
          periode = 1;
          periode1 = true;
        } else if (isNextMonthAnniversary) {
          periode = 1;
          periode1 = true;
        } else {
          if (Difference_In_Days <= 30) {
            periode = 1;
            periode1 = true;
          } else if (Difference_In_Days > 30 && Difference_In_Days <= 60) {
            periode = 1;
            periode1 = true;
          } else {
            periode1 = true;
            isDueDateMoreSixtyDays = true;
            enablePayment = false;
          }
        }
      } else if (
        polis?.paymentMethodCode === "A" ||
        polis?.paymentMethodCode === "Q" ||
        polis?.paymentMethodCode === "S" ||
        polis?.paymentMethodCode === "P"
      ) {
        if (
          polis?.paymentMethodCode === "A" ||
          polis?.paymentMethodCode === "Q" ||
          polis?.paymentMethodCode === "S"
        ) {
          periode = 1;
          periode1 = true;
        } else {
          if (Difference_In_Days <= 60) {
            periode = 1;
            periode1 = true;
          } else {
            periode1 = true;
            isDueDateMoreSixtyDays = true;
            enablePayment = false;
          }
        }
      } else {
        periode = 1;
        periode1 = true;
      }
    }

    let paymentValidation = {
      enablePayment,
      opsi,
      selected,
      policyStatus,
      frekuensiPembayaran,
      pembayaranPremi,
      pembayaranPremiGopay,
      autodebit,
      autodebitKartu,
      autodebitBca,
      autodebitSkdr,
      periode,
      periode1,
      isDueDateMoreSixtyDays,
      notificationDBB,
      notificationLapse,
      notificationSinglePremi,
      notificationBO,
      init: true,
      splitPaymentCC,
    };

    // console.log({ paymentValidation, polis })

    yield put(setPaymentValidation(paymentValidation));
  } else {
    yield put(
      setPaymentValidation({
        enablePayment: false,
        init: true,
      })
    );
  }
}

function* sagaCreateBcaPrivate(params) {
  yield put(actionCreateBcaPrivateStart());

  const { polis, payment, hubunganPembayar, dataPrivateCard } = params?.data;
  let hasPayment = !!payment?.payment;

  if (!hasPayment) {
    let generate = !!polis;
    if (generate === true) {
      let trxRefference = generateTransactionId(polis?.polisnumber);
      let maskedcc =
        dataPrivateCard?.card_number?.substr(0, 6) +
        "-" +
        dataPrivateCard?.card_number?.substr(
          dataPrivateCard?.card_number?.length - 4
        );
      let seYear = dataPrivateCard?.card_exp_year?.toString();
      let seMonth = dataPrivateCard?.card_exp_month?.toString();
      if (seMonth?.length === 1) {
        seMonth = "0" + seMonth;
      }
      let seTanggal = "30";
      if (
        seMonth === "01" ||
        seMonth === "03" ||
        seMonth === "05" ||
        seMonth === "07" ||
        seMonth === "08" ||
        seMonth === "10" ||
        seMonth === "12"
      ) {
        seTanggal = "31";
      } else if (seMonth === "02") {
        seTanggal = "28";
      }
      let eDate =
        "20" + seYear + "-" + seMonth + "-" + seTanggal + " 00:00:00 ";
      let transactionDT = new Date();
      transactionDT.setHours(transactionDT.getHours() + 7);
      let transDT = transactionDT.toISOString();
      let time = transDT.split(".")[0] + "Z";
      let time2 = time.replace("T", " ").split("Z")[0];

      let custom_field1 = "renewal";
      if (polis?.isPolisNB) {
        if (hubunganPembayar !== null) {
          if (hubunganPembayar === "1") {
            custom_field1 = "renewal";
          } else {
            custom_field1 = "renewalBO";
          }
        } else {
          custom_field1 = "renewal";
        }
      } else {
        if (hubunganPembayar !== null) {
          if (hubunganPembayar === "1") {
            custom_field1 = "switching";
          } else {
            custom_field1 = "switchingBO";
          }
        } else {
          custom_field1 = "switching";
        }
      }

      let paymentModeCode = 0;
      let custom_field3 =
        polis?.coresystem +
        "_" +
        polis?.polisnumber +
        "_" +
        paymentModeCode +
        "_AFI_1";

      let payload = {
        // trxRefference: trxRefference,
        // currencyCd: "IDR",
        // grossAmount: 0,
        // paymentType: "credit_card",
        paymentType: "bca_private",
        paymentDetails: {
          // bank: "bca",
          ccNo: dataPrivateCard?.card_number,
          // masked_card: maskedcc,
          // expired_date: eDate,
          // card_type: "private",
          // transactionTime: time,
          // statusMessage: "AFIPAY - Register BCA Private CC",
          saved_token_id_expired_at: eDate,
        },
        custom_field1: custom_field1,
        // custom_field2: dataPrivateCard?.card_owner,
        // custom_field3: custom_field3,
      };
      let sEntity = "AFI";
      if (polis?.coresystem === "DMTM") {
        sEntity = "ALI";
      }
      let headerPost = {
        entity: sEntity,
        target: "TokenApps",
        source: "AFIPAY",
        requestid: trxRefference,
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken(),
      };

      try {
        // const res = yield call(request, process.env.REACT_APP_API_PRIVATECARD, {
        const res = yield call(
          request,
          process.env.REACT_APP_API_FE + "/createTransaction",
          {
            method: "POST",
            headers: headerPost,
            body: JSON.stringify(payload),
          }
        );

        // let res = {
        //     "data": {
        //         "internalizedToken": "XF99E0B7098D6661"
        //     },
        //     "header": {
        //         "txnLogID": "20230201115910-219276a7-8035-4c1c-ad38-407f1dbd6763",
        //         "processingTime": 6520
        //     }
        // }

        let sJumlahPembayaran = "Rp 0";

        let sSaveCard = "Ya";
        if (
          typeof res?.data?.internalizedToken !== "undefined" ||
          typeof res?.data?.Message !== "undefined"
        ) {
          let bPassing = true;
          if (typeof res?.data?.Message !== "undefined") {
            if (res?.data.Message.indexOf("DMTM completed") < 0) {
              bPassing = false;
            }
          }
          if (bPassing) {
            sSaveCard = "Ya";
            let sAsterik1 = "*";
            let sAsterik1Note =
              "*Perubahan data hanya berlaku untuk Polis yang didaftarkan.";
            localStorage.setItem("AFIPAY01A25", encryptDecript(sAsterik1, 1)); // ndak dipakai
            localStorage.setItem(
              "AFIPAY01A26",
              encryptDecript(sAsterik1Note, 1)
            ); // ndak dipakai
          }else{
            sSaveCard = "Tidak";
          }
        }

        // save to local storage
        localStorage.setItem(
          "AFIPAY01A13",
          encryptDecript(sJumlahPembayaran, 1)
        ); // dipakai
        localStorage.setItem("AFIPAY01A14", encryptDecript(time2, 1)); // dipakai
        localStorage.setItem("AFIPAY01A15", encryptDecript(maskedcc, 1)); // dipakai
        localStorage.setItem("AFIPAY01A16", encryptDecript(sSaveCard, 1)); // dipakai
        localStorage.setItem("AFIPAY01A28", encryptDecript("bca", 1)); // dipakai

        if (sSaveCard.toLowerCase() === "ya") {
          let requestedAt = parseInt(new Date().getTime() / 1000);
          // let expiredAt = res?.data?.expiry_time

          yield put(
            actionCreateBcaPrivateSuccess({
              payment: payload,
              result: res?.data,
              type: "AutoDebitBcaPrivateCard",
              transactionId: null,
              requestedAt: requestedAt,
              expiredAt: null,
            })
          );
          yield put(acionTransactionSuccess());
        } else {
          yield put(actionCreateBcaPrivateFailed());
        }
      } catch (err) {
        yield put(actionCreateBcaPrivateFailed());
      }
    }
  }

  yield put(actionCreateBcaPrivateEnd());
}

function* sagaCreateBca(params) {
  yield put(actionCreateBcaStart());

  const { polis, payment, dataBca } = params?.data;
  let hasPayment = !!payment?.payment;

  if (!hasPayment) {
    let trxRefference = generateTransactionId(polis?.polisnumber);
    let customfield1 = polis?.polisnumber?.replace(/-/g, "");
    let custom_field3 = "V";
    let gAmount = 0;
    if (custom_field3 === "V") {
      gAmount = 0;
    }

    let payload = {
      // trxRefference: trxRefference,
      // grossAmount: gAmount,
      customer: {
        firstNM: dataBca?.card_owner,
        accountNumber: dataBca?.card_number,
        // phoneNumber: polis?.mobilePhoneNo,
      },
      paymentType: "bca_debit",
      // paymentDetails: {
      //   bank: "bca",
      //   channel: "95051",
      //   finish: process.env.REACT_APP_IMGELOGOBLUE,
      // },
      // custom_field1: customfield1,
      custom_field2: process.env.REACT_APP_CRED_ESKDRBCA, // non prod: 00010446
      // custom_field3: custom_field3,
      // coreSystem: polis?.coresystem,
    };

    let headerPost = {
      entity: "AFI",
      target: "TokenApps",
      source: "AFIPAY",
      requestid: trxRefference,
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken(),
    };

    try {
      // const res = yield call(request, process.env.REACT_APP_API_SKDRBCA, {
      const res = yield call(
        request,
        process.env.REACT_APP_API_FE + "/createTransaction",
        {
          method: "POST",
          headers: headerPost,
          body: JSON.stringify(payload),
        }
      );

      // let res = {
      //     "data": {
      //         "coreSystem": "RLS",
      //         "trxRefference": "PAYIF_3701010000834_167299119257",
      //         "custom_field1": "3703080000761",
      //         "custom_field3": "V",
      //         "custom_field2": "00010446",
      //         "grossAmount": "0",
      //         "paymentDetails": {
      //             "bank": "BCA",
      //             "finish": "https://pay.axa.co.id/assets/images/logo-axa.svg",
      //             "channel": "95051",
      //             "transactionTime": "1675227699028",
      //             "signature_key": "RZa0RueQt2KGy7ua",
      //             "save_token_id": "AF39D57EB3EDA7024E05349022B0A80F1",
      //             "saved_token_id_expired_at": "1675228599028"
      //         },
      //         "customer": {
      //             "firstNM": "Fulan John"
      //         },
      //         "paymentType": "bca_debit"
      //     },
      //     "header": {
      //         "txnLogID": "20230201120138-885f9f0e-c5c1-4518-888e-7b8ac67c4a78",
      //         "processingTime": 36292
      //     }
      // }

      if (
        res?.data?.status !== null &&
        typeof res?.data?.status !== "undefined"
      ) {
        yield put(actionCreateBcaFailed());
      } else {
        let regID = res?.data?.paymentDetails.save_token_id;
        let regIDmin = regID.substring(1);
        let randStr = res?.data?.paymentDetails.signature_key;
        let merchID = res?.data?.custom_field2;
        let gab = regIDmin + merchID + randStr;
        let verif = sha256(gab);
        let url =
          process.env.REACT_APP_URL_ESKDRBCA + regID + "&verification=" + verif;

        yield put(
          actionCreateBcaSuccess({
            payment: payload,
            result: {
              ...res?.data,
              url: url,
            },
            type: "AutoDebitBca",
            transactionId: null,
            requestedAt: res?.data?.paymentDetails?.transactionTime,
            expiredAt: res?.data?.paymentDetails?.saved_token_id_expired_at,
          })
        );
        yield put(acionTransactionSuccess());
      }
    } catch (err) {
      yield put(actionCreateBcaFailed());
    }
  }

  yield put(actionCreateBcaEnd());
}

function* paymentSaga() {
  yield all([
    takeEvery(actionTypes.CREATE_PAYMENT, sagaCreatePayment),
    takeEvery(actionTypes.INQUIRY_PAYMENT_STATUS, sagaInquiryPaymentStatus),
    takeEvery(actionTypes.CHECK_ACTIVE_PAYMENT, sagaCheckActivePayment),
    takeEvery(actionTypes.CREATE_BCA_PRIVATE, sagaCreateBcaPrivate),
    takeEvery(actionTypes.CREATE_BCA, sagaCreateBca),
    takeEvery(actionTypes.CHECK_PAYMENT_VALIDATION, sagaCheckPaymentValidation),
  ]);
}

export default paymentSaga;
