import Immutable from 'seamless-immutable'

const REQUESTING_START = 'REQUESTING_START'
const REQUESTING_END = 'REQUESTING_END'

const SET_VERIFICATION_POLIS = 'SET_VERIFICATION_POLIS'
const SET_VERIFICATION_POLIS_SUCCESS = 'SET_VERIFICATION_POLIS_SUCCESS'
const SET_VERIFICATION_POLIS_FAILED = 'SET_VERIFICATION_POLIS_FAILED'
const GENERATE = 'GENERATE'
const GENERATE_SUCCESS = 'GENERATE_SUCCESS'
const GENERATE_FAILED = 'GENERATE_FAILED'
const VERIFY = 'VERIFY'
const VERIFY_SUCCESS = 'VERIFY_SUCCESS'
const VERIFY_FAILED = 'VERIFY_FAILED'
const VERIFY_EXPIRED = 'VERIFY_EXPIRED'
const LIMIT = 'LIMIT'
const CANCEL = 'CANCEL'
const CANCEL_SUCCESS = 'CANCEL_SUCCESS'
const RESET_ERROR = 'RESET_ERROR'

const actionTypes = Immutable({
  REQUESTING_START,
  REQUESTING_END,

  SET_VERIFICATION_POLIS,
  SET_VERIFICATION_POLIS_SUCCESS,
  SET_VERIFICATION_POLIS_FAILED,
  GENERATE,
  GENERATE_SUCCESS,
  GENERATE_FAILED,
  VERIFY,
  VERIFY_SUCCESS,
  VERIFY_FAILED,
  VERIFY_EXPIRED,
  LIMIT,
  CANCEL,
  CANCEL_SUCCESS,
  RESET_ERROR,
})

export default actionTypes
