import React, { useEffect, useState } from "react";
import "./dashboard.css";
import axios from "axios";
import DataTable from "./DataTable";
import { encryptDecript } from "../../helpers/encryptDecrypt.js";
import Header from "../../components/Layout/AuthLayout/Header";
import AfipayDesc from "../../components/Layout/AdminLayout/AfipayDesc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import ModalCustom from "./ModalCustom";
import { alert } from "../../utils/alert";

const Role = (props) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [moduleAccess, setModuleAccess] = useState([]);
  const [data, setData] = useState([]);
  const [formRole, setFormRoleValues] = useState({
    id: "",
    role_name: "",
    role_description: "",
    module: [],
  });
  const [listError, setListError] = useState([]);
  const [totalEntries, setTotalEntries] = useState(10);
  const [modalTitle, setModalTitle] = useState("");
  const [modalAction, setModalAction] = useState("");
  const [module, setModule] = useState([]);
  const [rolename, setRolename] = useState("");
  const [checkedState, setCheckedState] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [hasPrivilege, setHasPrivilege] = useState(false);

  useEffect(() => {
    if (
      typeof localStorage.getItem("AFIPAY02B01") !== "undefined" ||
      localStorage.getItem("AFIPAY02B01") !== ""
    ) {
      setModuleAccess(encryptDecript(localStorage.getItem("AFIPAY02B01"), 2));
      getListData();
      getListModule();
    }
  }, []);

  useEffect(() => {
    getListPrivilege();
  }, [data]);

  const onSearch = (e) => {
    e.preventDefault();

    setData([]);
    getListData();
  };

  const getListData = () => {
    setLoading(true);
    let token = "";
    if (
      typeof localStorage.getItem("AFIPAY02B02") !== "undefined" ||
      localStorage.getItem("AFIPAY02B02") !== "" ||
      localStorage.getItem("AFIPAY02B02") !== null
    ) {
      token = encryptDecript(localStorage.getItem("AFIPAY02B02"), 2);
    }

    const headers = {
      Authorization: "Bearer " + token,
    };

    const params = {
      search: search,
      page: page,
      size: 999999,
      sortBy: "id",
      sortType: "ASC",
    };

    axios
      .get(process.env.REACT_APP_API_DASH + "/role/get-all", {
        params,
        headers,
      })
      .then(async (response) => {
        if (response?.data?.data?.Message === "Forbidden") {
          setData([]);
          setLoading(false);
          await alert({
            title: "Forbidden",
            html: "You don`t have access to read data or your access has been revoked in this menu",
          });
        } else {
          setData(response?.data?.data?._items);
          setLoading(false);
          setHasPrivilege(true);
          localStorage.setItem(
            "AFIPAY01A36",
            encryptDecript(response?.data?.data?._items, 1)
          );
        }
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await alert({
            title: "Your session has ended",
            html: "Please login again",
          });

          props.history.push(process.env.REACT_APP_SUBDIR + "/sign-out-admin");
        }
        setLoading(false);
      });
  };

  const getListModule = () => {
    let token = "";
    if (
      typeof localStorage.getItem("AFIPAY02B02") !== "undefined" ||
      localStorage.getItem("AFIPAY02B02") !== "" ||
      localStorage.getItem("AFIPAY02B02") !== null
    ) {
      token = encryptDecript(localStorage.getItem("AFIPAY02B02"), 2);
    }

    const headers = {
      Authorization: "Bearer " + token,
    };

    const params = {
      search: "",
      page: 1,
      size: 999999,
      sortBy: "id",
      sortType: "ASC",
    };

    axios
      .get(process.env.REACT_APP_API_DASH + "/module/get-all", {
        params,
        headers,
      })
      .then(async (response) => {
        if (response?.data?.data?.Message === "Forbidden") {
          setModule([]);
          setCheckedState([]);
        } else {
          setModule(response.data.data._items);
          setCheckedState(
            new Array(response.data.data._items.length).fill(false)
          );
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getListPrivilege = () => {
    data?.map((item, key) => {
      let token = "";
      if (
        typeof localStorage.getItem("AFIPAY02B02") !== "undefined" ||
        localStorage.getItem("AFIPAY02B02") !== "" ||
        localStorage.getItem("AFIPAY02B02") !== null
      ) {
        token = encryptDecript(localStorage.getItem("AFIPAY02B02"), 2);
      }

      const headers = {
        Authorization: "Bearer " + token,
      };

      axios
        .get(process.env.REACT_APP_API_DASH + "/role/get-by-id/" + item.id, {
          headers,
        })
        .then((response) => {
          data[key].module = response.data.data.module;
        })
        .catch((error) => {
          setLoading(false);
        });
    });
  };

  const closeModal = () => {
    let modalCloseBtn = document.getElementById("closeModal");

    if (modalCloseBtn) {
      modalCloseBtn.click();
    }
  };

  const postData = async (e) => {
    e.preventDefault();

    let valid = true;
    let _error = listError;

    if (formRole.role_name === "" || formRole.role_name === null) {
      _error["role_name"] = "Name field is required.";
      valid = false;
    } else {
      _error["role_name"] = "";
    }

    if (
      formRole.role_description === "" ||
      formRole.role_description === null
    ) {
      _error["role_description"] = "Description field is required.";
      valid = false;
    } else {
      _error["role_description"] = "";
    }

    if (modalAction === "formAccess") {
      if (formRole.module.length === 0) {
        _error["role_access"] =
          "Role access must have checked at least 1 access.";
        valid = false;
      } else {
        _error["role_access"] = "";
      }
    }

    setListError({
      ..._error,
    });

    if (valid) {
      setLoadingBtn(true);
      let token = "";
      if (
        typeof localStorage.getItem("AFIPAY02B02") !== "undefined" ||
        localStorage.getItem("AFIPAY02B02") !== "" ||
        localStorage.getItem("AFIPAY02B02") !== null
      ) {
        token = encryptDecript(localStorage.getItem("AFIPAY02B02"), 2);
      }

      const headers = {
        Authorization: "Bearer " + token,
      };

      const params = {
        id: formRole.id,
        role_name: formRole.role_name,
        role_description: formRole.role_description,
        module: formRole.module,
      };

      let url = process.env.REACT_APP_API_DASH + "/role/create";
      if (formRole?.id !== "") {
        url = process.env.REACT_APP_API_DASH + "/role/update";
      }

      setTimeout(() => {
        axios
          .post(url, params, {
            headers,
          })
          .then(async (response) => {
            if (response.status === 200) {
              let title = "Success";
              let html = "Data saved successfully.";
              if (response?.data?.data?.status === "FAILED") {
                if (response?.data?.data?.Message === "Forbidden") {
                  title = "Forbidden";
                  html =
                    "You don`t have access to read data or your access has been revoked in this menu";
                } else {
                  title = "Failed";
                  html = "Failed to save data.";
                }
              }
              const resultAlert = await alert({
                title: title,
                html: html,
              });
              if (title === "Success") {
                if (resultAlert === true) {
                  closeModal();
                  clearFormState();
                  getListData();
                }
              }
              setLoadingBtn(false);
            }
          })
          .catch(async (error) => {
            await alert({
              title: "Failed to save data.",
              html: "Error : " + error,
            });

            setLoadingBtn(false);
          });
      }, 1000);
    }
  };

  const onChange = (e, name) => {
    let _data = formRole;

    _data[name] = e?.target?.value;

    setFormRoleValues({
      ..._data,
    });

    let _error = listError;

    if (e?.target?.value === "" || e?.target?.value === null) {
      if (name === "role_name") {
        _error[name] = "Name field is required.";
      }

      if (name === "role_description") {
        _error[name] = "Description field is required.";
      }
    } else {
      _error[name] = "";
    }

    setListError({
      ..._error,
    });
  };

  const onClickEdit = (val) => {
    setLoading(false);

    setModalTitle("Edit Role");
    setModalAction("formRole");

    clearErrorState();
    setFormRoleValues(val);
    setFormRoleValues((previousState) => {
      return { ...previousState, val };
    });
  };

  const onClickAdd = (e) => {
    e.preventDefault();

    setModalTitle("Add Role");
    setModalAction("formRole");

    setLoading(false);
    clearFormState();
    clearErrorState();
  };

  const onClickAccess = (val) => {
    setLoading(false);

    let arrModule = [];
    let objModule = {};
    val?.module?.map((item) => {
      objModule = {
        id: item.id,
      };
      arrModule.push(objModule);
    });

    let moduleMap = [];
    module.map((item) => {
      let res = val?.module?.find((x) => x.id === item.id) ? true : false;
      moduleMap.push(res);
    });
    setCheckedState(moduleMap);

    const fillForm = {
      id: val.id,
      role_name: val.role_name,
      role_description: val.role_description,
      module: arrModule,
    };

    setModalTitle("Edit Access");
    setModalAction("formAccess");
    setRolename(val.role_name);
    clearFormState();
    setFormRoleValues(fillForm);
    setFormRoleValues((previousState) => {
      return { ...previousState, fillForm };
    });
  };

  const clearFormState = () => {
    setFormRoleValues({
      id: "",
      role_name: "",
      role_description: "",
      module: [],
    });
  };

  const clearErrorState = () => {
    setListError([]);
  };

  const onChangeEntries = (val) => {
    setTotalEntries(val);
    getListData();
  };

  const onChangeCheckBox = (e, index) => {
    const updatedCheckedState = checkedState?.map((item, key) =>
      key === index ? !item : item
    );

    setCheckedState(updatedCheckedState);

    let _data = formRole;

    const isChecked = e?.target?.checked;
    const value = parseInt(e?.target?.value);

    if (isChecked) {
      let res = _data["module"].find((x) => x.id === value) ? true : false;
      if (!res) {
        _data["module"].push({ id: value });
      }
    } else {
      let res = _data["module"].find((x) => x.id === value) ? true : false;
      if (res) {
        _data["module"] = _data["module"].filter((x) => x.id !== value);
      }
    }
  };

  return (
    <React.Fragment>
      <Header
        url={process.env.REACT_APP_SUBDIR + "/sign-out-admin"}
        urlLabel="Keluar"
        title={"Dashboard"}
        confirm={true}
        icon={"ic-out"}
        confirmTitle={"Keluar?"}
        confirmDescription={"Apakah anda yakin ingin mengakhiri sesi anda?"}
        right={true}
        isAdmin={true}
        moduleAccess={moduleAccess}
        moduleName={"roles"}
      />
      <AfipayDesc title={"User Management / Role"} />

      <div className="container pt-4 mb-5 px-5 mt-4 dashboard-admin">
        <div className="row justify-content-between">
          <div className="col-lg-4">
            <span>Showing</span>
            <select
              className="form-select form-select-sm ms-2 me-2"
              style={{
                paddingRight: "2rem",
                width: "auto",
                height: "auto",
                display: "inline-block",
              }}
              value={totalEntries}
              onChange={(e) => onChangeEntries(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span>Entries</span>
          </div>
          <div className="col-lg-4">
            <div className="input-group mb-3">
              <input
                type="text"
                name="search"
                id="search"
                placeholder=""
                className="form-control"
                onChange={(e) => setSearch(e.target.value)}
                style={{
                  borderRadius: "7px",
                  height: "35px",
                }}
              />
              <div className="input-group-append">
                <button
                  type="submit"
                  className="btn btn-block btn-primary"
                  id="btnSearch"
                  onClick={onSearch}
                  style={{
                    borderRadius: "7px",
                    width: "35px",
                    height: "35px",
                    padding: "0",
                  }}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </div>
              <div className="px-2"></div>
              <button
                className="btn btn-sm btn-primary"
                style={{
                  backgroundColor: "#00008f",
                  width: "75px",
                  height: "35px",
                  padding: "0",
                  borderRadius: "7px",
                }}
                href="."
                onClick={(e) => onClickAdd(e)}
                data-bs-toggle="modal"
                data-bs-target="#modalRole"
                disabled={!hasPrivilege}
              >
                Add Role
              </button>
            </div>
          </div>
        </div>
        <DataTable
          data={data}
          tableName={"role"}
          onClickEdit={onClickEdit}
          onClickAccess={onClickAccess}
          totalEntries={totalEntries}
          loading={loading}
        />
      </div>

      <ModalCustom
        id={"modalRole"}
        action={modalAction}
        title={modalTitle}
        model={formRole}
        onSubmit={postData}
        onChange={onChange}
        errors={listError}
        loadingBtn={loadingBtn}
        listModule={module}
        rolename={rolename}
        onChangeCheckBox={onChangeCheckBox}
        checkedState={checkedState}
      />
    </React.Fragment>
  );
};
export default Role;
