import React, { useState } from "react"
import IcSiku from "../../assets/new/img/icons/ic-siku.svg"

const CaraPembayaran = ({ title, data, show = true }) => {
    const [isShow, setIsShow] = useState(show)
    const [isFlip, setIsFlip] = useState(!show)

    const rotateToggle = () => {
        setIsShow(!isShow)
        setIsFlip(!isFlip)
    }

    return <React.Fragment>
        <div className="card">
            <div className="card-header accordion-item">
                <span className="fw-semibold">{title || "-"}</span>
                <span className={`force-block collapse-trigger ${isFlip === true ? 'flip-2' : ''}`} data-bs-toggle="collapse" aria-expanded="true"
                    aria-controls="collapseExample" href="#collapseCard" role="button"
                    onClick={() => rotateToggle()}
                >
                    <img src={IcSiku} width="100%" alt="siku" />
                </span>
            </div>
            {
                data ? <div className={`card-body accordion-collapse text-start collapse ${isShow === true ? 'show' : ''}`}>
                    <div className="accordion-body">
                        <ol style={{ paddingLeft: "1.3rem" }}>
                            {
                                data.map((val, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <li className="card-text-data">{val}</li>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </ol>
                    </div>
                </div> : null
            }
        </div>
    </React.Fragment>
}

export default CaraPembayaran