import React from "react"
import ScrollToTop from "../../components/ScrollToTop"

const RedirectGopay = ({ timeLeft = 0 }) => { 
    return <React.Fragment>
        <ScrollToTop />
        <div className="container redirect text-center pt-4 pb-5 mb-5">
            {
                timeLeft > 0 ?
                    <div className="redirect-timer">
                        <span>{timeLeft}</span>
                        <span>Seconds</span>
                    </div>
                    : null
            }
            <p className="px-4">Halaman ini secara otomatis akan membuka akun gopay Anda...</p>
        </div>
    </React.Fragment>
}

export default RedirectGopay