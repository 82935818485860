import React, { useEffect, useRef, useState } from "react"
import Header from "../components/Layout/AuthLayout/Header/index.js"
import AfipayDesc from "../components/Layout/AuthLayout/AfipayDesc/index.js"
import ImgBca from "../assets/new/img/logo-bca-lg.png"
import IcCopy from "../assets/new/img/icons/ic-copy.svg"
import CaraPembayaran from "./PembayaranVa/CaraPembayaran.js"
import { asCurrency, asTime, dateTime } from "../utils/formatter.js"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import toast, { Toaster } from 'react-hot-toast'
import { connect } from 'react-redux'
import { Link, withRouter } from "react-router-dom"
import { actionCreatePayment, actionResetPayment, actionSetLastSuccess, actionSetLastFailed, actionInquiryPaymentStatus } from "../store/payment/actions.js"
import Preloader from "../components/Preloader/index.js"

const PembayaranVaBca = (props) => {
    const data = props?.history?.location?.data || null


    const handleCopy = () => {
        toast('Berhasil disalin', {
            duration: 1000,
            position: 'bottom-left',
        })
    }

    const detailPembayaran = () => {
        return <React.Fragment>

            <div className="container pembayaran-kartu text-center pt-4 pb-5">

                <div className="card p-2">
                    <div className="card-header text-start d-flex">
                        <div style={{ width: "80%" }}>
                            <p className="fw-semibold mb-2 color-dark"><small>Virtual Akun BCA</small></p>
                        </div>
                        <div style={{ width: "20%" }}>
                            <img src={ImgBca} width="100%" alt="bca" />
                        </div>
                    </div>
                    <div className="card-body text-start d-flex">
                        <div style={{ width: "91%" }}>
                            <p className="card-text-label">Nomor Virtual Account</p>
                            <p className="card-text-data">15159{data?.polisnumber.replace('-','')}</p>
                            <p className="card-text-label">Total Pembayaran</p>
                            <p className="card-text-data">Rp {asCurrency(parseInt(data?.premium))}</p>
                        </div>
                        <div className="p-1" style={{ width: "9%" }}>
                            <CopyToClipboard onCopy={() => handleCopy()} text={'15159'+data?.polisnumber.replace('-','')}>
                                <a href="." onClick={(e) => e.preventDefault()}>
                                    <img src={IcCopy} style={{ width: "100%", maxWidth: "20px", float: "right" }} alt="copy" />
                                </a>
                            </CopyToClipboard>
                            
                            <CopyToClipboard onCopy={() => handleCopy()} text={data?.premium}>
                                
                                <a href="." onClick={(e) => e.preventDefault()}>
                                    <img src={IcCopy} style={{ width: "100%", maxWidth: "20px", float: "right", marginTop: "2rem" }} alt="copy" />
                                </a>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>

                <div>
                    <br />
                    <p className="fw-semibold">Cara pembayaran</p>
                    <CaraPembayaran title={"ATM BCA"}
                        data={[
                            <span>Masukkan kartu ATM dan PIN BCA Anda</span>,
                            <span>Pilih menu <b>Trasaksi Lainnya – Transfer – Ke Rekening BCA Virtual Account</b></span>,
                            <span>Masukkan nomor Virtual Account diatas sebagai rekening tujuan</span>,
                            <span>Periksa nominal yang tertera</span>,
                            <span>Ikuti instruksi untuk menyelesaikan transaksi</span>
                        ]}
                    />
                    <br />
                    <CaraPembayaran title={"Klik BCA"} show={false}
                        data={[
                            <span>Masuk ke website <b>KLIK BCA</b></span>,
                            <span>Pilih menu <b>Transfer Dana - Transfer ke BCA Virtual Account</b></span>,
                            <span>Masukkan nomor Virtual Account diatas sebagai rekening tujuan</span>,
                            <span>Periksa nominal yang tertera</span>,
                            <span>Ikuti instruksi untuk menyelesaikan transaksi</span>
                        ]} />
                    <br />
                    <CaraPembayaran title={"m-BCA (BCA mobile)"} show={false}
                        data={[
                            <span>Masuk ke aplikasi mobile <b>m-BCA</b></span>,
                            <span>Pilih menu <b>m-Transfer – Ke Rekening BCA Virtual Account</b></span>,
                            <span>Masukkan nomor Virtual Account diatas sebagai rekening tujuan</span>,
                            <span>Periksa nominal yang tertera</span>,
                            <span>Masukkan <b>PIN m-BCA</b> Anda</span>,
                            <span>Ikuti instruksi untuk menyelesaikan transaksi</span>
                        ]} />
                </div>


            </div>

        </React.Fragment>
    }

    const onClickBack = async () => {
        actionResetPayment()
        props.history.replace({
            pathname: (process.env.REACT_APP_SUBDIR + "/beranda")
        })
    }


    return <React.Fragment>
        <Header onClick={() => onClickBack()} urlLabel="Kembali" title={"Pembayaran"} confirm={true} confirmTitle={"Kembali"} confirmDescription={"Batalkan pembayaran?"} homeConfirm={true} />


        <div style={{ minHeight: "30vh" }}>
            <Preloader visible={false} />
            {
                detailPembayaran()
            }

        </div>
        <AfipayDesc />
        <Toaster />
    </React.Fragment>
}

const mapStateToProps = state => {
    return {
        polis: state.auth?.polis,
        hubunganPembayar: state.auth?.hubunganPembayar,
        payment: state.payment,
        buyRate: state.currency?.buyRate
    }
}

export default withRouter(connect(mapStateToProps, { actionCreatePayment, actionResetPayment, actionInquiryPaymentStatus, actionSetLastSuccess, actionSetLastFailed })(PembayaranVaBca))