import React, { useEffect, useRef, useState } from "react"
import SnK from "./SnK"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEye, faEyeSlash
} from "@fortawesome/free-solid-svg-icons";

const PembayaranKartuKredit = ({ selected = false, namaPemegangKartu, nomorKartuKredit, bulanBerlakuKartuKredit, tahunBerlakuKartuKredit, cvvKartuKredit, tncKartuKredit, agreeKartuKredit, setNomorKartuKredit, setBulanBerlakuKartuKredit, setTahunBerlakuKartuKredit, setCvvKartuKredit, setTncKartuKredit, setAgreeKartuKredit, saveKartuKredit, setSaveKartuKredit, errorNomorkartuKredit = null, errorBulanBerlakuKartuKredit = null, errorTahunBerlakuKartuKredit = null, setTrueTncKartuKredit, errorCvvKartuKredit = null, isSinglePremium = false, ccAgreement, setCcAgreement, setNamaPemegangKartu, errorNamaPemegangKartu }) => {
    const [show, setShow] = useState("v-hidden")
    const [tncClicked, seTncClicked] = useState(false)
    const refTnc = useRef(null)
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(faEyeSlash);

    useEffect(() => {
        if (selected === true) {
            setShow("v-hidden collapse-animate-lg")

            setTimeout(() => {
                setShow("collapse-animate-lg")
            }, 500)
        } else {
            setShow("v-hidden")
        }
    }, [selected])

    const handleToggle = () => {
        if (type === 'password') {
            setIcon(faEye);
            setType('text')
        } else {
            setIcon(faEyeSlash)
            setType('password')
        }
    }

    const onChangeTnc = () => {
        if (tncClicked === false) {
            refTnc.current.click()
        } else {
            setTncKartuKredit()
        }
    }

    const acceptTnc = () => {
        setTncKartuKredit()
        seTncClicked(true)
    }

    return <React.Fragment>
        <div className={"data-pembayaran " + show}>
            <div className="mb-3">
                <label htmlFor="holderName" className="form-label">Nama Pemegang Kartu</label>
                <input type="text" className="form-control" id="holderName"
                    placeholder="Nama Pemegang Kartu" value={namaPemegangKartu} onChange={setNamaPemegangKartu} maxLength={"50"} />

                {
                    errorNamaPemegangKartu ?
                        <p className="form-tip form-alert"><i>{errorNamaPemegangKartu}</i></p>
                        : null
                }
            </div>

            <div className="mb-3">
                <label htmlFor="cardNumber" className="form-label">Nomor Kartu</label>
                <input type="text" className="form-control" id="cardNumber" placeholder="Contoh : 1234 5678 9012 4152" value={nomorKartuKredit}
                    onChange={setNomorKartuKredit} onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }} />

                {
                    errorNomorkartuKredit ?
                        <p className="form-tip form-alert"><i>{errorNomorkartuKredit}</i></p>
                        : null
                }
            </div>
            <div className="d-flex flex-row gap-3">
                <div className={errorBulanBerlakuKartuKredit || errorTahunBerlakuKartuKredit || errorCvvKartuKredit ? "" : "mb-3"} style={{ width: "40%" }}>
                    <label htmlFor="" className="form-label">Masa Berlaku</label>
                    <div className="card-input">
                        <input type="text" className="form-control me-3" id="mmCard" placeholder="mm" value={bulanBerlakuKartuKredit} onChange={setBulanBerlakuKartuKredit} maxLength="2" onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }} />
                        <input type="text" className="form-control" id="yyCard" placeholder="yy" value={tahunBerlakuKartuKredit} onChange={setTahunBerlakuKartuKredit} maxLength="2" onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }} />
                    </div>
                </div>
                <div className={errorBulanBerlakuKartuKredit || errorTahunBerlakuKartuKredit || errorCvvKartuKredit ? "" : "mb-3"} style={{ width: "60%" }}>
                    <label htmlFor="cvv" className="form-label">CVV</label>
                    <div className="card-input">
                        <input type={type} className="form-control" id="cvv"
                            placeholder="Contoh : 123" maxLength="4" value={cvvKartuKredit} onChange={setCvvKartuKredit} style={{ width: "80%" }} onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                        <div>
                            <button
                                type="submit"
                                className="btn btn-block form-control"
                                onClick={handleToggle}
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    padding: "0",
                                }}
                            >
                                <FontAwesomeIcon icon={icon} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {
                errorBulanBerlakuKartuKredit || errorTahunBerlakuKartuKredit || errorCvvKartuKredit
                    ? <div className="mb-3">
                        {errorBulanBerlakuKartuKredit ? <p className="form-tip form-alert"><i>{errorBulanBerlakuKartuKredit}</i></p> : null}
                        {errorTahunBerlakuKartuKredit ? <p className="form-tip form-alert"><i>{errorTahunBerlakuKartuKredit}</i></p> : null}
                        {errorCvvKartuKredit ? <p className="form-tip form-alert"><i>{errorCvvKartuKredit}</i></p> : null}
                    </div> : null
            }

            <div className="warning-box">
                <p>
                    Jaga Kerahasiaan data pribadi Anda, Jangan berikan data pribadi Anda
                    ke pihak lain.
                </p>
            </div>

            <div className="grey-box ketentuan-pembayaran">
                <div className="form-check mb-2">
                    <label className="form-check-label" htmlFor="agreeCard">
                        <small>
                            Apakah kartu ini akan didaftarkan untuk pembayaran premi lanjutan?
                        </small>
                    </label>
                </div>

                <input className="form-check-input ms-4" type="radio" value="ya" onChange={setCcAgreement} checked={ccAgreement === "ya"} />
                <label className="ms-1">Ya</label>
                <input className="form-check-input ms-2" type="radio" value="tidak" onChange={setCcAgreement} checked={ccAgreement === "tidak"} />
                <label className="ms-1">Tidak</label>
                <div className="ps-3 mb-2">
                    <label className="form-check-label" htmlFor="agreeCard">
                        <small>
                            <strong>Catatan</strong> :
                        </small>
                    </label>
                </div>
                <ol className="ps-3">
                    <li>
                    Apabila Anda mendaftarkan kartu ini, maka Anda menyetujui informasi kartu akan disimpan untuk transaksi selanjutnya.
                    </li>
                    <li>
                    Pastikan sinyal Anda dalam kondisi yang stabil untuk dapat melanjutkan transaksi.
                    </li>
                    <li>
                    Pastikan kartu debit/kredit yang digunakan sudah mendukung 3D Secure. Informasi lebih lanjut dapat menghubungi Bank Penerbit Kartu Anda.
                    </li>
                    <li>
                    Pastikan pulsa Anda mencukupi supaya dapat menerima OTP (One Time Password) dari Bank Penerbit Kartu.
                    </li>
                </ol>

                <div className="form-check mt-2">
                    <input className="form-check-input" type="checkbox" value="tncCard" onChange={setTncKartuKredit} checked={tncKartuKredit === true} />
                    <label className="form-check-label" htmlFor="tncCard">
                        <small>
                            Saya setuju dan mengerti terhadap {" "}
                            <a ref={refTnc} href="." onClick={(e) => e.preventDefault()} data-bs-toggle="modal"
                                data-bs-target="#modalTncKartu">Syarat dan Ketentuan</a>
                        </small>
                    </label>
                </div>
            </div>

        </div>

        { /** <SnK id={"modalTncKartu"} acceptTnc={tncClicked ? null : acceptTnc} /> */}

        <SnK id={"modalTncKartu"} acceptTnc={setTrueTncKartuKredit} opsi="Pembayaran" />
    </React.Fragment>
}

export default PembayaranKartuKredit