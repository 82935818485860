import React, { Component } from "react";
import "./PopupEmail.css";
import imgKetentuan from "../assets/images/ketentuan-image.svg";

class PopupEmailFailed extends Component {
  hidePopup(e) {
    document.getElementById("PopupEmailFailed").style.display = "none";
    window.location.href = process.env.REACT_APP_BASEREF + "/success";
  }

  render() {
    return (
      <div className="modal" id="PopupEmailFailed">
        <div className="container-popup-email">
          <div className="img">
            <img src={imgKetentuan} alt="ketentuan-image" />
          </div>
          <div className="desc">
            Bukti pembayaran premi Anda gagal dikirimkan ke email yang telah
            didaftarkan. Mohon periksa kembali alamat email Anda.
          </div>
          <div className="form-group">
            <button
              className="btn btn-block btn-primary"
              onClick={this.hidePopup}
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default PopupEmailFailed;
