export function encryptDecript(sTempText, iTask) {
    // if (!sTempText) {
    //     return
    // }
     
    var CryptoJS = require("crypto-js");
    var sPassword = process.env.REACT_APP_ENC_KEY;
    var encryptDecryptText = "";
    //var sIv = process.env.REACT_APP_ENC_IV;

    if (iTask === 1) {
        encryptDecryptText = CryptoJS.AES.encrypt(JSON.stringify(sTempText), sPassword).toString();
    } else if (iTask === 2) {
        var bytes = CryptoJS.AES.decrypt(sTempText, sPassword);
        encryptDecryptText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }

    return encryptDecryptText;
}