import React, { Fragment, useEffect, useState } from "react"
import Header from "../components/Layout/AuthLayout/Header"
import AfipayDesc from "../components/Layout/AuthLayout/AfipayDesc"
import { asTime } from "../utils/formatter"
import RedirectGopay from "./PembayaranGopay/RedirectGopay"
import { isMobile } from "../utils/device"
import { Link, withRouter } from "react-router-dom"
import { connect } from 'react-redux'
import { actionCreatePayment, actionResetPayment, actionInquiryPaymentStatus, actionSetLastSuccess, actionSetLastFailed } from "../store/payment/actions"
import Preloader from "../components/Preloader/index.js"
import ImgFailed from "../assets/new/img/illustration-confirmation-failed.svg"
import CaraPembayaran from "./PembayaranGopay/CaraPembayaran"

const PembayaranGopay = (props) => {
    const { polis, payment, hubunganPembayar, actionCreatePayment, actionResetPayment, actionInquiryPaymentStatus, actionSetLastSuccess, actionSetLastFailed, buyRate } = props

    const [redirectGopay, setRedirectGopay] = useState(true)
    const [sisaWaktuPembayaran, setSiswaWaktuPembayaran] = useState(0)
    const [waktuMulai, setWaktuMulai] = useState(false)
    const [sisaWaktuRedirect, setSiswaWaktuRedirect] = useState(null)
    const [countCheck, setCountCheck] = useState(0)
    const [submit, setSubmit] = useState(false)

    useEffect(() => {
        createPayment()
    }, [])

    const createPayment = () => {
        actionCreatePayment({ polis, payment, hubunganPembayar, method: "Pembayaran", paymentType: "gopay", kurs: buyRate })
    }

    useEffect(() => {
        if (submit === true && payment?.success === true) {
            setRedirectGopay(true)
        }
        setSubmit(true)
    }, [payment?.success])

    useEffect(() => {
        if (payment?.transaction_success === true) {
            setCountCheck(-1)
            actionResetPayment() 
            actionSetLastSuccess({
                transactionId: payment?.payment?.trxRefference,
                noPolis: polis?.polisnumber,
                totalPembayaran: payment?.result?.gross_amount,
                tanggalTransaksi: payment?.result?.transaction_time,
                jenisTransaksi: "Pembayaran Premi",
                metodePembayaran: "Gopay",
                namaLengkap: polis?.policyHolderNm,
                noHp: polis?.mobilePhoneNo,
                lanjutAutoDebet: false,
                notified: false
            })
            props.history.replace({ pathname: process.env.REACT_APP_SUBDIR + "/pembayaran-berhasil" })
        } else if (payment?.transaction_success === false) {
            setCountCheck(-1)
            actionResetPayment()
            actionSetLastFailed({
                transactionId: payment?.payment?.trxRefference
            })
            props.history.replace({ pathname: process.env.REACT_APP_SUBDIR + "/pembayaran-gagal" })
        }
    }, [payment?.transaction_success])

    useEffect(() => {
        if (payment?.transactionId) {
            actionInquiryPaymentStatus({
                polis: payment?.polis,
                payment: payment?.payment,
                transactionID: payment?.transactionId
            })
            setCountCheck(1)
        }
    }, [payment?.transactionId])

    useEffect(() => {
        if (countCheck <= 0) return

        const intervalId = setInterval(() => {
            setCountCheck(countCheck + 1)
            actionInquiryPaymentStatus({
                polis: payment?.polis,
                payment: payment?.payment,
                transactionID: payment?.transactionId
            })
        }, 5000)

        return () => clearInterval(intervalId)
    }, [countCheck])

    useEffect(() => {
        let now = parseInt((new Date).getTime() / 1000)
        let sisaWaktu = payment?.expiredAt - now
        if (sisaWaktu > 0) {
            setWaktuMulai(true)
            setSiswaWaktuRedirect(3)
            setSiswaWaktuPembayaran(sisaWaktu)
        }
    }, [payment?.expiredAt])

    useEffect(() => {
        if (sisaWaktuPembayaran === null) return
        if (!sisaWaktuPembayaran) return

        const intervalId = setInterval(() => {
            setSiswaWaktuPembayaran(sisaWaktuPembayaran - 1)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [sisaWaktuPembayaran])

    useEffect(() => {
        if (waktuMulai === true) {
            if (sisaWaktuPembayaran < 1) {
                actionResetPayment()
                actionSetLastFailed({
                    transactionId: payment?.payment?.trxRefference
                })
                props.history.replace({ pathname: process.env.REACT_APP_SUBDIR + "/pembayaran-gagal" })
            }
        }
    }, [waktuMulai, sisaWaktuPembayaran])

    useEffect(() => {
        if (redirectGopay === false) return
        if (!sisaWaktuRedirect) return

        const intervalId = setInterval(() => {
            setSiswaWaktuRedirect(sisaWaktuRedirect - 1)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [sisaWaktuRedirect, redirectGopay])

    useEffect(() => {
        if (sisaWaktuRedirect !== null && sisaWaktuRedirect < 3) {
            setRedirectGopay(false)
            if (isMobile && payment?.urlGopay) {
                window.open(payment?.urlGopay, "_blank")
            }
        }
    }, [sisaWaktuRedirect, payment?.urlGopay])

    const detailPembayaran = () => {
        return <React.Fragment>
            {
                sisaWaktuPembayaran > 0 ?
                    <div className="container pembayaran text-center pt-4 pb-5 mb-5">
                        <div className="d-grid col-lg-9 mx-auto">
                            <div className="subtitle-page fw-semibold pt-2 mb-1">
                                Selesaikan Pembayaran Anda
                            </div>
                            <p className="countdown mb-4">Bayar dalam <b className="highlight-text-red-plain" id="countdownPayment">{asTime(sisaWaktuPembayaran)}</b>
                            </p>
                            <div className="card mb-4 d-none d-md-block">
                                <div className="card-body">
                                    <img src={payment?.qrGopay} alt="qr-pembayaran" style={{ width: "100%" }} />
                                </div>
                            </div>
                            <p className="fw-semibold">Cara pembayaran</p>

                            <CaraPembayaran title={"GOPAY"} />

                            <div className="inline-button pt-4 d-md-none">
                                <a href={payment?.urlGopay} type="button" className="btn btn-primary col mb-2" target={"_blank"} rel={"noreferrer"}>Buka Gopay</a>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </React.Fragment>
    }

    const onClickBack = async () => {
        actionResetPayment()
        props.history.replace({
            pathname: submit === true && payment?.success === true ? (process.env.REACT_APP_SUBDIR + "/beranda") : (process.env.REACT_APP_SUBDIR + "/metode-pembayaran")
        })
    }

    const paymentFailedScreen = () => {
        return <Fragment>
            <div className="container metode-pembayaran konfirmasi text-center pt-4 mb-5">
                <img className="pt-4 pb-3" src={ImgFailed} alt="confirmation" />
                <div className="subtitle-page fw-bold pt-2 mb-2" style={{color:"red",fontSize:"20px"}}>
                    Upps, Pembayaran Gagal Dibuat
                </div>
                <p style={{ marginBottom: "2rem" }} className="px-3"> Silakan coba lagi metode pembayaran Anda.
                </p>
                <div className="inline-button pt-4">
                    <button onClick={() => onClickBack()} type="button" className="btn btn-primary col mb-2">Ulangi Pembayaran</button>
                    <Link to={process.env.REACT_APP_SUBDIR + "/sign-out"} type="button" className="btn btn-outline-danger col mb-2">Keluar</Link>
                </div>
            </div>
        </Fragment>
    }

    return <React.Fragment>
        <Header onClick={() => onClickBack()} urlLabel="Kembali" title={redirectGopay === true ? "Redirect Gopay" : "Pembayaran"} confirm={submit === true && payment?.success === true} confirmTitle={"Kembali"} confirmDescription={"Batalkan pembayaran?"} homeConfirm={submit === true && payment?.success === true} />
        

        <div style={{ minHeight: "30vh" }}>
            <Preloader visible={payment?.loading === true} />
            {
                submit === true && payment?.loading === false ?
                    submit === true && payment?.success === false ?
                        paymentFailedScreen()
                        :
                        redirectGopay === true ? <RedirectGopay timeLeft={sisaWaktuRedirect} /> : detailPembayaran()
                    : null
            }
        </div>
        <AfipayDesc />
    </React.Fragment>
}

const mapStateToProps = state => {
    return {
        polis: state.auth?.polis,
        hubunganPembayar: state.auth?.hubunganPembayar,
        payment: state.payment,
        buyRate: state.currency?.buyRate
    }
}

export default withRouter(connect(mapStateToProps, { actionCreatePayment, actionResetPayment, actionInquiryPaymentStatus, actionSetLastSuccess, actionSetLastFailed })(PembayaranGopay))