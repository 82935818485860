import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

const SignOutAdmin = (props) => {
    useEffect(() => {
        localStorage.clear()
        props.history.replace(process.env.REACT_APP_SUBDIR + '/admin')
    }, [])

    return (null)
}

export default withRouter(SignOutAdmin)
