import React, { useEffect, useState } from "react";
import "./dashboard.css";
import axios from "axios";
import DataTable from "./DataTable";
import { encryptDecript } from "../../helpers/encryptDecrypt.js";
import DatePicker from "react-datepicker";
import "./../../components/Form/DatePicker/index.scss";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../components/Layout/AuthLayout/Header";
import AfipayDesc from "../../components/Layout/AdminLayout/AfipayDesc";
import Spinner from "../../components/Spinner";
import * as XLSX from "xlsx";
import { alert } from "../../utils/alert";

const TokenApps = (props) => {
  const [polisNumber, setPolisNumber] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [transType, setTransType] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [moduleAccess, setModuleAccess] = useState([]);
  const [totalEntries, setTotalEntries] = useState(10);

  useEffect(() => {
    if (
      typeof localStorage.getItem("AFIPAY02B01") !== "undefined" ||
      localStorage.getItem("AFIPAY02B01") !== ""
    ) {
      setModuleAccess(encryptDecript(localStorage.getItem("AFIPAY02B01"), 2));
    }
  }, []);

  const handleSelectDateFrom = (tempDate) => {
    let minDate = new Date("1/1/1900");
    let bUpdatedDate = true;
    if (tempDate < minDate) {
      bUpdatedDate = false;
    }
    if (bUpdatedDate) {
      setDateFrom(tempDate);
    }
  };

  const handleSelectDateTo = (tempDate) => {
    let minDate = new Date("1/1/1900");
    let bUpdatedDate = true;
    if (tempDate < minDate) {
      bUpdatedDate = false;
    }
    if (bUpdatedDate) {
      setDateTo(tempDate);
    }
  };

  const formatDate = (e) => {
    var date = new Date(e),
      month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    let ret = [year, month, day].join("-");

    return ret.toString();
  };

  const onSearch = (e) => {
    e.preventDefault();

    setData([]);
    getListData();
  };

  const getListData = () => {
    setLoading(true);
    let token = "";
    if (
      typeof localStorage.getItem("AFIPAY02B02") !== "undefined" ||
      localStorage.getItem("AFIPAY02B02") !== "" ||
      localStorage.getItem("AFIPAY02B02") !== null
    ) {
      token = encryptDecript(localStorage.getItem("AFIPAY02B02"), 2);
    }

    const headers = {
      Authorization: "Bearer " + token,
    };

    // format date
    let startdate = dateFrom;
    let enddate = dateTo;

    if (
      startdate !== null ||
      enddate !== null ||
      startdate !== "" ||
      enddate !== ""
    ) {
      startdate = formatDate(startdate);
      enddate = formatDate(enddate);

      if (
        startdate === "NaN-NaN-NaN" ||
        enddate === "NaN-NaN-NaN" ||
        startdate === "1970-01-01" ||
        enddate === "1970-01-01"
      ) {
        startdate = "";
        enddate = "";
      }
    }

    const params = {
      dateFrom: startdate,
      dateTo: enddate,
      policyNo: polisNumber,
      transType: transType,
      cardType: paymentType,
      entity: "AFI",
    };

    axios
      .get(
        process.env.REACT_APP_API_DASH +
          "/dashboard/report-transaction/token-apps-report",
        { params, headers }
      )
      .then(async (response) => {
        if (response?.data?.data?.Message === "Forbidden") {
          setData([]);
          setLoading(false);
          await alert({
            title: "Forbidden",
            html: "You don`t have access to read data or your access has been revoked in this menu",
          });
        } else {
          setData(response?.data?.data);
          setLoading(false);
          localStorage.setItem(
            "AFIPAY01A36",
            encryptDecript(response?.data?.data, 1)
          );
          document.getElementById("tableData").style.display = "block";
          document.getElementById("masterTable").style.display = "block";
        }
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await alert({
            title: "Your session has ended",
            html: "Please login again",
          });

          props.history.push(process.env.REACT_APP_SUBDIR + "/sign-out-admin");
        }
        setLoading(false);
      });
  };

  const onChangeEntries = (val) => {
    setTotalEntries(val);
    getListData();
  };

  const exportExcel = (data) => {
    // data.forEach((item) => {
    //   delete item.detailTransactionType;
    // });

    const workheader = [
      [
        "Transaction Date",
        "Policy Number",
        "Owner Name",
        "Source",
        "Order ID",
        "Internal Token",
        "External Token",
        "Card Holder Name",
        "Masked Card",
        "Bank Name",
        "Expired Date",
        "Card Type",
        "Transaction Type",
        "Status Type",
        "Amount Transaction",
        "Beneficiary Owner",
        "System Type",
        "Status",
        "Detail Transaction Type",
      ],
    ];

    // const worksheet = XLSX.utils.json_to_sheet(data);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, workheader);
    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: "A2",
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "TokenApps Report (TBC).xlsx");
  };

  return (
    <React.Fragment>
      <Header
        url={process.env.REACT_APP_SUBDIR + "/sign-out-admin"}
        urlLabel="Keluar"
        title={"Dashboard"}
        confirm={true}
        icon={"ic-out"}
        confirmTitle={"Keluar?"}
        confirmDescription={"Apakah anda yakin ingin mengakhiri sesi anda?"}
        right={true}
        isAdmin={true}
        moduleAccess={moduleAccess}
        moduleName={"tokenapps"}
      />
      <AfipayDesc title={"Report / TokenApps Report"} />

      <div className="container pt-4 mb-5 px-5 mt-4 dashboard-admin">
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="dateFrom" className="form-label">
                    From
                  </label>
                  <DatePicker
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={new Date("1/1/1900")}
                    maxDate={new Date()}
                    className="form-control datetimestyle"
                    dateFormat="yyyy-MM-dd"
                    name="dateFrom"
                    id="dateFrom"
                    placeholderText="YYYY-MM-DD"
                    selected={dateFrom}
                    onChange={(newDate) => handleSelectDateFrom(newDate)}
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="dateTo" className="form-label">
                    To
                  </label>
                  <DatePicker
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={new Date("1/1/1900")}
                    maxDate={new Date()}
                    className="form-control datetimestyle"
                    dateFormat="yyyy-MM-dd"
                    name="dateTo"
                    id="dateTo"
                    placeholderText="YYYY-MM-DD"
                    selected={dateTo}
                    onChange={(newDate) => handleSelectDateTo(newDate)}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <label className="form-label" htmlFor="paymentType">
              Payment Type
            </label>
            <select
              className="form-control form-select"
              id="paymentType"
              name="paymentType"
              onChange={(e) => setPaymentType(e.target.value)}
              style={{ borderRadius: "7px" }}
              defaultValue={"0"}
            >
              <option value="0" disabled>
                Please Choose
              </option>
              <option value="">All</option>
              <option value="credit">Visa/Master/JCB/AMEX</option>
              <option value="private">Private Card</option>
              <option value="others">Saving Account</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <label className="form-label" htmlFor="transType">
              Transaction Type
            </label>
            <select
              className="form-control form-select"
              id="transType"
              name="transType"
              onChange={(e) => setTransType(e.target.value)}
              style={{ borderRadius: "7px" }}
              defaultValue={"0"}
            >
              <option value="0" disabled>
                Please Choose
              </option>
              <option value="">All</option>
              <option value="payment">Payment</option>
              <option value="renewal">Renewal</option>
              <option value="tokenization">Tokenization</option>
              <option value="switching">Switching</option>
              <option value="ptd">Ptd</option>
            </select>
          </div>
          <div className="col-lg-6">
            <div className="mb-3">
              <label htmlFor="polisNumber" className="form-label">
                Policy Number
              </label>
              <input
                type="text"
                name="polisNumber"
                id="polisNumber"
                placeholder="123456789"
                className="form-control"
                onChange={(e) => setPolisNumber(e.target.value)}
                style={{ borderRadius: "7px" }}
              />
            </div>
          </div>
        </div>

        <div className="row mx-0 justify-content-end align-items-end">
          <button
            type="submit"
            className="btn btn-block btn-primary"
            id="btnSearch"
            onClick={onSearch}
            style={{
              backgroundColor: "#00008f",
              width: "125px",
              height: "47px",
              padding: "0",
              borderRadius: "7px",
              // left: "calc(50% - 48px/2)",
              // top: "calc(50% - 20px/2 - 0.5px)",
            }}
          >
            Search <Spinner visible={loading === true} bgColor={"#fff"} />
          </button>
        </div>

        <DataTable
          data={data}
          tableName={"tokenapps"}
          totalEntries={totalEntries}
          loading={loading}
          onChangeEntries={onChangeEntries}
          exportExcel={exportExcel}
        />
      </div>
    </React.Fragment>
  );
};

export default TokenApps;
