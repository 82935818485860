import Immutable from 'seamless-immutable';
import actionTypes from './actionTypes';

let model = {
  polis: null,
  paymentValidation: null,
  pemegangPolis: true,
  downloadDbo: false,
  downloadSkdkk: false,
  showProduct: true,
  showPolicyHolder: true,
  showInsured: true,
  hubunganPembayar: null,
  emailPembayar: null,
  expiredAt: null
}
export const INITIAL_STATE = Immutable(model);

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.SET_POLIS:
      return { ...state, polis: action.data }
    case actionTypes.SET_DBO_DOWNLOAD:
      return { ...state, downloadDbo: action.data }
    case actionTypes.SET_SKDKK_DOWNLOAD:
      return { ...state, downloadSkdkk: action.data }
    case actionTypes.SET_SHOW_PRODUCT:
      return { ...state, showProduct: action.data }
    case actionTypes.SET_SHOW_POLICY_HOLDER:
      return { ...state, showPolicyHolder: action.data }
    case actionTypes.SET_SHOW_INSURED:
      return { ...state, showInsured: action.data }
    case actionTypes.SET_HUBUNGAN_PEMBAYAR:
      return { ...state, hubunganPembayar: action.data }
    case actionTypes.SET_EMAIL_PEMBAYAR:
      return { ...state, emailPembayar: action.data }
    case actionTypes.SET_PEMEGANG_POLIS:
      return { ...state, pemegangPolis: action.data }
    case actionTypes.SET_PAYMENT_VALIDATION:
      return { ...state, paymentValidation: action.data }
    case actionTypes.SET_EXPIRED:
      return { ...state, expiredAt: action.data }
    case actionTypes.RESET_FIELD:
      return {
        ...state,
        pemegangPolis: true,
        downloadDbo: false,
        downloadSkdkk: false,
        showProduct: true,
        showPolicyHolder: true,
        showInsured: true,
        hubunganPembayar: null,
        emailPembayar: null
      }
    case actionTypes.LOGOUT:
      return { ...model }
    default:
      return state;
  }
}

export default reducer;
