import React from "react"

const TextInput = ({ type = "text", id = null, name, value, className = "", error = null, hint = null, placeholder = null, label = null, onChange }) => {
    return <React.Fragment>
        <div className="form-group fw-bold">
            {label && <label className="form-label">{label}</label>}
            <input
                type={type}
                id={id}
                className={'form-control ' + (error ? "border-danger" : "") + className}
                placeholder={placeholder}
                aria-label={label}
                aria-describedby={label}
                name={name}
                value={value}
                onChange={onChange}
            />
            {hint && <p className="form-tip"><i>{hint}</i></p>}
            {error && <p className="form-tip form-alert"><i>{error}</i></p>}
        </div>
    </React.Fragment>
}

export default TextInput