import React, { useEffect } from "react"
import Header from "../components/Layout/AuthLayout/Header"
import ImgFailed from "../assets/new/img/illustration-confirmation-failed.svg"
import { Link } from "react-router-dom"
import { withRouter } from "react-router-dom"
import { connect } from 'react-redux'

const PembayaranGagal = (props) => {
    const { data } = props

    useEffect(() => {
        if (data === null) {
            props.history.replace({
                pathname: process.env.REACT_APP_SUBDIR + "/beranda"
            })
        }
    }, [data])

    return <React.Fragment>
        <Header url={process.env.REACT_APP_SUBDIR + '/beranda'} urlLabel="Kembali" title={"Konfirmasi"} button={true} />

        <div className="container metode-pembayaran konfirmasi text-center pt-4 mb-5">
            <img className="pt-4 pb-3" src={ImgFailed} alt="confirmation" />
            <div className="subtitle-page fw-bold pt-2 mb-2" style={{color:"red",fontSize:"20px"}}>
                Upps, Pembayaran Gagal
            </div>

            <div className="warning-box">
                <p>
                    {data?.message !== null ? data?.message : "Transaksi Anda gagal"}
                </p>
            </div>
            <p style={{ marginBottom: "2rem" }} className="px-3"> Maaf Pembayaran Premi Anda Belum Berhasil,
                Silakan coba lagi metode pembayaran Anda.
            </p>
            <ul className="gap-2 ps-0 konfirmasi-detail text-center" style={{ listStyle: "none" }}>
                <li>
                    <span className="text-grey">Transaksi id</span>
                    <p className="color-dark">{data?.transactionId}</p>
                </li>
            </ul>
            <div className="inline-button pt-4">
                <Link to={process.env.REACT_APP_SUBDIR + "/metode-pembayaran"} type="button" className="btn btn-primary col mb-2">Ulangi Pembayaran</Link>
                <Link to={process.env.REACT_APP_SUBDIR + "/sign-out"} type="button" className="btn btn-outline-danger col mb-2">Keluar</Link>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = (state) => {
    return {
        data: state.payment?.lastFailed
    }
}

export default withRouter(connect(mapStateToProps, null)(PembayaranGagal))