import React, { Component } from "react";
import Cookies from "js-cookie";
import { encryptDecript } from "../helpers/encryptDecrypt.js";
import Header from "../components/Layout/AuthLayout/Header";
import AfipayDesc from "../components/Layout/AuthLayout/AfipayDesc";
import { Link } from "react-router-dom";
import PolisDetailView from "./Beranda/PolisDetailView";
import { asDate, hubunganPemilikPolis } from "../utils/formatter.js";
import TextInput from "../components/Form/TextInput/index.js";
import DropDown from "../components/Form/DropDown/index.js";
import Spinner from "../components/Spinner/index.js";
import { alert } from "../utils/alert.js";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  actionSetHubunganPembayar,
  actionSetEmailPembayar,
  actionSetDboDownload,
  actionSetPemegangPolis,
  actionSetShowProduct,
  actionSetShowPolicyHolder,
  actionSetShowInsured,
} from "../store/auth/actions";
import { actionCheckActivePayment } from "../store/payment/actions";
import "./beranda.css";

const optionHubungan = [
  // { value: '', label: "Hubungan Dengan Pemegang Polis" },
  // { value: 1, label: "Diri Sendiri" },
  { value: 2, label: "Suami/Istri" },
  { value: 3, label: "Orang Tua" },
  { value: 4, label: "Anak" },
  { value: 5, label: "Wali yang sah" },
];

function changeMoneyDecimal(x) {
  const premArray = x.split(".");
  let premi = premArray[0];
  premi = premi.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (premArray.length > 1) {
    premi = premi + "," + premArray[1];
  } else {
    premi = premi + ",00";
  }
  return premi;
}

class Beranda extends Component {
  constructor() {
    super();
    this.state = {
      polisNumber: "",
      dateOfBirth: null,
      polisStatus: "",
      frekuensiPembayaran: "",
      jumlahPremi: "",
      jumlahPremiAmount: "0",
      emailNasabah: "",
      isPolisNB: false,
      coreSystem: "",
      paymentduedate: null,
      paymentmethod: "",
      dueDate: null,
      namapemegang: "",
      namaproduct: "",
      currency: "",
      effectiveDt: "",
      isPaymentDisabled: false,
      jumlahPremiAmountDec: "0",

      paymentMethodCode: "",

      namatertanggung: "",
      emailtertanggung: "",
      jeniskelamintertanggung: "",
      nomorhptertanggung: "",
      tanggallahirtertanggung: "",
      tanggallahirpemegang: "",
      nomorhppemegang: "",
      jeniskelaminpemegang: "",
      policyDueDt: "",
      billingChannel: "",
      bankName: "",

      hubungan: "",
      insuredRelation: "",
      changePemegangPolis: false,
      hubunganObj: null,
      emailPemegangPremi: "",
      hubunganDisabled: false,
      sudahDownload: false,
      harusDownload: false,
      submit: false,

      errors: {},
      csserrors: {},
      cssfield: {},
      disclaimers: {},

      pemegangPolis: null,
      premi: 0,

      btnLanjut: false,
      enablePayment: true,
      enableRegist: false,
      notificationLapse: false,
      notificationSinglePremi: false,
      disclaimerInfo: false,
      notificationBO: false,
      notificationDBB: false,
      loading: false,
      init: false,

      detailHeight: "inherit",
      isRegistration: false,
      isWaitingSubmit: false,
      isPolicyPending: false,
      isPolicyNb: false,
      polisStatusDesc: "-",
      polisStatusDescLong: "",
    };
    this.onChang = this.onChang.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.pemegangPolisRef = React.createRef();
    this.formRef = React.createRef();
    this.dataProdukRef = React.createRef();
    this.dataPemegangRef = React.createRef();
    this.dataTertanggungRef = React.createRef();
  }

  signOut() {
    // localStorage.clear()
    // this.props.history.push({
    //   pathname: process.env.REACT_APP_SUBDIR + "/",
    // })
  }

  checkSize() {
    if (window.innerWidth > window.innerHeight) {
      let numArray = [
        this.dataProdukRef.current?.clientHeight,
        this.dataTertanggungRef.current?.clientHeight,
        this.dataPemegangRef.current?.clientHeight,
      ];
      numArray.sort().reverse();
      this.setState({
        detailHeight: numArray.length > 0 ? numArray[0] + "px" : 280,
      });
    } else {
      this.setState({ detailHeight: "inherit" });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkSize.bind(this));
  }

  setLocalStorage = (data) => {
    //define variable
    let sPolisNumber = "";
    let oDOB = null;
    let bPolisNB = "";
    let sPremium = "";
    let spremiumAmount = "";
    let sEmailAddress = "";
    let sPolicyStatusCode = "";
    let sPaymentModeCode = "";
    let sFrekuensiPembayaran = "";
    let sPolicyStatus = "";
    let sCoreSystem = "";
    let sHubungan = "";
    let sPaymentDueDate = "";
    let sPaymentMethod = "";
    let sDueDate = "";
    let sIssueDt = "";
    let sEffectiveDT = "";
    let sPolicyHolderNm = "";
    let sProductNm = "";
    let sCurrency = "";
    let bisPaymentDisabled = false;
    let spremiumAmountDec = "";

    // Get variable from data
    if (data !== null && typeof data !== "undefined") {
      sPolisNumber = data.polisnumber;
      oDOB = new Date(data.dateofbirth);
      bPolisNB = data.isPolisNB;
      sPolicyHolderNm = data.policyHolderNm;
      sProductNm = data.productNm;
      sCurrency = data.currency;

      if (sCurrency === "USD") {
        sPremium = "US$ " + this.changeMoney(data.premium);
        spremiumAmount = "US$ " + this.changeMoney(data.premiumAmount);
      } else {
        sPremium = "Rp " + this.changeMoney(data.premium);
        spremiumAmount = "Rp " + this.changeMoney(data.premiumAmount);
      }

      spremiumAmountDec = data.premiumAmount;
      sEmailAddress = data.emailAddress;
      sPolicyStatusCode = data.policyStatusCode;
      sPaymentModeCode = data.paymentMethodCode;
      sCoreSystem = data.coresystem;
      sPaymentDueDate = data.paymentduedate;
      sPaymentMethod = data.paymentmethod;
      sDueDate = sDueDate =
        sPaymentDueDate.slice(3, 6) +
        sPaymentDueDate.slice(0, 3) +
        sPaymentDueDate.slice(6, 10);
      sIssueDt = data.issueDt;
      sEffectiveDT = data.effectiveDt;
      bisPaymentDisabled = data.isPaymentDisabled;

      //set cookies expired time
      const expires = 10 * 60 * 1000;
      const expiredTime = new Date(new Date().getTime() + expires);

      //save data in cookies and local storage
      Cookies.set("AFIPAY01A01", encryptDecript(sPolisNumber, 1), {
        expires: expiredTime,
        httpOnly: true,
        secure: true,
        sameSite: "none",
      });
      localStorage.setItem("AFIPAY01A01", encryptDecript(sPolisNumber, 1));
      localStorage.setItem("AFIPAY01A02", encryptDecript(oDOB.toString(), 1));
      localStorage.setItem(
        "AFIPAY01A03",
        encryptDecript(bPolisNB.toString(), 1)
      );
      localStorage.setItem("AFIPAY01A04", encryptDecript(sEmailAddress, 1));
      localStorage.setItem(
        "AFIPAY01A05",
        encryptDecript(this.changeMoney(data.premium), 1)
      );
      localStorage.setItem(
        "AFIPAY01A35",
        encryptDecript(this.changeMoney(data.premiumAmount), 1)
      );
      localStorage.setItem(
        "AFIPAY01A38",
        encryptDecript(data.premiumAmount, 1)
      );

      localStorage.setItem("AFIPAY01A06", encryptDecript(sPolicyStatusCode, 1));
      localStorage.setItem("AFIPAY01A07", encryptDecript(sPaymentModeCode, 1));
      localStorage.setItem("AFIPAY01A08", encryptDecript(sCoreSystem, 1));
      localStorage.setItem("AFIPAY01A09", encryptDecript(sHubungan, 1));
      localStorage.setItem("AFIPAY01A12", encryptDecript(sPaymentDueDate, 1));
      localStorage.setItem("AFIPAY01A27", encryptDecript(sPaymentMethod, 1));
      localStorage.setItem("AFIPAY01A30", encryptDecript(sIssueDt, 1));
      localStorage.setItem("AFIPAY01A29", encryptDecript(sEffectiveDT, 1));
      localStorage.setItem("AFIPAY01A31", encryptDecript(sPolicyHolderNm, 1));
      localStorage.setItem("AFIPAY01A32", encryptDecript(sProductNm, 1));
      localStorage.setItem("AFIPAY01A33", encryptDecript(sCurrency, 1));
      localStorage.setItem(
        "AFIPAY01A37",
        encryptDecript(bisPaymentDisabled, 1)
      );
    }
  };

  componentDidMount() {
    this.checkSize();
    window.addEventListener("resize", this.checkSize.bind(this));

    this.props.actionCheckActivePayment(this.props.payment);
    // const { data } = this.props.location
    const data = this.props?.auth?.polis;
    const auth = this.props?.auth;

    this.setLocalStorage(data);

    //define variable
    let sPolisNumber = "";
    let oDOB = null;
    let bPolisNB = "";
    let sPremium = "";
    let spremiumAmount = "";
    let sEmailAddress = "";
    let sPolicyStatusCode = "";
    let sPaymentModeCode = "";
    let sFrekuensiPembayaran = "";
    let sPolicyStatus = "";
    let sCoreSystem = "";
    let sHubungan = "";
    let sPaymentDueDate = "";
    let sPaymentMethod = "";
    let sDueDate = "";
    let sIssueDt = "";
    let sEffectiveDT = "";
    let sPolicyHolderNm = "";
    let sProductNm = "";
    let sCurrency = "";
    let bisPaymentDisabled = false;
    let spremiumAmountDec = "";

    // Nama Tertanggung
    let sInsuredNm = "";
    // Email Tertanggung
    let sInsuredEmail = "";
    // No HP Tertanggung
    let sInsuredPhone = "";
    // Gender Tertanggung
    let sInsuredGender = "";
    // DoB Tertanggung
    let sInsuredDOB = "";
    // Tanggal Lahir Pemegang
    let sBirthDT = "";
    // No HP Pemegang
    let sMobilePhoneNo = "";
    // Gender Tertanggung
    let sGender = "";
    // Tanggal Jatuh Tempo
    let sPolicyDueDt = "";

    // Get variable from data
    if (data !== null && typeof data !== "undefined") {
      sPolisNumber = data.polisnumber;
      oDOB = new Date(data.dateofbirth);
      bPolisNB = data.isPolisNB;
      sPolicyHolderNm = data.policyHolderNm;
      sProductNm = data.productNm;
      sCurrency = data.currency;

      sInsuredNm = data.insuredNm;
      sInsuredEmail = data.insuredEmail;
      sInsuredPhone = data.insuredPhone;
      sInsuredGender = data.insuredGender;
      sInsuredDOB = data.insuredDOB;
      sBirthDT = data.birthDT;
      sMobilePhoneNo = data.mobilePhoneNo;
      sGender = data.gender;
      sPolicyDueDt = data.policyDueDt;

      if (sCurrency === "USD") {
        sPremium = "US$ " + this.changeMoney(data.premium);
        spremiumAmount = "US$ " + this.changeMoney(data.premiumAmount);
      } else {
        sPremium = "Rp " + this.changeMoney(data.premium);
        spremiumAmount = "Rp " + this.changeMoney(data.premiumAmount);
      }

      spremiumAmountDec = data.premiumAmount;
      sEmailAddress = data.emailAddress;
      sPolicyStatusCode = data.policyStatusCode;
      sPaymentModeCode = data.paymentMethodCode;
      sCoreSystem = data.coresystem;
      sPaymentDueDate = data.paymentduedate;
      sPaymentMethod = data.paymentmethod;
      sDueDate = sDueDate =
        sPaymentDueDate.slice(3, 6) +
        sPaymentDueDate.slice(0, 3) +
        sPaymentDueDate.slice(6, 10);
      sIssueDt = data.issueDt;
      sEffectiveDT = data.effectiveDt;
      bisPaymentDisabled = data.isPaymentDisabled;

      //set cookies expired time
      const expires = 10 * 60 * 1000;
      const expiredTime = new Date(new Date().getTime() + expires);

      //save data in cookies and local storage
      Cookies.set("AFIPAY01A01", encryptDecript(sPolisNumber, 1), {
        expires: expiredTime,
        httpOnly: true,
        secure: true,
        sameSite: "none",
      });
    }

    /* VALIDATION */
    /* Policy Status */
    if (sCoreSystem === "RLS") {
      let arrPolicyStatus = sPolicyStatusCode.split("]");
      let sCodeActive = arrPolicyStatus[0].replace("[", "").trim();
      if (
        sCodeActive === "1" ||
        sCodeActive === "2" ||
        sCodeActive === "5" ||
        sCodeActive === "6" ||
        sCodeActive === "B" ||
        sCodeActive.toLowerCase() === "aktif"
      ) {
        sPolicyStatus = "Aktif";
      } else if (
        sCodeActive === "Y" ||
        sCodeActive.toLowerCase() === "polis baru" ||
        sIssueDt === "0"
      ) {
        // patch
        sPolicyStatus = "Polis Baru";
      } else {
        sPolicyStatus = "Tidak Aktif";
      }

      //notification condition BO
      if (sHubungan !== "" && sHubungan !== "0" && sHubungan !== "1") {
        this.setState({ notificationBO: true });
      } else {
        this.setState({ notificationBO: false });
      }
    } else if (sCoreSystem === "DMTM") {
      if (
        sPolicyStatusCode === "PE" ||
        sPolicyStatusCode.toLowerCase() === "polis baru"
      ) {
        sPolicyStatus = "Polis Baru";
      } else if (
        sPolicyStatusCode === "IN" ||
        sPolicyStatusCode === "PU" ||
        sPolicyStatusCode.toLowerCase() === "aktif"
      ) {
        sPolicyStatus = "Aktif";
      } else {
        sPolicyStatus = "Tidak Aktif";
      }

      //notification condition DBB
      if (sPaymentMethod.toLowerCase() === "dbb") {
        this.setState({ notificationDBB: true });
      }
    }

    if (sPolicyStatus !== "Polis Baru") {
      this.setState({
        isPolicyNb: true,
      })
    }

    if (data.registrationStatus === "01") {
      this.setState({
        isRegistration: true
      })
    } else if (data.registrationStatus === "02") {
      this.setState({
        isWaitingSubmit: true
      })
    }





    if (sPolicyStatus.trim().toLowerCase() === "tidak aktif" && !bPolisNB) {
      this.setState({
        btnLanjut: false,
        enablePayment: false,
        notificationLapse: true,
        disclaimerInfo: false,
        hubungan: 1,
        hubunganDisabled: true,
      });
    } else if (bisPaymentDisabled) {
      this.setState({
        btnLanjut: false,
        enablePayment: false,
        notificationSinglePremi: true,
        disclaimerInfo: false,
        hubungan: 1,
        hubunganDisabled: true,
      });
    }

    /* Frekuensi Pembayaran */
    switch (sPaymentModeCode) {
      case "A":
        sFrekuensiPembayaran = "Tahunan";
        break;
      case "Q":
        sFrekuensiPembayaran = "Triwulan";
        break;
      case "S":
        sFrekuensiPembayaran = "Semester";
        break;
      case "M":
        sFrekuensiPembayaran = "Bulanan";
        break;
      case "P":
        sFrekuensiPembayaran = "Sekali Bayar";
        break;
      default:
        break;
    }

    if (this.props.auth?.paymentValidation?.isDueDateMoreSixtyDays === true) {
      this.setState({
        btnLanjut: false,
        enablePayment: false,
      });
    }

    if (this.props.auth?.polis?.isPresubmission === true) {
      this.setState({
        btnLanjut: false,
        enablePayment: false,
      });
    }

    if (
      this.props.auth?.paymentValidation?.pembayaranPremi === false &&
      this.props.auth?.paymentValidation?.pembayaranPremiGopay === false
    ) {
      this.setState({
        btnLanjut: false,
        enablePayment: false,
      });
    }

    if (
      this.props.auth?.paymentValidation?.pembayaranPremi === false &&
      this.props.auth?.paymentValidation?.pembayaranPremiGopay === false
    ) {
      this.setState({
        btnLanjut: false,
        enablePayment: false,
      });
    }


    if (this.state.enablePayment === true && this.props.auth?.polis?.isPresubmission !== true) {
      this.setState({
        polisStatusDesc: "Menunggu Pembayaran",
        polisStatusDescLong: "Silakan pilih Metode Pembayaran yang tersedia.",
      })
    }


    if (data.isPending || data.registrationStatus === "02") {
      this.setState({
        isPolicyPending: true,
        polisStatusDesc: "Verifikasi Data",
        polisStatusDescLong: "Polis anda sedang dalam proses underwriting. Anda dapat melanjutkan pembayaran setelah proses verifikasi data selesai.",
      })
    }


    if (data.paidPremium == data.premiumAmount && data.paidPremium >= data.premiumAmount || data.isPremiumHoliday === true) {
      this.setState({
        polisStatusDesc: "Premi Diterima",
        polisStatusDescLong: "Terima kasih. Premi polis Anda telah kami terima.",
      })
    }

    if (sPolicyStatus.trim().toLowerCase() === "tidak aktif") {
      this.setState({
        polisStatusDesc: "Polis Tidak Aktif",
        polisStatusDescLong: "Polis Anda sedang tidak aktif. Hubungi Customer Care 1500 940, atau layanan portal https://myportal.axa.co.id",
      })
    }

    let obj = optionHubungan.filter(
      (val) => val.value == auth?.hubunganPembayar
    );
    this.setState(
      {
        polisNumber: sPolisNumber,
        dateOfBirth: oDOB,
        isPolisNB: bPolisNB,
        jumlahPremi: sPremium,
        jumlahPremiAmount: spremiumAmount,
        jumlahPremiAmountDec: spremiumAmountDec,
        emailNasabah: sEmailAddress.trim(),
        frekuensiPembayaran: sFrekuensiPembayaran,
        polisStatus: sPolicyStatus,
        coreSystem: sCoreSystem,
        // hubungan: sHubungan,
        paymentduedate: sPaymentDueDate,
        paymentmethod: sPaymentMethod,
        effectiveDt: sEffectiveDT,
        dueDate: sDueDate,
        namapemegang: sPolicyHolderNm,
        namaproduct: sProductNm,
        currency: sCurrency,

        namatertanggung: sInsuredNm,
        emailtertanggung: sInsuredEmail,
        nomorhptertanggung: sInsuredPhone,
        jeniskelamintertanggung: sInsuredGender,
        tanggallahirtertanggung: sInsuredDOB,
        tanggallahirpemegang: sBirthDT,
        nomorhppemegang: sMobilePhoneNo,
        jeniskelaminpemegang: sGender,
        policyDueDt: sPolicyDueDt,
        billingChannel: data?.billingChannel,

        paymentMethodCode: data?.paymentMethodCode,

        isSyariah: data?.isSyariah,
        isSinglePremium: data?.isSinglePremium,
        sumInsured: data?.sumInsured,

        insuredRelation: data?.insuredRelation || "",
        hubungan: auth?.hubunganPembayar || "",
        hubunganObj: obj ? obj[0] : null,
        emailPemegangPremi: auth?.emailPembayar || "",
        sudahDownload: auth?.downloadDbo === true ? true : false,
        init: true,
      },
      () => {
        this.onChangePemegangPolis(this.props.auth.pemegangPolis);
        if (this.state.coreSystem === "DMTM") {
          this.setState({
            hubungan: 1,
            hubunganDisabled: true,
          });
        }

        if (this.state.isPolisNB) {
          let cssfields = {};
          cssfields["frekuensiPembayaran"] = "hidden";
          if (this.state.coreSystem === "RLS") {
            cssfields["dueDate"] = "hidden";
            cssfields["namapemegang"] = "hidden";
            cssfields["namaproduct"] = "hidden";
          }
          this.setState({
            cssfield: cssfields,
          });
        }
      }
    );


  }

  onChang(e) {
    let bUpdatedValue = false;
    let value = e.target.value;
    let fieldID = e.target.id;
    let error = {};
    let csserror = {};
    let disclaimer = {};

    //validate email
    if (fieldID === "emailNasabah") {
      value = value.trim();
      bUpdatedValue = true;
      let regexEmail =
        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
      if (!regexEmail.test(value) && value.trim() !== "") {
        error["emailNasabah"] = "* Email tidak valid.";
        csserror["emailNasabah"] = "notification-error";
      }
    }
    //validate jumlah premi
    else if (fieldID === "jumlahPremi") {
      value = value.replace(/[Rp \.]/g, "");
      bUpdatedValue = true;
      let premAmount = this.state.jumlahPremiAmountDec;
      // premAmount = premAmount.replace(/[\,]/g, ".")
      let sRegexTest = /^[0-9\.,]+$/;
      if (value.indexOf("0") !== 0) {
        if (sRegexTest.test(value)) {
          if (parseInt(value) > parseInt(premAmount)) {
            bUpdatedValue = true;
            error["jumlahPremi"] =
              "* Nilai yang dimasukkan lebih besar dari Premi";
            csserror["jumlahPremi"] = "notification-error";
          } else if (parseInt(value) < parseInt(premAmount)) {
            bUpdatedValue = true;
            disclaimer["jumlahPremiAmt"] =
              "* Premi dianggap Lunas apabila pembayaran sudah sesuai dengan premi tertagih 1";
          }
        } else if (value.trim() === "") {
          bUpdatedValue = true;
          error["jumlahPremi"] = "* Jumlah premi tidak boleh kosong";
          csserror["jumlahPremi"] = "notification-error";
        } else {
          bUpdatedValue = true;
          error["jumlahPremi"] = "* Mohon dimasukkan angka";
          csserror["jumlahPremi"] = "notification-error";
        }
      }

      if (bUpdatedValue) {
        if (value !== "") {
          value = "Rp " + this.changeMoney(value);
        }
      }
    } else {
      if (fieldID === "hubungan" && this.state.coreSystem === "RLS") {
        if (value !== "0" && value !== "1") {
          this.setState({ notificationBO: true });
        } else {
          this.setState({ notificationBO: false });
        }
      }
      bUpdatedValue = true;
    }

    if (bUpdatedValue) {
      this.setState({
        [e.target.id]: value,
        errors: error,
        csserrors: csserror,
        disclaimers: disclaimer,
      });
    }
  }

  changeMoney(x) {
    if (!x) return 0;
    x = x.replace(".", ",");
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  onBack(e) {
    document.getElementById("popupBeranda").style.display = "block";
  }

  onSubmit(e, payOption) {
    e.preventDefault();
    this.setState({ loading: true });
    this.setState({ submit: true });

    //validate value
    let bError = false;
    // let error = this.state.errors
    let error = [];

    //email
    let regexEmail =
      /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

    if (this.state.pemegangPolis === false) {
      if (!this.state.hubungan) {
        error["hubungan"] =
          "* Hubungan pembayar premi dengan pemegang polis tidak boleh kosong.";
      } else {
        delete error["hubungan"];
      }

      if (this.state.emailPemegangPremi.trim() === "") {
        error["emailPemegangPremi"] =
          "* Email pembayar premi tidak boleh kosong.";
      } else if (!regexEmail.test(this.state.emailPemegangPremi)) {
        error["emailPemegangPremi"] = "* Email pembayar premi tidak valid.";
      } else {
        delete error["emailPemegangPremi"];
      }

      if (this.state.sudahDownload === false) {
        this.setState({ harusDownload: true });
        bError = true;
      }

      if (bError === true) {
        this.formRef.current.scrollIntoView();
      }
    }

    if (this.state.isPaymentDisabled) {
      alert({
        title: "Maaf",
        html: "<p>Produk ini tidak boleh melakukan Pembayaran</p>",
      });
    }

    // Object.keys(this.state.errors).length > 0
    if (Object.keys(error).length > 0) {
      bError = true;
    }

    if (!bError) {
      let sCurrency = this.state.currency;
      let sPremium = "";
      if (sCurrency === "USD") {
        sPremium = this.state.jumlahPremi
          .replace(/\./g, "")
          .replace(/,/g, ".")
          .replace("US$ ", "");
      } else {
        sPremium = this.state.jumlahPremi
          .replace(/\./g, "")
          .replace(/,/g, ".")
          .replace("Rp ", "");
      }

      let data = {
        polisnumber: this.state.polisNumber,
        dateofbirth: this.state.dateOfBirth,
        isPolisNB: this.state.isPolisNB,
        emailAddress: this.state.emailNasabah,
        premium: sPremium,
        premiumAmount: this.state.jumlahPremiAmount,
        policyStatusCode: this.state.polisStatus,
        paymentMethodCode: this.state.paymentMethodCode,
        hubungan: this.state.hubungan,
        coresystem: this.state.coreSystem,
        paymentduedate: this.state.paymentduedate,
        paymentmethod: this.state.paymentmethod,
        namapemegang: this.state.namapemegang,
        namaproduct: this.state.namaproduct,
        effectiveDt: this.state.effectiveDt,
        currency: this.state.currency,

        nomorhppemegang: this.state.nomorhppemegang,
        policyDueDt: this.state.policyDueDt,
        payOption: payOption,

      };

      if (payOption === "pembayaran") {
        this.props.history.push({
          pathname: process.env.REACT_APP_SUBDIR + "/metode-pembayaran",
          data: data,
        });
      } else {
        this.props.history.push({
          pathname: process.env.REACT_APP_SUBDIR + "/registrasi-autodebit",
          data: data,
        });
      }
    }

    this.setState({
      errors: error,
      loading: false,
    });
  }

  downloadDokumenBO(e) {
    e.preventDefault();

    this.setState({ sudahDownload: true }, () => {
      this.props.actionSetDboDownload(true);
      // && this.state.sudahDownload
      if (
        this.state.emailPemegangPremi &&
        this.state.hubungan &&
        this.state.enablePayment
      ) {
        this.setState({ btnLanjut: true, harusDownload: false });
      } else {
        this.setState({ btnLanjut: false, harusDownload: false });
      }

      window.open(
        process.env.REACT_APP_BASEREF +
        "/assets/docs/Formulir-Beneficial-Owner-Perorangan-AFI-Sep2022.pdf",
        "_blank"
      );
    });
  }

  onChangePemegangPolis = (val) => {
    this.setState({
      pemegangPolis: true,
      btnLanjut: this.state.enablePayment ? true : false,
    });
    if (this.state.isPolicyNb === true) {
      if (this.props.auth?.paymentValidation?.isDueDateMoreSixtyDays === true)
        return;

      if (
        val === true &&
        (this.state.pemegangPolis === false || this.state.pemegangPolis === null)
      ) {
        this.setState({
          changePemegangPolis: true,
        });
        this.props.actionSetPemegangPolis(true);
        this.setState({
          pemegangPolis: true,
          btnLanjut: this.state.enablePayment ? true : false,
        });
        this.pemegangPolisRef.current.className = "v-hidden";
        setTimeout(() => {
          this.setState({
            changePemegangPolis: false,
          });
        }, 500);
      } else if (
        val === false &&
        (this.state.pemegangPolis === true || this.state.pemegangPolis === null)
      ) {
        this.setState({
          changePemegangPolis: true,
        });
        this.props.actionSetPemegangPolis(false);
        this.setState({ pemegangPolis: false });
        // this.pemegangPolisRef.current.className = "collapse-animate"
        this.pemegangPolisRef.current.className = "collapse-animate v-hidden";
        // if (this.state.pemegangPolis === false) {
        setTimeout(() => {
          this.pemegangPolisRef.current.className = "collapse-animate";
          this.setState({
            changePemegangPolis: false,
          });
        }, 500);
        // }

        // && this.state.sudahDownload
        if (
          this.state.emailPemegangPremi &&
          this.state.hubungan &&
          this.state.enablePayment
        ) {
          this.setState({
            btnLanjut: true,
          });
        } else {
          this.setState({
            btnLanjut: false,
          });
        }
      }
    }
  };

  hookOnChange() {
    if (this.state.pemegangPolis === false) {
      // && this.state.sudahDownload
      if (
        this.state.emailPemegangPremi &&
        this.state.hubungan &&
        this.state.enablePayment
      ) {
        this.setState({
          btnLanjut: true,
        });
      } else {
        this.setState({
          btnLanjut: false,
        });
      }
    }
  }

  onChange = (e, name) => {
    if (name == "emailPemegangPremi") {
      this.setState(
        {
          emailPemegangPremi: e.target.value,
        },
        () => {
          this.hookOnChange();
          this.props.actionSetEmailPembayar(
            this.state.emailPemegangPremi || null
          );
        }
      );
    } else if (name == "hubungan") {
      let obj = optionHubungan.filter((val) => val.value == e.value);
      this.setState(
        {
          hubungan: e.value,
          hubunganObj: obj ? obj[0] : null,
        },
        () => {
          this.hookOnChange();
          this.props.actionSetHubunganPembayar(this.state.hubungan || null);
        }
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <Header
          url={process.env.REACT_APP_SUBDIR + "/sign-out"}
          urlLabel="Keluar"
          title={"Detail Polis"}
          confirm={true}
          icon={"ic-out"}
          confirmTitle={"Keluar?"}
          confirmDescription={"Apakah anda yakin ingin mengakhiri sesi anda?"}
          right={true}
        />


        <div className="container text-center pt-4 pb-1 mb-1">
          <h5 className="pt-2 mb-0">
            Hi <b className="highlight-text">{this.state.namapemegang}</b>
          </h5>
          <p className="mb-0">
            Terima Kasih Atas pengajuan polis asuransi dengan nomor :{" "}
            <b className="highlight-text">{this.state.polisNumber}</b>
          </p>

          <div className="row mb-4">
            <div
              className="col-md-4 col-lg-4 col-sm-12 mb-4"
            ></div>
            <div
              className="col-md-4 col-lg-4 col-sm-12 mb-4"
              ref={this.dataProdukRef}
            >

              <PolisDetailView
                title={"Informasi Polis"}
                data={[
                  { label: "Nama Produk", value: this.state.namaproduct },
                  // {
                  //   label: "Uang Pertanggungan",
                  //   value:
                  //     "Rp " +
                  //     (this.state.sumInsured
                  //       ? this.changeMoney(this.state.sumInsured)
                  //       : 0),
                  // },
                  // { label: "Premi", value: this.state.jumlahPremi },

                  {
                    label: "Nama Tertanggung",
                    value: this.state.namatertanggung,
                  },
                  { label: "Premi", value: this.state.jumlahPremiAmount },
                  {
                    label: "Tanggal Jatuh Tempo",
                    value:
                      this.state.policyDueDt !== "" &&
                        this.state.policyDueDt !== "0"
                        ? asDate(this.state.policyDueDt)
                        : "-",
                  },
                  {
                    label: "Frekuensi Pembayaran",
                    value: this.state.frekuensiPembayaran,
                  },
                  {
                    label: "Metode Pembayaran",
                    value: this.state.billingChannel,
                  },
                  {
                    label: "Status Polis",
                    type: "status",
                    value: this.state.polisStatusDesc,
                    additional: this.state.polisStatusDescLong
                  },
                ]}
                height={this.state.detailHeight}
              />
            </div>
            {/* <div
              className="col-md-4 col-lg-4 col-sm-12 mb-4"
              ref={this.dataPemegangRef}
            >
              <PolisDetailView
                title={"Data Pemegang Polis"}
                data={[
                  {
                    label: "Nama Pemegang Polis",
                    value: this.state.namapemegang,
                  },
                  {
                    label: "Tanggal Lahir",
                    value: asDate(this.state.tanggallahirpemegang),
                  },
                  {
                    label: "Jenis Kelamin",
                    value: this.state.jeniskelaminpemegang,
                  },
                  { label: "Email", value: this.state.emailNasabah },
                  { label: "No HP", value: this.state.nomorhppemegang },
                ]}
                height={this.state.detailHeight}
              />
            </div>
            <div
              className="col-md-4 col-lg-4 col-sm-12 mb-4"
              ref={this.dataTertanggungRef}
            >
              <PolisDetailView
                title={"Data Tertanggung"}
                data={[
                  {
                    label: "Nama Tertanggung",
                    value: this.state.namatertanggung,
                  },
                  {
                    label: "Tanggal Lahir",
                    value: asDate(this.state.tanggallahirtertanggung),
                  },
                  {
                    label: "Jenis Kelamin",
                    value: this.state.jeniskelamintertanggung,
                  },
                  { label: "Email", value: this.state.emailtertanggung },
                  { label: "No HP", value: this.state.nomorhptertanggung },
                ]}
                height={this.state.detailHeight}
              />
            </div> */}
          </div>

          <form onSubmit={this.onSubmit} ref={this.formRef}>


            {this.state.isPolicyNb === true && (
              <div>
                <p>Apakah Anda adalah Pemegang Polis ?</p>
                <div className="custom-radiobutton d-flex align-items-center justify-content-center mb-4">
                  <div className="form-check form-check-inline custom">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="pemegangPolis"
                      id="yaPemegang"
                      value="Ya"
                      onChange={() => this.onChangePemegangPolis(true)}
                      checked={this.state.pemegangPolis === true ? true : false}
                      disabled={
                        this.state.enablePayment !== true ||
                        this.state.changePemegangPolis
                      }
                    />
                    <label className="form-check-label" htmlFor="yaPemegang">
                      Ya
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="pemegangPolis"
                      id="noPemegang"
                      value="Tidak"
                      onChange={() => this.onChangePemegangPolis(false)}
                      checked={this.state.pemegangPolis === false ? true : false}
                      disabled={
                        this.state.enablePayment !== true ||
                        this.state.changePemegangPolis
                      }
                    />
                    <label className="form-check-label" htmlFor="noPemegang">
                      Tidak
                    </label>
                  </div>
                </div>
                <div
                  id="beneficialDownload"
                  ref={this.pemegangPolisRef}
                  className={"v-hidden"}
                >
                  <p>
                    Silakan unduh dan lengkapi formulir dibawah ini dan kirimkan ke{" "}
                    <b>customer@axa-financial.co.id</b>
                  </p>
                  <a
                    href="."
                    onClick={(e) => this.downloadDokumenBO(e)}
                    // href={
                    //   process.env.REACT_APP_BASEREF +
                    //   "/assets/docs/Formulir-Beneficial-Owner-Perorangan-AFI-Sep2022.pdf"
                    // }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      type="button"
                      className="btn btn-outline-primary px-5 mb-4"
                    >
                      Download Beneficial Owner
                    </button>
                  </a>

                  <div className="d-grid col-lg-6 mx-auto text-start mb-4">
                    <p
                      className={
                        "form-tip " +
                        (this.state.submit === true &&
                          this.state.harusDownload === true
                          ? "text-danger fw-bold"
                          : "")
                      }
                      style={{ margin: 0 }}
                    >
                      <i>Dokumen wajib di download</i>
                    </p>

                    {this.state.init ? (
                      <DropDown
                        label={"Hubungan Pembayar Premi Dengan Pemegang Polis"}
                        placeholder={"Hubungan Dengan Pemegang Polis"}
                        value={this.state.hubunganObj}
                        onChange={(e) => this.onChange(e, "hubungan")}
                        options={optionHubungan}
                        error={this.state.errors["hubungan"]}
                        disabled={this.state.hubunganDisabled}
                      />
                    ) : null}

                    <TextInput
                      type="email"
                      label={"Email Pembayar Premi"}
                      placeholder={"Email Pembayar Premi"}
                      value={this.state.emailPemegangPremi}
                      onChange={(e) => this.onChange(e, "emailPemegangPremi")}
                      error={this.state.errors["emailPemegangPremi"]}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* {this.state.isRegistration === true && (
              <div className="warning-wrapper">
                <div className="warning-box warning-error mx-auto">
                  <div className="d-block d-lg-flex align-items-center">
                    <span className="ic-alert m-auto me-lg-3"></span>
                    <p className="m-0">
                      Silakan lakukan registrasi autodebit kartu kredit Anda dengan cara click pada menu ‘Registrasi Autodebit’ dibawah.

                    </p>
                  </div>
                </div>
              </div>
            )}


            {this.state.isWaitingSubmit === true && (
              <div className="warning-wrapper">
                <div className="warning-box warning-error mx-auto">
                  <div className="d-block d-lg-flex align-items-center">
                    <span className="ic-alert m-auto me-lg-3"></span>
                    <p className="m-0">
                      Polis Anda sedang dalam proses verifikasi data. Silakan coba login beberapa saat lagi untuk melakukan pembayaran premi Anda.
                    </p>
                  </div>
                </div>
              </div>
            )}

            {this.state.isPolicyPending === true && (
              <div className="warning-wrapper">
                <div className="warning-box warning-error mx-auto">
                  <div className="d-block d-lg-flex align-items-center">
                    <span className="ic-alert m-auto me-lg-3"></span>
                    <p className="m-0">
                      Polis Anda sedang dalam proses verifikasi data. Silahkan coba login beberapa saat lagi untuk melakukan pembayaran premi Anda.
                      <br />
                      <br />
                      Jika Anda belum melakukan proses registrasi autodebit, silakan lakukan registrasi autodebit Anda dengan cara click pada menu ‘Registrasi Autodebit’ dibawah.
                    </p>
                  </div>
                </div>
              </div>
            )} */}

            {/* {(this.props.auth?.paymentValidation?.isDueDateMoreSixtyDays ===
              true ||
              this.state.notificationSinglePremi === true ||
              this.state.notificationLapse === true ||
              this.state.notificationBO === true ||
              this.state.notificationDBB === true ||
              this.state.disclaimerInfo === true) && (
                <div className="warning-wrapper">
                  {this.props.auth?.paymentValidation?.isDueDateMoreSixtyDays ===
                    true && (
                      <div className="warning-box warning-info mx-auto">
                        <div className="d-block d-lg-flex align-items-center">
                          <span className="ic-info m-auto me-lg-3"></span>
                          <p className="m-0">
                            Saat ini Polis Anda belum Jatuh Tempo, mohon melakukan
                            kembali saat Jatuh Tempo Premi.
                          </p>
                        </div>
                      </div>
                    )}

                  {this.state.notificationSinglePremi === true && (
                    <div className="warning-box warning-error mx-auto">
                      <div className="d-block d-lg-flex align-items-center">
                        <span className="ic-alert m-auto me-lg-3"></span>
                        <p className="m-0">
                          Polis Anda tidak memiliki fitur premi lanjutan. Apabila
                          Anda ingin melakukan Top Up silakan kunjungi Customer
                          Portal{" "}
                          <a
                            href={"https://myportal.axa.co.id/"}
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue"
                          >
                            <b>https://myportal.axa.co.id/</b>
                          </a>{" "}
                          pada menu Rincian Polis Anda.
                        </p>
                      </div>
                    </div>
                  )}



                  {this.state.notificationLapse === true && (
                    <div className="warning-box warning-error mx-auto">
                      <div className="d-block d-lg-flex align-items-center">
                        <span className="ic-alert m-auto me-lg-3"></span>
                        <p className="m-0">
                          Polis Anda sedang tidak aktif, silakan melakukan
                          pengaktifan polis dengan melakukan pembayaran di
                          Customer Portal{" "}
                          <a
                            href={"https://myportal.axa.co.id/"}
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue"
                          >
                            <b>https://myportal.axa.co.id/</b>
                          </a>{" "}
                          pada menu Rincian Polis Anda.
                        </p>
                      </div>
                    </div>
                  )}

                  {this.state.notificationBO === true && (
                    <div className="warning-box warning-error mx-auto">
                      <div className="d-block d-lg-flex align-items-center">
                        <span className="ic-alert m-auto me-lg-3"></span>
                        <p className="m-0">
                          Unduh dan lengkapi formulir Beneficial Owner pada link{" "}
                          <a
                            href={
                              process.env.REACT_APP_BASEREF +
                              "/assets/docs/Formulir-Beneficial-Owner-Perorangan-AFI-Sep2022.pdf"
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue"
                          >
                            <b>berikut</b>
                          </a>{" "}
                          dan dikirimkan ke{" "}
                          <a
                            href={"mailto:customer@axa-financial.co.id"}
                            className="text-blue"
                          >
                            <b>customer@axa-financial.co.id</b>
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  )}

                  {this.state.notificationDBB === true && (
                    <div className="warning-box warning-error mx-auto">
                      <div className="d-block d-lg-flex align-items-center">
                        <span className="ic-alert m-auto me-lg-3"></span>
                        <p className="m-0">
                          Anda saat ini terdaftar dengan metode pembayaran
                          Autodebit Rekening, silakan menghubungi Customer Care
                          AFI 1500 940 apabila Anda ingin melakukan perubahan
                          menjadi Autodebit Kartu Kredit.
                        </p>
                      </div>
                    </div>
                  )}



                  {this.state.disclaimerInfo === true && (
                    <div className="warning-box warning-info mx-auto">
                      <div className="d-block d-lg-flex align-items-center">
                        <span className="ic-info d-block d-lg-none m-auto"></span>
                        <span className="ic-info d-none d-lg-block me-3"></span>
                        <p className="m-0">
                          Pastikan informasi yang tercantum telah sesuai.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )} */}

            {this.state.enablePayment === true &&
              this.state.btnLanjut === true &&
              this.state.loading === false &&
              this.state.coreSystem !== "DMTM" ? (
              <button
                onClick={(e) => this.onSubmit(e, "pembayaran")}
                className="btn btn-primary px-5"
              >
                Pilih Metode Pembayaran
              </button>
            ) : (
              <button className="btn btn-primary px-5" disabled>
                Pilih Metode Pembayaran{" "}
                <Spinner visible={this.state.loading === true} />
              </button>
            )}
            <br />
            <br />
            <button
              onClick={(e) => this.onSubmit(e, "registrasi")}
              className="btn btn-primary-regis same-size"
            >
              Registrasi AutoDebit
            </button>
          </form>
          <div style={{ paddingBottom: "1rem" }}></div>
          <AfipayDesc />
        </div>

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    payment: state.payment,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    actionSetHubunganPembayar,
    actionSetEmailPembayar,
    actionSetDboDownload,
    actionSetPemegangPolis,
    actionSetShowProduct,
    actionSetShowPolicyHolder,
    actionSetShowInsured,
    actionCheckActivePayment,
  })(Beranda)
);
