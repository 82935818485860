import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { encryptDecript } from "../helpers/encryptDecrypt.js";
import ReactDOMServer from "react-dom/server";
import { v4 as uuidv4 } from "uuid";
import PopupSuccess from "../shared/PopupEmailSuccess";
import PopupFailed from "../shared/PopupEmailFailed";
import Header from "../shared/Header";
import Footer from "../shared/Footer";
import DocsAttached from "../assets/docs/Surat-Kuasa-Debet-Kartu-Kredit-AXA-Financial-Indonesia.pdf";
import DocsBOAttached from "../assets/docs/Formulir-Beneficial-Owner-Perorangan-AFI-Sep2022.pdf";
import home from "../assets/images/home-logo.JPG";
import imgSuccess from "../assets/images/success-image.png";

export function determinePeriode(x) {
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agu",
    "Sep",
    "Okt",
    "Nov",
    "Des",
  ];

  let month = x.slice(0, 2);
  let jatuhTempo =
    x.slice(3, 5) + "-" + monthNames[month - 1] + "-" + x.slice(6, 10); // format dd-mmm-yyyy

  let periode = 1;
  // retrieve periode pembayaran
  if (
    localStorage.getItem("AFIPAY01A01") !== "" &&
    localStorage.getItem("AFIPAY01A01") !== null &&
    typeof localStorage.getItem("AFIPAY01A01") !== "undefined"
  ) {
    periode = parseInt(encryptDecript(localStorage.getItem("AFIPAY01A20"), 2));
  } else {
    localStorage.clear();
    window.location.href = process.env.REACT_APP_BASEREF + "/";
  }

  // validasi periode
  if (periode === 2) {
    // separate date, month, year
    let date = x.slice(3, 5);

    let month = x.slice(0, 2);
    let monthadd = parseInt(month, 0);

    let year = x.slice(6, 10);
    let yearadd = parseInt(year, 0);

    // add 1 month for periode 2
    if (monthadd === 12) {
      monthadd = 1;
      yearadd = yearadd + 1;
    } else {
      monthadd = monthadd + 1;
    }
    if (monthadd < 10) {
      monthadd = "0" + monthadd.toString();
    }

    let jatuhTempo2 = date + "-" + monthNames[monthadd - 1] + "-" + yearadd; // format dd-mmm-yyyy

    jatuhTempo = jatuhTempo + " & " + jatuhTempo2;
  }
  return jatuhTempo;
}

class SuccessPage extends Component {
  constructor() {
    super();
    this.state = {
      transactionID:
        localStorage.getItem("AFIPAY01A10") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A10"), 2)
          : "",
      polisNumber:
        localStorage.getItem("AFIPAY01A01") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A01"), 2)
          : "",
      grossAmount:
        localStorage.getItem("AFIPAY01A13") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A13"), 2)
          : "",
      premiAmount:
        localStorage.getItem("AFIPAY01A35") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A35"), 2)
          : "",
      periodeJatuhTempo:
        localStorage.getItem("AFIPAY01A12") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A12"), 2)
          : "",
      waktuTransaksi:
        localStorage.getItem("AFIPAY01A14") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A14"), 2)
          : "",
      nomorKartu:
        localStorage.getItem("AFIPAY01A15") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A15"), 2)
          : "",
      saveCard:
        localStorage.getItem("AFIPAY01A16") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A16"), 2)
          : "Tidak",
      jenisTransaksi:
        localStorage.getItem("AFIPAY01A11") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A11"), 2)
          : "",

      email: "",
      docHTML: "",
      emailStatus: "",
      emailAddress: "",
      successTitle: "Transaksi Pembayaran Berhasil",
      hidePembayaran: "",
      hidePremiAmount: "",
      hidePeriode: "",
      classNotification: "",
      showBCA: false,
      showBO: false,
      showGrossAmount: true,
      showPremiAmount: true,
      showPeriode: true,
      isPolisNB: "",
    };

    this.onSendEmail = this.onSendEmail.bind(this);
  }

  componentDidMount() {
    let sTransactionID = "";
    let sNomorPolis = "";
    let sJumlahPembayaran = "";
    let spremiAmount = "";
    let sPeriodeJatuhTempo = "";
    let sWaktuTransaksi = "";
    let sNomorKartu = "";
    let sSaveCard = "Tidak";
    let sTransactionTitle = "";
    let sEmailAddress = "";
    let sBCAAutoDebit = "";
    let sCoreSystem = "";
    let sHubungan = "";
    let bPolisNB = "";
    let sCurrency = "";
    /* if (
      typeof Cookies.get("AFIPAY01A01") !== "undefined" &&
      Cookies.get("AFIPAY01A01") !== ""
    ) { */
    if (
      localStorage.getItem("AFIPAY01A01") !== "" &&
      localStorage.getItem("AFIPAY01A01") !== null &&
      typeof localStorage.getItem("AFIPAY01A01") !== "undefined"
    ) {
      sTransactionID = encryptDecript(localStorage.getItem("AFIPAY01A10"), 2);
      sNomorPolis = encryptDecript(localStorage.getItem("AFIPAY01A01"), 2);
      sJumlahPembayaran = encryptDecript(
        localStorage.getItem("AFIPAY01A13"),
        2
      );
      spremiAmount = encryptDecript(localStorage.getItem("AFIPAY01A35"), 2);
      sPeriodeJatuhTempo = determinePeriode(
        encryptDecript(localStorage.getItem("AFIPAY01A12"), 2)
      );
      sWaktuTransaksi = encryptDecript(localStorage.getItem("AFIPAY01A14"), 2);
      sNomorKartu = encryptDecript(localStorage.getItem("AFIPAY01A15"), 2);
      sSaveCard = encryptDecript(localStorage.getItem("AFIPAY01A16"), 2);
      sTransactionTitle = encryptDecript(
        localStorage.getItem("AFIPAY01A11"),
        2
      );
      sEmailAddress = encryptDecript(localStorage.getItem("AFIPAY01A04"), 2);
      sCoreSystem = encryptDecript(localStorage.getItem("AFIPAY01A08"), 2);
      sBCAAutoDebit =
        localStorage.getItem("AFIPAY01A28") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A28"), 2)
          : "";
      sHubungan = encryptDecript(localStorage.getItem("AFIPAY01A09"), 2);
      bPolisNB =
        encryptDecript(localStorage.getItem("AFIPAY01A03"), 2).toLowerCase() ===
        "true";
      sCurrency =
        localStorage.getItem("AFIPAY01A33") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A33"), 2)
          : "";
    } else {
      // localStorage.clear();
      this.props.history.push({
        pathname: process.env.REACT_APP_SUBDIR + "/",
      });
    }

    let sSuccessTitle = "Transaksi Pembayaran Berhasil";
    let sPembayaran = "";
    let sPremiAmountDis = "";
    let sPeriode = "";
    let bShowGrossAmount = true;
    let bShowPremiAmount = true;
    let bShowPeriode = true;
    if (
      sTransactionTitle.toLowerCase() === "pembayaran premi" ||
      sTransactionTitle.toLowerCase() === "pembayaran"
    ) {
      if (sSaveCard.toLowerCase() === "ya") {
        sSuccessTitle = "Transaksi Pembayaran dan Registrasi Berhasil";
      }
      if (bPolisNB) {
        bShowPeriode = false;
        bShowPremiAmount = false;
        document.getElementById("periodeJatuhTempo").style.display = "none";
        document.getElementById("premiJatuhTempo").style.display = "none";
      } else if (sCurrency === "USD") {
        document.getElementById("premiJatuhTempo").style.display = "none";
        bShowPremiAmount = false;
      }
    } else {
      sSuccessTitle = "Transaksi Registrasi Berhasil";
      document.getElementById("jumlahPembayaran").style.display = "none";
      document.getElementById("premiJatuhTempo").style.display = "none";
      document.getElementById("periodeJatuhTempo").style.display = "none";
      sPembayaran = "hidePembayaran";
      sPremiAmountDis = "hidePremiAmount";
      sPeriode = "hidePeriode";
      bShowGrossAmount = false;
      bShowPremiAmount = false;
      bShowPeriode = false;
    }
    // validation GoPay
    if (sNomorKartu === "") {
      document.getElementById("cardno").style.display = "none";
    }

    let sClassNotification = "";
    let bShowBCA = false;
    if (
      sBCAAutoDebit.toLowerCase() === "bca" &&
      sCoreSystem.toLowerCase() === "rls"
    ) {
      document.getElementById("BcaNotification").style.display = "block";
      sClassNotification = "BcaNotification-show";
      bShowBCA = true;
    } else {
      sClassNotification = "BcaNotification-hide";
    }

    let sClassNotificationBO = "";
    let bShowBO = false;
    if (sCoreSystem.toLowerCase() === "rls") {
      if (sHubungan !== "" && sHubungan !== "0" && sHubungan !== "1") {
        document.getElementById("BONotification").style.display = "block";
        sClassNotificationBO = "BcaNotification-show";
        bShowBO = true;
      } else {
        document.getElementById("BONotification").style.display = "none";
        sClassNotificationBO = "BcaNotification-hide";
      }
    } else {
      sClassNotificationBO = "BcaNotification-hide";
    }

    this.setState(
      {
        polisNumber: sNomorPolis,
        transactionID: sTransactionID,
        grossAmount: sJumlahPembayaran,
        premiAmount: spremiAmount,
        periodeJatuhTempo: sPeriodeJatuhTempo,
        waktuTransaksi: sWaktuTransaksi,
        nomorKartu: sNomorKartu,
        saveCard: sSaveCard,
        jenisTransaksi: sTransactionTitle,
        emailAddress: sEmailAddress,
        successTitle: sSuccessTitle,
        hidePembayaran: sPembayaran,
        hidePremiAmount: sPremiAmountDis,
        hidePeriode: sPeriode,
        classNotification: sClassNotification,
        classNotificationBO: sClassNotificationBO,
        showBCA: bShowBCA,
        showBO: bShowBO,
        showGrossAmount: bShowGrossAmount,
        showPremiAmount: bShowPremiAmount,
        showPeriode: bShowPeriode,
      }
      // initial pagehtml for email
      //this.htmlPage()
    );
  }

  toPDF() {
    window.location.href = process.env.REACT_APP_BASEREF + "/download";
  }

  changeMoney(x) {
    x = x.replace(".", ",");
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  /*
  htmlPage() {
    const element = (
      <div
        className="container container-download"
        style={{ maxWidth: "640px" }}
      >
        <div class="ref-block" style={{ margin: "50px" }}>
          <div id="divToPrint" class="printPage">
            <div class="block-atas" style={{ marginBottom: "20px" }}>
              <h1 style={{ fontSize: "14px", fontWeight: "bold" }}>
                Kepada Yth
              </h1>
              <h2 style={{ fontSize: "12px" }}>Bapak/Ibu Pemegang Polis</h2>
              <br />
              <p style={{ margin: 0, fontSize: "12px" }}>
                Terima kasih atas kepercayaan Bapak/Ibu untuk menggunakan
                fasilitas AFIPAY
              </p>
              <p style={{ fontSize: "12px", margin: 0 }}>
                Berikut rincian transaksi yang telah Bapak/Ibu lakukan:
              </p>
            </div>
            <div
              class="table"
              style={{
                backgroundColor: "#F3F4F5",
                overflow: "auto",
                width: "100%!important",
              }}
            >
              <div
                class="table-title"
                style={{
                  backgroundColor: "#EAEAEA",
                  fontSize: "12px",
                  margin: "10px",
                  verticalAlign: "middle",
                  padding: "10px 10px",
                  margin: "0 0 0 0",
                }}
              >
                Detail Transaksi
              </div>
              <table
                style={{
                  fontSize: "10px",
                  width: "calc(100%)",
                  backgroundColor: "#F3F4F5",
                  margin: 0,
                  width: "100%",
                }}
              >
                <tr
                  style={{
                    backgroundColor: "#E2E2E2",
                    border: "20px solid #EAEAEA",
                  }}
                >
                  <td
                    className="td-left"
                    style={{ paddingLeft: "10px !important", maxWidth: "45%" }}
                  >
                    Order ID
                  </td>
                  <td style={{ padding: "5px" }}>:</td>
                  <td style={{ padding: "5px" }}>{this.state.transactionID}</td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#E2E2E2",
                    border: "20px solid #EAEAEA",
                  }}
                >
                  <td
                    className="td-left"
                    style={{ paddingLeft: "10px !important", maxWidth: "45%" }}
                  >
                    Tanggal Transaksi
                  </td>
                  <td style={{ padding: "5px" }}>:</td>
                  <td style={{ padding: "5px" }}>
                    {this.state.waktuTransaksi}
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#E2E2E2",
                    border: "20px solid #EAEAEA",
                  }}
                >
                  <td
                    className="td-left"
                    style={{ paddingLeft: "10px !important", maxWidth: "45%" }}
                  >
                    Nomor Polis
                  </td>
                  <td style={{ padding: "5px" }}>:</td>
                  <td style={{ padding: "5px" }}>{this.state.polisNumber}</td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#E2E2E2",
                    border: "20px solid #EAEAEA",
                  }}
                >
                  <td
                    className="td-left"
                    style={{ paddingLeft: "10px !important", maxWidth: "45%" }}
                  >
                    Jenis Transaksi
                  </td>
                  <td style={{ padding: "5px" }}>:</td>
                  <td style={{ padding: "5px" }}>
                    {this.state.jenisTransaksi}
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#E2E2E2",
                    border: "20px solid #EAEAEA",
                  }}
                  className={this.state.hidePeriode}
                >
                  <td
                    className="td-left"
                    style={{ paddingLeft: "10px !important", maxWidth: "45%" }}
                  >
                    Periode Jatuh Tempo
                  </td>
                  <td style={{ padding: "5px" }}>:</td>
                  <td style={{ padding: "5px" }}>
                    {this.state.periodeJatuhTempo}
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#E2E2E2",
                    border: "20px solid #EAEAEA",
                  }}
                  className={this.state.hidePremiAmount}
                >
                  <td
                    className="td-left"
                    style={{ paddingLeft: "10px !important", maxWidth: "45%" }}
                  >
                    Premi Jatuh Tempo
                  </td>
                  <td style={{ padding: "5px" }}>:</td>
                  <td style={{ padding: "5px" }}>{this.state.premiAmount}</td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#E2E2E2",
                    border: "20px solid #EAEAEA",
                  }}
                  className={this.state.hidePembayaran}
                >
                  <td
                    className="td-left"
                    style={{ paddingLeft: "10px !important", maxWidth: "45%" }}
                  >
                    Jumlah Pembayaran
                  </td>
                  <td style={{ padding: "5px" }}>:</td>
                  <td style={{ padding: "5px" }}>{this.state.grossAmount}</td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#E2E2E2",
                    border: "20px solid #EAEAEA",
                  }}
                >
                  <td
                    className="td-left"
                    style={{ paddingLeft: "10px !important", maxWidth: "45%" }}
                  >
                    Nomor Kartu Kredit/Rekening
                  </td>
                  <td style={{ padding: "5px" }}>:</td>
                  <td style={{ padding: "5px" }}>{this.state.nomorKartu}</td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#E2E2E2",
                    border: "20px solid #EAEAEA",
                  }}
                >
                  <td
                    className="td-left"
                    style={{ paddingLeft: "10px !important", maxWidth: "45%" }}
                  >
                    Mendaftar untuk premi lanjutan dengan autodebet
                  </td>
                  <td style={{ padding: "5px" }}>:</td>
                  <td style={{ padding: "5px" }}>{this.state.saveCard}</td>
                </tr>
              </table>
              <div className={this.state.classNotification}>
                Bagi Anda yang mendaftarkan jenis kartu BCA Card, silakan unduh
                dan lengkapi Formulir Surat Kuasa Debet Kartu Kredit (SKDKK)
                pada tautan{" "}
                <a
                  href={
                    process.env.REACT_APP_BASEREF +
                    "/assets/docs/Surat-Kuasa-Debet-Kartu-Kredit-AXA-Financial-Indonesia.pdf"
                  }
                  target="_blank"
                >
                  berikut
                </a>
                . Kirimkan formulir tersebut dengan disertai Salinan Identitas
                Diri (KTP/SIM) dan Salinan Kartu Kredit bagian depan ke alamat
                email{" "}
                <a href="mailto:customer@axa-financial.co.id">
                  customer@axa-financial.co.id
                </a>{" "}
                atau Tenaga Pemasar atau Kantor Pusat AXA Financial Indonesia
              </div>
              <div className={this.state.classNotificationBO}>
                Bagi Anda yang mendaftarkan Kartu Kredit/Gopay bukan atas nama
                Pemegang Polis, silakan unduh dan lengkapi Formulir Beneficiary
                Owner Perorangan pada tautan{" "}
                <a
                  href={
                    process.env.REACT_APP_BASEREF +
                    "/assets/docs/Formulir-Beneficial-Owner-Perorangan-AFI.pdf"
                  }
                  target="_blank"
                >
                  berikut
                </a>
                . Kirimkan formulir tersebut dengan disertai salinan Identitas
                Diri (KTP/SIM) dan salinan kartu keluarga/akta nikah/surat
                perwalian ke alamat email{" "}
                <a href="mailto:customer@axa-financial.co.id">
                  customer@axa-financial.co.id
                </a>{" "}
                atau Tenaga Pemasar atau Kantor Pusat AXA Financial Indonesia
              </div>
            </div>
            <div class="block-bawah" style={{ marginTop: "20px" }}>
              <p
                class="informasi"
                style={{ marginBottom: "20px", fontSize: "12px" }}
              >
                Untuk informasi lebih lanjut, Bapak/ibu dapat menghubungi
                layanan Customer Care Center di nomor 1500 940 atau melalui
                email ke{" "}
                <a href="mailto:customer@axa-financial.co.id">
                  customer@axa-financial.co.id
                </a>
              </p>
              <p style={{ fontSize: "12px", margin: 0 }}>Hormat Kami</p>
              <p style={{ fontSize: "12px", margin: 0 }}>
                PT AXA Financial Indonesia
              </p>
            </div>
            <div
              class="download-footer"
              style={{
                textAlign: "center",
                color: "#B1AEAE",
                fontSize: "10px",
                marginTop: "30px",
              }}
            >
              <p style={{ fontSize: "11px", margin: 0 }}>
                PT AXA Financial Indonesia
              </p>
              <p style={{ fontSize: "11px", margin: 0 }}>
                AXA Tower lt. 16, Jl. Prof.Dr.Satrio Kav.18 Kuningan City,
                Jakarta 12940, Indonesia, Jakarta, Indonesia
              </p>
              <p style={{ fontSize: "11px", margin: 0 }}>1500 940</p>
              <div class="download-footer-logo">
                <img
                  style={{ margin: " 20px 0" }}
                  className="logo-axa"
                  src={process.env.REACT_APP_IMGEMAILREF}
                  alt="logo-axa"
                />
              </div>
              <p>
                You are receiving this email because of your relationship with
                us.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
    var elementString = ReactDOMServer.renderToString(element);
    var cssStyle =
      "<style>body { margin: auto; width:50%; " +
      "font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}" +
      ".container-download{ max-width: 640px;}h1{ font-size: 16px; font-weight: bold;}h2{ font-size: 14px;}" +
      "p{ font-size: 14px; margin: 0;}table{ font-size: 14px; width: calc(100% - 60px); background-color: #F3F4F5; margin: 30px;}" +
      "table tr{ background-color: #E2E2E2; border: 20px solid #EAEAEA;}table td, .table td{ padding: 5px;}.td-left{ padding-left: 20px !important;}" +
      ".ref-block{ margin: 50px;}.block-atas{ margin-bottom: 20px;}.block-bawah{ margin-top: 20px;}.table{ background-color: #F3F4F5; overflow: hidden;}" +
      ".table-title{ background-color: #EAEAEA; margin: 10px; vertical-align: middle; padding: 10px 30px; margin: 20px 30px 0 30px;}.informasi{ margin-bottom: 20px;}.download-footer{ text-align: center; color: #B1AEAE; font-size: 10px; margin-top: 30px;}" +
      ".download-footer p{ font-size: 12px;}.download-footer img{ margin: 20px 0;}@media only screen and (max-width: 640px){ .ref-block{ margin: 50px 20px; }}</style>";

    var cssStyle =
      "<style> .hidePembayaran{ display: none; } .BcaNotification-show{ display: block !important; color: red; font-size: 14px; background-color: #e9ecef; " +
      " margin-top: 20px; padding: 12px; padding-left: 20px; font-size: 10px; text-align: justify;} .BcaNotification-hide{ display: none !important; } @media only screen and (max-width: 640px){ .ref-block{ margin: 50px 20px; }}</style>";
    var htmlString =
      "<html>" +
      cssStyle +
      "<body style= 'margin: auto; width:50%; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}'>" +
      elementString +
      "</body><html>";

    var newStr = htmlString.replace(/"/g, "'");

    this.setState({
      docHTML: newStr,
    });
    return newStr;
  }*/

  onSendEmail(e) {
    e.preventDefault();
    //let sHTMLBody = this.htmlPage();
    // transaction time
    //var transactionDT = new Date(); // timezone GMT

    // add +7 (timezone jakarta)
    /*transactionDT.setMinutes(transactionDT.getMinutes() + 1);
    var transDT = transactionDT.toISOString();
    var time = transDT.split(".")[0] + "Z"; // untuk payload
    var guid = uuidv4();

    // set payload
    let sSubjectEmail = "Bukti Pembayaran Premi No Polis " + this.state.polisNumber;
    if (this.state.jenisTransaksi.toLowerCase() === "pembayaran premi" ||
    this.state.jenisTransaksi.toLowerCase() === "pembayaran"){
      if (this.state.saveCard.toLowerCase() === "ya"){
        sSubjectEmail = "Bukti Pembayaran Premi dan Registrasi No Polis " + this.state.polisNumber;
      }
    } else {
      sSubjectEmail = "Bukti Registrasi No Polis " + this.state.polisNumber;
    }

    let payload = new FormData();
    let payloadJson = {
      transactionDTTM: time,
      message: {
        transportType: "email", // hardcode "email"
        scheduledTime: time,
        from: {
          address: "noreply@axa.co.id", // sender email address
        },
        recipient: {
          to: [
            {
              address: this.state.emailAddress, // policy holder email
            },
          ],
        },
        subject: sSubjectEmail, // subject email
        body: sHTMLBody
      },
    };
    payload.append('notify', JSON.stringify(payloadJson));
    let headerPost = {
      entity: "AFI",
      // "target": "MIDTRANS",
      source: "AFIPAY",
      requestid: guid,
      "Content-Type": "multipart/form-data"
    };*/

    let headerPost = {
      "Content-Type": "multipart/form-data",
    };

    let payloadJson = {
      orderID: this.state.transactionID,
      // transactionDate: this.state.waktuTransaksi,
      // policyNumber: this.state.polisNumber,
      transactionType: this.state.jenisTransaksi,
      // grossAmount: this.state.grossAmount,
      ccNumber: this.state.nomorKartu,
      savecard: this.state.saveCard,
      showBCA: this.state.showBCA,
      showBO: this.state.showBO,
      emailTo: this.state.emailAddress,
      showGrossAmount: this.state.showGrossAmount,
      dueDatePeriod: this.state.periodeJatuhTempo,
      showPeriode: this.state.showPeriode,
      // premiAmount: this.state.premiAmount,
      showPremiAmount: this.state.showPremiAmount,
      notifType: "email",
    };

    // let payloadString = encryptDecript(JSON.stringify(payloadJson), 1);
    // payloadString =
    //   process.env.REACT_APP_PREFIX +
    //   payloadString.substring(0, 4) +
    //   process.env.REACT_APP_PREFIX +
    //   payloadString.substring(4, payloadString.length);
    // let payload = new FormData();
    // payload.append("notify", payloadString);

    axios
      // .post(process.env.REACT_APP_API_NOTIFY, payloadJson, {
      .post(process.env.REACT_APP_API_FE + "/notify", payloadJson, {
        headers: headerPost,
      })
      .then((response) => {
        if (response.data.data.status === "SUCCESS") {
          this.setState({
            emailStatus: "success",
          });
          document.getElementById("PopupEmailSuccess").style.display = "block";
        } else {
          this.setState({
            emailStatus: "failed",
          });
          document.getElementById("PopupEmailFailed").style.display = "block";
        }
      });
  }

  render() {
    return (
      <div className="container">
        <PopupSuccess />
        <PopupFailed />
        <Header />

        <div className="form-field-group success-page-group">
          <div className="success-left">
            <a href={process.env.REACT_APP_BASEREF}>
              <img
                className="home-logo"
                src={home}
                alt="home-logo"
                width="44px"
                height="34px"
              />
            </a>
            <div className="success-header">
              <div className="success-title">{this.state.successTitle}</div>
            </div>
            <div className="mobile-success-image">
              <img src={imgSuccess} alt="success" />
            </div>
          </div>
          <div className="information-success-wrap">
            <table>
              <tr>
                <td className="td-left">Order ID</td>
                <td>:</td>
                <td>{this.state.transactionID}</td>
              </tr>
              <tr>
                <td className="td-left">Nomor Polis</td>
                <td>:</td>
                <td>{this.state.polisNumber}</td>
              </tr>
              <tr>
                <td className="td-left">Jenis Transaksi</td>
                <td>:</td>
                <td>{this.state.jenisTransaksi}</td>
              </tr>
              <tr id="periodeJatuhTempo">
                <td className="td-left">Periode Jatuh Tempo</td>
                <td>:</td>
                <td>{this.state.periodeJatuhTempo}</td>
              </tr>
              <tr id="premiJatuhTempo">
                <td className="td-left">Premi Jatuh Tempo</td>
                <td>:</td>
                <td>{this.state.premiAmount}</td>
              </tr>
              <tr></tr>
              <tr id="jumlahPembayaran">
                <td className="td-left">Jumlah Pembayaran</td>
                <td>:</td>
                <td>{this.state.grossAmount}</td>
              </tr>
              <tr id="cardno">
                <td className="td-left">Nomor Kartu Kredit/Rekening</td>
                <td>:</td>
                <td>{this.state.nomorKartu}</td>
              </tr>
              <tr>
                <td className="td-left">Tanggal Transaksi</td>
                <td>:</td>
                <td>{this.state.waktuTransaksi}</td>
              </tr>
              <tr>
                <td className="td-left">
                  Mendaftar untuk premi lanjutan dengan autodebet
                </td>
                <td>:</td>
                <td>{this.state.saveCard}</td>
              </tr>
            </table>
            <div
              className="notification-block-dbb"
              id="BcaNotification"
              style={{ display: "none" }}
            >
              Bagi Anda yang mendaftarkan jenis kartu BCA Card, silakan unduh
              dan lengkapi Formulir Surat Kuasa Debet Kartu Kredit (SKDKK) pada
              tautan{" "}
              <a href={DocsAttached} target="_blank">
                berikut
              </a>
              . Kirimkan formulir tersebut dengan disertai Salinan Identitas
              Diri (KTP/SIM) dan Salinan Kartu Kredit bagian depan ke alamat
              email{" "}
              <a href="mailto:customer@axa-financial.co.id">
                customer@axa-financial.co.id
              </a>{" "}
              atau Tenaga Pemasar atau Kantor Pusat AXA Financial Indonesia
            </div>
            <div
              className="notification-block-dbb"
              id="BONotification"
              style={{ display: "none" }}
            >
              Bagi Anda yang mendaftarkan Kartu Kredit/Gopay bukan atas nama
              Pemegang Polis, silakan unduh dan lengkapi Formulir Beneficiary
              Owner Perorangan pada tautan{" "}
              <a href={DocsBOAttached} target="_blank">
                berikut
              </a>
              . Kirimkan formulir tersebut dengan disertai salinan Identitas
              Diri (KTP/SIM) dan salinan kartu keluarga/akta nikah/surat
              perwalian ke alamat email{" "}
              <a href="mailto:customer@axa-financial.co.id">
                customer@axa-financial.co.id
              </a>{" "}
              atau Tenaga Pemasar atau Kantor Pusat AXA Financial Indonesia
            </div>
            <div className="form-group">
              <br />
              <button
                className="btn btn-block btn-primary"
                onClick={this.toPDF}
              >
                Unduh Bukti {this.state.jenisTransaksi}
              </button>
              <button
                className="btn btn-block btn-primary-light"
                onClick={this.onSendEmail}
              >
                Kirim Email Bukti {this.state.jenisTransaksi}
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default SuccessPage;
