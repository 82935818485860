import { useEffect } from 'react'
import { connect } from 'react-redux'

import { logout } from '../store/actions'
import { withRouter } from 'react-router-dom'

const SignOut = (props) => {
    useEffect(() => {
        props.logout()
        localStorage.clear()
        props.history.replace(process.env.REACT_APP_SUBDIR + '/sign-in')
    }, [])

    return (null)
}

export default withRouter(connect(null, { logout })(SignOut))
