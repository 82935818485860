import React, { useEffect, useState } from "react";
import "./dashboard.css";
import axios from "axios";
import DataTable from "./DataTable";
import { encryptDecript } from "../../helpers/encryptDecrypt.js";
import Header from "../../components/Layout/AuthLayout/Header";
import AfipayDesc from "../../components/Layout/AdminLayout/AfipayDesc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import ImgRefund from "../../assets/new/img/icons/ic-refund.svg";
import Spinner from "../../components/Spinner";
import { alert } from "../../utils/alert";
import { asCurrency, asDateV4 } from "../../utils/formatter";

const RefundSplitPayment = (props) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [moduleAccess, setModuleAccess] = useState([]);
  const [data, setData] = useState([]);
  const [totalEntries, setTotalEntries] = useState(10);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const [formRefund, setFormRefund] = useState({
    idList: [],
  });
  const [filterData, setFilterData] = useState([]);
  const [hiddenBtn, setHiddenBtn] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [cbAllState, setCbAllState] = useState(true);

  useEffect(() => {
    if (
      typeof localStorage.getItem("AFIPAY02B01") !== "undefined" ||
      localStorage.getItem("AFIPAY02B01") !== ""
    ) {
      setModuleAccess(encryptDecript(localStorage.getItem("AFIPAY02B01"), 2));
      getListData();
    }
  }, []);

  useEffect(() => {
    if (data !== null && data?.length > 0) {
      setCheckedState(new Array(data.length).fill(false));
      setHiddenBtn(false);
      setCbAllState(false);
    } else {
      setCheckedAll(false);
      setHiddenBtn(true);
      setCbAllState(true);
    }
    data?.map((item, key) => {
      data[key].sumPayment =
        item.currency +
        " " +
        asCurrency(item.sumPayment.replace(".00", "").trim());
      data[key].premium =
        item.currency +
        " " +
        asCurrency(item.premium.replace(".00", "").trim());
      data[key].outstanding =
        item.currency +
        " " +
        asCurrency(item.outstanding.replace(".00", "").trim());
      data[key].splitDueDate =
        data[key].splitDueDate === null || data[key].splitDueDate === ""
          ? "-"
          : asDateV4(item.splitDueDate, false);
      data[key].dueDate =
        data[key].dueDate === null || data[key].dueDate === ""
          ? "-"
          : asDateV4(item.dueDate, false);
    });
  }, [data]);

  useEffect(() => {
    const count = checkedState.filter((value) => value === true).length;

    setCheckedAll(false);
    if (data.length > 0) {
      if (count === data.length) {
        setCheckedAll(true);
      }
    }
  }, [checkedState]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const onSearch = async (e) => {
    e.preventDefault();

    if (search === null || search === "") {
      setData([]);
      setFilterData([]);
      getListData();
    } else {
      setLoading(true);
      setFilterData([]);

      const filteredData = data?.filter((item) => {
        if (search !== null || search !== "") {
          if (
            item?.dueDate?.toLowerCase().includes(search.toLowerCase()) ||
            item?.policyNumber?.toLowerCase().includes(search.toLowerCase()) ||
            item?.outstanding?.toLowerCase().includes(search.toLowerCase()) ||
            item?.premium?.toLowerCase().includes(search.toLowerCase()) ||
            item?.splitDueDate?.toLowerCase().includes(search.toLowerCase()) ||
            item?.sumPayment?.toLowerCase().includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });

      await delay(1000);
      if (typeof filteredData !== "undefined") {
        setFilterData(filteredData);
      }
      setLoading(false);
    }
  };

  const getListData = () => {
    setLoading(true);
    let token = "";
    if (
      typeof localStorage.getItem("AFIPAY02B02") !== "undefined" ||
      localStorage.getItem("AFIPAY02B02") !== "" ||
      localStorage.getItem("AFIPAY02B02") !== null
    ) {
      token = encryptDecript(localStorage.getItem("AFIPAY02B02"), 2);
    }

    const headers = {
      Authorization: "Bearer " + token,
    };

    const params = {
      search: search,
      page: page,
      size: 999999,
      sortBy: "id",
      sortType: "ASC",
    };

    axios
      .get(
        process.env.REACT_APP_API_DASH +
          "/dashboard/transaction/get-refund-payment",
        {
          params,
          headers,
        }
      )
      .then(async (response) => {
        if (response?.data?.data?.Message === "Forbidden") {
          setData([]);
          setFilterData([]);
          setLoading(false);
          await alert({
            title: "Forbidden",
            html: "You don`t have access to read data or your access has been revoked in this menu",
          });
        } else {
          setData(response?.data?.data?._items);
          setFilterData(response.data.data._items);
          setLoading(false);
          localStorage.setItem(
            "AFIPAY01A36",
            encryptDecript(response?.data?.data?._items, 1)
          );
        }
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await alert({
            title: "Your session has ended",
            html: "Please login again",
          });

          props.history.push(process.env.REACT_APP_SUBDIR + "/sign-out-admin");
        }
        setLoading(false);
      });
  };

  const postData = async (e) => {
    e.preventDefault();

    setLoadingBtn(true);
    let token = "";
    if (
      typeof localStorage.getItem("AFIPAY02B02") !== "undefined" ||
      localStorage.getItem("AFIPAY02B02") !== "" ||
      localStorage.getItem("AFIPAY02B02") !== null
    ) {
      token = encryptDecript(localStorage.getItem("AFIPAY02B02"), 2);
    }

    const headers = {
      Authorization: "Bearer " + token,
    };

    const params = {
      idList: formRefund.idList,
    };

    axios
      .post(
        process.env.REACT_APP_API_DASH +
          "/dashboard/transaction/refund-payment",
        params,
        {
          headers,
        }
      )
      .then(async (response) => {
        if (response.status === 200) {
          let title = "Success";
          let html = "Data saved successfully.";
          if (response?.data?.data?.status === "FAILED") {
            if (response?.data?.data?.Message === "Forbidden") {
              title = "Forbidden";
              html =
                "You don`t have access to read data or your access has been revoked in this menu";
            } else {
              title = "Failed";
              html = "Failed to save data.";
            }
          }
          const resultAlert = await alert({
            title: title,
            html: html,
          });
          if (title === "Success") {
            if (resultAlert === true) {
              getListData();
              setFormRefund({ idList: [] });
            }
          }
          setLoadingBtn(false);
        }
      })
      .catch(async (error) => {
        await alert({
          title: "Failed to save data.",
          html: "Error : " + error,
        });

        setLoadingBtn(false);
      });
  };

  const onChangeEntries = async (val) => {
    setTotalEntries(val);
    if (search === null || search === "") {
      setData([]);
      setFilterData([]);
      getListData();
    } else {
      setLoading(true);
      setFilterData([]);

      const filteredData = data?.filter((item) => {
        if (search !== null || search !== "") {
          if (
            item?.dueDate?.toLowerCase().includes(search.toLowerCase()) ||
            item?.policyNumber?.toLowerCase().includes(search.toLowerCase()) ||
            item?.outstanding?.toLowerCase().includes(search.toLowerCase()) ||
            item?.premium?.toLowerCase().includes(search.toLowerCase()) ||
            item?.splitDueDate?.toLowerCase().includes(search.toLowerCase()) ||
            item?.sumPayment?.toLowerCase().includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });

      await delay(1000);

      if (typeof filteredData !== "undefined") {
        setFilterData(filteredData);
      }
      setLoading(false);
    }
  };

  const onCheckedAll = (e) => {
    if (e?.target?.checked) {
      setCheckedAll(true);
      setCheckedState(new Array(data.length).fill(true));
      let arr = [];
      data?.map((item) => {
        arr.push(item.id);
      });
      const fillForm = {
        idList: arr,
      };
      setFormRefund(fillForm);
    } else {
      setCheckedAll(false);
      setCheckedState(new Array(data.length).fill(false));
      setFormRefund({ idList: [] });
    }
  };

  const onChangeCheckBox = (e, index) => {
    const updatedCheckedState = checkedState?.map((item, key) =>
      key === index ? !item : item
    );

    setCheckedState(updatedCheckedState);

    if (checkedAll) {
      setCheckedAll(false);
    }

    let _data = formRefund;

    const isChecked = e?.target?.checked;
    const value = parseInt(e?.target?.value);

    if (isChecked) {
      let res = _data.idList.find((x) => x === value) ? true : false;
      if (!res) {
        _data.idList.push(value);
      }
    } else {
      let res = _data.idList.find((x) => x === value) ? true : false;
      if (res) {
        _data.idList = _data.idList.filter((x) => x !== value);
      }
    }
  };

  return (
    <React.Fragment>
      <Header
        url={process.env.REACT_APP_SUBDIR + "/sign-out-admin"}
        urlLabel="Keluar"
        title={"Dashboard"}
        confirm={true}
        icon={"ic-out"}
        confirmTitle={"Keluar?"}
        confirmDescription={"Apakah anda yakin ingin mengakhiri sesi anda?"}
        right={true}
        isAdmin={true}
        moduleAccess={moduleAccess}
        moduleName={"refund"}
      />
      <AfipayDesc title={"Refund Split Payment"} />

      <div className="container pt-4 mb-5 px-5 mt-4 dashboard-admin">
        <div className="row justify-content-between">
          <div className="col-lg-4">
            <span>Showing</span>
            <select
              className="form-select form-select-sm ms-2 me-2"
              style={{
                paddingRight: "2rem",
                width: "auto",
                height: "auto",
                display: "inline-block",
              }}
              value={totalEntries}
              onChange={(e) => onChangeEntries(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span>Entries</span>
          </div>
          <div className="col-lg-3">
            <div className="input-group mb-3">
              <input
                type="text"
                name="search"
                id="search"
                placeholder=""
                className="form-control"
                onChange={(e) => setSearch(e.target.value)}
                style={{
                  borderRadius: "7px",
                  height: "35px",
                }}
              />
              <div className="input-group-append">
                <button
                  type="submit"
                  className="btn btn-block btn-primary"
                  id="btnSearch"
                  onClick={onSearch}
                  style={{
                    borderRadius: "7px",
                    width: "35px",
                    height: "35px",
                    padding: "0",
                  }}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </div>
            </div>
          </div>
        </div>

        <DataTable
          data={filterData}
          tableName={"refund"}
          totalEntries={totalEntries}
          checkedAll={checkedAll}
          onCheckedAll={onCheckedAll}
          checkedState={checkedState}
          onChangeCheckBox={onChangeCheckBox}
          loading={loading}
          cbAllState={cbAllState}
        />
        <div className="row mt-5 justify-content-end align-items-end">
          <div className="col d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-block btn-outline-primary px-5"
              id="btnUnduh"
              onClick={postData}
              style={{
                borderRadius: "7px",
              }}
              hidden={hiddenBtn}
            >
              <img
                height="17.5px"
                width="auto"
                src={ImgRefund}
                alt="refund"
                className="me-2"
              />
              Refund <Spinner visible={loadingBtn} bgColor={"#00006e"} />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default RefundSplitPayment;
