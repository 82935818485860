import { takeEvery, put, all, call } from "redux-saga/effects";
import { encryptDecript } from "../../helpers/encryptDecrypt";
import {
  actionCheckCurrencyStart,
  actionCheckCurrencyEnd,
  actionCheckCurrencySuccess,
  actionCheckCurrencyFailed,
} from "./actions";
import actionTypes from "./actionTypes";
import { request } from "../../utils/request";
import { formatDate } from "../../utils/formatter";
import { accessToken } from "../../helpers/accessToken";

function* sagaCheckCurrency() {
  yield put(actionCheckCurrencyStart());

  // const responseKurs = yield call(request, `${process.env.REACT_APP_API_KURS}?currency=USD`, {

  // let headerPost = {
  //   Authorization: "Bearer " + accessToken(),
  // };
  const responseKurs = yield call(
    request,
    `${process.env.REACT_APP_API_FE}/currency?currency=USD`,
    {
      // headers: headerPost,
      method: "GET",
    }
  );

  let success = responseKurs?.data?.success;
  if (success === "true") {
    let buyRate = responseKurs?.data?.data?.buyingRate; // buying rate USD
    let effDate = responseKurs?.data?.data?.effectiveDate;

    // define with current date
    let date = new Date();
    let month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1).toString()
        : date.getMonth().toString;
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let year = date.getFullYear().toString();
    let dateFormat = year + month + day;
    let dateFormatNew = formatDate(dateFormat);

    if (effDate === dateFormatNew) {
      // set kurs
      yield put(actionCheckCurrencySuccess(buyRate));
      localStorage.setItem("AFIPAY01A34", encryptDecript(buyRate, 1));
    } else {
      yield put(actionCheckCurrencyFailed());
    }
  } else {
    yield put(actionCheckCurrencyFailed());
  }

  yield put(actionCheckCurrencyEnd());
}

function* paymentSaga() {
  yield all([takeEvery(actionTypes.CHECK_CURRENCY, sagaCheckCurrency)]);
}

export default paymentSaga;
