import Immutable from 'seamless-immutable'

const CHECK_CURRENCY = 'CHECK_CURRENCY'
const CHECK_CURRENCY_START = 'CHECK_CURRENCY_START'
const CHECK_CURRENCY_END = 'CHECK_CURRENCY_END'
const CHECK_CURRENCY_SUCCESS = 'CHECK_CURRENCY_SUCCESS'
const CHECK_CURRENCY_FAILED = 'CHECK_CURRENCY_FAILED'

const RESET_CURRENCY = 'RESET_CURRENCY'

const actionTypes = Immutable({
    CHECK_CURRENCY,
    CHECK_CURRENCY_START,
    CHECK_CURRENCY_END,
    CHECK_CURRENCY_SUCCESS,
    CHECK_CURRENCY_FAILED,

    RESET_CURRENCY
})

export default actionTypes
