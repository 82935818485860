import React, { useEffect, useRef, useState } from "react"
import SnK from "./SnK"

const PembayaranGopay = ({ selected = false, tncGopay = false, agreeGopay = false, setTncGopay, setAgreeGopay, setTrueTncGopay }) => {
    const [show, setShow] = useState("v-hidden")
    const [tncClicked, seTncClicked] = useState(false)
    const refTnc = useRef(null)

    useEffect(() => {
        if (selected === true) {
            setShow("v-hidden collapse-animate-md")

            setTimeout(() => {
                setShow("collapse-animate-md")
            }, 500)
        } else {
            setShow("v-hidden")
        }
    }, [selected])

    const onChangeTnc = () => {
        if (tncClicked === false) {
            refTnc.current.click()
        } else {
            setTncGopay()
        }
    }

    const acceptTnc = () => {
        setTncGopay()
        seTncClicked(true)
    }

    return <React.Fragment>
        <div className={"data-pembayaran " + show}>
            <div className="grey-box ketentuan-pembayaran">
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox" value="tncGopay" id="tncGopay" onChange={setTncGopay} checked={tncGopay === true} />
                    <label className="form-check-label" htmlFor="tncGopay">
                        <small>
                            Saya setuju dan mengerti terhadap {" "}
                            <a ref={refTnc} href="." onClick={(e) => e.preventDefault()} data-bs-toggle="modal"
                                data-bs-target="#modalTncGopay">Syarat dan Ketentuan</a>
                        </small>
                    </label>
                </div>
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox" value="agreeGopay"
                        id="agreeGopay" onChange={setAgreeGopay} checked={agreeGopay === true} />
                    <label className="form-check-label" htmlFor="agreeGopay">
                        <small>
                            Dengan mengonfirmasi pembayaran ini, saya bersedia dilakukan pemotongan saldo Gopay untuk pembayaran premi pertama/ lanjutan
                        </small>
                    </label>
                </div>
            </div>
        </div>

        { /** <SnK id={"modalTncGopay"} acceptTnc={tncClicked ? null : acceptTnc} /> */}

        <SnK id={"modalTncGopay"} acceptTnc={setTrueTncGopay} opsi="PembayaranNonCC" />
    </React.Fragment>
}

export default PembayaranGopay