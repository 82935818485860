import React from "react";
import ImgPhone from "../../../assets/new/img/icons/ic-call.svg";

const Footer = () => {
    return (
        <React.Fragment>
            <footer>
                <span className={"ic-out"}></span>
                <div style={{ textAlign: "center" }}>
                    <b>AFI Customer Care Centre: </b>
                    <img
                        src={ImgPhone}
                        height={15}
                    />
                    <b>1500 940</b>
                </div>
                <div className="footer-legal">
                    <small>
                        ©{new Date().getFullYear()} Hak Cipta Dilindungi.
                        <br />
                        PT AXA Financial Indonesia berizin dan diawasi oleh Otoritas Jasa
                        Keuangan
                    </small>
                </div>
            </footer>
        </React.Fragment >
    );
};

export default Footer;
