import React, { useEffect, useRef, useState } from "react"
import Select from 'react-select'
import SnK from "./SnK"

const AutoDebitBca = ({ selected = false, autoNamaPemilikRekeningBca, setAutoNamaPemilikRekeningBca, autoNomorRekeningBca, setAutoNomorRekeningBca, autoNomorPonselPendaftaranBca, setAutoNomorPonselPendaftaranBca, autoTncBca, setAutoTncBca, optionNomorHp, setTrueAutoTncBca }) => {
    const [show, setShow] = useState("v-hidden")
    const [tncClicked, seTncClicked] = useState(false)
    const refTnc = useRef(null)

    useEffect(() => {
        if (selected === true) {
            setShow("v-hidden collapse-animate-lg")

            setTimeout(() => {
                setShow("collapse-animate-lg")
            }, 500)
        } else {
            setShow("v-hidden")
        }
    }, [selected])

    const onChangeTnc = () => {
        if (tncClicked === false) {
            refTnc.current.click()
        } else {
            setAutoTncBca()
        }
    }

    const acceptTnc = () => {
        setAutoTncBca()
        seTncClicked(true)
    }

    return <React.Fragment>
        <div className={"data-pembayaran " + show}>
            <div className="mb-3">
                <label htmlFor="rekeningBCA" className="form-label">Nama Pemilik Rekening</label>
                <input type="text" className="form-control" id="rekeningBCA"
                    placeholder="Nama Pemilik Rekening" value={autoNamaPemilikRekeningBca} onChange={setAutoNamaPemilikRekeningBca} />
            </div>
            <div className="mb-3">
                <label htmlFor="nomorRekening" className="form-label">Nomor Rekening BCA</label>
                <input type="text" className="form-control" id="nomorRekening"
                    placeholder="Nomor Rekening BCA" value={autoNomorRekeningBca} onChange={setAutoNomorRekeningBca} onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }} maxLength={"24"} />
            </div>
            <div className="mb-3">
                <label htmlFor="nomorPonsel" className="form-label">No Ponsel Untuk Konfirmasi
                    Pendaftaran</label>
                <Select
                    placeholder={"Pilih No Ponsel Anda"}
                    aria-label={"Pilih No Ponsel Anda"}
                    aria-describedby={"Pilih No Ponsel Anda"}
                    name={"nomorPonsel"}
                    options={optionNomorHp}
                    defaultValue={autoNomorPonselPendaftaranBca}
                    onChange={setAutoNomorPonselPendaftaranBca}
                    id="nomorPonsel"
                    styles={{
                        control: provided => ({
                            ...provided,
                            padding: "0.375rem 0.6rem"
                        }),
                        valueContainer: provided => ({
                            ...provided,
                            fontWeight: 400,
                        }),
                        input: provided => ({
                            ...provided,
                            fontWeight: 400,
                        }),
                        placeholder: provided => ({
                            ...provided,
                            fontSize: "1rem",
                            color: "#999999",
                            fontWeight: 400
                        })
                    }} />
            </div>
            <div className="grey-box ketentuan-pembayaran">
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox"
                        id="tncRekening" value="autoTncBca" onChange={setAutoTncBca} checked={autoTncBca === true} />
                    <label className="form-check-label" htmlFor="tncRekening">
                        <small>
                            Saya setuju dan mengerti terhadap {" "}
                            <a ref={refTnc} href="." onClick={(e) => e.preventDefault()} data-bs-toggle="modal"
                                data-bs-target="#modalAutoTncBca">Syarat dan Ketentuan</a>
                        </small>
                    </label>
                </div>
            </div>
        </div>

        { /** <SnK id={"modalAutoTncBca"} acceptTnc={tncClicked ? null : acceptTnc} /> */}
        <SnK id={"modalAutoTncBca"} acceptTnc={setTrueAutoTncBca} opsi="AutodebitNonCC" />
    </React.Fragment>
}

export default AutoDebitBca