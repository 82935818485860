import React from "react"
import { useEffect } from "react"
import DocsAttached from "../assets/docs/Surat-Kuasa-Debet-Kartu-Kredit-AXA-Financial-Indonesia.pdf"
import DocsBOAttached from "../assets/docs/Formulir-Beneficial-Owner-Perorangan-AFI.pdf"
import ImgLogo from "../assets/images/axa-logo-bgwhite.svg"
import { dateTime, maskString } from "../utils/formatter"
import "./download.css"
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"

const UnduhBuktiPendaftaranBcaCard = (props) => {
    const { download } = props

    useEffect(() => {
        if (download === null) {
            props.history.replace({
                pathname: process.env.REACT_APP_SUBDIR + "/beranda"
            })
        } else {
            document.body.classList.add('download')
            generatePDF()
        }
    }, [])

    const generatePDF = () => {
        let fileName = download?.transactionId || "Bukti Pembayaran AFIPAY"

        const input = document.getElementById("divToPrint")
        html2canvas(input, { scrollY: -window.scrollY, scale: 2.5 }).then(
            (canvas) => {
                // image
                const imgData = canvas.toDataURL("img/PNG")

                // pdf set margin, ratio, etc
                var pageHeight = 220
                const pdf = new jsPDF("p", "mm", "a4")
                const pageWidth = pdf.internal.pageSize.getWidth()
                // const pageHeight = pdf.internal.pageSize.getHeight()
                const widthRatio = pageWidth / canvas.width
                const heightRatio = pageHeight / canvas.height
                const ratio = widthRatio > heightRatio ? heightRatio : widthRatio
                const canvasWidth = canvas.width * ratio
                const canvasHeight = canvas.height * ratio
                const marginX = (pageWidth - canvasWidth) / 2
                const marginY = (pdf.internal.pageSize.getHeight() - pageHeight) / 2

                // adding image to pdf
                pdf.addImage(
                    imgData,
                    "PNG",
                    marginX,
                    marginY,
                    canvasWidth,
                    canvasHeight,
                    "",
                    "FAST"
                )
                // save pdf
                pdf.save(fileName)
            }
        )
    }

    return <React.Fragment>
        <div className="container container-download">
            <div className="ref-block">
                <div id="divToPrint" className="printPage">
                    <div className="block-atas">
                        <h1>Kepada Yth</h1>
                        <h2>Bapak/Ibu Pemegang Polis</h2>
                        <br />
                        <p>
                            Terima kasih atas kepercayaan Bapak/Ibu untuk menggunakan
                            fasilitas AFIPAY
                        </p>
                        <p>Berikut rincian transaksi yang telah Bapak/Ibu lakukan:</p>
                    </div>
                    <div className="table">
                        <div className="table-title">Detail Transaksi</div>
                        <table className="desktop-view-table">
                            <tbody>
                                <tr>
                                    <td className="td-left">Order ID</td>
                                    <td>:</td>
                                    <td>{download?.transactionId}</td>
                                </tr>
                                <tr>
                                    <td className="td-left">Nomor Polis</td>
                                    <td>:</td>
                                    <td>{download?.noPolis}</td>
                                </tr>
                                <tr>
                                    <td className="td-left">Jenis Transaksi</td>
                                    <td>:</td>
                                    <td>{download?.jenisTransaksi}</td>
                                </tr>
                                <tr>
                                    <td className="td-left">Nomor Kartu Kredit/Rekening</td>
                                    <td>:</td>
                                    <td>{maskString(download?.nomorKartu)}</td>
                                </tr>
                                <tr>
                                    <td className="td-left">Tanggal Transaksi</td>
                                    <td>:</td>
                                    <td>{dateTime(download?.tanggalTransaksi)}</td>
                                </tr>
                                <tr>
                                    <td className="td-left">
                                        Mendaftar untuk premi lanjutan dengan autodebet
                                    </td>
                                    <td>:</td>
                                    <td>{download?.lanjutAutoDebet === true ? "Ya" : "Tidak"}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="mobile-view-table">
                            <tbody>
                                <tr>
                                    <td className="td-left">Order ID</td>
                                </tr>
                                <tr>
                                    <td>{download?.transactionId}</td>
                                </tr>
                                <tr>
                                    <td className="td-left">Nomor Polis</td>
                                </tr>
                                <tr>
                                    <td>{download?.noPolis}</td>
                                </tr>
                                <tr>
                                    <td className="td-left">Jenis Transaksi</td>
                                </tr>
                                <tr>
                                    <td>{download?.jenisTransaksi}</td>
                                </tr>
                                <tr>
                                    <td className="td-left">Nomor Kartu Kredit/Rekening</td>
                                </tr>
                                <tr>
                                    <td>{maskString(download?.nomorKartu)}</td>
                                </tr>
                                <tr>
                                    <td className="td-left">Tanggal Transaksi</td>
                                </tr>
                                <tr>
                                    <td>{dateTime(download?.tanggalTransaksi)}</td>
                                </tr>
                                <tr>
                                    <td className="td-left">
                                        Mendaftar untuk premi lanjutan dengan autodebet
                                    </td>
                                </tr>
                                <tr>
                                    <td>{download?.lanjutAutoDebet === true ? "Ya" : "Tidak"}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div
                            className="notification-block-dbb"
                            id="BcaNotification"
                            style={{ display: "none" }}
                        >
                            Bagi Anda yang mendaftarkan jenis kartu BCA Card, silakan unduh
                            dan lengkapi Formulir Surat Kuasa Debet Kartu Kredit (SKDKK)
                            pada tautan{" "}
                            <a href={DocsAttached} target="_blank" rel="noreferrer">
                                berikut
                            </a>
                            . Kirimkan formulir tersebut dengan disertai Salinan Identitas
                            Diri (KTP/SIM) dan Salinan Kartu Kredit bagian depan ke alamat
                            email{" "}
                            <a href="mailto:customer@axa-financial.co.id">
                                customer@axa-financial.co.id
                            </a>{" "}
                            atau Tenaga Pemasar atau Kantor Pusat AXA Financial Indonesia
                        </div>
                        <div
                            className="notification-block-dbb"
                            id="BONotification"
                            style={{ display: "none" }}
                        >
                            Bagi Anda yang mendaftarkan Kartu Kredit/Gopay bukan atas nama
                            Pemegang Polis, silakan unduh dan lengkapi Formulir Beneficiary
                            Owner Perorangan pada tautan{" "}
                            <a href={DocsBOAttached} target="_blank" rel="noreferrer">
                                berikut
                            </a>
                            . Kirimkan formulir tersebut dengan disertai salinan Identitas
                            Diri (KTP/SIM) dan salinan kartu keluarga/akta nikah/surat
                            perwalian ke alamat email{" "}
                            <a href="mailto:customer@axa-financial.co.id">
                                customer@axa-financial.co.id
                            </a>{" "}
                            atau Tenaga Pemasar atau Kantor Pusat AXA Financial Indonesia
                        </div>
                    </div>
                    <div className="block-bawah">
                        <p className="informasi">
                            Untuk informasi lebih lanjut, Bapak/ibu dapat menghubungi
                            layanan Customer Care Center di nomor 1500 940 atau melalui
                            email ke{" "}
                            <a href="mailto:customer@axa-financial.co.id">
                                customer@axa-financial.co.id
                            </a>
                        </p>
                        <p>Hormat Kami</p>
                        <p>PT AXA Financial Indonesia</p>
                    </div>
                    <div className="download-footer">
                        <p>PT AXA Financial Indonesia</p>
                        <p>
                            AXA Tower lt. 16, Jl. Prof.Dr.Satrio Kav.18 Kuningan City,
                            Jakarta 12940, Indonesia, Jakarta, Indonesia
                        </p>
                        <p>1500 940</p>
                        <div className="download-footer-logo">
                            <img className="logo-axa" src={ImgLogo} alt="logo-axa" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    return {
        polis: state.auth?.polis,
        download: state.payment?.download,
    }
}

export default withRouter(connect(mapStateToProps, null)(UnduhBuktiPendaftaranBcaCard))