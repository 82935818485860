import { encryptDecript } from "./encryptDecrypt";

export function accessToken() {
  let token = "";
  if (
    typeof localStorage.getItem("AFIPAY02B03") !== "undefined" ||
    localStorage.getItem("AFIPAY02B03") !== "" ||
    localStorage.getItem("AFIPAY02B03") !== null
  ) {
    token = encryptDecript(localStorage.getItem("AFIPAY02B03"), 2);
  }

  return token;
}

export function tokenExpiredTime() {
  let tokenExpiredTime = "";
  if (
    typeof localStorage.getItem("AFIPAY02B04") !== "undefined" ||
    localStorage.getItem("AFIPAY02B04") !== "" ||
    localStorage.getItem("AFIPAY02B04") !== null
  ) {
    tokenExpiredTime = encryptDecript(localStorage.getItem("AFIPAY02B04"), 2);
  }

  return tokenExpiredTime / 1000;
}
