import React from "react";
import { Link } from "react-router-dom";
import { confirm } from "../../../../utils/alert";
import history from "../../../../utils/history";
import ImgAxa from "../../../../assets/new/img/Afipay-logo.svg";
import ImgAxaLogo from '../../../../assets/images/axa_logo_solid_rgb_2.svg'
import Dropdown from "react-bootstrap/Dropdown";

const ask = async (url = null, title, html, onClick = null, e = null) => {
  if (e) {
    e.preventDefault();
  }

  let isConfirm = await confirm({
    title,
    html,
  });

  if (isConfirm === true) {
    if (onClick) {
      onClick();
    } else {
      history.push(url);
    }
  }
};

const goHome = async ({
  e,
  url,
  needConfirm = false,
  title = null,
  html = null,
}) => {
  e.preventDefault();

  if (needConfirm === true) {
    let isConfirm = await confirm({
      title,
      html,
    });

    if (isConfirm === true) {
      history.push(url);
    }
  } else {
    history.push(url);
  }
};

const Header = ({
  url = null,
  title,
  confirm = false,
  icon = "ic-back",
  iconMobile = "ic-back-mobile nav-mobile",
  confirmTitle = null,
  confirmDescription = null,
  right = false,
  button = true,
  homeConfirm = false,
  homeTitle = "Kembali ke beranda",
  homeHtml = "<p>Apakah anda yakin?</p>",
  onClick = null,
  homeUrl = process.env.REACT_APP_SUBDIR + "/beranda",
  isAdmin = false,
  moduleAccess = null,
  moduleName = null,
}) => {
  return (
    <React.Fragment>

      
      <div className="row mx-0">
        <div className="d-flex align-items-center justify-content-center py-4">
        <img
        style={{ paddingLeft: "1rem", paddingTop: "1rem" }}
        src={ImgAxaLogo}
        alt="AXA Financial Indonesia"
        height={79}
      />
          {homeConfirm ? (
            <button
              type="button"
              className="logo-link"
              onClick={
                onClick
                  ? (e) =>
                    ask(url, confirmTitle, confirmDescription, onClick, e)
                  : (e) =>
                    goHome({
                      e,
                      url: homeUrl,
                      needConfirm: true,
                      title: homeTitle,
                      html: homeHtml,
                    })
              }
            >
              <img
                className="py-2 no-drag"
                src={ImgAxa}
                alt="AXA Financial Indonesia"
                height={79}
              />
            </button>
          ) : (
            <button
              type="button"
              className="logo-link"
              onClick={
                onClick
                  ? onClick
                  : (e) =>
                    goHome({
                      e,
                      url: homeUrl,
                    })
              }
            >
              <img
                className="py-2 no-drag"
                src={ImgAxa}
                alt="AXA Financial Indonesia"
                height={79}
              />
            </button>
          )}
        </div>
      </div>
      <div className="row mx-0 ribbon">
        <div className="position-relative d-flex align-items-center justify-content-center py-2">
          {button ? (
            right === false ? (
              confirm ? (
                <button
                  onClick={() =>
                    ask(url, confirmTitle, confirmDescription, onClick)
                  }
                  className="nav-page"
                >
                  <span className={icon}></span>
                  <span className={iconMobile}></span>
                  <span> Kembali </span>
                </button>
              ) : onClick ? (
                <button onClick={onClick} className="nav-page">
                  <span className={icon}></span>
                  <span className={iconMobile}></span>
                  <span> Kembali </span>
                </button>
              ) : (
                <Link to={url} type="button" className="nav-page">
                  <span className={icon}></span>
                  <span className={iconMobile}></span>
                  <span> Kembali </span>
                </Link>
              )
            ) : null
          ) : null}

          {isAdmin ? (
            moduleAccess?.map((module, key) =>
              module.name === "user_management" ? (
                <Dropdown key={"dropdown1" + key}>
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-basic"
                    className={
                      moduleName === "users" ||
                        moduleName === "roles" ||
                        moduleName === "menus" ||
                        moduleName === "logs"
                        ? "fw-bolder"
                        : "fw-normal"
                    }
                  >
                    User Management
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={Link}
                      to={process.env.REACT_APP_SUBDIR + "/users"}
                      className={
                        moduleName === "users" ? "fw-bolder" : "fw-normal"
                      }
                    >
                      User
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to={process.env.REACT_APP_SUBDIR + "/roles"}
                      className={
                        moduleName === "roles" ? "fw-bolder" : "fw-normal"
                      }
                    >
                      Role
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to={process.env.REACT_APP_SUBDIR + "/menus"}
                      className={
                        moduleName === "menus" ? "fw-bolder" : "fw-normal"
                      }
                    >
                      Menu
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to={process.env.REACT_APP_SUBDIR + "/logs"}
                      className={
                        moduleName === "logs" ? "fw-bolder" : "fw-normal"
                      }
                    >
                      User Logs
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : module.name === "report" ? (
                <Dropdown key={"dropdown2" + key}>
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-basic"
                    className={
                      moduleName === "tokenapps" || moduleName === "charge"
                        ? "fw-bolder"
                        : "fw-normal"
                    }
                  >
                    Report
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={Link}
                      to={process.env.REACT_APP_SUBDIR + "/tokenapps"}
                      className={
                        moduleName === "tokenapps" ? "fw-bolder" : "fw-normal"
                      }
                    >
                      TokenApps Report
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to={process.env.REACT_APP_SUBDIR + "/charge"}
                      className={
                        moduleName === "charge" ? "fw-bolder" : "fw-normal"
                      }
                    >
                      Charge Report
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : module.name === "skdr_bca_registration" ? (
                <Link
                  key={module.id}
                  to={{ pathname: process.env.REACT_APP_SUBDIR + "/dashboard" }}
                  className={
                    moduleName === "skdr"
                      ? "fw-bolder m-3 text-white"
                      : "fw-normal m-3 text-white"
                  }
                >
                  SKDR BCA Registration
                </Link>
              ) : module.name === "exclude_auto_debit" ? (
                <Link
                  key={module.id}
                  to={{
                    pathname:
                      process.env.REACT_APP_SUBDIR + "/exclude-auto-debit",
                  }}
                  className={
                    moduleName === "exclude"
                      ? "fw-bolder m-3 text-white"
                      : "fw-normal m-3 text-white"
                  }
                >
                  Exclude Auto Debit
                </Link>
              ) : module.name === "refund_split_payment" ? (
                <Link
                  key={module.id}
                  to={{
                    pathname:
                      process.env.REACT_APP_SUBDIR + "/refund-split-payment",
                  }}
                  className={
                    moduleName === "refund"
                      ? "fw-bolder m-3 text-white"
                      : "fw-normal m-3 text-white"
                  }
                >
                  Refund Split Payment
                </Link>
              ) : null
            )
          ) : (
            <h5 className="fw-semibold m-0 text-white">{title}</h5>
          )}

          {button ? (
              confirm ? (
                <button
                  onClick={() => ask(process.env.REACT_APP_SUBDIR + "/sign-out", "Keluar?", "Apakah anda yakin ingin mengakhiri sesi anda?")}
                  className="nav-page logout"
                >
                  <span className={"ic-out"}></span>
                  <span className={"ic-out-mobile nav-mobile"}></span>
                  <span> Keluar </span>
                </button>
              ) : (
                <button
                  onClick={() => ask(process.env.REACT_APP_SUBDIR + "/sign-out", "Keluar?", "Apakah anda yakin ingin mengakhiri sesi anda?")}
                  className="nav-page logout"
                >
                  <span className={"ic-out"}></span>
                  <span className={"ic-out-mobile nav-mobile"}></span>
                  <span> Keluar </span>
                </button>
              )
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
