import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "./components/Layout/AuthLayout";
import VerificationLayout from "./components/Layout/VerificationLayout";
import NonAuthLayout from "./components/Layout/NonAuthLayout";
import AdminLayout from "./components/Layout/AdminLayout";
import ErrorLayout from "./components/Layout/ErrorLayout";
import LandingPage from "./pages/LandingPage";
import Beranda from "./pages/Beranda";
import MetodePembayaran from "./pages/MetodePembayaran";
import Otp from "./pages/Otp";
import PembayaranGopay from "./pages/PembayaranGopay";
import PembayaranVa from "./pages/PembayaranVa";
import PembayaranVaBca from "./pages/PembayaranVaBca";
import PembayaranKartu from "./pages/PembayaranKartu";
import PembayaranBerhasil from "./pages/PembayaranBerhasil";
import PembayaranGagal from "./pages/PembayaranGagal";
import PembayaranDiproses from "./pages/PembayaranDiproses";
import AutoDebitBca from "./pages/AutoDebitBca";
import AutoDebitBerhasil from "./pages/AutoDebitBerhasil";
import AutoDebitDiproses from "./pages/AutoDebitDiproses";
import AutoDebitGagal from "./pages/AutoDebitGagal";
import { useSelector } from "react-redux";
import Page404 from "./pages/Error/Page404";
import SignOut from "./pages/SignOut";
import SignOutAdmin from "./pages/SignOutAdmin";
import Download from "./pages/download";
import AutoDebitKartuKredit from "./pages/AutoDebitKartuKredit";
import LandingPageAdmin from "./pages/LandingPageAdmin";
import UnduhBuktiPembayaran from "./pages/UnduhBuktiPembayaran";
import UnduhBuktiPendaftaranBcaCard from "./pages/UnduhBuktiPendaftaranBcaCard";
import AutoDebitBcaPrivateCard from "./pages/AutoDebitBcaPrivateCard";
import Dashboard from "./pages/dashboard/Dashboard";
import User from "./pages/dashboard/User";
import Menu from "./pages/dashboard/Menu";
import Role from "./pages/dashboard/Role";
import UserLog from "./pages/dashboard/UserLog";
import TokenApps from "./pages/dashboard/TokenApps";
import Charge from "./pages/dashboard/Charge";
import ExcludeAutoDebit from "./pages/dashboard/ExcludeAutoDebit";
import RefundSplitPayment from "./pages/dashboard/RefundSplitPayment";

import Cookies from "js-cookie";
import { visiblePaymentVa } from "./utils/config";
import RegistrasiAutoDebit from "./pages/RegistrasiAutoDebit";
import { encryptDecript } from "./helpers/encryptDecrypt";
import Page403 from "./pages/Error/Page403";

const App = ({ childProps }) => {
  return (
    <Switch>
      <Route
        exact
        path={"/"}
        render={() => (
          <Redirect to={process.env.REACT_APP_SUBDIR + "/sign-in"} />
        )}
      />
      <Route
        exact
        path={process.env.REACT_APP_SUBDIR + "/"}
        render={() => (
          <Redirect to={process.env.REACT_APP_SUBDIR + "/sign-in"} />
        )}
      />
      <Route
        path={process.env.REACT_APP_SUBDIR + "/unduh-bukti-pembayaran"}
        component={UnduhBuktiPembayaran}
        props={childProps}
      />
      <Route
        path={
          process.env.REACT_APP_SUBDIR + "/unduh-bukti-pendaftaran-bca-card"
        }
        component={UnduhBuktiPendaftaranBcaCard}
        props={childProps}
      />
      <Route
        path={process.env.REACT_APP_SUBDIR + "/download"}
        component={Download}
        props={childProps}
      />

      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/beranda"}
        component={Beranda}
        props={childProps}
      />
      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/metode-pembayaran"}
        component={MetodePembayaran}
        props={childProps}
      />
      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/registrasi-autodebit"}
        component={RegistrasiAutoDebit}
        props={childProps}
      />
      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/pembayaran-gopay"}
        component={PembayaranGopay}
        props={childProps}
      />
      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/pembayaran-kartu"}
        component={PembayaranKartu}
        props={childProps}
      />
      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/pembayaran-va"}
        component={visiblePaymentVa ? PembayaranVa : Page404}
        props={childProps}
      />
      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/pembayaran-va-bca"}
        component={PembayaranVaBca}
        props={childProps}
      />
      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/pembayaran-berhasil"}
        component={PembayaranBerhasil}
        props={childProps}
      />
      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/pembayaran-gagal"}
        component={PembayaranGagal}
        props={childProps}
      />
      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/pembayaran-diproses"}
        component={PembayaranDiproses}
        props={childProps}
      />
      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/auto-debit-bca"}
        component={AutoDebitBca}
        props={childProps}
      />
      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/auto-debit-bca-private-card"}
        component={AutoDebitBcaPrivateCard}
        props={childProps}
      />
      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/auto-debit-kartu-kredit"}
        component={AutoDebitKartuKredit}
        props={childProps}
      />
      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/auto-debit-berhasil"}
        component={AutoDebitBerhasil}
        props={childProps}
      />
      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/auto-debit-gagal"}
        component={AutoDebitGagal}
        props={childProps}
      />
      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/auto-debit-diproses"}
        component={AutoDebitDiproses}
        props={childProps}
      />
      <AuthRoute
        path={process.env.REACT_APP_SUBDIR + "/sign-out"}
        component={SignOut}
        props={childProps}
      />

      <NonAuthRoute
        path={process.env.REACT_APP_SUBDIR + "/sign-in"}
        component={LandingPage}
        props={childProps}
      />

      <VerificationRoute
        path={process.env.REACT_APP_SUBDIR + "/otp"}
        component={Otp}
        props={childProps}
      />

      <AdminNonAuthRoute
        path={process.env.REACT_APP_SUBDIR + "/admin"}
        exact
        component={LandingPageAdmin}
      />

      <AdminAuthRoute
        path={process.env.REACT_APP_SUBDIR + "/dashboard"}
        exact
        component={Dashboard}
      />
      <AdminAuthRoute
        path={process.env.REACT_APP_SUBDIR + "/users"}
        exact
        component={User}
      />
      <AdminAuthRoute
        path={process.env.REACT_APP_SUBDIR + "/menus"}
        exact
        component={Menu}
      />
      <AdminAuthRoute
        path={process.env.REACT_APP_SUBDIR + "/roles"}
        exact
        component={Role}
      />
      <AdminAuthRoute
        path={process.env.REACT_APP_SUBDIR + "/logs"}
        exact
        component={UserLog}
      />
      <AdminAuthRoute
        path={process.env.REACT_APP_SUBDIR + "/tokenapps"}
        exact
        component={TokenApps}
      />
      <AdminAuthRoute
        path={process.env.REACT_APP_SUBDIR + "/charge"}
        exact
        component={Charge}
      />
      <AdminAuthRoute
        path={process.env.REACT_APP_SUBDIR + "/exclude-auto-debit"}
        exact
        component={ExcludeAutoDebit}
      />
      <AdminAuthRoute
        path={process.env.REACT_APP_SUBDIR + "/refund-split-payment"}
        exact
        component={RefundSplitPayment}
      />
      <AdminAuthRoute
        path={process.env.REACT_APP_SUBDIR + "/sign-out-admin"}
        component={SignOutAdmin}
        props={childProps}
      />

      <ErrorRoute path="*" exact={true} component={Page404} />
    </Switch>
  );
};

const ErrorRoute = ({ component: Component, location, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <ErrorLayout>
          <Component {...props} />
        </ErrorLayout>
      )}
    />
  );
};

const NonAuthRoute = ({ component: Component, location, ...rest }) => {
  const auth = useSelector((state) => state.auth);
  const payment = useSelector((state) => state.payment);
  let pass = false;

  if (auth?.polis) {
    if (payment.payment) {
      pass = true;
    } else {
      const now = parseInt(new Date().getTime() / 1000);
      const sisaWaktu = auth?.expiredAt - now;

      if (sisaWaktu > 0) {
        pass = true;
      }
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        pass === true ? (
          <Redirect
            to={{
              pathname: process.env.REACT_APP_SUBDIR + "/beranda",
              state: { from: props.location },
            }}
          />
        ) : (
          <NonAuthLayout>
            <Component {...props} />
          </NonAuthLayout>
        )
      }
    />
  );
};

const VerificationRoute = ({ component: Component, location, ...rest }) => {
  const verification = useSelector((state) => state.verification);

  return (
    <Route
      {...rest}
      render={(props) =>
        verification?.polis ? (
          <VerificationLayout>
            <Component {...props} />
          </VerificationLayout>
        ) : (
          <Redirect
            to={{
              pathname: process.env.REACT_APP_SUBDIR + "/sign-in",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const AuthRoute = ({ component: Component, location, ...rest }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        auth?.polis ? (
          <AuthLayout>
            <Component {...props} />
          </AuthLayout>
        ) : (
          <Redirect
            to={{
              pathname: process.env.REACT_APP_SUBDIR + "/sign-in",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const AdminNonAuthRoute = ({ component: Component, location, ...rest }) => {
  let isAuthorized = true;
  if (
    // typeof Cookies.get("AFIPAY01A01") !== "undefined" &&
    // Cookies.get("AFIPAY01A01") !== ""
    typeof localStorage.getItem("AFIPAY01A01") !== "undefined" ||
    localStorage.getItem("AFIPAY01A01") !== ""
  ) {
    isAuthorized = false;
  }

  let moduleAccess = "";
  let endpoint = "";
  if (
    typeof localStorage.getItem("AFIPAY02B01") !== "undefined" &&
    localStorage.getItem("AFIPAY02B01") !== null
  ) {
    moduleAccess = encryptDecript(localStorage.getItem("AFIPAY02B01"), 2);
    if (moduleAccess[0].name === "skdr_bca_registration") {
      endpoint = "dashboard";
    } else if (moduleAccess[0].name === "report") {
      endpoint = "tokenapps";
    } else if (moduleAccess[0].name === "user_management") {
      endpoint = "users";
    } else if (moduleAccess[0].name === "exclude_auto_debit") {
      endpoint = "exclude-auto-debit";
    } else if (moduleAccess[0].name === "refund_split_payment") {
      endpoint = "refund-split-payment";
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthorized === true ? (
          <Redirect
            to={{
              pathname: process.env.REACT_APP_SUBDIR + "/" + endpoint,
              state: { from: props.location },
            }}
          />
        ) : (
          <NonAuthLayout>
            <Component {...props} />
          </NonAuthLayout>
        )
      }
    />
  );
};

const AdminAuthRoute = ({ component: Component, location, ...rest }) => {
  let isAuthorized = true;
  if (
    // typeof Cookies.get("AFIPAY01A01") === children"undefined" ||
    // Cookies.get("AFIPAY01A01") === ""
    typeof localStorage.getItem("AFIPAY01A01") === "undefined" ||
    localStorage.getItem("AFIPAY01A01") === "" ||
    localStorage.getItem("AFIPAY01A01") === null
  ) {
    isAuthorized = false;
  }

  let listModule = [];
  let endpoint = "";
  let isHaveAccess = false;
  let pathName = "";
  let subPathName = location.pathname.split("/");
  if (subPathName.length === 3) {
    pathName = subPathName[2];
  } else {
    pathName = location.pathname.replace("/", "");
  }

  if (
    typeof localStorage.getItem("AFIPAY02B01") !== "undefined" &&
    localStorage.getItem("AFIPAY02B01") !== null
  ) {
    listModule = encryptDecript(localStorage.getItem("AFIPAY02B01"), 2);
    if (listModule[0].name === "skdr_bca_registration") {
      endpoint = "dashboard";
    } else if (listModule[0].name === "report") {
      endpoint = "tokenapps";
    } else if (listModule[0].name === "user_management") {
      endpoint = "users";
    } else if (listModule[0].name === "exclude_auto_debit") {
      endpoint = "exclude-auto-debit";
    } else if (listModule[0].name === "refund_split_payment") {
      endpoint = "refund-split-payment";
    }
    listModule.map((item) => {
      if (item.name === "skdr_bca_registration") {
        if (pathName === "dashboard") isHaveAccess = true;
      } else if (item.name === "user_management") {
        if (
          pathName === "users" ||
          pathName === "roles" ||
          pathName === "menus" ||
          pathName === "logs"
        )
          isHaveAccess = true;
      } else if (item.name === "report") {
        if (pathName === "tokenapps" || pathName === "charge")
          isHaveAccess = true;
      } else if (item.name === "exclude_auto_debit") {
        if (pathName === "exclude-auto-debit") isHaveAccess = true;
      } else if (item.name === "refund_split_payment") {
        if (pathName === "refund-split-payment") isHaveAccess = true;
      }
    });
  }

  if (pathName === "sign-out-admin") isHaveAccess = true;

  return (
    <Route
      {...rest}
      true
      render={(props) =>
        isAuthorized === true ? (
          isHaveAccess === true ? (
            <AdminLayout>
              <Component {...props} />
            </AdminLayout>
          ) : (
            <ErrorLayout>
              <Page403 {...props} endpoint={endpoint} />
            </ErrorLayout>
          )
        ) : (
          <Redirect
            to={{
              pathname: process.env.REACT_APP_SUBDIR + "/admin",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default App;
