import React, { useEffect, useState } from "react"
import IcSiku from "../../assets/new/img/icons/ic-siku.svg"

const PolisDetailView = ({ title, data, height = null }) => {
    const [isShow, setIsShow] = useState(true)
    const [isFlip, setIsFlip] = useState(false)

    let resizeWindow = () => {
        if (window.innerWidth > window.innerHeight) {
            setIsShow(true)
            setIsFlip(false)
        }
    }

    useEffect(() => {
        resizeWindow()
        window.addEventListener("resize", resizeWindow)
        return () => window.removeEventListener("resize", resizeWindow)
    }, [])

    const rotateToggle = () => {
        setIsShow(!isShow)
        setIsFlip(!isFlip)
    }

    return (
        <React.Fragment>
            <div className="card" style={{ minHeight: height ? height : 'inherit' }}>
                <div className="card-header accordion-item text-start">
                    <span className="fw-semibold">{title || "-"}</span>
                    <span className={`collapse-trigger ${isFlip === true ? 'flip' : ''}`} data-bs-toggle="collapse" aria-expanded="true"
                        aria-controls="collapseExample" href="#collapseCard" role="button"
                        onClick={() => rotateToggle()}>
                        <img src={IcSiku} width="100%" alt="siku" />
                    </span>
                </div>
                <div className={`card-body accordion-collapse text-start collapse ${isShow === true ? 'show' : ''}`}>
                    <div className="accordion-body">
                        {
                            data.map((val, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <p className="card-text-label">{val.label || "-"}</p>
                                        {


                                            !val.value ?

                                                <p className="card-text-data text-danger">-</p>
                                                : val.label === 'Tanggal Jatuh Tempo' ?
                                                    <p className="card-text-data text-danger"><b>{val.value}</b></p> :
                                                    <p className="card-text-data"><b>{val.value}</b></p>


                                        }
                                        {

                                            val.type === "status" ?
                                                <p>{val.additional}</p>
                                                : ""
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PolisDetailView
