import actionTypes from './actionTypes'

export const actionCheckCurrency = () => {
    return {
        type: actionTypes.CHECK_CURRENCY
    }
}

export const actionCheckCurrencyStart = () => {
    return {
        type: actionTypes.CHECK_CURRENCY_START
    }
}

export const actionCheckCurrencyEnd = () => {
    return {
        type: actionTypes.CHECK_CURRENCY_END
    }
}

export const actionCheckCurrencyFailed = () => {
    return {
        type: actionTypes.CHECK_CURRENCY_FAILED
    }
}

export const actionCheckCurrencySuccess = (data) => {
    return {
        type: actionTypes.CHECK_CURRENCY_SUCCESS,
        data
    }
}

export const actionResetCurrency = () => {
    return {
        type: actionTypes.RESET_CURRENCY
    }
}