import React, { useEffect, useRef, useState } from "react"
import Header from "../components/Layout/AuthLayout/Header"
import AfipayDesc from "../components/Layout/AuthLayout/AfipayDesc"
import ImgBca from "../assets/new/img/logo-bca-lg.png"
import ImgPermata from "../assets/new/img/logo-permata.png"
import IcCopy from "../assets/new/img/icons/ic-copy.svg"
import IcTimeleft from "../assets/new/img/icons/ic-timeleft.svg"
import CaraPembayaran from "./PembayaranVa/CaraPembayaran"
import { asCurrency, asTime, dateTime } from "../utils/formatter"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import toast, { Toaster } from 'react-hot-toast'
import { connect } from 'react-redux'
import { Link, withRouter } from "react-router-dom"
import { actionCreatePayment, actionResetPayment, actionSetLastSuccess, actionSetLastFailed, actionInquiryPaymentStatus } from "../store/payment/actions"
import Preloader from "../components/Preloader/index.js"
import ImgFailed from "../assets/new/img/illustration-confirmation-failed.svg"
import ImgProcess from "../assets/new/img/illustration-confirmation-process.svg"

const PembayaranVa = (props) => {
    let data = props?.history?.location?.data || null
    let bank = data?.bank

    const { polis, payment, hubunganPembayar, actionCreatePayment, actionResetPayment, actionInquiryPaymentStatus, actionSetLastSuccess, actionSetLastFailed, buyRate } = props

    const [sisaWaktuPembayaran, setSiswaWaktuPembayaran] = useState(null)
    const [waktuMulai, setWaktuMulai] = useState(false)
    const [countCheck, setCountCheck] = useState(0)
    const [submit, setSubmit] = useState(false)

    const [pending, setPending] = useState(false)

    useEffect(() => {
        createPayment()
    }, [])

    const createPayment = () => {
        actionCreatePayment({
            polis, payment, hubunganPembayar, method: "Pembayaran", paymentType: (bank === "BCA" ? "va_bca" : (bank === "Permata" ? "va_permata" : null)), kurs: buyRate
        })
    }

    useEffect(() => {
        setSubmit(true)
    }, [payment?.success])

    const handleCopy = () => {
        toast('Berhasil disalin', {
            duration: 1000,
            position: 'bottom-left',
        })
    }

    useEffect(() => {
        if (payment?.transaction_success === true) {
            setCountCheck(-1)
            actionResetPayment()
            actionSetLastSuccess({
                transactionId: payment?.payment?.trxRefference,
                noPolis: polis?.polisnumber,
                totalPembayaran: payment?.result?.gross_amount,
                tanggalTransaksi: payment?.result?.transaction_time,
                jenisTransaksi: "Pembayaran Premi",
                metodePembayaran: "Rekening Virtual " + (payment?.result?.va_bank === "bca" ? "BCA" : (payment?.result?.va_bank === "permata" ? "Permata" : (bank || ""))),
                namaLengkap: polis?.policyHolderNm,
                noHp: polis?.mobilePhoneNo,
                nomorVa: payment?.result?.va_number,
                lanjutAutoDebet: false,
                notified: false
            })
            props.history.replace({ pathname: process.env.REACT_APP_SUBDIR + "/pembayaran-berhasil" })
        } else if (payment?.transaction_success === false) {
            setCountCheck(-1)
            actionResetPayment()
            actionSetLastFailed({
                transactionId: payment?.payment?.trxRefference
            })
            props.history.replace({ pathname: process.env.REACT_APP_SUBDIR + "/pembayaran-gagal" })
        }
    }, [payment?.transaction_success])

    useEffect(() => {
        if (payment?.transaction_pending === true) {
            setPending(true)
        } else if (payment?.transaction_pending === false) {
            setPending(false)
        }
    }, [payment?.transaction_pending])

    useEffect(() => {
        if (payment?.transactionId) {
            actionInquiryPaymentStatus({
                polis: payment?.polis,
                payment: payment?.payment,
                transactionID: payment?.transactionId
            })
            setCountCheck(1)
        }
    }, [payment?.transactionId])

    useEffect(() => {
        if (countCheck <= 0) return

        const intervalId = setInterval(() => {
            setCountCheck(countCheck + 1)
            actionInquiryPaymentStatus({
                polis: payment?.polis,
                payment: payment?.payment,
                transactionID: payment?.transactionId
            })
        }, 5000)

        return () => clearInterval(intervalId)
    }, [countCheck])

    useEffect(() => {
        let now = parseInt((new Date).getTime() / 1000)
        let sisaWaktu = payment?.expiredAt - now
        if (sisaWaktu > 0) {
            // setSiswaWaktuPembayaran(sisaWaktu > 86399 ? 86399 : sisaWaktu)
            setSiswaWaktuPembayaran(sisaWaktu)
            setWaktuMulai(true)
        }
    }, [payment?.expiredAt])

    useEffect(() => {
        if (sisaWaktuPembayaran === null) return
        if (!sisaWaktuPembayaran) return

        const intervalId = setInterval(() => {
            setSiswaWaktuPembayaran(sisaWaktuPembayaran - 1)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [sisaWaktuPembayaran])

    useEffect(() => {
        if (waktuMulai === true) {
            if (sisaWaktuPembayaran < 1) {
                actionResetPayment()
                actionSetLastFailed({
                    transactionId: payment?.payment?.trxRefference
                })
                props.history.replace({ pathname: process.env.REACT_APP_SUBDIR + "/pembayaran-gagal" })
            }
        }
    }, [waktuMulai, sisaWaktuPembayaran])

    const paymentFailedScreen = () => {
        return <React.Fragment>
            <div className="container metode-pembayaran konfirmasi text-center pt-4 mb-5">
                <img className="pt-4 pb-3" src={ImgFailed} alt="confirmation" />
                <div className="subtitle-page fw-bold pt-2 mb-2" style={{color:"red",fontSize:"20px"}}>
                    Upps, Pembayaran Gagal Dibuat
                </div>
                <p style={{ marginBottom: "2rem" }} className="px-3"> Silakan coba lagi metode pembayaran Anda.
                </p>
                <div className="inline-button pt-4">
                    <button onClick={() => onClickBack()} type="button" className="btn btn-primary col mb-2">Ulangi Pembayaran</button>
                    <Link to={process.env.REACT_APP_SUBDIR + "/sign-out"} type="button" className="btn btn-outline-danger col mb-2">Keluar</Link>
                </div>
            </div>
        </React.Fragment>
    }

    const detailPembayaran = () => {
        return <React.Fragment>
            {
                sisaWaktuPembayaran > 0 ?
                    <div className="container pembayaran-kartu text-center pt-4 pb-5">
                        <p className="card-text-label">Batas Akhir Pembayaran</p>
                        <p className="fw-semibold">{dateTime(payment?.result?.expiry_time)}</p>
                        <p className="highlight-text-red-md">
                            <img src={IcTimeleft} style={{ maxHeight: "15px" }} alt="waktu-tersisa" />
                            {" "}
                            {asTime(sisaWaktuPembayaran, false, true)}
                        </p>

                        <div className="card p-2">
                            <div className="card-header text-start d-flex">
                                <div style={{ width: payment?.result?.va_bank === "bca" || bank === "BCA" ? "80%" : "70%" }}>
                                    {
                                        payment?.result?.va_bank ?
                                            <p className="fw-semibold mb-2 color-dark"><small>Virtual Akun {(payment?.result?.va_bank === "bca" ? "BCA" : (payment?.result?.va_bank === "permata" ? "Permata" : (bank || "")))}</small></p>
                                            :
                                            <p className="fw-semibold mb-2 color-dark"><small>Virtual Akun {bank}</small></p>
                                    }
                                </div>
                                <div style={{ width: payment?.result?.va_bank === "bca" || bank === "BCA" ? "20%" : "30%" }}>
                                    {
                                        payment?.result?.va_bank === "bca" || bank === "BCA" ?
                                            <img src={ImgBca} width="100%" alt="bca" /> : null
                                    }
                                    {
                                        payment?.result?.va_bank === "permata" || bank === "Permata" ?
                                            <img src={ImgPermata} width="100%" alt="permata" /> : null
                                    }
                                </div>
                            </div>
                            <div className="card-body text-start d-flex">
                                <div style={{ width: "91%" }}>
                                    <p className="card-text-label">Nomor Virtual Account</p>
                                    <p className="card-text-data">{payment?.result?.va_number}</p>
                                    <p className="card-text-label">Total Pembayaran</p>
                                    <p className="card-text-data">Rp {asCurrency(parseInt(payment?.result?.gross_amount))}</p>
                                </div>
                                <div className="p-1" style={{ width: "9%" }}>
                                    <CopyToClipboard onCopy={() => handleCopy()} text={payment?.result?.va_number}>
                                        <a href="." onClick={(e) => e.preventDefault()}>
                                            <img src={IcCopy} style={{ width: "100%", maxWidth: "20px", float: "right" }} alt="copy" />
                                        </a>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>

                        {
                            payment?.result?.va_bank === "bca" || bank === "BCA" ? <div>
                                <br />
                                <p className="fw-semibold">Cara pembayaran</p>
                                <CaraPembayaran title={"ATM BCA"}
                                    data={[
                                        <span>Masukkan kartu ATM dan PIN BCA Anda</span>,
                                        <span>Pilih menu <b>Trasaksi Lainnya – Transfer – Ke Rekening BCA Virtual Account</b></span>,
                                        <span>Masukkan nomor Virtual Account diatas sebagai rekening tujuan</span>,
                                        <span>Periksa nominal yang tertera</span>,
                                        <span>Ikuti instruksi untuk menyelesaikan transaksi</span>
                                    ]}
                                />
                                <br />
                                <CaraPembayaran title={"Klik BCA"} show={false}
                                    data={[
                                        <span>Masuk ke website <b>KLIK BCA</b></span>,
                                        <span>Pilih menu <b>Transfer Dana - Transfer ke BCA Virtual Account</b></span>,
                                        <span>Masukkan nomor Virtual Account diatas sebagai rekening tujuan</span>,
                                        <span>Periksa nominal yang tertera</span>,
                                        <span>Ikuti instruksi untuk menyelesaikan transaksi</span>
                                    ]} />
                                <br />
                                <CaraPembayaran title={"m-BCA (BCA mobile)"} show={false}
                                    data={[
                                        <span>Masuk ke aplikasi mobile <b>m-BCA</b></span>,
                                        <span>Pilih menu <b>m-Transfer – Ke Rekening BCA Virtual Account</b></span>,
                                        <span>Masukkan nomor Virtual Account diatas sebagai rekening tujuan</span>,
                                        <span>Periksa nominal yang tertera</span>,
                                        <span>Masukkan <b>PIN m-BCA</b> Anda</span>,
                                        <span>Ikuti instruksi untuk menyelesaikan transaksi</span>
                                    ]} />
                            </div> : null
                        }

                        {
                            payment?.result?.va_bank?.toLowerCase() === "permata" || bank?.toLowerCase() === "permata" ? <div>
                                <br />
                                <p className="fw-semibold">Cara pembayaran</p>
                                <CaraPembayaran title={"ATM Permata"}
                                    data={[
                                        <span>Masukkan kartu ATM dan PIN Bank Permata Anda</span>,
                                        <span>Pilih menu <b>Trasaksi Lainnya – Transfer – Ke Rekening Permata Virtual Account</b></span>,
                                        <span>Masukkan nomor Virtual Account diatas sebagai rekening tujuan</span>,
                                        <span>Periksa nominal yang tertera</span>,
                                        <span>Ikuti instruksi untuk menyelesaikan transaksi</span>
                                    ]}
                                />
                                <br />
                                <CaraPembayaran title={"Permata Internet Banking"} show={false}
                                    data={[
                                        <span>Masuk ke website <b>new.permata.net</b></span>,
                                        <span>Pilih menu <b>Transfer - Transfer ke Permata Virtual Account</b></span>,
                                        <span>Masukkan nomor Virtual Account diatas sebagai rekening tujuan</span>,
                                        <span>Periksa nominal yang tertera</span>,
                                        <span>Ikuti instruksi untuk menyelesaikan transaksi</span>
                                    ]} />
                                <br />
                            </div> : null
                        }
                    </div>
                    : null
            }
        </React.Fragment>
    }

    const onClickBack = async () => {
        actionResetPayment()
        props.history.replace({
            pathname: submit === true && payment?.success === true ? (process.env.REACT_APP_SUBDIR + "/beranda") : (process.env.REACT_APP_SUBDIR + "/metode-pembayaran")
        })
    }

    const viewPending = () => {
        return <React.Fragment>
            <div className="container metode-pembayaran konfirmasi text-center pt-4 mb-1">
                <img className="pt-4 pb-3" src={ImgProcess} alt="confirmation" />
                <div className="subtitle-page fw-bold pt-2 mt-2 mb-2">
                    Pendaftaran Autodebit Anda Pending
                </div>
            </div>
        </React.Fragment>
    }

    return <React.Fragment>
        <Header onClick={() => onClickBack()} urlLabel="Kembali" title={"Pembayaran"} confirm={submit === true && payment?.success === true} confirmTitle={"Kembali"} confirmDescription={"Batalkan pembayaran?"} homeConfirm={submit === true && payment?.success === true} />


        <div style={{ minHeight: "30vh" }}>
            <Preloader visible={payment?.loading === true} />
            {
                submit === true && payment?.loading === false ?
                    submit === true && payment?.success === false ?
                        paymentFailedScreen()
                        :
                        pending === true ?
                            viewPending()
                            :
                            detailPembayaran()
                    : null
            }

        </div>
        <AfipayDesc />
        <Toaster />
    </React.Fragment>
}

const mapStateToProps = state => {
    return {
        polis: state.auth?.polis,
        hubunganPembayar: state.auth?.hubunganPembayar,
        payment: state.payment,
        buyRate: state.currency?.buyRate
    }
}

export default withRouter(connect(mapStateToProps, { actionCreatePayment, actionResetPayment, actionInquiryPaymentStatus, actionSetLastSuccess, actionSetLastFailed })(PembayaranVa))