import actionTypes from './actionTypes'

export const actionCreatePayment = (data) => {
    return {
        type: actionTypes.CREATE_PAYMENT,
        data
    }
}

export const actionCreatePaymentStart = (data) => {
    return {
        type: actionTypes.CREATE_PAYMENT_START,
        data
    }
}

export const actionSetPaymentSuccess = (data) => {
    return {
        type: actionTypes.CREATE_PAYMENT_SUCCESS,
        data
    }
}

export const actionSetPaymentFailed = (data) => {
    return {
        type: actionTypes.CREATE_PAYMENT_FAILED,
        data
    }
}

export const actionCreatePaymentEnd = (data) => {
    return {
        type: actionTypes.CREATE_PAYMENT_END,
        data
    }
}

export const actionCreateBcaPrivate = (data) => {
    return {
        type: actionTypes.CREATE_BCA_PRIVATE,
        data
    }
}

export const actionCreateBcaPrivateStart = (data) => {
    return {
        type: actionTypes.CREATE_BCA_PRIVATE_START,
        data
    }
}

export const actionCreateBcaPrivateSuccess = (data) => {
    return {
        type: actionTypes.CREATE_BCA_PRIVATE_SUCCESS,
        data
    }
}

export const actionCreateBcaPrivateFailed = (data) => {
    return {
        type: actionTypes.CREATE_BCA_PRIVATE_FAILED,
        data
    }
}

export const actionCreateBcaPrivateEnd = (data) => {
    return {
        type: actionTypes.CREATE_BCA_PRIVATE_END,
        data
    }
}

export const actionResetPayment = () => {
    return {
        type: actionTypes.RESET_PAYMENT,
    }
}

export const actionInquiryPaymentStatus = (data) => {
    return {
        type: actionTypes.INQUIRY_PAYMENT_STATUS,
        data
    }
}

export const acionTransactionSuccess = () => {
    return {
        type: actionTypes.TRANSACTION_SUCCESS,
    }
}

export const acionTransactionFailed = (data) => {
    return {
        type: actionTypes.TRANSACTION_FAILED,
        data
    }
}

export const acionTransactionPending = () => {
    return {
        type: actionTypes.TRANSACTION_PENDING,
    }
}

export const actionCheckPaymentValidation = (data) => {
    return {
        type: actionTypes.CHECK_PAYMENT_VALIDATION,
        data
    }
}

export const actionCheckActivePayment = (data) => {
    return {
        type: actionTypes.CHECK_ACTIVE_PAYMENT,
        data
    }
}

export const actionSetQrGopay = (data) => {
    return {
        type: actionTypes.SET_QR_GOPAY,
        data
    }
}

export const actionSetUrlGopay = (data) => {
    return {
        type: actionTypes.SET_URL_GOPAY,
        data
    }
}

export const actionSetDownload = (data) => {
    return {
        type: actionTypes.SET_DOWNLOAD,
        data
    }
}

export const actionCreateBca = (data) => {
    return {
        type: actionTypes.CREATE_BCA,
        data
    }
}

export const actionCreateBcaStart = (data) => {
    return {
        type: actionTypes.CREATE_BCA_START,
        data
    }
}

export const actionCreateBcaSuccess = (data) => {
    return {
        type: actionTypes.CREATE_BCA_SUCCESS,
        data
    }
}

export const actionCreateBcaFailed = (data) => {
    return {
        type: actionTypes.CREATE_BCA_FAILED,
        data
    }
}

export const actionCreateBcaEnd = (data) => {
    return {
        type: actionTypes.CREATE_BCA_END,
        data
    }
}

export const actionSetLastSuccess = (data) => {
    return {
        type: actionTypes.SET_LAST_SUCCESS,
        data
    }
}

export const actionSetLastFailed = (data) => {
    return {
        type: actionTypes.SET_LAST_FAILED,
        data
    }
}

export const actionSetLastSuccessNotified = () => {
    return {
        type: actionTypes.SET_LAST_SUCCESS_NOTIFIED,
    }
}