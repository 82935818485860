import React, { useEffect, useState } from "react"
import Header from "../components/Layout/AuthLayout/Header"
import AfipayDesc from "../components/Layout/AuthLayout/AfipayDesc"
import IcTimeleft from "../assets/new/img/icons/ic-timeleft.svg"
import ImgFailed from "../assets/new/img/illustration-confirmation-failed.svg"
import { asTime } from "../utils/formatter"
import { actionCreateBca, actionResetPayment, actionSetLastFailed } from "../store/payment/actions"
import { connect } from 'react-redux'
import { Link, withRouter } from "react-router-dom"
import Preloader from "../components/Preloader"

const AutoDebitBca = (props) => {
    let data = props?.history?.location?.data || null
    const { polis, payment, actionCreateBca, actionResetPayment, actionSetLastFailed } = props

    const [sisaWaktuPembayaran, setSiswaWaktuPembayaran] = useState(null)
    const [submit, setSubmit] = useState(false)

    const [frameSrc, setFrameSrc] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        createPayment()
    }, [])

    const createPayment = () => {
        actionCreateBca({
            polis, payment, dataBca: {
                "card_owner": data?.card_owner,
                "card_number": data?.card_number,
                "card_exp_month": data?.card_exp_month,
                "card_exp_year": data?.card_exp_year,
                "card_cvv": data?.card_cvv,
                "saveCard": data?.saveCard,
            }
        })
    }

    useEffect(() => {
        setSubmit(true)
    }, [payment?.success])

    useEffect(() => {
        if (sisaWaktuPembayaran === null) return
        if (!sisaWaktuPembayaran) return

        const intervalId = setInterval(() => {
            setSiswaWaktuPembayaran(sisaWaktuPembayaran - 1)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [sisaWaktuPembayaran])

    useEffect(() => {
        if (sisaWaktuPembayaran === null) return
        if (sisaWaktuPembayaran < 1) {
            gagal()
        }
    }, [sisaWaktuPembayaran])

    const gagal = () => {
        actionResetPayment()
        actionSetLastFailed({
            transactionId: payment?.payment?.trxRefference
        })
        props.history.replace({ pathname: process.env.REACT_APP_SUBDIR + "/auto-debit-gagal" })
    }

    useEffect(() => {
        if (payment?.transaction_success === true) {
            let now = parseInt((new Date).getTime() / 1000)
            let sisaWaktu = payment?.expiredAt - now
            if (sisaWaktu > 0) {
                setSiswaWaktuPembayaran(sisaWaktu)
                setLoading(true)
                setFrameSrc(payment?.result?.url)
            } else {
                gagal()
            }
        } else if (payment?.transaction_success === false) {
            gagal()
        }
    }, [payment?.transaction_success])

    const iframeLoaded = () => {
        setLoading(false)
    }

    const detailPembayaran = () => {
        return <React.Fragment>
            <Preloader visible={loading === true} />

            {
                frameSrc ?
                    <iframe
                        title="Registrasi Kartu"
                        src={frameSrc}
                        scrolling="no"
                        style={{
                            height: 480,
                            overflow: "hidden"
                        }} className="ma-frame" onLoad={iframeLoaded} />
                    : null
            }
        </React.Fragment>
    }

    const paymentFailedScreen = () => {
        return <React.Fragment>
            <div className="container metode-pembayaran konfirmasi text-center pt-4 mb-5">
                <img className="pt-4 pb-3" src={ImgFailed} alt="confirmation" />
                <div className="subtitle-page fw-bold pt-2 mb-2" style={{color:"red",fontSize:"20px"}}>
                    Upps, Pembayaran Gagal Dibuat
                </div>
                <p style={{ marginBottom: "2rem" }} className="px-3"> Silakan coba lagi metode pembayaran Anda.
                </p>
                <div className="inline-button pt-4">
                    <button onClick={() => onClickBack()} type="button" className="btn btn-primary col mb-2">Ulangi Pembayaran</button>
                    <Link to={process.env.REACT_APP_SUBDIR + "/sign-out"} type="button" className="btn btn-outline-danger col mb-2">Keluar</Link>
                </div>
            </div>
        </React.Fragment>
    }

    const onClickBack = async () => {
        actionResetPayment()
        props.history.replace({
            pathname: submit === true && payment?.success === true ? (process.env.REACT_APP_SUBDIR + "/beranda") : (process.env.REACT_APP_SUBDIR + "/registrasi-autodebit")
        })
    }

    return <React.Fragment>
        <Header onClick={() => onClickBack()} urlLabel="Kembali" title={"Pembayaran"} confirm={submit === true && payment?.success === true} confirmTitle={"Kembali"} confirmDescription={"Batalkan pembayaran?"} homeConfirm={submit === true && payment?.success === true} />
        

        <div style={{ minHeight: "30vh" }}>
            <Preloader visible={payment?.loading === true} />
            {
                submit === true && payment?.loading === false ?
                    submit === true && payment?.success === false ?
                        paymentFailedScreen()
                        :
                        detailPembayaran()
                    : null
            }
        </div>
        <AfipayDesc />
    </React.Fragment>
}

const mapStateToProps = state => {
    return {
        polis: state.auth?.polis,
        payment: state.payment
    }
}

export default withRouter(connect(mapStateToProps, { actionCreateBca, actionResetPayment, actionSetLastFailed })(AutoDebitBca))