import actionTypes from './actionTypes';

export const failure = (error) => {
  return {
    type: actionTypes.FAILURE,
    error,
  };
}

export const signIn = (param, query) => {
  return {
    type: actionTypes.SIGN_IN,
    param,
    query
  };
}

export const signInSuccess = (data) => {
  return {
    type: actionTypes.SIGN_IN_SUCCESS,
    data,
  };
}

export const signInFailed = (data) => {
  return {
    type: actionTypes.SIGN_IN_FAILED,
    data,
  };
}

export const logout = () => {
  return {
    type: actionTypes.LOGOUT,
  };
}

export const setPolis = (data) => {
  return {
    type: actionTypes.SET_POLIS,
    data
  }
}

export const actionSetHubunganPembayar = (data) => {
  return {
    type: actionTypes.SET_HUBUNGAN_PEMBAYAR,
    data
  }
}

export const actionSetEmailPembayar = (data) => {
  return {
    type: actionTypes.SET_EMAIL_PEMBAYAR,
    data
  }
}

export const actionSetDboDownload = (data) => {
  return {
    type: actionTypes.SET_DBO_DOWNLOAD,
    data
  }
}

export const actionSetSkdkkDownload = (data) => {
  return {
    type: actionTypes.SET_SKDKK_DOWNLOAD,
    data
  }
}

export const actionSetShowProduct = (data) => {
  return {
    type: actionTypes.SET_SHOW_PRODUCT,
    data
  }
}

export const actionSetShowPolicyHolder = (data) => {
  return {
    type: actionTypes.SET_SHOW_POLICY_HOLDER,
    data
  }
}

export const actionSetShowInsured = (data) => {
  return {
    type: actionTypes.SET_SHOW_INSURED,
    data
  }
}

export const actionSetPemegangPolis = (data) => {
  return {
    type: actionTypes.SET_PEMEGANG_POLIS,
    data
  }
}

export const actionResetAuth = () => {
  return {
    type: actionTypes.RESET_AUTH
  }
}

export const setExpired = (data) => {
  return {
    type: actionTypes.SET_EXPIRED,
    data
  }
}

export const setPaymentValidation = (data) => {
  return {
    type: actionTypes.SET_PAYMENT_VALIDATION,
    data
  }
}

export const actionResetField = () => {
  return {
    type: actionTypes.RESET_FIELD
  }
}