import React, { useState } from "react";

import useTable from "./useTable";
import TableFooter from "./TableFooter";
import Spinner from "../../../components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { asCurrency, asDateV4 } from "../../../utils/formatter";

const getHeader = () => {
  var keyHeader = [
    "Transaction Date",
    "Policy Number",
    "Owner Name",
    "Source",
    "Order ID",
    "Internal Token",
    "External Token",
    "Card Holder Name",
    "Masked Card",
    "Bank Name",
    "Expired Date",
    "Card Type",
    "Transaction Type",
    "Status Type",
    "Amount Transaction",
    "Beneficiary Owner",
    "System Type",
    "Status",
    "Remark",
  ];
  return keyHeader.map((key, index) => {
    return <th key={key}>{key}</th>;
  });
};

const mapRowsData = (data) => {
  return data.map((row, index) => {
    const {
      transactionDate,
      policyNumber,
      ownerName,
      source,
      orderID,
      internalToken,
      externalToken,
      cardHolderName,
      maskedCard,
      bankName,
      expiredDate,
      cardType,
      transactionType,
      statusType,
      amountTransaction,
      beneficiaryOwner,
      systemType,
      status,
      remark,
    } = row;

    return (
      <tr key={index}>
        <td>{asDateV4(transactionDate, true)}</td>
        <td>{policyNumber}</td>
        <td>{ownerName}</td>
        <td>{source}</td>
        <td>{orderID}</td>
        <td>{internalToken}</td>
        <td>{externalToken}</td>
        <td>{cardHolderName}</td>
        <td>{maskedCard}</td>
        <td>{bankName}</td>
        <td>{expiredDate}</td>
        <td>{cardType}</td>
        <td>{transactionType}</td>
        <td>{statusType}</td>
        <td>{asCurrency(amountTransaction)}</td>
        <td>{beneficiaryOwner}</td>
        <td>{systemType}</td>
        <td>{status}</td>
        <td>{remark}</td>
      </tr>
    );
  });
};

const TableTokenapps = ({
  data,
  rowsPerPage,
  loading,
  onChangeEntries,
  exportExcel,
}) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);

  return (
    <React.Fragment>
      <div id="masterTable" style={{ display: "none" }}>
        <div className="row justify-content-between pt-5 pb-1">
          <div className="col-lg-4">
            <span>Showing</span>
            <select
              className="form-select form-select-sm ms-2 me-2"
              style={{
                paddingRight: "2rem",
                width: "auto",
                height: "auto",
                display: "inline-block",
              }}
              value={rowsPerPage}
              onChange={(e) => onChangeEntries(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span>Entries</span>
          </div>
          <div className="col d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-block btn-outline-primary px-4"
              id="btnUnduh"
              onClick={() => exportExcel(data)}
              style={{
                borderRadius: "7px",
              }}
            >
              <FontAwesomeIcon icon={faDownload} className="me-2" />
              Unduh Excel
            </button>
          </div>
        </div>
        <div
          className="dashboard-table table-responsive mb-4"
          id="tableData"
          style={{ display: "none" }}
        >
          <div className="mt-2">
            <table
              className="table text-nowrap"
              width="100%"
              style={{
                marginLeft: 0,
                backgroundColor: "white",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: "rgb(0 0 143)",
                    marginLeft: "10px",
                    marginRight: "10px",
                    // borderCollapse: "collapse",
                    textAlign: "center",
                    color: "white",
                    verticalAlign: "center",
                  }}
                >
                  {getHeader()}
                </tr>
              </thead>
              <tbody
                style={{
                  backgroundColor: "white",
                  textAlign: "center",
                }}
              >
                {loading ? (
                  <tr>
                    <td colSpan={17}>
                      <Spinner
                        visible={loading}
                        bgColor={"#00006e"}
                        size={30}
                      />
                    </td>
                  </tr>
                ) : data !== null && data.length > 0 ? (
                  mapRowsData(slice)
                ) : (
                  <tr>
                    <td colSpan={17}>No Data Available in Table</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <TableFooter
          range={range}
          slice={slice}
          setPage={setPage}
          page={page}
        />
      </div>
    </React.Fragment>
  );
};

export default TableTokenapps;
