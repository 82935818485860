import React, { useState } from "react";
import axios from "axios";
import Header from "../components/Layout/AuthLayout/Header";
import ImgSuccess from "../assets/new/img/illustration-confirmation.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import {
  asCurrency,
  asDateV3,
  dateTime,
  dateTimeFree,
} from "../utils/formatter";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  actionSetDownload,
  actionSetLastSuccessNotified,
} from "../store/payment/actions";
import { encryptDecript } from "../helpers/encryptDecrypt";
import { actionResetField } from "../store/auth/actions";
import { accessToken } from "../helpers/accessToken";

const PembayaranBerhasil = (props) => {
  const {
    data,
    polis,
    pemegangPolis,
    hubunganPembayar,
    emailPembayar,
    actionSetDownload,
    actionSetLastSuccessNotified,
    actionResetField,
  } = props;
  const [sBCAAutoDebit, setSBCAAutoDebit] = useState(
    localStorage.getItem("AFIPAY01A28") !== null
      ? encryptDecript(localStorage.getItem("AFIPAY01A28"), 2)
      : ""
  );

  useEffect(() => {
    sendMail();
  }, []);

  useEffect(() => {
    if (data === null) {
      props.history.replace({
        pathname: process.env.REACT_APP_SUBDIR + "/beranda",
      });
    }
  }, [data]);

  let unduhBuktiPembayaran = () => {
    console.log(data)
    actionSetDownload(data);
    setTimeout(() => {
      window.open(
        process.env.REACT_APP_SUBDIR + "/unduh-bukti-pembayaran",
        "_blank"
      );
    }, 500);
  };

  let sendMail = () => {
    if (data?.notified !== true) {
      let headerPost = {
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken(),
      };

      let nomorKartu = "-";
      if (
        (data?.jenisTransaksi === "Pembayaran Premi" &&
          data?.metodePembayaran === "Kartu Kredit / Debit") ||
        data?.jenisTransaksi === "Pendaftaran Autodebit Kartu Kredit"
      ) {
        nomorKartu = data?.nomorKartu;
      }

      let showBCA = false;
      if (
        sBCAAutoDebit === "bca" &&
        polis?.coresystem?.toLowerCase() === "rls"
      ) {
        showBCA = true;
      }

      let showBO = false;
      if (
        pemegangPolis === false &&
        polis?.coresystem?.toLowerCase() === "rls" &&
        hubunganPembayar != null &&
        hubunganPembayar != 0 &&
        hubunganPembayar != 1
      ) {
        showBO = true;
      }

      let showPeriode = true,
        showGrossAmount = true,
        showPremiAmount = true;
      let totalPembayaran = data?.totalPembayaran;
      if (data?.jenisTransaksi === "Pembayaran Premi") {
        if (polis?.isPolisNB && polis?.coresystem?.toLowerCase() === "rls") {
          showPeriode = false;
          showPremiAmount = false;
        } else if (polis?.currency === "USD") {
          showPremiAmount = false;
        }
      } else {
        showPeriode = false;
        showGrossAmount = false;
        showPremiAmount = false;

        totalPembayaran = "0";
      }

      let emailAddress = polis?.emailAddress;
      if (
        pemegangPolis === false &&
        hubunganPembayar != null &&
        hubunganPembayar != 0 &&
        hubunganPembayar != 1
      ) {
        emailAddress = emailPembayar;
      }

      let payloadJson = {
        orderID: data?.transactionId,
        // transactionDate: dateTimeFree(data?.tanggalTransaksi, "YYYY-MM-DD HH:mm:ss"),
        // policyNumber: data?.noPolis,
        transactionType: data?.jenisTransaksi,
        // grossAmount: "Rp " + asCurrency(totalPembayaran)?.replace(",00", ""),
        ccNumber: nomorKartu,
        savecard: data?.lanjutAutoDebet === true ? "Ya" : "Tidak",
        showBCA: showBCA,
        showBO: showBO,
        emailTo: emailAddress,
        showGrossAmount: showGrossAmount,
        dueDatePeriod: asDateV3(polis?.paymentduedate) || "-",
        showPeriode: showPeriode,
        // premiAmount: "Rp " + asCurrency(polis?.premium)?.replace(",00", ""),
        showPremiAmount: showPremiAmount,
        notifType: "email",
      };

      // let payloadString = encryptDecript(JSON.stringify(payloadJson), 1)
      // payloadString =
      //     process.env.REACT_APP_PREFIX +
      //     payloadString.substring(0, 4) +
      //     process.env.REACT_APP_PREFIX +
      //     payloadString.substring(4, payloadString.length)
      // let payload = new FormData()
      // payload.append("notify", payloadString)

      // axios
      //   // .post(process.env.REACT_APP_API_NOTIFY, payload, { headers: headerPost })
      //   .post(process.env.REACT_APP_API_FE + "/notify", payloadJson, {
      //     headers: headerPost,
      //   })
      //   .then((response) => {
      //     console.log(response);
      //     if (response.data.data.status === "SUCCESS") {
      //       actionSetLastSuccessNotified();
      //       actionResetField();
      //     }
      //   });
    }
  };

  return (
    <React.Fragment>
      <Header
        url={process.env.REACT_APP_SUBDIR + "/beranda"}
        urlLabel="Kembali"
        title={"Konfirmasi"}
        button={false}
      />

      <div className="container metode-pembayaran konfirmasi text-center pt-4 mb-5">
        <img className="pt-4 pb-3" src={ImgSuccess} alt="confirmation" />
        <div className="subtitle-page fw-bold pt-2 mb-2" style={{color:"green",fontSize:"20px"}}>
          Pembayaran Berhasil
        </div>
        <p style={{ marginBottom: "2rem" }} className="px-3">
          Selamat Pembayaran Premi Anda Berhasil, 
          <br />
          Detail pembayaran Anda sebagai berikut :
        </p>
        <div className="card mb-4">
          <div className="card-body py-2">
            <p className="color-dark fw-semibold mb-2 text-start">
              Detail Pembayaran
            </p>
            <div className="mt-2">
              <div className="gap-2 ps-0 konfirmasi-detail d-block d-md-grid">
                <div>
                  <span className="text-grey">Transaksi id</span>
                  <p className="color-dark">{data?.transactionId}</p>
                </div>
                <div>
                  <span className="text-grey">No Polis</span>
                  <p className="color-dark">{data?.noPolis}</p>
                </div>
                <div>
                  <span className="text-grey">Total Pembayaran</span>
                  <p className="color-dark">
                    Rp {asCurrency(data?.totalPembayaran)}
                  </p>
                </div>
                <div>
                  <span className="text-grey">Tanggal Transaksi</span>
                  <p className="color-dark">
                    {dateTime(data?.tanggalTransaksi)}
                  </p>
                </div>
                <div>
                  <span className="text-grey">Metode Pembayaran</span>
                  <p className="color-dark">{data?.metodePembayaran}</p>
                </div>
                <div>
                  <span className="text-grey">Nama Lengkap</span>
                  <p className="color-dark">{data?.namaLengkap}</p>
                </div>
                <div>
                  <span className="text-grey">No HP</span>
                  <p className="color-dark">{data?.noHp}</p>
                </div>
                <div>
                  <span className="text-grey">
                    Mendaftar untuk premi lanjutan dengan autodebet
                  </span>
                  <p className="color-dark">
                    {data?.lanjutAutoDebet === true ? "Ya" : "Tidak"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p>
          Bukti pembayaran ini secara otomatis juga dikirim ke alamat email yang
          terdaftar.
        </p>

        <div className="inline-button pt-4">
          <button
            type="button"
            onClick={() => unduhBuktiPembayaran()}
            className="btn btn-primary col mb-2"
          >
            Unduh Bukti Pembayaran
          </button>
          <Link
            to={process.env.REACT_APP_SUBDIR + "/sign-out"}
            type="button"
            className="btn btn-primary-regis same-size"
          >
            Keluar
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.payment?.lastSuccess,
    polis: state.auth?.polis,
    hubunganPembayar: state.auth?.hubunganPembayar,
    emailPembayar: state.auth?.emailPembayar,
    pemegangPolis: state.auth?.pemegangPolis,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    actionSetDownload,
    actionSetLastSuccessNotified,
    actionResetField,
  })(PembayaranBerhasil)
);
