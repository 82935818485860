import React from "react"
import IcDropdown from "../../../assets/new/img/icons/ic-dropdown.svg"
import Select, { components } from 'react-select'

const DropDown = ({ id = null, options = [], name, value, className = "", error = null, hint = null, placeholder = null, label = null, onChange, isSearchable = false, disabled = false }) => {
    return <React.Fragment>
        <div className="form-group fw-bold">
            {label && <label className="form-label">{label}</label>}
            <Select
                id={id}
                placeholder={placeholder}
                aria-label={label}
                aria-describedby={label}
                name={name}
                defaultValue={value}
                onChange={onChange}
                options={options}
                isSearchable={isSearchable}
                isDisabled={disabled}
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: (props) => {
                        return (
                            <components.DropdownIndicator {...props}>
                                <img src={IcDropdown} width="20px" height="20px" alt="" />
                            </components.DropdownIndicator>
                        )
                    }
                }}
                styles={{
                    control: provided => ({
                        ...provided,
                        padding: "0.375rem 0.6rem",
                    }),
                    valueContainer: provided => ({
                        ...provided,
                        fontWeight: 400,
                    }),
                    input: provided => ({
                        ...provided,
                        fontWeight: 400,
                    }),
                    placeholder: provided => ({
                        ...provided,
                        fontSize: "1rem",
                        color: "#999999",
                        fontWeight: 400
                    })
                }}
            />
            {hint && <p className="form-tip"><i>{hint}</i></p>}
            {error && <p className="form-tip form-alert"><i>{error}</i></p>}
        </div>
    </React.Fragment>
}

export default DropDown