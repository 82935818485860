import React, { useEffect, useState } from "react";
import Header from "../components/Layout/AuthLayout/Header";
import AfipayDesc from "../components/Layout/AuthLayout/AfipayDesc";
import IcTimeleft from "../assets/new/img/icons/ic-timeleft.svg";
import { asTime, dateTime } from "../utils/formatter";
import Preloader from "../components/Preloader/index.js";
import ImgFailed from "../assets/new/img/illustration-confirmation-failed.svg";
import {
  actionCreateBcaPrivate,
  actionResetPayment,
  actionInquiryPaymentStatus,
  actionSetLastSuccess,
  actionSetLastFailed,
} from "../store/payment/actions";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

const AutoDebitBcaPrivateCard = (props) => {
  let data = props?.history?.location?.data || null;
  const {
    polis,
    payment,
    hubunganPembayar,
    actionCreateBcaPrivate,
    actionResetPayment,
    actionInquiryPaymentStatus,
    actionSetLastSuccess,
    actionSetLastFailed,
  } = props;

  const [sisaWaktuPembayaran, setSiswaWaktuPembayaran] = useState(0);
  const [waktuMulai, setWaktuMulai] = useState(false);
  const [countCheck, setCountCheck] = useState(0);
  const [submit, setSubmit] = useState(false);

  const [frameSrc, setFrameSrc] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    createPayment();
  }, []);

  const createPayment = () => {
    actionCreateBcaPrivate({
      polis,
      payment,
      hubunganPembayar,
      dataPrivateCard: {
        card_owner: data?.card_owner,
        card_number: data?.card_number,
        card_exp_month: data?.card_exp_month,
        card_exp_year: data?.card_exp_year,
        card_cvv: data?.card_cvv,
      },
    });
  };

  useEffect(() => {
    setSubmit(true);
  }, [payment?.success]);

  useEffect(() => {
    if (payment?.transaction_success === true) {
      actionResetPayment();
      actionSetLastSuccess({
        transactionId: payment?.payment?.trxRefference, 
        noPolis: polis?.polisnumber,
        totalPembayaran: payment?.payment?.grossAmount,
        tanggalTransaksi: payment?.requestedAt,
        jenisTransaksi: "Pendaftaran Autodebit Kartu Kredit",
        metodePembayaran: "BCA Private Card",
        namaLengkap: polis?.policyHolderNm,
        noHp: polis?.mobilePhoneNo,
        nomorKartu: data?.card_number,
        lanjutAutoDebet: true,
        notified: false,
      });
      props.history.replace({
        pathname: process.env.REACT_APP_SUBDIR + "/auto-debit-berhasil",
      });
    } else if (payment?.transaction_success === false) {
      actionResetPayment();
      actionSetLastFailed({
        transactionId: payment?.payment?.trxRefference,
      });
      props.history.replace({
        pathname: process.env.REACT_APP_SUBDIR + "/auto-debit-gagal",
      });
    }
  }, [payment?.transaction_success]);

  useEffect(() => {
    if (payment?.transactionId) {
      actionInquiryPaymentStatus({
        polis: payment?.polis,
        payment: payment?.payment,
        transactionID: payment?.transactionId,
      });
      setCountCheck(1);
    }
  }, [payment?.transactionId]);

  useEffect(() => {
    if (countCheck <= 0) return;

    const intervalId = setInterval(() => {
      setCountCheck(countCheck + 1);
      actionInquiryPaymentStatus({
        polis: payment?.polis,
        payment: payment?.payment,
        transactionID: payment?.transactionId,
      });
    }, 5000);

    return () => clearInterval(intervalId);
  }, [countCheck]);

  useEffect(() => {
    let now = parseInt(new Date().getTime() / 1000);
    let sisaWaktu = payment?.expiredAt - now;
    if (sisaWaktu > 0) {
      setSiswaWaktuPembayaran(sisaWaktu);
      setWaktuMulai(true);
      setLoading(true);
      setFrameSrc(payment?.result?.redirect_url);
    }
  }, [payment?.expiredAt]);

  useEffect(() => {
    if (!sisaWaktuPembayaran) return;

    const intervalId = setInterval(() => {
      setSiswaWaktuPembayaran(sisaWaktuPembayaran - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [sisaWaktuPembayaran]);

  useEffect(() => {
    if (waktuMulai === true) {
      if (sisaWaktuPembayaran < 1) {
        actionResetPayment();
        actionSetLastFailed({
          transactionId: payment?.payment?.trxRefference,
        });
        props.history.replace({
          pathname: process.env.REACT_APP_SUBDIR + "/auto-debit-gagal",
        });
      }
    }
  }, [waktuMulai, sisaWaktuPembayaran]);

  const paymentFailedScreen = () => {
    return (
      <React.Fragment>
        <div className="container metode-pembayaran konfirmasi text-center pt-4 mb-5">
          <img className="pt-4 pb-3" src={ImgFailed} alt="confirmation" />
          <div className="subtitle-page fw-bold pt-2 mb-2" style={{color:"red",fontSize:"20px"}}>
            Upps, Pembayaran Gagal Dibuat
          </div>
          <p style={{ marginBottom: "2rem" }} className="px-3">
            {" "}
            Silakan coba lagi metode pembayaran Anda.
          </p>
          <div className="inline-button pt-4">
            <button
              onClick={() => onClickBack()}
              type="button"
              className="btn btn-primary col mb-2"
            >
              Ulangi Pembayaran
            </button>
            <Link
              to={process.env.REACT_APP_SUBDIR + "/sign-out"}
              type="button"
              className="btn btn-outline-danger col mb-2"
            >
              Keluar
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const onClickBack = async () => {
    actionResetPayment();
    props.history.replace({
      pathname:
        submit === true && payment?.success === true
          ? process.env.REACT_APP_SUBDIR + "/beranda"
          : process.env.REACT_APP_SUBDIR + "/registrasi-autodebit",
    });
  };

  const detailPembayaranOld = () => {
    return (
      <React.Fragment>
        {sisaWaktuPembayaran > 0 ? (
          <div className="container pembayaran-kartu text-center pt-4 pb-5">
            <p className="card-text-label">
              Silakan selesaikan proses Pendaftaran Auto Debet melalui layanan
              kartu kredit anda, berlaku hingga
            </p>
            <p className="fw-semibold">
              {dateTime(payment?.result?.expiry_time)}
            </p>
            <p className="highlight-text-red-md">
              <img
                src={IcTimeleft}
                style={{ maxHeight: "15px" }}
                alt="waktu-tersisa"
              />{" "}
              {asTime(sisaWaktuPembayaran)}
            </p>
            <div className="inline-button">
              <a
                href="."
                onClick={(e) => e.preventDefault()}
                type="button"
                className="btn btn-primary"
              >
                OK
              </a>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  const iframeLoaded = () => {
    setLoading(false);
  };

  const detailPembayaran = () => {
    return (
      <React.Fragment>
        <Preloader visible={loading === true} />

        {sisaWaktuPembayaran > 0 && frameSrc ? (
          <iframe
            title="Registrasi Kartu"
            src={frameSrc}
            scrolling="no"
            style={{
              height: 480,
              overflow: "hidden",
            }}
            className="ma-frame"
            onLoad={iframeLoaded}
          />
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Header
        onClick={() => onClickBack()}
        urlLabel="Kembali"
        title={"Pembayaran"}
        confirm={submit === true && payment?.success === true}
        confirmTitle={"Kembali"}
        confirmDescription={"Batalkan pembayaran?"}
        homeConfirm={submit === true && payment?.success === true}
      />
      

      <div style={{ minHeight: "30vh" }}>
        <Preloader visible={payment?.loading === true} />
        {submit === true && payment?.loading === false
          ? submit === true && payment?.success === false
            ? paymentFailedScreen()
            : detailPembayaran()
          : null}
      </div>
      <AfipayDesc />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    polis: state.auth?.polis,
    hubunganPembayar: state.auth?.hubunganPembayar,
    payment: state.payment,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    actionCreateBcaPrivate,
    actionResetPayment,
    actionInquiryPaymentStatus,
    actionSetLastSuccess,
    actionSetLastFailed,
  })(AutoDebitBcaPrivateCard)
);
