import React from "react"
import Header from "../components/Layout/AuthLayout/Header"
import ImgProcess from "../assets/new/img/illustration-confirmation-process.svg"
import { Link } from "react-router-dom"

const PembayaranDiproses = () => {
    return <React.Fragment>
        <Header url={process.env.REACT_APP_SUBDIR + '/beranda'} urlLabel="Kembali" title={"Konfirmasi"} button={false} />

        <div className="container metode-pembayaran konfirmasi text-center pt-4 mb-5">
            <img className="pt-4 pb-3" src={ImgProcess} alt="confirmation" />
            <div className="subtitle-page fw-bold pt-2 mb-2">
                Transaksi Pembayaran<br />Sedang Diproses
            </div>
            <div className="inline-button pt-4">
                <Link to={process.env.REACT_APP_SUBDIR + "/metode-pembayaran"} type="button" className="btn btn-primary col mb-2">Kembali ke Pembayaran</Link>
                <Link to={process.env.REACT_APP_SUBDIR + "/sign-out"} type="button" className="btn btn-outline-danger col mb-2">Keluar</Link>
            </div>
        </div>
    </React.Fragment>
}

export default PembayaranDiproses