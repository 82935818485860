import Immutable from 'seamless-immutable'

const CHECK_PAYMENT_VALIDATION = 'CHECK_PAYMENT_VALIDATION'
const CHECK_ACTIVE_PAYMENT = 'CHECK_ACTIVE_PAYMENT'

const CREATE_PAYMENT = 'CREATE_PAYMENT'
const CREATE_PAYMENT_START = 'CREATE_PAYMENT_START'
const CREATE_PAYMENT_END = 'CREATE_PAYMENT_END'
const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS'
const CREATE_PAYMENT_FAILED = 'CREATE_PAYMENT_FAILED'

const CREATE_BCA_PRIVATE = 'CREATE_BCA_PRIVATE'
const CREATE_BCA_PRIVATE_START = 'CREATE_BCA_PRIVATE_START'
const CREATE_BCA_PRIVATE_END = 'CREATE_BCA_PRIVATE_END'
const CREATE_BCA_PRIVATE_SUCCESS = 'CREATE_BCA_PRIVATE_SUCCESS'
const CREATE_BCA_PRIVATE_FAILED = 'CREATE_BCA_PRIVATE_FAILED'

const CREATE_BCA = 'CREATE_BCA'
const CREATE_BCA_START = 'CREATE_BCA_START'
const CREATE_BCA_END = 'CREATE_BCA_END'
const CREATE_BCA_SUCCESS = 'CREATE_BCA_SUCCESS'
const CREATE_BCA_FAILED = 'CREATE_BCA_FAILED'

const SET_LAST_SUCCESS = 'SET_LAST_SUCCESS'
const SET_LAST_SUCCESS_NOTIFIED = 'SET_LAST_SUCCESS_NOTIFIED'
const SET_LAST_FAILED = 'SET_LAST_FAILED'

const SET_QR_GOPAY = 'SET_QR_GOPAY'
const SET_URL_GOPAY = 'SET_URL_GOPAY'
const SET_DOWNLOAD = 'SET_DOWNLOAD'
const INQUIRY_PAYMENT_STATUS = 'INQUIRY_PAYMENT_STATUS'
const TRANSACTION_SUCCESS = 'TRANSACTION_SUCCESS'
const TRANSACTION_FAILED = 'TRANSACTION_FAILED'
const TRANSACTION_PENDING = 'TRANSACTION_PENDING'
const PAYMENT_FAILED = 'PAYMENT_FAILED'
const RESET_PAYMENT = 'RESET_PAYMENT'

const actionTypes = Immutable({
    CHECK_PAYMENT_VALIDATION,
    CHECK_ACTIVE_PAYMENT,

    CREATE_PAYMENT,
    CREATE_PAYMENT_START,
    CREATE_PAYMENT_END,
    CREATE_PAYMENT_SUCCESS,
    CREATE_PAYMENT_FAILED,

    CREATE_BCA_PRIVATE,
    CREATE_BCA_PRIVATE_START,
    CREATE_BCA_PRIVATE_END,
    CREATE_BCA_PRIVATE_SUCCESS,
    CREATE_BCA_PRIVATE_FAILED,

    CREATE_BCA,
    CREATE_BCA_START,
    CREATE_BCA_END,
    CREATE_BCA_SUCCESS,
    CREATE_BCA_FAILED,

    SET_QR_GOPAY,
    SET_URL_GOPAY,

    SET_LAST_SUCCESS,
    SET_LAST_SUCCESS_NOTIFIED,
    SET_LAST_FAILED,

    SET_DOWNLOAD,

    INQUIRY_PAYMENT_STATUS,

    TRANSACTION_SUCCESS,
    TRANSACTION_FAILED,
    TRANSACTION_PENDING,

    PAYMENT_FAILED,

    RESET_PAYMENT
})

export default actionTypes
