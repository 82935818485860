import React from "react"
import Header from "../components/Layout/AuthLayout/Header"
import AfipayDesc from "../components/Layout/AuthLayout/AfipayDesc"
import ImgProcess from "../assets/new/img/illustration-confirmation-process.svg"
import { Link } from "react-router-dom"

const AutoDebitDiproses = () => {
    return <React.Fragment>
        <Header url={process.env.REACT_APP_SUBDIR + '/beranda'} title={"Pendaftaran Auto Debit Diproses"} button={false} />


        <div className="container metode-pembayaran konfirmasi text-center pt-4 mb-5">
            <img className="pt-4 pb-3 " src={ImgProcess} alt="confirmation" />
            <div className="subtitle-page fw-semibold pt-2 mb-2" style={{ color: "royal blue", fontSize: "20px" }}>
                Pendaftaran Diproses
            </div>
            <p style={{ marginBottom: "2rem" }} className="px-3">
                Pendaftaran auto debit Anda sedang diproses,
                Mohon tidak menutup halaman ini.
            </p>
            <div className="inline-button pt-4">
                {/* <button type="button" className="btn btn-primary col mb-2">Kembali ke  Pembayaran</button> */}
                <Link to={process.env.REACT_APP_SUBDIR + "/beranda"} type="button" className="btn btn-outline-dark col mb-2">Informasi Polis</Link>
            </div>
        </div>
        <AfipayDesc />
    </React.Fragment>
}

export default AutoDebitDiproses