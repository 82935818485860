import React, { useState } from "react";
import Spinner from "../../components/Spinner";

const ModalCustom = ({
  id,
  action = "",
  title = "",
  onSubmit = null,
  model = null,
  onChange = null,
  listRole = null,
  loadingBtn = false,
  errors = null,
  listModule = null,
  rolename = "",
  onChangeCheckBox = null,
  checkedState = null,
}) => {
  return (
    <React.Fragment>
      <div
        className="modal fade"
        id={id}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          className={
            action === "formAccess"
              ? "modal-dialog modal-lg modal-dialog-centered"
              : "modal-dialog modal-md modal-dialog-centered"
          }
        >
          <div className={"modal-content"}>
            <div className="modal-header">
              <h5 className="modal-title">
                {action === "formAccess" ? (
                  <>
                    {title} -{" "}
                    <p
                      style={{
                        display: "inline",
                        color: "#00008F",
                      }}
                    >
                      {rolename}
                    </p>
                  </>
                ) : (
                  title
                )}
              </h5>
            </div>
            <form>
              <div className="modal-body pt-0 pb-3">
                {action === "formUser" ? (
                  <div className="row py-3">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label htmlFor="username" className="form-label">
                          Username
                        </label>
                        <span className="label-required text-danger">*</span>
                        <input
                          type="text"
                          name="username"
                          id="username"
                          placeholder=""
                          value={
                            model?.username !== null ? model?.username : ""
                          }
                          onChange={(e) => onChange(e, "username")}
                          className="form-control"
                          style={{ borderRadius: "7px" }}
                          required={true}
                          disabled={
                            model?.id !== "" && model?.id !== null
                              ? true
                              : false
                          }
                        />
                        <span className="notifications-error text-danger">
                          {errors["username"]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        {model?.id === "" ? (
                          <span className="label-required text-danger">*</span>
                        ) : null}

                        <input
                          type="password"
                          name="password"
                          id="password"
                          placeholder=""
                          value={
                            model?.password !== null ? model?.password : ""
                          }
                          onChange={(e) => onChange(e, "password")}
                          className="form-control"
                          style={{ borderRadius: "7px" }}
                        />
                        <span className="notifications-error text-danger">
                          {errors["password"]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Full Name
                        </label>
                        <span className="label-required text-danger">*</span>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder=""
                          value={model?.name !== null ? model?.name : ""}
                          onChange={(e) => onChange(e, "name")}
                          className="form-control"
                          style={{ borderRadius: "7px" }}
                        />
                        <span className="notifications-error text-danger">
                          {errors["name"]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label htmlFor="entity" className="form-label">
                          Entity
                        </label>
                        <span className="label-required text-danger">*</span>
                        <input
                          type="text"
                          name="entity"
                          id="entity"
                          placeholder=""
                          value={model?.entity !== null ? model?.entity : ""}
                          onChange={(e) => onChange(e, "entity")}
                          className="form-control"
                          style={{ borderRadius: "7px" }}
                        />
                        <span className="notifications-error text-danger">
                          {errors["entity"]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <label htmlFor="role_id" className="form-label">
                        Role
                      </label>
                      <span className="label-required text-danger">*</span>
                      <select
                        className="form-control form-select"
                        id="role_id"
                        name="role_id"
                        value={model?.role_id !== null ? model?.role_id : ""}
                        onChange={(e) => onChange(e, "role_id")}
                        style={{ borderRadius: "7px" }}
                      >
                        <option value="">Please Choose</option>
                        {listRole?.map((module, key) => (
                          <option key={module.id} value={module.id}>
                            {module.role_name}
                          </option>
                        ))}
                      </select>
                      <span className="notifications-error text-danger">
                        {errors["role_id"]}
                      </span>
                    </div>
                  </div>
                ) : null}
                {action === "formRole" ? (
                  <div className="row py-3">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label htmlFor="role_name" className="form-label">
                          Name
                        </label>
                        <span className="label-required text-danger">*</span>
                        <input
                          type="text"
                          name="role_name"
                          id="role_name"
                          placeholder=""
                          value={
                            model?.role_name !== null ? model?.role_name : ""
                          }
                          onChange={(e) => onChange(e, "role_name")}
                          className="form-control"
                          style={{ borderRadius: "7px" }}
                        />
                        <span className="notifications-error text-danger">
                          {errors["role_name"]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label
                          htmlFor="role_description"
                          className="form-label"
                        >
                          Description
                        </label>
                        <span className="label-required text-danger">*</span>
                        <input
                          type="text"
                          name="role_description"
                          id="role_description"
                          placeholder=""
                          value={
                            model?.role_description !== null
                              ? model?.role_description
                              : ""
                          }
                          onChange={(e) => onChange(e, "role_description")}
                          className="form-control"
                          style={{ borderRadius: "7px" }}
                        />
                        <span className="notifications-error text-danger">
                          {errors["role_description"]}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}
                {action === "formAccess" ? (
                  <div className="row">
                    <input
                      type="hidden"
                      name="id"
                      id="id"
                      placeholder=""
                      value={model?.id !== null ? model?.id : ""}
                    />
                    <input
                      type="hidden"
                      name="role_name"
                      id="role_name"
                      placeholder=""
                      value={model?.role_name !== null ? model?.role_name : ""}
                    />
                    <input
                      type="hidden"
                      name="role_description"
                      id="role_description"
                      placeholder=""
                      value={
                        model?.role_description !== null
                          ? model?.role_description
                          : ""
                      }
                    />
                    {listModule?.map((item, key) => (
                      <div key={`cb-${key}`} className="col-6 py-2">
                        <input
                          type="checkbox"
                          className="btn-check"
                          id={`cb-${key}`}
                          name={item.name}
                          value={item.id}
                          autoComplete="off"
                          checked={checkedState[key]}
                          onChange={(e) => onChangeCheckBox(e, key)}
                        />
                        <label
                          className="btn btn-outline cus-checkbox"
                          htmlFor={`cb-${key}`}
                        >
                          {item.description}
                        </label>
                      </div>
                    ))}
                    <span className="notifications-error text-danger">
                      {errors["role_access"]}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="modal-footer align-items-end">
                <React.Fragment>
                  <button
                    type="button"
                    id="closeModal"
                    className="btn btn-outline-dark col-4"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <div className="mx-1"></div>
                  <button
                    type="submit"
                    className="btn btn-primary col-4"
                    onClick={onSubmit}
                    disabled={loadingBtn}
                  >
                    Save
                    <Spinner visible={loadingBtn} bgColor={"#00006e"} />
                  </button>
                </React.Fragment>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ModalCustom;
