import React, { useState } from "react";

import useTable from "./useTable";
import TableFooter from "./TableFooter";
import Spinner from "../../../components/Spinner";

const getHeader = (checkedAll, onCheckedAll, cbAllState) => {
  var keyHeader = [
    "",
    "Policy Number",
    "Total Premi",
    "Premi Paid",
    "Outstanding Premi",
    "Partial Payment Due Date",
    "Pay to Date",
  ];
  return keyHeader.map((key, index) => {
    if (index === 0) {
      return (
        <th key={key}>
          <input
            className="form-check-input position-static"
            type="checkbox"
            id="cbAll"
            checked={checkedAll}
            onChange={(e) => onCheckedAll(e)}
            disabled={cbAllState}
          ></input>
        </th>
      );
    }
    return <th key={key}>{key}</th>;
  });
};

const mapRowsData = (data, checkedState, onChangeCheckBox) => {
  return data.map((row, index) => {
    const {
      id,
      policyNumber,
      premium,
      sumPayment,
      outstanding,
      splitDueDate,
      dueDate,
    } = row;

    return (
      <tr key={index}>
        <td>
          <input
            className="form-check-input position-static"
            type="checkbox"
            id={`cb-${index}`}
            value={id}
            autoComplete="off"
            checked={checkedState[index]}
            onChange={(e) => onChangeCheckBox(e, index)}
          />
        </td>
        <td>{policyNumber}</td>
        <td>{premium}</td>
        <td>{sumPayment}</td>
        <td>{outstanding}</td>
        <td>{splitDueDate}</td>
        <td>{dueDate}</td>
      </tr>
    );
  });
};

const TableRefundSplitPayment = ({
  data,
  rowsPerPage,
  checkedAll,
  onCheckedAll,
  checkedState,
  onChangeCheckBox,
  loading,
  cbAllState,
}) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);

  return (
    <React.Fragment>
      <div
        className="dashboard-table table-responsive mb-4"
        id="tableData"
        style={{ display: "block" }}
      >
        <div className="mt-2">
          <table
            className="table"
            width="100%"
            style={{
              marginLeft: 0,
              backgroundColor: "white",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "rgb(0 0 143)",
                  marginLeft: "10px",
                  marginRight: "10px",
                  // borderCollapse: "collapse",
                  textAlign: "center",
                  color: "white",
                  verticalAlign: "center",
                }}
              >
                {getHeader(checkedAll, onCheckedAll, cbAllState)}
              </tr>
            </thead>
            <tbody
              style={{
                backgroundColor: "white",
                textAlign: "center",
              }}
            >
              {loading ? (
                <tr>
                  <td colSpan={7}>
                    <Spinner visible={loading} bgColor={"#00006e"} size={30} />
                  </td>
                </tr>
              ) : data !== null && data.length > 0 ? (
                mapRowsData(slice, checkedState, onChangeCheckBox)
              ) : (
                <tr>
                  <td colSpan={7}>No Data Available in Table</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </React.Fragment>
  );
};

export default TableRefundSplitPayment;
