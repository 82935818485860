import React, { useEffect, useState } from "react";
import "./dashboard.css";
import axios from "axios";
import DataTable from "./DataTable";
import { encryptDecript } from "../../helpers/encryptDecrypt.js";
import Header from "../../components/Layout/AuthLayout/Header";
import AfipayDesc from "../../components/Layout/AdminLayout/AfipayDesc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import ModalCustom from "./ModalCustom";
import { alert } from "../../utils/alert";
import sha256 from "crypto-js/sha256";

const User = (props) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [moduleAccess, setModuleAccess] = useState([]);
  const [data, setData] = useState([]);
  const [role, setRole] = useState([]);
  const [formUser, setFormUserValues] = useState({
    id: "",
    username: "",
    password: "",
    name: "",
    entity: "",
    role_id: "",
  });
  const [listError, setListError] = useState([]);
  const [totalEntries, setTotalEntries] = useState(10);
  const [modalTitle, setModalTitle] = useState("");
  const [filterData, setFilterData] = useState(data);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [hasPrivilege, setHasPrivilege] = useState(false);

  useEffect(() => {
    if (
      typeof localStorage.getItem("AFIPAY02B01") !== "undefined" ||
      localStorage.getItem("AFIPAY02B01") !== ""
    ) {
      setModuleAccess(encryptDecript(localStorage.getItem("AFIPAY02B01"), 2));
      getListData();
      getListRole();
    }
  }, []);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const onSearch = async (e) => {
    e.preventDefault();

    if (search === null || search === "") {
      setData([]);
      setFilterData([]);
      getListData();
    } else {
      setLoading(true);
      setFilterData([]);

      const filteredData = data?.filter((item) => {
        if (search !== null || search !== "") {
          if (
            item?.name?.toLowerCase().includes(search.toLowerCase()) ||
            item?.username?.toLowerCase().includes(search.toLowerCase()) ||
            item?.entity?.toLowerCase().includes(search.toLowerCase()) ||
            item?.roleName?.toLowerCase().includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });

      await delay(1000);

      if (typeof filteredData !== "undefined") {
        setFilterData(filteredData);
      }
      setLoading(false);
    }
  };

  const getListRole = () => {
    let token = "";
    if (
      typeof localStorage.getItem("AFIPAY02B02") !== "undefined" ||
      localStorage.getItem("AFIPAY02B02") !== "" ||
      localStorage.getItem("AFIPAY02B02") !== null
    ) {
      token = encryptDecript(localStorage.getItem("AFIPAY02B02"), 2);
    }

    const headers = {
      Authorization: "Bearer " + token,
    };

    const params = {
      search: "",
      page: 1,
      size: 999999,
      sortBy: "id",
      sortType: "ASC",
    };

    axios
      .get(process.env.REACT_APP_API_DASH + "/role/get-all", {
        params,
        headers,
      })
      .then((response) => {
        setRole(response.data.data._items);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getListData = () => {
    setLoading(true);
    let token = "";
    if (
      typeof localStorage.getItem("AFIPAY02B02") !== "undefined" ||
      localStorage.getItem("AFIPAY02B02") !== "" ||
      localStorage.getItem("AFIPAY02B02") !== null
    ) {
      token = encryptDecript(localStorage.getItem("AFIPAY02B02"), 2);
    }

    const headers = {
      Authorization: "Bearer " + token,
    };

    const params = {
      search: "",
      page: page,
      size: 999999,
      sortBy: "username",
      sortType: "ASC",
    };

    axios
      .get(process.env.REACT_APP_API_DASH + "/user/get-all", {
        params,
        headers,
      })
      .then(async (response) => {
        if (response?.data?.data?.Message === "Forbidden") {
          setData([]);
          setFilterData([]);
          setLoading(false);
          await alert({
            title: "Forbidden",
            html: "You don`t have access to read data or your access has been revoked in this menu",
          });
        } else {
          if (search === null || search === "") {
            setData(response?.data?.data?._items);
            setFilterData(response.data.data._items);
          } else {
            setFilterData([]);

            const filteredData = response?.data?.data?._items?.filter(
              (item) => {
                if (search !== null || search !== "") {
                  if (
                    item?.name?.toLowerCase().includes(search.toLowerCase()) ||
                    item?.username
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    item?.entity
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    item?.roleName?.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return item;
                  }
                }
              }
            );

            if (typeof filteredData !== "undefined") {
              setFilterData(filteredData);
            }
          }
          setLoading(false);
          setHasPrivilege(true);
          localStorage.setItem(
            "AFIPAY01A36",
            encryptDecript(response?.data?.data?._items, 1)
          );
        }
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await alert({
            title: "Your session has ended",
            html: "Please login again",
          });

          props.history.push(process.env.REACT_APP_SUBDIR + "/sign-out-admin");
        }
        setLoading(false);
      });
  };

  const closeModal = () => {
    let modalCloseBtn = document.getElementById("closeModal");

    if (modalCloseBtn) {
      modalCloseBtn.click();
    }
  };

  const postData = async (e) => {
    e.preventDefault();

    let valid = true;
    let _error = listError;

    if (formUser.username === "" || formUser.username === null) {
      _error["username"] = "Username field is required.";
      valid = false;
    } else {
      _error["username"] = "";
    }

    if (formUser?.id === "") {
      if (formUser.password === "" || formUser.password === null) {
        _error["password"] = "Password field is required.";
        valid = false;
      } else {
        _error["password"] = "";
      }
    }

    if (formUser.name === "" || formUser.name === null) {
      _error["name"] = "Full Name field is required.";
      valid = false;
    } else {
      _error["name"] = "";
    }

    if (formUser.entity === "" || formUser.entity === null) {
      _error["entity"] = "Entity field is required.";
      valid = false;
    } else {
      _error["entity"] = "";
    }

    if (formUser.role_id === "" || formUser.role_id === null) {
      _error["role_id"] = "Role field is required.";
      valid = false;
    } else {
      _error["role_id"] = "";
    }

    setListError({
      ..._error,
    });

    if (valid) {
      setLoadingBtn(true);
      let token = "";
      if (
        typeof localStorage.getItem("AFIPAY02B02") !== "undefined" ||
        localStorage.getItem("AFIPAY02B02") !== "" ||
        localStorage.getItem("AFIPAY02B02") !== null
      ) {
        token = encryptDecript(localStorage.getItem("AFIPAY02B02"), 2);
      }

      const headers = {
        Authorization: "Bearer " + token,
      };

      const params = {
        id: formUser.id,
        username: formUser.username,
        password:
          formUser.password === "" || formUser.password === null
            ? null
            : "" + sha256(formUser.password),
        name: formUser.name,
        entity: formUser.entity,
        role_id: formUser.role_id,
      };

      let url = process.env.REACT_APP_API_DASH + "/user/create";
      if (formUser?.id !== "") {
        url = process.env.REACT_APP_API_DASH + "/user/update";
      }

      setTimeout(() => {
        axios
          .post(url, params, {
            headers,
          })
          .then(async (response) => {
            if (response.status === 200) {
              let title = "Success";
              let html = "Data saved successfully.";
              if (response?.data?.data?.status === "FAILED") {
                if (response?.data?.data?.Message === "Forbidden") {
                  title = "Forbidden";
                  html =
                    "You don`t have access to read data or your access has been revoked in this menu";
                } else {
                  title = "Failed";
                  html = "Failed to save data.";
                }
              }
              const resultAlert = await alert({
                title: title,
                html: html,
              });
              if (title === "Success") {
                if (resultAlert === true) {
                  closeModal();
                  clearFormState();
                  getListData();
                }
              }
              setLoadingBtn(false);
            }
          })
          .catch(async (error) => {
            await alert({
              title: "Failed to save data.",
              html: "Error : " + error,
            });

            setLoadingBtn(false);
          });
      }, 1000);
    }
  };

  const onChange = (e, name) => {
    let _data = formUser;

    _data[name] = e?.target?.value;

    setFormUserValues({
      ..._data,
    });

    let _error = listError;

    if (e?.target?.value === "" || e?.target?.value === null) {
      if (name === "username") {
        _error[name] = "Username field is required.";
      }

      if (name === "password") {
        _error[name] = "Password field is required.";
      }

      if (name === "name") {
        _error[name] = "Full Name field is required.";
      }

      if (name === "entity") {
        _error[name] = "Entity field is required.";
      }

      if (name === "role_id") {
        _error[name] = "Role field is required.";
      }
    } else {
      _error[name] = "";
    }

    setListError({
      ..._error,
    });
  };

  const onClickEdit = (val) => {
    setLoading(false);

    setModalTitle("Edit User");

    clearErrorState();
    setFormUserValues(val);
    setFormUserValues((previousState) => {
      return { ...previousState, val };
    });
  };

  const onClickAdd = (e) => {
    e.preventDefault();

    setModalTitle("Add User");

    setLoading(false);
    clearFormState();
    clearErrorState();
  };

  const clearFormState = () => {
    setFormUserValues({
      id: "",
      username: "",
      password: "",
      name: "",
      entity: "",
      role_id: "",
    });
  };

  const clearErrorState = () => {
    setListError([]);
  };

  const onChangeEntries = async (val) => {
    setTotalEntries(val);
    if (search === null || search === "") {
      setData([]);
      setFilterData([]);
      getListData();
    } else {
      setLoading(true);
      setFilterData([]);

      const filteredData = data?.filter((item) => {
        if (search !== null || search !== "") {
          if (
            item?.name?.toLowerCase().includes(search.toLowerCase()) ||
            item?.username?.toLowerCase().includes(search.toLowerCase()) ||
            item?.entity?.toLowerCase().includes(search.toLowerCase()) ||
            item?.roleName?.toLowerCase().includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });

      await delay(1000);

      if (typeof filteredData !== "undefined") {
        setFilterData(filteredData);
      }
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Header
        url={process.env.REACT_APP_SUBDIR + "/sign-out-admin"}
        urlLabel="Keluar"
        title={"Dashboard"}
        confirm={true}
        icon={"ic-out"}
        confirmTitle={"Keluar?"}
        confirmDescription={"Apakah anda yakin ingin mengakhiri sesi anda?"}
        right={true}
        isAdmin={true}
        moduleAccess={moduleAccess}
        moduleName={"users"}
      />
      <AfipayDesc title={"User Management / User"} />

      <div className="container pt-4 mb-5 px-5 mt-4 dashboard-admin">
        <div className="row justify-content-between">
          <div className="col-lg-4">
            <span>Showing</span>
            <select
              className="form-select form-select-sm ms-2 me-2"
              style={{
                paddingRight: "2rem",
                width: "auto",
                height: "auto",
                display: "inline-block",
              }}
              value={totalEntries}
              onChange={(e) => onChangeEntries(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span>Entries</span>
          </div>
          <div className="col-lg-4">
            <div className="input-group mb-3">
              <input
                type="text"
                name="search"
                id="search"
                placeholder=""
                className="form-control"
                onChange={(e) => setSearch(e.target.value)}
                style={{
                  borderRadius: "7px",
                  height: "35px",
                }}
              />
              <div className="input-group-append">
                <button
                  type="submit"
                  className="btn btn-block btn-primary"
                  id="btnSearch"
                  onClick={onSearch}
                  style={{
                    borderRadius: "7px",
                    width: "35px",
                    height: "35px",
                    padding: "0",
                  }}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </div>
              <div className="px-2"></div>
              <button
                className="btn btn-sm btn-primary"
                style={{
                  backgroundColor: "#00008f",
                  width: "75px",
                  height: "35px",
                  padding: "0",
                  borderRadius: "7px",
                }}
                href="."
                onClick={(e) => onClickAdd(e)}
                data-bs-toggle="modal"
                data-bs-target="#modalUser"
                disabled={!hasPrivilege}
              >
                Add User
              </button>
            </div>
          </div>
        </div>

        <DataTable
          data={filterData}
          tableName={"user"}
          onClickEdit={onClickEdit}
          totalEntries={totalEntries}
          loading={loading}
        />
      </div>

      <ModalCustom
        id={"modalUser"}
        action="formUser"
        title={modalTitle}
        model={formUser}
        onSubmit={postData}
        onChange={onChange}
        listRole={role}
        errors={listError}
        loadingBtn={loadingBtn}
      />
    </React.Fragment>
  );
};
export default User;
