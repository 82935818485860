import Immutable from "seamless-immutable";
import actionTypes from "./actionTypes";

const model = {
  payment: null,
  lastSuccess: null,
  lastFailed: null,
  result: null,
  download: null,
  message: null,
  transaction_success: null,
  transaction_pending: null,
  transactionId: null,
  type: null,
  qrGopay: null,
  urlGopay: null,
  requestedAt: null,
  expiredAt: null,
  success: null,
  loading: false,
};
export const INITIAL_STATE = Immutable(model);

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    
    case actionTypes.CREATE_PAYMENT_START:
      return { ...state, loading: true };
    case actionTypes.CREATE_PAYMENT_END:
      return { ...state, loading: false };
    case actionTypes.CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.data?.payment,
        result: action.data?.result,
        type: action.data?.type,
        transactionId: action.data?.transactionId,
        requestedAt: action.data?.requestedAt,
        expiredAt: action.data?.expiredAt,
        success: true,
        message:action.data?.message
      };
    case actionTypes.CREATE_PAYMENT_FAILED:
      return {
        ...model,
        success: false,
        message:action.data?.message
      };
    case actionTypes.CREATE_BCA_PRIVATE_START:
      return { ...state, loading: true };
    case actionTypes.CREATE_BCA_PRIVATE_END:
      return { ...state, loading: false };
    case actionTypes.CREATE_BCA_PRIVATE_SUCCESS:
      return {
        ...state,
        payment: action.data?.payment,
        result: action.data?.result,
        type: action.data?.type,
        transactionId: action.data?.transactionId,
        requestedAt: action.data?.requestedAt,
        expiredAt: action.data?.expiredAt,
        success: true,
      };
    case actionTypes.CREATE_BCA_PRIVATE_FAILED:
      return {
        ...model,
        success: false,
        message: action.data || null,
      };
    case actionTypes.CREATE_BCA_START:
      return { ...state, loading: true };
    case actionTypes.CREATE_BCA_END:
      return { ...state, loading: false };
    case actionTypes.CREATE_BCA_SUCCESS:
      return {
        ...state,
        payment: action.data?.payment,
        result: action.data?.result,
        type: action.data?.type,
        transactionId: action.data?.transactionId,
        requestedAt: action.data?.requestedAt,
        expiredAt: action.data?.expiredAt,
        success: true,
      };
    case actionTypes.CREATE_BCA_FAILED:
      return {
        ...model,
        success: false,
        message: action.data || null,
      };
    case actionTypes.TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction_success: true,
        transaction_pending: false,
      };
    case actionTypes.TRANSACTION_FAILED:
      return {
        ...state,
        transaction_success: false,
        transaction_pending: false,
        errMessage : action.data?.errMessage
      };
    case actionTypes.TRANSACTION_PENDING:
      return { ...state, transaction_pending: true };
    case actionTypes.SET_QR_GOPAY:
      return { ...state, qrGopay: action.data };
    case actionTypes.SET_URL_GOPAY:
      return { ...state, urlGopay: action.data };
    case actionTypes.SET_DOWNLOAD:
      return { ...state, download: action.data };
    case actionTypes.SET_LAST_SUCCESS:
      return { ...state, lastSuccess: action.data };
    case actionTypes.SET_LAST_FAILED:
      return { ...state, lastFailed: action.data };
    case actionTypes.SET_LAST_SUCCESS_NOTIFIED:
      return {
        ...state,
        lastSuccess: {
          ...state?.lastSuccess,
          notified: true,
        },
      };
    case actionTypes.RESET_PAYMENT:
      return {
        ...model,
      };
    default:
      return state;
  }
}

export default reducer;
