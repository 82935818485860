import Immutable from 'seamless-immutable'

const SET_POLIS = 'SET_POLIS'
const SET_HUBUNGAN_PEMBAYAR = 'SET_HUBUNGAN_PEMBAYAR'
const SET_EMAIL_PEMBAYAR = 'SET_EMAIL_PEMBAYAR'
const SET_DBO_DOWNLOAD = 'SET_DBO_DOWNLOAD'
const SET_SKDKK_DOWNLOAD = 'SET_SKDKK_DOWNLOAD'
const SET_SHOW_PRODUCT = 'SET_SHOW_PRODUCT'
const SET_SHOW_POLICY_HOLDER = 'SET_SHOW_POLICY_HOLDER'
const SET_SHOW_INSURED = 'SET_SHOW_INSURED'
const SET_PEMEGANG_POLIS = 'SET_PEMEGANG_POLIS'
const SET_PAYMENT_VALIDATION = 'SET_PAYMENT_VALIDATION'
const RESET_AUTH = 'RESET_AUTH'
const RESET_FIELD = 'RESET_FIELD'

const SET_EXPIRED = 'SET_EXPIRED'

const FAILURE = 'FAILURE'
const SIGN_IN = 'SIGN_IN'
const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
const SIGN_IN_FAILED = 'SIGN_IN_FAILED'
const LOGOUT = 'LOGOUT'

const actionTypes = Immutable({
  SET_POLIS,
  SET_HUBUNGAN_PEMBAYAR,
  SET_EMAIL_PEMBAYAR,
  SET_DBO_DOWNLOAD,
  SET_SKDKK_DOWNLOAD,
  SET_SHOW_PRODUCT,
  SET_SHOW_POLICY_HOLDER,
  SET_SHOW_INSURED,
  SET_PEMEGANG_POLIS,
  SET_PAYMENT_VALIDATION,
  RESET_AUTH,
  RESET_FIELD,

  SET_EXPIRED,

  FAILURE,
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILED,
  LOGOUT
})

export default actionTypes
