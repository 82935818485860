import React, { useState } from "react";

import useTable from "./useTable";
import TableFooter from "./TableFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../../components/Spinner";

const getHeader = () => {
  var keyHeader = ["Username", "Full Name", "Entity", "Role Name", "Action"];
  return keyHeader.map((key, index) => {
    return <th key={key}>{key}</th>;
  });
};

const mapRowsData = (data, onClickEdit) => {
  return data.map((row, index) => {
    const { username, name, entity, roleName } = row;
    return (
      <tr key={index}>
        <td>{username}</td>
        <td>{name}</td>
        <td>{entity}</td>
        <td>{roleName}</td>
        <td>
          <button
            className="btn btn-sm btn-light"
            style={{
              width: "58px",
              height: "23px",
              fontSize: "10px",
              background: "#F1F3F4",
              borderRadius: "4px",
              color: "#00008f",
              padding: "0",
            }}
            data-bs-toggle="modal"
            data-bs-target="#modalUser"
            onClick={() => onClickEdit(row)}
          >
            <FontAwesomeIcon icon={faPencil} className="me-1" /> Edit
          </button>
        </td>
      </tr>
    );
  });
};

const TableUser = ({ data, rowsPerPage, onClickEdit, loading }) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);

  return (
    <React.Fragment>
      <div
        className="dashboard-table table-responsive mb-4"
        id="tableData"
        style={{ display: "block" }}
      >
        <div className="mt-2">
          <table
            className="table"
            width="100%"
            style={{
              marginLeft: 0,
              backgroundColor: "white",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "rgb(0 0 143)",
                  marginLeft: "10px",
                  marginRight: "10px",
                  // borderCollapse: "collapse",
                  textAlign: "center",
                  color: "white",
                  verticalAlign: "center",
                }}
              >
                {getHeader()}
              </tr>
            </thead>
            <tbody
              style={{
                backgroundColor: "white",
                textAlign: "center",
              }}
            >
              {loading ? (
                <tr>
                  <td colSpan={5}>
                    <Spinner visible={loading} bgColor={"#00006e"} size={30} />
                  </td>
                </tr>
              ) : data !== null && data.length > 0 ? (
                mapRowsData(slice, onClickEdit)
              ) : (
                <tr>
                  <td colSpan={5}>No Data Available in Table</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </React.Fragment>
  );
};

export default TableUser;
