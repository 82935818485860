import React, { Component, createRef } from "react"
import Cookies from "js-cookie"
import { encryptDecript } from "../helpers/encryptDecrypt.js"
import "./download.css"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import DocsAttached from "../assets/docs/Surat-Kuasa-Debet-Kartu-Kredit-AXA-Financial-Indonesia.pdf"
import DocsBOAttached from "../assets/docs/Formulir-Beneficial-Owner-Perorangan-AFI.pdf"
import { determinePeriode } from "./SuccessPage.js"
import ImgLogo from "../assets/images/axa-logo-bgwhite.svg"

class Download extends Component {
  constructor() {
    super()
    this.state = {
      transactionID:
        localStorage.getItem("AFIPAY01A10") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A10"), 2)
          : "",
      polisNumber:
        localStorage.getItem("AFIPAY01A01") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A01"), 2)
          : "",
      grossAmount:
        localStorage.getItem("AFIPAY01A13") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A13"), 2)
          : "",
      premiAmount:
        localStorage.getItem("AFIPAY01A35") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A35"), 2)
          : "",
      periodeJatuhTempo:
        localStorage.getItem("AFIPAY01A12") !== null
          ? determinePeriode(
            encryptDecript(localStorage.getItem("AFIPAY01A12"), 2)
          )
          : "",
      waktuTransaksi:
        localStorage.getItem("AFIPAY01A14") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A14"), 2)
          : "",
      nomorKartu:
        localStorage.getItem("AFIPAY01A15") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A15"), 2)
          : "",
      saveCard:
        localStorage.getItem("AFIPAY01A16") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A16"), 2)
          : "Tidak",
      jenisTransaksi:
        localStorage.getItem("AFIPAY01A11") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A11"), 2)
          : "",
      classNotification:
        localStorage.getItem("AFIPAY01A28") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A28"), 2)
          : "",
      isPolisNB:
        localStorage.getItem("AFIPAY01A03") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A03"), 2)
          : "",
      currency:
        localStorage.getItem("AFIPAY01A33") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A33"), 2)
          : "",
    }
    this.generatePDF = this.generatePDF.bind(this)
  }

  componentDidMount() {
    document.body.classList.add('download')

    let sTransactionID = ""
    let sNomorPolis = ""
    let sJumlahPembayaran = ""
    let sPeriodeJatuhTempo = ""
    let sPremiJatuhTempo = ""
    let sWaktuTransaksi = ""
    let sNomorKartu = ""
    let sSaveCard = "Tidak"
    let sTransactionTitle = ""
    let sBCAAutoDebit = ""
    let sCoreSystem = ""
    let sHubungan = ""
    let bPolisNB = ""
    let isDownload = true
    let sCurrency = ""

    if (
      localStorage.getItem("AFIPAY01A01") !== "" &&
      localStorage.getItem("AFIPAY01A01") !== null
    ) {
      sTransactionID = encryptDecript(localStorage.getItem("AFIPAY01A10"), 2)
      sNomorPolis = encryptDecript(localStorage.getItem("AFIPAY01A01"), 2)
      sJumlahPembayaran = this.changeMoney(
        encryptDecript(localStorage.getItem("AFIPAY01A13"), 2)
      )
      sPeriodeJatuhTempo = determinePeriode(
        encryptDecript(localStorage.getItem("AFIPAY01A12"), 2)
      )

      sWaktuTransaksi = encryptDecript(localStorage.getItem("AFIPAY01A14"), 2)
      sNomorKartu = encryptDecript(localStorage.getItem("AFIPAY01A15"), 2)
      sSaveCard = encryptDecript(localStorage.getItem("AFIPAY01A16"), 2)
      sTransactionTitle = encryptDecript(
        localStorage.getItem("AFIPAY01A11"),
        2
      )
      sCoreSystem = encryptDecript(localStorage.getItem("AFIPAY01A08"), 2)
      sBCAAutoDebit =
        localStorage.getItem("AFIPAY01A28") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A28"), 2)
          : ""
      sHubungan = encryptDecript(localStorage.getItem("AFIPAY01A09"), 2)
      bPolisNB =
        encryptDecript(localStorage.getItem("AFIPAY01A03"), 2).toLowerCase() ===
        "true"
      sCurrency =
        localStorage.getItem("AFIPAY01A33") !== null
          ? encryptDecript(localStorage.getItem("AFIPAY01A33"), 2)
          : ""

      sPremiJatuhTempo = encryptDecript(localStorage.getItem("AFIPAY01A35"), 2)
    } else {
      isDownload = false
      localStorage.clear()
      // this.props.history.push({
      //   pathname: process.env.REACT_APP_SUBDIR + "/",
      // })
    }

    if (
      this.state.jenisTransaksi.toLowerCase() === "pembayaran premi" ||
      this.state.jenisTransaksi.toLowerCase() === "pembayaran"
    ) {
      if (bPolisNB && sCoreSystem.toLowerCase() === "rls") {
        document.getElementById("periodeJatuhTempo").style.display = "none"
        document.getElementById("premiJatuhTempo").style.display = "none"

        //mobile
        document.getElementById("periodeJatuhTempoMobile").style.display =
          "none"
        document.getElementById("periodeJatuhTempoMobileValue").style.display =
          "none"
        document.getElementById("premiJatuhTempoMobile").style.display = "none"
        document.getElementById("premiJatuhTempoMobileValue").style.display =
          "none"
      } else if (sCurrency === "USD") {
        document.getElementById("premiJatuhTempo").style.display = "none"
        document.getElementById("premiJatuhTempoMobile").style.display = "none"
        document.getElementById("premiJatuhTempoMobileValue").style.display =
          "none"
      }
    } else {
      document.getElementById("jumlahPembayaran").style.display = "none"
      document.getElementById("periodeJatuhTempo").style.display = "none"
      document.getElementById("premiJatuhTempo").style.display = "none"
      document.getElementById("periodeJatuhTempoMobile").style.display = "none"
      document.getElementById("periodeJatuhTempoMobileValue").style.display =
        "none"
      document.getElementById("premiJatuhTempoMobile").style.display = "none"
      document.getElementById("premiJatuhTempoMobileValue").style.display =
        "none"
    }

    if (
      sBCAAutoDebit.toLowerCase() === "bca" &&
      sCoreSystem.toLowerCase() === "rls"
    ) {
      document.getElementById("BcaNotification").style.display = "block"
    }

    if (sCoreSystem.toLowerCase() === "rls") {
      if (sHubungan !== "" && sHubungan !== "0" && sHubungan !== "1") {
        document.getElementById("BONotification").style.display = "block"
      } else {
        document.getElementById("BONotification").style.display = "none"
      }
    }

    if (isDownload) {
      this.setState(
        {
          polisNumber: sNomorPolis,
          transactionID: sTransactionID,
          grossAmount: sJumlahPembayaran,
          periodeJatuhTempo: sPeriodeJatuhTempo,
          waktuTransaksi: sWaktuTransaksi,
          nomorKartu: sNomorKartu,
          saveCard: sSaveCard,
          jenisTransaksi: sTransactionTitle,
          premiAmount: sPremiJatuhTempo,
        },
        this.generatePDF()
      )
    }
  }

  changeMoney(x) {
    x = x.replace(".", ",")
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }

  changePremiAmount(x) {
    x = x.replace(",", "")
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }

  // generate pdf function
  generatePDF() {
    let fileName =
      localStorage.getItem("AFIPAY01A10") !== null
        ? encryptDecript(localStorage.getItem("AFIPAY01A10"), 2)
        : ""
    if (
      fileName === "" ||
      fileName === null ||
      typeof fileName === "undefined"
    ) {
      fileName = "Bukti Pembayaran AFIPAY"
    }
    const input = document.getElementById("divToPrint")
    html2canvas(input, { scrollY: -window.scrollY, scale: 2.5 }).then(
      (canvas) => {
        // image
        const imgData = canvas.toDataURL("img/PNG")

        // pdf set margin, ratio, etc
        var pageHeight = 220
        const pdf = new jsPDF("p", "mm", "a4")
        const pageWidth = pdf.internal.pageSize.getWidth()
        // const pageHeight = pdf.internal.pageSize.getHeight()
        const widthRatio = pageWidth / canvas.width
        const heightRatio = pageHeight / canvas.height
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio
        const canvasWidth = canvas.width * ratio
        const canvasHeight = canvas.height * ratio
        const marginX = (pageWidth - canvasWidth) / 2
        const marginY = (pdf.internal.pageSize.getHeight() - pageHeight) / 2

        // adding image to pdf
        pdf.addImage(
          imgData,
          "PNG",
          marginX,
          marginY,
          canvasWidth,
          canvasHeight,
          "",
          "FAST"
        )
        // save pdf
        pdf.save(fileName)
      }
    )
  }

  render() {
    const ref = React.createRef()
    return (
      <div className="container container-download">
        <div ref={ref} className="ref-block">
          <div id="divToPrint" className="printPage">
            <div className="block-atas">
              <h1>Kepada Yth</h1>
              <h2>Bapak/Ibu Pemegang Polis</h2>
              <br />
              <p>
                Terima kasih atas kepercayaan Bapak/Ibu untuk menggunakan
                fasilitas AFIPAY
              </p>
              <p>Berikut rincian transaksi yang telah Bapak/Ibu lakukan:</p>
            </div>
            <div className="table">
              <div className="table-title">Detail Transaksi</div>
              <table class="desktop-view-table">
                <tr>
                  <td className="td-left">Order ID</td>
                  <td>:</td>
                  <td>{this.state.transactionID}</td>
                </tr>
                <tr>
                  <td className="td-left">Tanggal Transaksi</td>
                  <td>:</td>
                  <td>{this.state.waktuTransaksi}</td>
                </tr>
                <tr>
                  <td className="td-left">Nomor Polis</td>
                  <td>:</td>
                  <td>{this.state.polisNumber}</td>
                </tr>
                <tr>
                  <td className="td-left">Jenis Transaksi</td>
                  <td>:</td>
                  <td>{this.state.jenisTransaksi}</td>
                </tr>
                <tr id="periodeJatuhTempo">
                  <td className="td-left">Periode Jatuh Tempo</td>
                  <td>:</td>
                  <td>{this.state.periodeJatuhTempo}</td>
                </tr>
                <tr id="premiJatuhTempo">
                  <td className="td-left">Premi Jatuh Tempo</td>
                  <td>:</td>
                  <td>{this.state.premiAmount}</td>
                </tr>
                <tr id="jumlahPembayaran">
                  <td className="td-left">Jumlah Pembayaran</td>
                  <td>:</td>
                  <td>{this.state.grossAmount}</td>
                </tr>
                <tr>
                  <td className="td-left">Nomor Kartu Kredit/Rekening</td>
                  <td>:</td>
                  <td>{this.state.nomorKartu}</td>
                </tr>
                <tr>
                  <td className="td-left">
                    Mendaftar untuk premi lanjutan dengan autodebet
                  </td>
                  <td>:</td>
                  <td>{this.state.saveCard}</td>
                </tr>
              </table>
              <table class="mobile-view-table">
                <tr>
                  <td className="td-left">Order ID</td>
                </tr>
                <tr>
                  <td>{this.state.transactionID}</td>
                </tr>
                <tr>
                  <td className="td-left">Tanggal Transaksi</td>
                </tr>
                <tr>
                  <td>{this.state.waktuTransaksi}</td>
                </tr>
                <tr>
                  <td className="td-left">Nomor Polis</td>
                </tr>
                <tr>
                  <td>{this.state.polisNumber}</td>
                </tr>
                <tr>
                  <td className="td-left">Jenis Transaksi</td>
                </tr>
                <tr>
                  <td>{this.state.jenisTransaksi}</td>
                </tr>
                <tr id="periodeJatuhTempoMobile">
                  <td className="td-left">Periode Jatuh Tempo</td>
                </tr>
                <tr id="periodeJatuhTempoMobileValue">
                  <td>{this.state.periodeJatuhTempo}</td>
                </tr>
                <tr id="premiJatuhTempoMobile">
                  <td className="td-left">Premi Jatuh Tempo</td>
                </tr>
                <tr id="premiJatuhTempoMobileValue">
                  <td>{this.state.premiAmount},00</td>
                </tr>
                <tr id="jumlahPembayaran">
                  <td className="td-left">Jumlah Pembayaran</td>
                </tr>
                <tr id="jumlahPembayaran">
                  <td>{this.state.grossAmount}</td>
                </tr>
                <tr>
                  <td className="td-left">Nomor Kartu Kredit/Rekening</td>
                </tr>
                <tr>
                  <td>{this.state.nomorKartu}</td>
                </tr>
                <tr>
                  <td className="td-left">
                    Mendaftar untuk premi lanjutan dengan autodebet
                  </td>
                </tr>
                <tr>
                  <td>{this.state.saveCard}</td>
                </tr>
              </table>
              <div
                className="notification-block-dbb"
                id="BcaNotification"
                style={{ display: "none" }}
              >
                Bagi Anda yang mendaftarkan jenis kartu BCA Card, silakan unduh
                dan lengkapi Formulir Surat Kuasa Debet Kartu Kredit (SKDKK)
                pada tautan{" "}
                <a href={DocsAttached} target="_blank">
                  berikut
                </a>
                . Kirimkan formulir tersebut dengan disertai Salinan Identitas
                Diri (KTP/SIM) dan Salinan Kartu Kredit bagian depan ke alamat
                email{" "}
                <a href="mailto:customer@axa-financial.co.id">
                  customer@axa-financial.co.id
                </a>{" "}
                atau Tenaga Pemasar atau Kantor Pusat AXA Financial Indonesia
              </div>
              <div
                className="notification-block-dbb"
                id="BONotification"
                style={{ display: "none" }}
              >
                Bagi Anda yang mendaftarkan Kartu Kredit/Gopay bukan atas nama
                Pemegang Polis, silakan unduh dan lengkapi Formulir Beneficiary
                Owner Perorangan pada tautan{" "}
                <a href={DocsBOAttached} target="_blank">
                  berikut
                </a>
                . Kirimkan formulir tersebut dengan disertai salinan Identitas
                Diri (KTP/SIM) dan salinan kartu keluarga/akta nikah/surat
                perwalian ke alamat email{" "}
                <a href="mailto:customer@axa-financial.co.id">
                  customer@axa-financial.co.id
                </a>{" "}
                atau Tenaga Pemasar atau Kantor Pusat AXA Financial Indonesia
              </div>
            </div>
            <div className="block-bawah">
              <p className="informasi">
                Untuk informasi lebih lanjut, Bapak/ibu dapat menghubungi
                layanan Customer Care Center di nomor 1500 940 atau melalui
                email ke{" "}
                <a href="mailto:customer@axa-financial.co.id">
                  customer@axa-financial.co.id
                </a>
              </p>
              <p>Hormat Kami</p>
              <p>PT AXA Financial Indonesia</p>
            </div>
            <div className="download-footer">
              <p>PT AXA Financial Indonesia</p>
              <p>
                AXA Tower lt. 16, Jl. Prof.Dr.Satrio Kav.18 Kuningan City,
                Jakarta 12940, Indonesia, Jakarta, Indonesia
              </p>
              <p>1500 940</p>
              <div className="download-footer-logo">
                <img className="logo-axa" src={ImgLogo} alt="logo-axa" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Download
