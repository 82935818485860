import React, { useState } from "react";

import useTable from "./useTable";
import TableFooter from "./TableFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faPencil } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../../components/Spinner";

const getHeader = () => {
  var keyHeader = ["Role ID", "Role Name", "Role Description", "Action"];
  return keyHeader.map((key, index) => {
    return <th key={key}>{key}</th>;
  });
};

const mapRowsData = (data, onClickEdit, onClickAccess) => {
  return data.map((row, index) => {
    const { id, role_name, role_description } = row;

    return (
      <tr key={index}>
        <td>{id}</td>
        <td>{role_name}</td>
        <td>{role_description}</td>
        <td>
          <button
            className="btn btn-sm btn-light"
            style={{
              width: "58px",
              height: "23px",
              fontSize: "10px",
              background: "#F1F3F4",
              borderRadius: "4px",
              color: "#00008f",
              padding: "0",
            }}
            data-bs-toggle="modal"
            data-bs-target="#modalRole"
            onClick={() => onClickEdit(row)}
          >
            <FontAwesomeIcon icon={faPencil} className="me-1" /> Edit
          </button>

          <button
            className="btn btn-sm btn-light"
            style={{
              width: "58px",
              height: "23px",
              fontSize: "10px",
              background: "#F1F3F4",
              borderRadius: "4px",
              color: "#00008f",
              padding: "0",
            }}
            data-bs-toggle="modal"
            data-bs-target="#modalRole"
            onClick={() => onClickAccess(row)}
          >
            <FontAwesomeIcon icon={faKey} className="me-1" /> Access
          </button>
        </td>
      </tr>
    );
  });
};

const TableRole = ({
  data,
  rowsPerPage,
  onClickEdit,
  onClickAccess,
  loading,
}) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);

  return (
    <React.Fragment>
      <div
        className="dashboard-table table-responsive mb-4"
        id="tableData"
        style={{ display: "block" }}
      >
        <div className="mt-2">
          <table
            className="table"
            width="100%"
            style={{
              marginLeft: 0,
              backgroundColor: "white",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "rgb(0 0 143)",
                  marginLeft: "10px",
                  marginRight: "10px",
                  // borderCollapse: "collapse",
                  textAlign: "center",
                  color: "white",
                  verticalAlign: "center",
                }}
              >
                {getHeader()}
              </tr>
            </thead>
            <tbody
              style={{
                backgroundColor: "white",
                textAlign: "center",
              }}
            >
              {loading ? (
                <tr>
                  <td colSpan={4}>
                    <Spinner visible={loading} bgColor={"#00006e"} size={30} />
                  </td>
                </tr>
              ) : data !== null && data.length > 0 ? (
                mapRowsData(slice, onClickEdit, onClickAccess)
              ) : (
                <tr>
                  <td colSpan={4}>No Data Available in Table</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </React.Fragment>
  );
};

export default TableRole;
