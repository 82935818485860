import React, { Component } from "react";
import ImgLogo from "../assets/images/logo-axa.svg";

class Header extends Component {
	render() {
		return <div className="header">
            <a href={process.env.REACT_APP_BASEREF}>
				<img className="logo-axa" src={ImgLogo} alt="logo-axa" />
			</a>
        </div>;
	}
}
export default Header;