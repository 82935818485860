import { takeEvery, put, all, call, select } from "redux-saga/effects";
import actionTypes from "./actionTypes";
import {
  actionSetVerificationPolisFailed,
  actionSetVerificationPolisSuccess,
  cancelSuccess,
  actionGenerateOtpSuccess,
  actionGenerateOtpFailed,
  verifyFailed,
  verifyExpired,
  verifySuccess,
  actionRequestingStart,
  actionRequestingEnd,
  actionLimit,
} from "./actions";
import { generateRandomChar } from "../../utils/formatter";
import {
  authExpiredTime,
  bypassOtp,
  dummyOtp,
  otpExpired,
  otpIntervalRequest,
  otpLength,
} from "../../utils/config";
import history from "../../utils/history";
import { setPolis, setExpired } from "../auth/actions";
import { actionCheckPaymentValidation } from "../payment/actions";
import { request } from "../../utils/request";
import { encryptDecript } from "../../helpers/encryptDecrypt";
import { accessToken } from "../../helpers/accessToken";

function* sagaSetVerificationPolis(params) {
  let polis = params?.data?.polis;
  if (polis) {
    yield put(actionSetVerificationPolisSuccess(polis));
  } else {
    yield put(actionSetVerificationPolisFailed());
  }
}

function* doGenerateOtp(params) {
  yield put(actionRequestingStart());

  let otp = generateRandomChar(otpLength, true);
  // let target = (params?.data?.polis?.mobilePhoneNo)?.toString()?.replace(/\s/g, '')?.replace(/\+/g, '')?.replace(/^08/, "628")?.replace(/^8/, "628")
  let target = params?.data?.polis?.mobilePhoneNo
    ?.toString()
    ?.replace(/\s/g, "")
    ?.replace(/[^0-9\s]/g, "")
    ?.replace(/^08/, "628")
    ?.replace(/^8/, "628");

  if (dummyOtp === true) {
    let requestedAt = parseInt(new Date().getTime() / 1000);
    let nextRequestAt = requestedAt + otpIntervalRequest;
    let expiredAt = requestedAt + otpExpired;
    let data = {
      otp,
      target,
      requestedAt,
      expiredAt,
      nextRequestAt,
    };

    yield put(actionGenerateOtpSuccess(data));
    yield put(actionRequestingEnd());
  } else {
    let requestedAt = parseInt(new Date().getTime() / 1000);
    try {
      let headerPost = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken(),
      };
      let payloadJson = {
        otp,
        target,
        // policyNumber: params?.data?.polis?.polisnumber,
        isRetry: params?.retry === true ? "true" : "false",
        notifType: "sms",
      };
      // let payloadString = encryptDecript(JSON.stringify(payloadJson), 1)
      // payloadString =
      //   process.env.REACT_APP_PREFIX +
      //   payloadString.substring(0, 4) +
      //   process.env.REACT_APP_PREFIX +
      //   payloadString.substring(4, payloadString.length)
      // let payload = new FormData()
      // payload.append("data", payloadString)

      // const res = yield call(request, `${process.env.REACT_APP_API_OTP}`, {
      const res = yield call(
        request,
        `${process.env.REACT_APP_API_FE}/notify`,
        {
          method: "POST",
          headers: headerPost,
          body: JSON.stringify(payloadJson),
        }
      );

      if (res?.data?.status === "SUCCESS") {
        let nextRequestAt =
          requestedAt +
          (res?.data?.otpInterval
            ? parseInt(res?.data?.otpInterval)
            : otpIntervalRequest);
        let expiredAt =
          requestedAt +
          (res?.data?.otpExpiry ? parseInt(res?.data?.otpExpiry) : otpExpired);
        let data = {
          otp,
          target,
          requestedAt,
          expiredAt,
          nextRequestAt,
        };

        yield put(actionGenerateOtpSuccess(data));
      } else if (res?.data?.status === "LIMIT") {
        let data = {
          otp: null,
          target,
          requestedAt,
          expiredAt: null,
          nextRequestAt: null,
        };
        yield put(actionLimit(data));
      } else {
        let nextRequestAt = requestedAt + 3;
        let data = {
          otp,
          target,
          requestedAt,
          expiredAt: null,
          nextRequestAt,
        };
        yield put(actionGenerateOtpFailed(data));
      }
      yield put(actionRequestingEnd());
    } catch (err) {
      let nextRequestAt = requestedAt + 3;
      let data = {
        otp,
        target,
        requestedAt,
        expiredAt: null,
        nextRequestAt,
      };
      yield put(actionGenerateOtpFailed(data));
      yield put(actionRequestingEnd());
    }
  }
}

function* doVerify(params) {
  let verification = yield select((state) => state.verification);
  let valid = false;
  let expired = false;
  let now = parseInt(new Date().getTime() / 1000);

  if (bypassOtp === false) {
    if (params?.data?.toString() === verification?.otp?.toString()) {
      if (now < verification?.expiredAt) {
        valid = true;
      } else {
        expired = true;
      }
    }
  } else {
    valid = true;
  }

  if (expired === true) {
    yield put(verifyExpired());
  } else {
    if (valid === true) {
      let polis = verification?.polis;
      yield put(setPolis(polis));
      yield put(actionCheckPaymentValidation(polis));

      yield put(setExpired(now + authExpiredTime));

      yield put(verifySuccess());
      yield call(history.replace, process.env.REACT_APP_SUBDIR + "/beranda", {
        data: verification?.polis,
      });
    } else {
      yield put(verifyFailed());
    }
  }
}

function* doCancel() {
  yield put(cancelSuccess());
  yield call(history.push, process.env.REACT_APP_SUBDIR + "/sign-in");
}

function* authSaga() {
  yield all([
    takeEvery(actionTypes.SET_VERIFICATION_POLIS, sagaSetVerificationPolis),
    takeEvery(actionTypes.GENERATE, doGenerateOtp),
    takeEvery(actionTypes.VERIFY, doVerify),
    takeEvery(actionTypes.CANCEL, doCancel),
  ]);
}

export default authSaga;
