import React, { useEffect, useRef, useState } from "react"
import { Redirect, withRouter } from "react-router-dom"
import Header from "../components/Layout/AuthLayout/Header"
import Preloader from "../components/Preloader"
import { bypassOtp, dummyOtp, otpIntervalRequest } from "../utils/config"
import { asTime, leadingZero, maskPhoneNumber } from "../utils/formatter"
import { connect } from 'react-redux'
import { generate, verify, cancel, resetError } from '../store/verification/actions'

const Otp = (props) => {
    const { verification, verify, generate, cancel, resetError } = props

    const [sisaWaktu, setSiswaWaktu] = useState(null)
    const [bisaKirimUlang, setBisaKirimUlang] = useState(false)
    const [error, setError] = useState(null)

    const [digit1, setDigit1] = useState("")
    const [digit2, setDigit2] = useState("")
    const [digit3, setDigit3] = useState("")
    const [digit4, setDigit4] = useState("")
    const [digit5, setDigit5] = useState("")
    const [digit6, setDigit6] = useState("")

    const [semuaDigit, setSemuaDigit] = useState(false)

    useEffect(() => {
        resetError()
        if (verification?.polis) {
            if (!verification?.otp) {
                if (verification?.limit !== true) {
                    generate(verification, false)
                }
            }
        }
    }, [])

    useEffect(() => {
        if (verification?.limit === true) {
            setSiswaWaktu(0)
            setBisaKirimUlang(false)
        } else {
            if (verification?.otp) {
                let now = parseInt((new Date).getTime() / 1000)
                if (now < verification?.expiredAt) {
                    if (now < verification?.nextRequestAt) {
                        setSiswaWaktu(verification?.nextRequestAt - now)
                    } else {
                        setBisaKirimUlang(true)
                    }
                } else {
                    setBisaKirimUlang(true)
                }
            }
        }
    }, [verification?.otp, verification?.expiredAt, verification?.nextRequestAt, verification?.limit])

    const regenerateOtp = (e) => {
        e.preventDefault()
        if (bisaKirimUlang) {
            generate(verification, true)
            // setSiswaWaktu(otpIntervalRequest)
            setBisaKirimUlang(false)
            reset()
        }
    }

    useEffect(() => {
        if (!sisaWaktu) return

        const intervalId = setInterval(() => {
            setSiswaWaktu(sisaWaktu - 1)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [sisaWaktu])

    useEffect(() => {
        if (verification?.limit === true) {
            setBisaKirimUlang(false)
        } else {
            if (sisaWaktu !== null && sisaWaktu < 1) {
                setBisaKirimUlang(true)
            }
        }
    }, [sisaWaktu, verification?.limit])

    useEffect(() => {
        if (bypassOtp === false) {
            if (digit1?.trim() && digit2?.trim() && digit3?.trim() && digit4?.trim() && digit5?.trim() && digit6?.trim()) {
                setSemuaDigit(true)
            } else {
                setSemuaDigit(false)
            }
        } else {
            setSemuaDigit(true)
        }
    }, [digit1, digit2, digit3, digit4, digit5, digit6])

    const inputDigit1 = useRef(null)
    const inputDigit2 = useRef(null)
    const inputDigit3 = useRef(null)
    const inputDigit4 = useRef(null)
    const inputDigit5 = useRef(null)
    const inputDigit6 = useRef(null)

    const onClick = (digit) => {
        if (digit === 1) {
            inputDigit1.current.value && inputDigit1.current.select()
        } else if (digit === 2) {
            inputDigit2.current.value && inputDigit2.current.select()
        } else if (digit === 3) {
            inputDigit3.current.value && inputDigit3.current.select()
        } else if (digit === 4) {
            inputDigit4.current.value && inputDigit4.current.select()
        } else if (digit === 5) {
            inputDigit5.current.value && inputDigit5.current.select()
        } else if (digit === 6) {
            inputDigit6.current.value && inputDigit6.current.select()
        }
    }

    const onChange = (e, digit) => {
        let notAllowedChar = [
            'e',
            'E'
        ]

        if (notAllowedChar.includes(inputDigit1.current.value.trim())) {
            return
        }

        if (digit === 1) {
            inputDigit1.current.value.trim() ? setDigit1(inputDigit1.current.value?.substring(0, 1)) : setDigit1("")
        }
        if (digit === 2) {
            inputDigit2.current.value.trim() ? setDigit2(inputDigit2.current.value?.substring(0, 1)) : setDigit2("")
        }
        if (digit === 3) {
            inputDigit3.current.value.trim() ? setDigit3(inputDigit3.current.value?.substring(0, 1)) : setDigit3("")
        }
        if (digit === 4) {
            inputDigit4.current.value.trim() ? setDigit4(inputDigit4.current.value?.substring(0, 1)) : setDigit4("")
        }
        if (digit === 5) {
            inputDigit5.current.value.trim() ? setDigit5(inputDigit5.current.value?.substring(0, 1)) : setDigit5("")
        }
        if (digit === 6) {
            inputDigit6.current.value.trim() ? setDigit6(inputDigit6.current.value?.substring(0, 1)) : setDigit6("")
        }
    }

    const onKeyUp = (e, digit) => {
        // || (e.keyCode >= 65 && e.keyCode <= 90)

        if (isNaN(e?.target?.valueAsNumber) && (e.keyCode !== 37 && e.keyCode !== 39 && e.keyCode !== 8 && e.keyCode !== 46)) {
            e.preventDefault()
            return false
        }

        const valid = (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39
        const back = e.keyCode === 8 || e.keyCode === 37

        if (digit === 1) {
            if (back === true) {
                inputDigit1.current.focus()
                inputDigit1.current.value && inputDigit1.current.select()
            } else if (valid === true) {
                inputDigit2.current.focus()
                inputDigit2.current.value && inputDigit2.current.select()
            }
        } else if (digit === 2) {
            if (back === true) {
                inputDigit1.current.focus()
                inputDigit1.current.value && inputDigit1.current.select()
            } else if (valid === true) {
                inputDigit3.current.focus()
                inputDigit3.current.value && inputDigit3.current.select()
            }
        } else if (digit === 3) {
            if (back === true) {
                inputDigit2.current.focus()
                inputDigit2.current.value && inputDigit2.current.select()
            } else if (valid === true) {
                inputDigit4.current.focus()
                inputDigit4.current.value && inputDigit4.current.select()
            }
        } else if (digit === 4) {
            if (back === true) {
                inputDigit3.current.focus()
                inputDigit3.current.value && inputDigit3.current.select()
            } else if (valid === true) {
                inputDigit5.current.focus()
                inputDigit5.current.value && inputDigit5.current.select()
            }
        } else if (digit === 5) {
            if (back === true) {
                inputDigit4.current.focus()
                inputDigit4.current.value && inputDigit4.current.select()
            } else if (valid === true) {
                inputDigit6.current.focus()
                inputDigit6.current.value && inputDigit6.current.select()
            }
        } else if (digit === 6) {
            if (back === true) {
                inputDigit5.current.focus()
                inputDigit5.current.value && inputDigit5.current.select()
            }
        }
    }

    const reset = () => {
        setDigit1("")
        setDigit2("")
        setDigit3("")
        setDigit4("")
        setDigit5("")
        setDigit6("")
        setError(null)
        resetError()
    }

    useEffect(() => {
        if (verification?.submit === true && verification?.load === false && verification?.success === false) {
            setError("Kode OTP salah")
        } else if (verification?.submit === true && verification?.load === false && verification?.expired === true) {
            setError("Kode OTP sudah kadaluarsa")
        } else {
            setError(null)
        }
    }, [verification?.submit, verification?.load, verification?.success, verification?.expired])

    const onSubmit = () => {
        const digit = `${digit1}${digit2}${digit3}${digit4}${digit5}${digit6}`
        verify(digit)
    }

    const view = () => {
        return <div className="container otp text-center pt-4 pb-5 mb-5">
            <form method="get" className="digit-group mb-4" data-group-name="digits" data-autosubmit="false" autoComplete="off">
                <input ref={inputDigit1} type="number" onKeyDown={(evt) => ['e', '-', '.', '+'].includes(evt.key) && evt.preventDefault()} name="digit-1" value={digit1} onChange={(e) => onChange(e, 1)} onClick={() => onClick(1)} onKeyUp={(e) => onKeyUp(e, 1)} maxLength="1" className={error ? " border-danger " : ""} />
                <input ref={inputDigit2} type="number" onKeyDown={(evt) => ['e', '-', '.', '+'].includes(evt.key) && evt.preventDefault()} name="digit-2" value={digit2} onChange={(e) => onChange(e, 2)} onClick={() => onClick(2)} onKeyUp={(e) => onKeyUp(e, 2)} maxLength="1" className={error ? " border-danger " : ""} />
                <input ref={inputDigit3} type="number" onKeyDown={(evt) => ['e', '-', '.', '+'].includes(evt.key) && evt.preventDefault()} name="digit-3" value={digit3} onChange={(e) => onChange(e, 3)} onClick={() => onClick(3)} onKeyUp={(e) => onKeyUp(e, 3)} maxLength="1" className={error ? " border-danger " : ""} />
                <input ref={inputDigit4} type="number" onKeyDown={(evt) => ['e', '-', '.', '+'].includes(evt.key) && evt.preventDefault()} name="digit-4" value={digit4} onChange={(e) => onChange(e, 4)} onClick={() => onClick(4)} onKeyUp={(e) => onKeyUp(e, 4)} maxLength="1" className={error ? " border-danger " : ""} />
                <input ref={inputDigit5} type="number" onKeyDown={(evt) => ['e', '-', '.', '+'].includes(evt.key) && evt.preventDefault()} name="digit-5" value={digit5} onChange={(e) => onChange(e, 5)} onClick={() => onClick(5)} onKeyUp={(e) => onKeyUp(e, 5)} maxLength="1" className={error ? " border-danger " : ""} />
                <input ref={inputDigit6} type="number" onKeyDown={(evt) => ['e', '-', '.', '+'].includes(evt.key) && evt.preventDefault()} name="digit-6" value={digit6} onChange={(e) => onChange(e, 6)} onClick={() => onClick(6)} onKeyUp={(e) => onKeyUp(e, 6)} maxLength="1" className={error ? " border-danger " : ""} />
            </form>

            {
                dummyOtp === true ? <p className="form-tip text-center mb-2">Dummy OTP: {verification?.otp}</p> : null
            }

            {
                !error && verification?.limit === true ? <p className="form-tip form-alert text-center mb-4"><i>Nomor polis Anda mencapai batas permintaan OTP, silakan coba lagi besok.</i></p> : null
            }
            {
                !error && verification?.generated === false ? <p className="form-tip form-alert text-center mb-2"><i>OTP gagal dikirim</i></p> : null
            }
            <p className="form-tip form-alert text-center mb-2"><i>{error}</i></p>

            <p className="px-4 mb-0">Kami telah mengirimkan kode verifikasi OTP ke nomor ponsel <span>{maskPhoneNumber(verification?.polis?.nomorhp?.replace(/\s/g, ''))}</span></p>
            <p className="mt-0">
                Tidak menerima kode OTP?
                <a href="." onClick={(e) => regenerateOtp(e)} className={bisaKirimUlang ? "fw-semibold" : "fw-normal text-black-50 disabled"}> Kirim Ulang</a>
            </p>
            <div className="mx-auto d-flex align-items-center justify-content-center mb-5">
                <span className="ic-clock-blue me-2"></span>
                <span className="otp-timer">{asTime(sisaWaktu, true)}</span>
            </div>
            <div style={{ maxWidth: "360px" }} className="d-flex mx-auto align-items-center justify-content-center">
                <button onClick={onSubmit} className={"btn btn-primary btn-responsive col-12 " + (!semuaDigit && "disabled")} disabled={
                    !semuaDigit
                }>Verifikasi</button>
            </div>
        </div>
    }

    return <React.Fragment>
        <Header onClick={() => cancel()} urlLabel="Batal" title={"Verifikasi Kode OTP"} confirm={true} icon={"ic-out"} confirmTitle={"Batal?"} confirmDescription={"Apakah anda yakin ingin membatalkan sesi anda?"} />

        <div style={{ minHeight: "40vh" }}>
            <Preloader visible={verification?.requesting === true} />
            {
                verification?.requesting === false ?
                    view()
                    : null
            }
        </div>

    </React.Fragment>
}

const mapStateToProps = state => {
    return { verification: state.verification }
}

export default withRouter(connect(mapStateToProps, { generate, verify, cancel, resetError })(Otp))