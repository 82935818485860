import React from "react"

const SnK = ({ id, acceptTnc = null, opsi = "Pembayaran" }) => {



    return <React.Fragment>
        <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-md modal-dialog-centered">
                <div className={"modal-content tnc"}>
                    <div className="modal-header">
                        <h5 className="modal-title">Syarat dan Ketentuan</h5>
                    </div>
                    <div className="modal-body pt-0 pb-3">

                        {

                            opsi === "vaBca" ?
                                <ol className="ps-3">
                                    <li>
                                        Mohon pastikan nominal premi yang diinput sudah sesuai dengan premi yang tertagih.
                                    </li>
                                    <li>
                                        Bukti Pembayaran Premi WAJIB dikirim ke email customer@axa-financial.co.id, subjek Email diisi [BUKTI BAYAR VA] No Polis - Nama Pemegang Polis.
                                    </li>
                                    <li>
                                        Transaksi akan diproses apabila dana yang dibayarkan sesuai dengan premi tertagih dan berhasil masuk ke rekening PT AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        Transaksi yang kami terima pada hari kerja sebelum pukul 16.00 WIB akan diproses di hari yang sama, dan transaksi diatas jam 16.00 WIB akan diproses pada hari kerja berikutnya.
                                    </li>
                                    <li>
                                        Pembayar wajib Pemegang Polis dan menggunakan akun atas nama Pemegang Polis. Bila Pembayar bukan Pemegang Polis atau menggunakan akun bukan milik Pemegang Polis, akun yang dapat digunakan HANYA akun dari keluarga inti Pemegang Polis (suami/istri/anak-orangtua/wali resmi). Pemilik Akun WAJIB mengisi dan menandatangani formulir Beneficial Owner disertai fotocopy kartu keluarga/akta nikah/surat perwalian/dokumen lainnya sebagaimana dipersyaratkan oleh PT AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        Pembayaran premi dinyatakan sah setelah dana diterima di rekening PT AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        Seluruh biaya yang timbul atas transaksi pembayaran menjadi tanggung jawab Nasabah sepenuhnya.
                                    </li>
                                    <li>
                                        PT AXA Financial Indonesia tidak bertanggung jawab atas penolakan otorisasi oleh bank penerbit.
                                    </li>
                                </ol>
                                : null

                        }
                        {
                            opsi === "Pembayaran" ?
                                <ol className="ps-3">
                                    <li>
                                        Pembayaran Premi Asuransi <b><u>WAJIB</u></b> menggunakan kartu kredit atas nama Pemegang Polis sendiri. Apabila kartu kredit yang digunakan bukan atas nama/milik Pemegang Polis maka:
                                    </li>
                                    <ul className="ps-4">
                                        <li>
                                            <b><u>HANYA</u></b> diperbolehkan untuk kartu kredit milik pihak yang memiliki Insurable Interest dengan Pemegang Polis (contoh: suami/istri/anak-orangtua/wali resmi) (“<b><i>Pihak dengan Insurable Interest</i></b>“); dan
                                        </li>
                                        <li>
                                            Pihak dengan Insurable Interest <b><u>WAJIB</u></b> mengisi dan menandatangani Formulir Beneficial Owner serta melampirkan salinan Kartu Keluarga/Akta Perkawinan/Surat Perwalian, dan dokumen lainnya sebagaimana dipersyaratkan oleh PT AXA Financial Indonesia.
                                        </li>
                                    </ul>
                                    <li>
                                        Pemilik Kartu Kredit (baik Pemegang Polis atau Pihak dengan Insurable Interest) memberikan persetujuan untuk pemberian kuasa yang tidak dapat dicabut kembali kepada PT AXA Financial Indonesia untuk melakukan penarikan dana dari kartu kredit untuk pembayaran premi pertama dan premi lanjutan, termasuk bila ada perubahan premi yang telah diinformasikan sebelumnya oleh PT AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        Pemilik Kartu Kredit memberikan persetujuan untuk dilakukan pendebitan dana sebesar Rp10.000,- (sepuluh ribu rupiah) dalam proses pendaftaran kartu kredit. Setelah transaksi pendebitan berhasil, dana tersebut akan dikembalikan secara otomatis ke limit kartu kredit Pemilik Kartu Kredit.
                                    </li>
                                    <li>
                                        Pembayaran Premi Asuransi dapat dinyatakan sah setelah dana diterima di rekening PT AXA Financial Indonesia
                                    </li>
                                    <li>
                                        PT AXA Financial Indonesia tidak bertanggung jawab atas penolakan otorisasi oleh bank penerbit kartu kredit.
                                    </li>
                                    <li>
                                        Pemegang Polis dan/atau Pemilik Kartu Kredit dengan ini menjamin serta bertanggung jawab atas kebenaran data dan informasi yang diberikan terkait dengan Nama Pemegang Kartu Kredit dan Nomor Kartu Kredit pada halaman Registrasi Auto Debit.
                                    </li>
                                    <li>
                                        Pemegang Polis dan/atau Pemilik Kartu Kredit dengan ini membebaskan PT AXA Financial Indonesia dan pihak ketiga yang bekerja sama dengan PT AXA Financial Indonesia dari segala tuntutan yang timbul dikemudian hari, khususnya atas hal-hal yang diakibatkan dari pembayaran Premi Asuransi dengan pembayaran menggunakan Kartu Kredit melalui AFIPay.
                                    </li>
                                </ol>
                                : null
                        }

                        {
                            opsi === "AutoDebit" ?
                                <ol className="ps-3">
                                    <li>
                                        Pembayaran Premi Asuransi <b><u>WAJIB</u></b> menggunakan kartu kredit atas nama Pemegang Polis sendiri. Apabila kartu kredit yang digunakan bukan atas nama/milik Pemegang Polis maka:
                                    </li>
                                    <ul className="ps-4">
                                        <li>
                                            <b><u>HANYA</u></b> diperbolehkan untuk kartu kredit milik pihak yang memiliki Insurable Interest dengan Pemegang Polis (contoh: suami/istri/anak-orangtua/wali resmi) (“<b><i>Pihak dengan Insurable Interest</i></b>“); dan
                                        </li>
                                        <li>
                                            Pihak dengan Insurable Interest <b><u>WAJIB</u></b> mengisi dan menandatangani Formulir Beneficial Owner serta melampirkan salinan Kartu Keluarga/Akta Perkawinan/Surat Perwalian, dan dokumen lainnya sebagaimana dipersyaratkan oleh PT AXA Financial Indonesia.
                                        </li>
                                    </ul>
                                    <li>
                                        Pemilik Kartu Kredit (baik Pemegang Polis atau Pihak dengan Insurable Interest) memberikan persetujuan untuk pemberian kuasa yang tidak dapat dicabut kembali kepada PT AXA Financial Indonesia untuk melakukan penarikan dana dari kartu kredit untuk pembayaran premi pertama dan premi lanjutan, termasuk bila ada perubahan premi yang telah diinformasikan sebelumnya oleh PT AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        Pemilik Kartu Kredit memberikan persetujuan untuk dilakukan pendebitan dana sebesar Rp10.000,- (sepuluh ribu rupiah) dalam proses pendaftaran kartu kredit. Setelah transaksi pendebitan berhasil, dana tersebut akan dikembalikan secara otomatis ke limit kartu kredit Pemilik Kartu Kredit.
                                    </li>
                                    <li>
                                        Pembayaran Premi Asuransi dapat dinyatakan sah setelah dana diterima di rekening PT AXA Financial Indonesia
                                    </li>
                                    <li>
                                        PT AXA Financial Indonesia tidak bertanggung jawab atas penolakan otorisasi oleh bank penerbit kartu kredit.
                                    </li>
                                    <li>
                                        Pemegang Polis dan/atau Pemilik Kartu Kredit dengan ini menjamin serta bertanggung jawab atas kebenaran data dan informasi yang diberikan terkait dengan Nama Pemegang Kartu Kredit dan Nomor Kartu Kredit pada halaman Registrasi Auto Debit.
                                    </li>
                                    <li>
                                        Pemegang Polis dan/atau Pemilik Kartu Kredit dengan ini membebaskan PT AXA Financial Indonesia dan pihak ketiga yang bekerja sama dengan PT AXA Financial Indonesia dari segala tuntutan yang timbul dikemudian hari, khususnya atas hal-hal yang diakibatkan dari pembayaran Premi Asuransi dengan pembayaran menggunakan Kartu Kredit melalui AFIPay.
                                    </li>
                                </ol>
                                : null
                        }

                        {
                            opsi === "PembayaranNonCC" ?
                                <ol className="ps-3">
                                    <li>
                                        Pembayar wajib Pemegang Polis dan menggunakan akun atas nama Pemegang Polis. Bila Pembayar bukan Pemegang Polis atau menggunakan akun bukan milik Pemegang Polis, akun yang dapat digunakan HANYA akun dari keluarga inti Pemegang Polis (suami/istri/anak-orangtua/wali resmi). Pemilik Akun WAJIB mengisi dan menandatangani formulir Beneficial Owner disertai fotocopy kartu keluarga/akta nikah/surat perwalian/dokumen lainnya sebagaimana dipersyaratkan oleh PT AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        Persetujuan ini juga termasuk persetujuan untuk pemberian kuasa yang tidak dapat dicabut kembali kepada PT AXA Financial Indonesia untuk melakukan penarikan dana dari akun untuk pembayaran premi pertama dan premi lanjutan termasuk bila ada perubahan premi yang telah disetujui oleh PT AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        Pembayaran premi dinyatakan sah setelah dana diterima di rekening PT. AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        PT AXA Financial Indonesia tidak bertanggung jawab atas penolakan otorisasi oleh bank penerbit.
                                    </li>
                                </ol>
                                : null




                        }

                        {
                            opsi === "AutodebitNonCC" ?
                                <ol className="ps-3">
                                    <li>
                                        Pembayar wajib Pemegang Polis dan menggunakan akun atas nama Pemegang Polis. Bila Pembayar bukan Pemegang Polis atau menggunakan akun bukan milik Pemegang Polis, akun yang dapat digunakan HANYA akun dari keluarga inti Pemegang Polis (suami/istri/anak orang tua/ wali resmi). Dan pemilik akun wajib menandatangani formulir Beneficial Owner disertai fotocopy kartu keluarga/akta nikah/surat perwalian.
                                    </li>
                                    <li>
                                        Persetujuan ini juga termasuk persetujuan untuk pemberian kuasa yang tidak dapat dicabut kembali kepada PT AXA Financial Indonesia untuk melakukan penarikan dana dari akun untuk pembayaran premi pertama dan premi lanjutan termasuk bila ada perubahan premi yang telah disetujui oleh PT AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        Pembayaran premi dinyatakan sah setelah dana diterima di rekening PT. AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        PT AXA Financial Indonesia tidak bertanggung jawab atas penolakan otorisasi oleh bank penerbit.
                                    </li>
                                </ol>
                                : null


                        }


                    </div>
                    <div className="modal-footer d-flex">
                        {
                            acceptTnc ?
                                <button type="button" className="btn btn-primary col" data-bs-dismiss="modal" onClick={acceptTnc}>Setuju</button>
                                : <button type="button" className="btn btn-primary col" data-bs-dismiss="modal">Setuju</button>
                        }

                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default SnK