import Immutable from 'seamless-immutable';
import actionTypes from './actionTypes';

const model = {
  polis: null,
  otp: null,
  target: null,
  requestedAt: null,
  expiredAt: null,
  nextRequestAt: null,
  submit: false,
  load: false,
  requesting: null,
  generated: null,
  success: null,
  expired: false,
  limit: false
}
export const INITIAL_STATE = Immutable(model);

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.REQUESTING_START:
      return { ...state, requesting: true, generated: null, limit: false }
    case actionTypes.REQUESTING_END:
      return { ...state, requesting: false }
    case actionTypes.SET_VERIFICATION_POLIS_SUCCESS:
      let res = {
        ...model,
        polis: action.data,
      }
      return res
    case actionTypes.VERIFY:
      return {
        ...state,
        submit: true,
        load: true,
        success: null,
        expired: false
      }
    case actionTypes.VERIFY_SUCCESS:
      return {
        ...model,
        load: false,
        success: true,
        limit: false
      }
    case actionTypes.VERIFY_FAILED:
      return {
        ...state,
        load: false,
        success: false
      }
    case actionTypes.VERIFY_EXPIRED:
      return {
        ...state,
        load: false,
        expired: true
      }
    case actionTypes.GENERATE_SUCCESS:
      return {
        ...state,
        otp: action.data?.otp,
        target: action.data?.target,
        requestedAt: action.data?.requestedAt,
        expiredAt: action.data?.expiredAt,
        nextRequestAt: action.data?.nextRequestAt,
        generated: true
      }
    case actionTypes.GENERATE_FAILED:
      return {
        ...state,
        otp: action.data?.otp,
        target: action.data?.target,
        requestedAt: action.data?.requestedAt,
        expiredAt: action.data?.expiredAt,
        nextRequestAt: action.data?.nextRequestAt,
        generated: false
      }
    case actionTypes.LIMIT:
      return {
        ...state,
        otp: action.data?.otp,
        target: action.data?.target,
        requestedAt: action.data?.requestedAt,
        expiredAt: action.data?.expiredAt,
        nextRequestAt: action.data?.nextRequestAt,
        limit: true
      }
    case actionTypes.CANCEL_SUCCESS:
      return {
        ...model
      }
    case actionTypes.RESET_ERROR:
      return {
        ...state,
        submit: false,
        load: false,
        success: null
      }
    default:
      return state;
  }
}

export default reducer;
