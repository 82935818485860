import Immutable from 'seamless-immutable';
import actionTypes from './actionTypes';

const model = {
    buyRate: 0,
    success: null,
    loading: false
}
export const INITIAL_STATE = Immutable(model);

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.CHECK_CURRENCY_START:
            return { ...model, loading: true }
        case actionTypes.CHECK_CURRENCY_SUCCESS:
            return { ...state, success: true, buyRate: action.data }
        case actionTypes.CHECK_CURRENCY_FAILED:
            return { ...state, success: false }
        case actionTypes.CHECK_CURRENCY_END:
            return { ...state, loading: false }
        case actionTypes.RESET_CURRENCY:
            return { ...model }
        default:
            return state;
    }
}

export default reducer;
