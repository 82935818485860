import Loader from "react-js-loader";
import "./index.css";

const Spinner = ({
  visible = false,
  bgColor = "#3d3daa",
  color = "#fff",
  size = 20,
}) => {
  return (
    visible === true && (
      <div id="spinner" className="ilb">
        <Loader
          type="spinner-default"
          bgColor={bgColor}
          color={color}
          size={size}
        />
      </div>
    )
  );
};

export default Spinner;
