import React, { Component } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import https from "https";
import Header from "../shared/Header";
import Footer from "../shared/Footer";
// import "react-datepicker/dist/react-datepicker.css";
import Cookies from "js-cookie";
import { encryptDecript } from "../helpers/encryptDecrypt.js";
import ImgLandingMobile from "../assets/images/landing-image.svg";
import ImgLandingDesktop from "../assets/images/landing-image-desktop.svg";
import sha256 from "crypto-js/sha256";
import Spinner from "../components/Spinner";
import IcUser from "../assets/new/img/icons/ic-user.svg";
import IcPass from "../assets/new/img/icons/ic-pass.svg";

class LandingPageAdmin extends Component {
  constructor() {
    super();
    this.state = {
      user: "",
      pass: "",
      bValidation: false,
      errors: new Object(),
      csserrors: new Object(),
      loading: false,
    };
    this.onChang = this.onChang.bind(this);
    this.passwordMasking = this.passwordMasking.bind(this);
    // this.validateOnBlur = this.validateOnBlur.bind(this);
    // this.handleSelectDate = this.handleSelectDate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // const expiredTime = new Date().getTime();
    // Cookies.set("AFIPAY01A01", encryptDecript("", 1), { expires: expiredTime });
    // localStorage.clear();
  }

  passwordMasking(pass) {
    let password = pass;
    for (var i = 0; i < pass.length; i++) {
      // password = ""
    }
  }

  onChang(e) {
    let value = e.target.value;
    let fieldID = e.target.id;
    let csserror = {};
    let error = {};
    let bUpdatedValue = false;

    //user
    //validate user
    if (fieldID === "user") {
      bUpdatedValue = true;
      if (value === "" || value === null) {
        error["user"] = "* Username tidak boleh kosong.";
        csserror["user"] = "notification-error";
      }
    }

    //validate pass
    let showLength = 1;
    if (fieldID === "pass") {
      if (value.length > showLength) {
        this.setState({
          pass:
            value.substring(0, value.length - showLength).replace(/./g, "•") +
            value.substring(value.length - showLength, value.length),
        });
      }

      bUpdatedValue = true;
      if (value === "" || value === null) {
        error["pass"] = "* Password tidak boleh kosong.";
        csserror["pass"] = "notification-error";
      }
    }

    if (bUpdatedValue) {
      this.setState({
        [e.target.id]: value,
        errors: error,
        csserrors: csserror,
      });
    }
  }

  onSubmit(e) {
    this.setState({ loading: true });
    e.preventDefault();

    //validate value
    let bError = false;
    let bErrorUpdated = false;
    let error = this.state.errors;
    let csserror = this.state.csserrors;
    let endpoint = "";

    //validate username and pass
    //user
    if (this.state.user.trim() === "") {
      error["user"] = "* Username tidak boleh kosong.";
      csserror["user"] = "notification-error";
      bErrorUpdated = true;
    }

    //validate pass
    if (this.state.pass === "" || this.state.pass === null) {
      error["pass"] = "Password tidak boleh kosong.";
      csserror["pass"] = "notification-error";
      bErrorUpdated = true;
    }

    if (bErrorUpdated) {
      this.setState({
        errors: error,
        csserrors: csserror,
      });
    }

    if (Object.keys(this.state.errors).length > 0) {
      bError = true;
    }

    if (!bError) {
      // validate account
      let sUser = this.state.user;
      let sPass = this.state.pass;
      let sPasshash = sha256(sPass);

      // declare payload
      //process register
      let payload = {
        username: sUser,
        password: "" + sPasshash,
      };

      let headerPost = {
        "Content-Type": "application/json",
      };

      // hit API login dashboard

      axios
        .post(process.env.REACT_APP_API_DASH + "/dashboard/login", payload, {
          headers: headerPost,
        })
        .then((response) => {
          let bValid = true;
          if (response?.data?.data?.status !== "SUCCESS") {
            if (response?.data?.data?.status !== null) {
              bValid = false;
              error["generalerror"] = response.data.data.message;
              csserror["generalerror"] = "notification-error";
            } else {
              bValid = false;
              error["generalerror"] =
                "*Username atau Password Tidak Terdaftar.";
              csserror["generalerror"] = "notification-error";
            }
            this.setState({
              csserrors: csserror,
              errors: error,
            });
          }

          let data = {
            bValidation: bValid,
          };
          if (bValid) {
            this.setState({ loading: false });
            if (response?.data?.data?.module.length === 0) {
              error["generalerror"] =
                "*User tidak memiliki akses untuk login ke CMS";
              csserror["generalerror"] = "notification-error";
              this.setState({
                csserrors: csserror,
                errors: error,
                user: "",
                pass: "",
              });
            } else {
              localStorage.setItem(
                "AFIPAY01A01",
                encryptDecript(this.state.user, 1)
              );
              localStorage.setItem(
                "AFIPAY02B01",
                encryptDecript(response.data.data.module, 1)
              );
              localStorage.setItem(
                "AFIPAY02B02",
                encryptDecript(response.data.data.token, 1)
              );

              if (
                response?.data?.data?.module[0]?.name ===
                "skdr_bca_registration"
              ) {
                endpoint = "dashboard";
              } else if (response?.data?.data?.module[0]?.name === "report") {
                endpoint = "tokenapps";
              } else if (
                response?.data?.data?.module[0]?.name === "user_management"
              ) {
                endpoint = "users";
              } else if (
                response?.data?.data?.module[0]?.name === "exclude_auto_debit"
              ) {
                endpoint = "exclude-auto-debit";
              } else if (
                response?.data?.data?.module[0]?.name === "refund_split_payment"
              ) {
                endpoint = "refund-split-payment";
              }

              this.props.history.push({
                pathname: process.env.REACT_APP_SUBDIR + "/" + endpoint,
                data: data,
                userData: response.data.data,
              });
            }
          } else {
            this.setState({ loading: false });
            error["generalerror"] = "*Username atau Password tidak terdaftar.";
            csserror["generalerror"] = "notification-error";
            this.setState({
              csserrors: csserror,
              errors: error,
              user: "",
              pass: "",
            });
            // document.getElementById("user").value = "";
            // document.getElementById("pass").value = "";
          }
        })
        .catch((error) => {
          error["generalerror"] = "*Username atau Password tidak terdaftar.";
          csserror["generalerror"] = "notification-error";
          this.setState({
            csserrors: csserror,
            errors: error,
          });
          this.setState({ loading: false });
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="card login-form dashboard-login">
          <form onSubmit={this.onSubmit}>
            <div className="card-body">
              <h5 className="fw-semibold">Log in</h5>
              <span className="mb-4">Untuk masuk ke AFIPAY</span>

              <div className="input-group-custom mt-4">
                <span className="input-group-icon" id="polis">
                  <img src={IcUser} width="24px" height="24px" alt="" />
                </span>

                <input
                  type="text"
                  id="user"
                  className={
                    "form-control " +
                    (this.state.errors["user"] ? " border-danger " : "")
                  }
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="Username"
                  name="user"
                  value={this.state.user}
                  onChange={this.onChang}
                  onBlur={this.validateOnBlur}
                />
              </div>
              <p className="form-tip">
                <i>Username wajib diisi</i>
              </p>
              {this.state.errors["user"] && (
                <p className="form-tip form-alert">
                  <i>{this.state.errors["user"]}</i>
                </p>
              )}

              <div className="input-group-custom">
                <span className="input-group-icon" id="polis">
                  <img src={IcPass} width="22px" height="22px" alt="" />
                </span>

                <input
                  type="password"
                  id="pass"
                  className={
                    "form-control " +
                    (this.state.errors["pass"] ? " border-danger " : "")
                  }
                  placeholder="Password"
                  aria-label="Password"
                  aria-describedby="Password"
                  name="pass"
                  value={this.state.pass}
                  onChange={this.onChang}
                  onBlur={this.validateOnBlur}
                />
              </div>
              <p className="form-tip">
                <i>Password wajib diisi</i>
              </p>
              {this.state.errors["pass"] && (
                <p className="form-tip form-alert">
                  <i>{this.state.errors["pass"]}</i>
                </p>
              )}

              <div className="mt-4 mb-4">
                {this.state.errors["generalerror"] && (
                  <p className="form-tip form-alert mb-3">
                    <i>{this.state.errors["generalerror"]}</i>
                  </p>
                )}
              </div>

              <div className="d-grid gap-2 col-12 mx-auto pb-2">
                <button
                  className="btn btn-lg btn-primary col-12"
                  type="submit"
                  onClick={this.onSubmit}
                  disabled={
                    !this.state.user ||
                    !this.state.pass ||
                    this.state.loading === true
                  }
                >
                  Masuk <Spinner visible={this.state.loading === true} />
                </button>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
export default LandingPageAdmin;
