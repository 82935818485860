import React from 'react'
import { withRouter } from 'react-router-dom'
import ScrollToTop from '../../ScrollToTop'
import Footer from '../Footer'
import ImgAxa from '../../../assets/images/axa_logo_solid_rgb_2.svg'
import ImgLogin from '../../../assets/new/img/Afipay-logo.svg'

const NonAuthLayout = (props) => {
    const { children } = props

    return (
        <React.Fragment>
            <ScrollToTop />
            <div>
                <img className="login-logo no-drag" src={ImgAxa} width="auto" height="79px" alt="logo axa" style={{ paddingLeft: "1rem", paddingTop: "1rem" }}/>
            </div>

            <div className="content-wrapper login-page">
                <div className="row h-100">
                    <div className="col-md-7 d-flex flex-column align-items-center justify-content-center text-center">

                        <img className="login-illustration no-drag" style={{ marginBottom: "10px" }} src={ImgLogin} width="376px"
                            height="auto" alt="illustration" />
                    </div>
                    <div className="col-md-5 d-flex flex-column align-items-center justify-content-center text-center">
                        {children}
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default withRouter(NonAuthLayout)