import React, { useEffect, useState, useRef } from "react"
import SnK from "./SnK"

const PembayaranVa = ({ selected = false, tncVa, setTncVa, setTrueTncVa }) => {
    const [show, setShow] = useState("v-hidden")
    const [tncClicked, seTncClicked] = useState(false)
    const refTnc = useRef(null)

    useEffect(() => {
        
        if (selected === true) {
            setShow("v-hidden collapse-animate-sm")

            setTimeout(() => {
                setShow("collapse-animate-sm")
            }, 500)
        } else {
            setShow("v-hidden")
        }
        
    }, [selected])

    const onChangeTnc = () => {
        if (tncClicked === false) {
            refTnc.current.click()
        } else {
            setTncVa()
        }
    }

    const acceptTnc = () => {
        setTncVa()
        seTncClicked(true)
    }

    return <React.Fragment>
        <div className={"data-pembayaran " + show}>
            <div className="warning-box">
                <p>
                    Jaga Kerahasiaan data pribadi Anda, Jangan berikan data pribadi Anda
                    ke pihak lain.
                </p>
            </div>
            <div className="grey-box ketentuan-pembayaran">
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox" value="tncVA" id="tncVA" onChange={setTncVa} checked={tncVa === true} />
                    <label className="form-check-label" htmlFor="tncVA">
                        <small>
                            Saya setuju dan mengerti terhadap {" "}
                            <a ref={refTnc} href="." onClick={(e) => e.preventDefault()} data-bs-toggle="modal"
                                data-bs-target="#modalTncVa">Syarat dan Ketentuan</a>
                        </small>
                    </label>
                </div>
            </div>
        </div>

        {/** <SnK id={"modalTncVa"} acceptTnc={tncClicked ? null : acceptTnc} /> */}
        <SnK id={"modalTncVa"} acceptTnc={setTrueTncVa} opsi="PembayaranNonCC" />
    </React.Fragment>
}

export default PembayaranVa