import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ScrollToTop from "../../ScrollToTop";
import Footer from "../Footer";
import { useIdleTimer } from "react-idle-timer";
import { alert, confirm } from "../../../utils/alert";
import { connect } from "react-redux";
import { setExpired } from "../../../store/auth/actions";
// import { authExpiredTime } from "../../../utils/config";
import { tokenExpiredTime } from "../../../helpers/accessToken";

const AuthLayout = (props) => {
  const { children, auth, setExpired, payment } = props;

  const [isCounting, setIsCounting] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [expiredTime, setExpiredTime] = useState(tokenExpiredTime());

  //   const handleOnIdle = () => {
  //     if (!payment?.payment) {
  //       setIsExpired(true);
  //     } else {
  //       addExpired();
  //     }
  //   };

  const handleOnIdle = () => {
    let now = parseInt(new Date().getTime() / 1000);
    let sisaWaktu = auth?.expiredAt - now;
    setExpiredTime(sisaWaktu);

    if (expiredTime < 0) {
      setIsExpired(true);
      pause();
    }
  };

  const handleOnAction = () => {
    let now = parseInt(new Date().getTime() / 1000);
    let sisaWaktu = auth?.expiredAt - now;
    setExpiredTime(sisaWaktu);

    if (expiredTime < 0) {
      setIsExpired(true);
      pause();
    }
  };

  const handleOnActive = () => {
    let now = parseInt(new Date().getTime() / 1000);
    let sisaWaktu = auth?.expiredAt - now;
    setExpiredTime(sisaWaktu);

    if (expiredTime < 0) {
      setIsExpired(true);
      pause();
    }
  };

  //   const addExpired = () => {
  //     let now = parseInt(new Date().getTime() / 1000);
  //     setExpired(now + authExpiredTime);
  //   };

  //   const handleOnAction = () => {
  //     if (isExpired === false) {
  //       addExpired();
  //     }
  //   };

  const { start, pause } = useIdleTimer({
    startManually: true,
    // timeout: 1000 * expiredTime,
    timeout: expiredTime < 1 ? 1 : expiredTime,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    onActive: handleOnActive,
    debounce: 250,
  });

  useEffect(() => {
    let now = parseInt(new Date().getTime() / 1000);
    // let sisaWaktu = auth?.expiredAt - now;
    let sisaWaktu = auth?.expiredAt - now;
    setExpiredTime(sisaWaktu);
    // setExpired(expiredTime);
    if (sisaWaktu === 1) {
      setIsExpired(true);
      //   if (!payment?.payment) {
      //     setIsExpired(true);
      //   } else {
      //     addExpired();
      //   }
      pause();
    } else {
      start();
      setIsCounting(true);
    }
    //   }, [auth?.expiredAt]);
  }, [expiredTime]);

  useEffect(() => {
    if (isExpired === true) {
      if (isCounting === true) {
        logout();
      }

      // if (isCounting === true) {
      //   askLogout();
      // } else {
      //   logout();
      // }
    }
  }, [isExpired, isCounting]);

  //   const askLogout = async () => {
  //     let isConfirm = await confirm({
  //       title: "Sesi anda telah berakhir",
  //       html: "<p>Tetap terhubung?</p>",
  //     });

  //     if (isConfirm === true) {
  //       setIsExpired(false);
  //       addExpired();
  //     } else {
  //       props.history.push(process.env.REACT_APP_SUBDIR + "/sign-out");
  //     }
  //   };

  const logout = async () => {
    await alert({
      title: "Sesi anda telah berakhir",
      html: "Silahkan login kembali",
    });

    props.history.push(process.env.REACT_APP_SUBDIR + "/sign-out");
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      {children}
      <Footer />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    payment: state.payment,
  };
};

export default withRouter(connect(mapStateToProps, { setExpired })(AuthLayout));
