import actionTypes from './actionTypes'

export const actionSetVerificationPolis = (data) => {
  return {
    type: actionTypes.SET_VERIFICATION_POLIS,
    data
  }
}

export const actionSetVerificationPolisSuccess = (data) => {
  return {
    type: actionTypes.SET_VERIFICATION_POLIS_SUCCESS,
    data
  }
}

export const actionSetVerificationPolisFailed = () => {
  return {
    type: actionTypes.SET_VERIFICATION_POLIS_FAILED,
  }
}

export const generate = (data, retry = false) => {
  return {
    type: actionTypes.GENERATE,
    data,
    retry
  }
}

export const actionGenerateOtpSuccess = (data) => {
  return {
    type: actionTypes.GENERATE_SUCCESS,
    data
  }
}

export const actionGenerateOtpFailed = (data) => {
  return {
    type: actionTypes.GENERATE_FAILED,
    data
  }
}

export const verify = (data) => {
  return {
    type: actionTypes.VERIFY,
    data
  }
}

export const verifySuccess = () => {
  return {
    type: actionTypes.VERIFY_SUCCESS,
  }
}

export const verifyFailed = () => {
  return {
    type: actionTypes.VERIFY_FAILED,
  }
}

export const verifyExpired = () => {
  return {
    type: actionTypes.VERIFY_EXPIRED
  }
}

export const cancel = () => {
  return {
    type: actionTypes.CANCEL
  }
}

export const cancelSuccess = () => {
  return {
    type: actionTypes.CANCEL_SUCCESS,
  }
}

export const resetError = () => {
  return {
    type: actionTypes.RESET_ERROR
  }
}

export const actionRequestingStart = () => {
  return {
    type: actionTypes.REQUESTING_START
  }
}

export const actionRequestingEnd = () => {
  return {
    type: actionTypes.REQUESTING_END
  }
}

export const actionLimit = (data) => {
  return {
    type: actionTypes.LIMIT,
    data
  }
}