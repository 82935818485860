import React, { Component } from "react";
import PopupBeranda from "../../shared/PopupBeranda";
import "./dashboard.css";
import ImgIconBack from "../../assets/images/logo-back.svg";
import ImgIconExcel from "../../assets/images/file-excel-regular.svg";
import axios from "axios";
import DashboardTable from "./DashboardTable";
import { encryptDecript } from "../../helpers/encryptDecrypt.js";
import DatePicker from "react-datepicker";
import "./../../components/Form/DatePicker/index.scss";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "js-cookie";
import Header from "../../components/Layout/AuthLayout/Header";
import AfipayDesc from "../../components/Layout/AdminLayout/AfipayDesc";
import Spinner from "../../components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      polisNumber: "",
      dateFrom: "",
      dateTo: "",
      isPolisNB: false,
      cardNumber: "",
      captha: "",
      statusReg: "",
      data: [
        {
          policy_number: "111111111111111",
          customer_name: "085799999999",
          db_account_no: "012345678",
          status: "01",
          created_dt: "2021-12-30 14:12:42.7966667",
          core_system: "DMTM",
        },
        {
          policy_number: "111111111111111",
          customer_name: "085799999999",
          db_account_no: "012345678",
          status: "01",
          created_dt: "2021-12-30 14:12:42.7966667",
          core_system: "DMTM",
        },
      ],
      dataCompl: [
        {
          request_id: "AD4A4AD684B107363E05349022B0AB7DC",
          policy_number: "111111111111111",
          customer_name: "085799999999",
          db_account_no: "012345678",
          status: "01",
          email_address: "alif@example.com",
          document_id: "55555523",
          channel: "95051",
          core_system: "DMTM",
          created_dt: "2021-12-30 14:12:42.7966667",
          expired_dt: "2021-12-30 14:12:42.7966667",
          active_dt: "2021-12-30 14:12:42.7966667",
          failed_dt: "2021-12-30 14:12:42.7966667",
          is_sent_to_core: false,
          is_emailed: false,
          policy_owner_name: "policy_owner_name",
          policy_status: "policy_status",
        },
        {
          request_id: "AD4A4AD684B107363E05349022B0AB7DC",
          policy_number: "111111111111111",
          customer_name: "085799999999",
          db_account_no: "012345678",
          status: "01",
          email_address: "alif@example.com",
          document_id: "55555523",
          channel: "95051",
          core_system: "DMTM",
          created_dt: "2021-12-30 14:12:42.7966667",
          expired_dt: "2021-12-30 14:12:42.7966667",
          active_dt: "2021-12-30 14:12:42.7966667",
          failed_dt: "2021-12-30 14:12:42.7966667",
          is_sent_to_core: false,
          is_emailed: false,
          policy_owner_name: "policy_owner_name",
          policy_status: "policy_status",
        },
      ],
      errors: new Object(),
      csserrors: new Object(),
      loading: false,
      moduleAccess: null,
    };
    this.onChang = this.onChang.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleSelectDateFrom = this.handleSelectDateFrom.bind(this);
    this.handleSelectDateTo = this.handleSelectDateTo.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    this.exportCSVFile = this.exportCSVFile.bind(this);
    this.convertToCSV = this.convertToCSV.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }

  componentDidMount() {
    const { data } = this.props.location;
    // localStorage.setItem("AFIPAY01A36", encryptDecript(this.state.data, 1));

    // if (data === null || typeof data === "undefined") {
    //   localStorage.clear();
    //   this.props.history.push({
    //     pathname: process.env.REACT_APP_SUBDIR + "/admin",
    //   });
    // } else {
    //   if (
    //     typeof Cookies.get("AFIPAY01A01") === "undefined" ||
    //     Cookies.get("AFIPAY01A01") === ""
    //   ) {
    //     localStorage.clear();
    //     this.props.history.push({
    //       pathname: process.env.REACT_APP_SUBDIR + "/admin",
    //     });
    //   }
    // }

    if (
      typeof localStorage.getItem("AFIPAY02B01") !== "undefined" ||
      localStorage.getItem("AFIPAY02B01") !== ""
    ) {
      this.setState({
        moduleAccess: encryptDecript(localStorage.getItem("AFIPAY02B01"), 2),
      });
    }
  }

  onChang(e) {
    let bUpdatedValue = false;
    let value = e.target.value;
    let fieldID = e.target.id;
    let error = {};
    let csserror = {};

    // status
    if (fieldID === "statusReg") {
      if (value !== "0") {
        value = "0" + value;
        bUpdatedValue = true;
      } else {
        value = "";
      }
    }

    this.setState({
      [e.target.id]: value,
      csserrors: csserror,
      errors: error,
    });
  }

  handleSelectDateFrom(tempDate) {
    let minDate = new Date("1/1/1900");
    let bUpdatedDate = true;
    if (tempDate < minDate) {
      bUpdatedDate = false;
    }
    if (bUpdatedDate) {
      this.setState({
        dateFrom: tempDate,
      });
    }
  }

  handleSelectDateTo(tempDate) {
    let minDate = new Date("1/1/1900");
    let bUpdatedDate = true;
    if (tempDate < minDate) {
      bUpdatedDate = false;
    }
    if (bUpdatedDate) {
      this.setState({
        dateTo: tempDate,
      });
    }
  }

  downloadCSV(e) {
    e.preventDefault();

    var headers = {
      request_id: "Request ID".replace(/,/g, ""), // remove commas to avoid errors
      policy_number: "Policy_Number",
      customer_name: "Name",
      db_account_no: "db_account_no",
      status: "Status",
      email_address: "email_address",
      document_id: "document_id",
      channel: "channel",
      core_system: "core_system",
      created_dt: "created_dt",
      expired_dt: "expired_dt",
      active_dt: "active_dt",
      failed_dt: "failed_dt",
      is_sent_to_core: "is_sent_to_core",
      is_emailed: "is_emailed",
      policy_owner_name: "policy_owner_name",
      policy_status: "policy_status",
    };

    var itemsNotFormatted = JSON.parse(this.state.dataCompl);

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        request_id: item.request_id.replace(/,/g, ""), // remove commas to avoid errors,
        policy_number: item.policy_number,
        customer_name: item.customer_name,
        db_account_no: item.db_account_no,
        status: item.status,
        email_address: item.email_address,
        document_id: item.document_id,
        channel: item.channel,
        core_system: item.core_system,
        created_dt: item.created_dt,
        expired_dt: item.expired_dt,
        active_dt: item.active_dt,
        failed_dt: item.failed_dt,
        is_sent_to_core: item.is_sent_to_core,
        is_emailed: item.is_emailed,
        policy_owner_name: item.policy_owner_name,
        policy_status: item.policy_status,
      });
    });

    var d = new Date();
    // d = new Date(d.getTime() - 3000000);
    var date_format_str =
      d.getFullYear().toString() +
      ((d.getMonth() + 1).toString().length == 2
        ? (d.getMonth() + 1).toString()
        : "0" + (d.getMonth() + 1).toString()) +
      (d.getDate().toString().length == 2
        ? d.getDate().toString()
        : "0" + d.getDate().toString()) +
      (d.getHours().toString().length == 2
        ? d.getHours().toString()
        : "0" + d.getHours().toString()) +
      (d.getMinutes().toString().length == 2
        ? d.getMinutes().toString()
        : "0" + d.getMinutes().toString()) +
      (d.getSeconds().toString().length == 2
        ? d.getSeconds().toString()
        : "0" + d.getSeconds().toString());

    var fileTitle = "ReportESKDR" + date_format_str; // or 'my-unique-title'

    this.exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  exportCSVFile(headers, items, fileTitle) {
    if (headers) {
      items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = this.convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + ".csv" || "export.csv";

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  convertToCSV(objArray) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ",";

        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  }

  formatDate(e) {
    var date = new Date(e),
      month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    let ret = [year, month, day].join("-");

    return ret.toString();
  }

  onSubmit(e) {
    this.setState({ loading: true });
    e.preventDefault();
    document.getElementById("tableData").style.display = "block";

    // retrieve data
    let stat = this.state.statusReg;
    if (stat !== "" || stat !== null) {
      if (stat.toLowerCase() === "success") {
        stat = "01";
      } else if (stat.toLowerCase() === "failed") {
        stat = "02";
      } else if (stat.toLowerCase() === "pending") {
        stat = "03";
      }
    }

    // format date
    let startdate = this.state.dateFrom;
    let enddate = this.state.dateTo;

    if (
      startdate !== null ||
      enddate !== null ||
      startdate !== "" ||
      enddate !== ""
    ) {
      startdate = this.formatDate(startdate);
      enddate = this.formatDate(enddate);

      if (
        startdate === "NaN-NaN-NaN" ||
        enddate === "NaN-NaN-NaN" ||
        startdate === "1970-01-01" ||
        enddate === "1970-01-01"
      ) {
        startdate = "";
        enddate = "";
      }
    }

    const params = {
      status: stat,
      policyNumber: this.state.polisNumber,
      accountNo: this.state.cardNumber,
      dateStart: startdate,
      dateEnd: enddate,
    };

    let token = "";
    if (
      typeof localStorage.getItem("AFIPAY02B02") !== "undefined" ||
      localStorage.getItem("AFIPAY02B02") !== "" ||
      localStorage.getItem("AFIPAY02B02") !== null
    ) {
      token = encryptDecript(localStorage.getItem("AFIPAY02B02"), 2);
    }

    const headers = {
      Authorization: "Bearer " + token,
    };

    axios
      .get(
        process.env.REACT_APP_API_DASH +
          "/dashboard/get-data/registration-skdr-bca?",
        { params, headers }
      )
      .then((responseGet) => {
        if (typeof responseGet.data.data.status !== "undefined") {
          if (responseGet.data.data.status.toLowerCase() === "failed") {
            // window.location.href = process.env.REACT_APP_BASEREF + "/dashboard";
          }
        }

        // retrieve data
        var dataCom = JSON.stringify(responseGet.data.data.data_skdr_bca);
        // const json = "'" + dataCom + "'";
        const json = dataCom;

        function removeKey(obj, key) {
          delete obj[key];
        }

        const arr = JSON.parse(json);
        // arr.forEach((obj) => renameKey(obj, "created_dt", "tanggal"));
        var remKey = [
          "request_id",
          "email_address",
          "document_id",
          "channel",
          "expired_dt",
          "active_dt",
          "failed_dt",
          "is_sent_to_core",
          "is_emailed",
        ];

        // set new json object remove other key
        for (let i = 0; i < remKey.length; i++) {
          arr.forEach((obj) => removeKey(obj, remKey[i]));
        }

        // replace status to Success or failed
        arr.forEach(function (a) {
          if (a.status !== null && a.status !== "") {
            if (a.status === "01") {
              a.status = a.status.toString().replace("01", "Success");
            } else if (a.status === "02") {
              a.status = a.status.toString().replace("02", "Failed");
            } else {
              a.status = a.status.toString().replace("03", "Pending");
            }
          }
        });

        const updatedJson = JSON.stringify(arr);

        this.setState({
          data: arr,
          dataCompl: dataCom,
          loading: false,
        });
        localStorage.setItem("AFIPAY01A36", encryptDecript(arr, 1));
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await alert({
            title: "Your session has ended",
            html: "Please login again",
          });

          this.props.history.push(
            process.env.REACT_APP_SUBDIR + "/sign-out-admin"
          );
        }
        this.setState({ loading: false });
        // window.location.href = process.env.REACT_APP_BASEREF + "/dashboard";
      });
  }

  oldView = () => {
    return (
      <div className="container">
        <Header />
        <form>
          <div className="header-image-dashboard">
            <div className="back-container">
              <a href={process.env.REACT_APP_SUBDIR + "/admin"}>
                <img src={ImgIconBack} alt="back-icon" />
              </a>
            </div>
            <div>
              <h1 className="beranda-header-afipay d-md-none d-lg-block">
                AFIPAY
              </h1>
              <h1 className="beranda-header-beranda d-md-none d-lg-block">
                Dashboard
              </h1>
            </div>
          </div>
          <div className="dashboard-left">
            <h2>Dashboard</h2>
            <h1>Registrasi SKDR Digital BCA</h1>
            <div className="text">
              Dashboard untuk melihat status polis yang melakukan pendaftaran
              e-SKDR Digital
            </div>
          </div>
          <div className="dashboard-right">
            <div
              className="form-field-group"
              style={{
                marginTop: "30px",
                marginLeft: "50px",
                marginRight: "50px",
              }}
            >
              <div className="row">
                <div className="form-group col-lg-6">
                  <label htmlFor="date" style={{ fontSize: "13px" }}>
                    Tanggal Dari - Sampai
                  </label>
                  <div className="row">
                    <div className="form-group col-lg-5">
                      <DatePicker
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        minDate={new Date("1/1/1900")}
                        maxDate={new Date()}
                        className="form-control datetimestyle"
                        dateFormat="yyyy-MM-dd"
                        name="dateFrom"
                        id="dateFrom"
                        // placeholderText="YYYY-MM-DD"
                        selected={this.state.dateFrom}
                        onChange={(newDate) =>
                          this.handleSelectDateFrom(newDate)
                        }
                        autoComplete="off"
                      />
                    </div>
                    &thinsp;&ensp;-&ensp;&thinsp;
                    <div className="form-group col-lg-5">
                      <DatePicker
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        minDate={new Date("1/1/1900")}
                        maxDate={new Date()}
                        className="form-control datetimestyle"
                        dateFormat="yyyy-MM-dd"
                        name="dateTo"
                        id="dateTo"
                        // placeholderText="YYYY-MM-DD"
                        selected={this.state.dateTo}
                        onChange={(newDate) => this.handleSelectDateTo(newDate)}
                        autoComplete="off"
                      />
                    </div>
                    <span
                      className="notification-error"
                      style={{ marginTop: "-10px", display: "none" }}
                    >
                      *Lorem Ipsum
                    </span>
                  </div>
                </div>
                <div className="form-group col-lg-6">
                  <label htmlFor="polisNumber" style={{ fontSize: "13px" }}>
                    Nomor Polis
                  </label>
                  <input
                    type="text"
                    name="polisNumber"
                    id="polisNumber"
                    placeholder="123456789"
                    className="form-control"
                    onChange={this.onChang}
                  />
                </div>
                <div className="form-group col-lg-6">
                  <label htmlFor="cardNumber" style={{ fontSize: "13px" }}>
                    Nomor Rekening BCA
                  </label>
                  <input
                    type="text"
                    name="cardNumber"
                    id="cardNumber"
                    placeholder="52xxxxx90"
                    className="form-control"
                    onChange={this.onChang}
                  />
                </div>
                <div className="form-group col-lg-6">
                  <label htmlFor="statusReg" style={{ fontSize: "13px" }}>
                    Status Registrasi eSKDR BCA
                  </label>
                  {/* <input
                type="text"
                name="statusReg"
                id="statusReg"
                placeholder="Success"
                className="form-control"
              />*/}
                  <select
                    className="form-control custom-select"
                    id="statusReg"
                    name="statusReg"
                    onChange={this.onChang}
                    // value={this.state.statusReg}
                  >
                    <option value="0">Silakan Pilih</option>
                    <option value="1">Success</option>
                    <option value="2">Failed</option>
                    <option value="3">Pending</option>
                  </select>
                </div>
                <div className="form-group col-lg-6">
                  <button
                    type="submit"
                    className="btn btn-block btn-primary btn-beranda"
                    id="btnSearch"
                    onClick={this.onSubmit}
                    style={{
                      marginLeft: "10px",
                      marginTop: "25px",
                      width: "325px",
                    }}
                  >
                    Search &#128269;
                  </button>
                </div>
              </div>
            </div>
            <div
              className="dashboard-table"
              id="tableData"
              style={{ display: "none" }}
            >
              <button
                type="submit"
                // className="btn btn-block btn-primary btn-beranda"
                id="btnSearch"
                onClick={this.downloadCSV}
                style={{
                  marginLeft: "10px",
                  marginTop: "25px",
                  width: "130px",
                  fontSize: "12px",
                }}
              >
                Unduh CSV &ensp;
                <img
                  className="excel"
                  src={ImgIconExcel}
                  alt="excel"
                  width="15px"
                  height="15px"
                />
              </button>
              <div>
                <DashboardTable data={this.state.data} />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Header
          url={process.env.REACT_APP_SUBDIR + "/sign-out-admin"}
          urlLabel="Keluar"
          title={"Dashboard"}
          confirm={true}
          icon={"ic-out"}
          confirmTitle={"Keluar?"}
          confirmDescription={"Apakah anda yakin ingin mengakhiri sesi anda?"}
          right={true}
          isAdmin={true}
          moduleAccess={this.state.moduleAccess}
          moduleName={"skdr"}
        />
        <AfipayDesc
          title={"Registrasi SKDR Digital BCA."}
          subTitle={
            "Dashboard untuk melihat status polis yang melakukan pendaftaran e-SKDR Digital."
          }
        />

        <div className="container pt-4 mb-5 px-5 mt-4 dashboard-admin">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="dateFrom" className="form-label">
                      Tanggal Dari
                    </label>
                    <DatePicker
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      minDate={new Date("1/1/1900")}
                      maxDate={new Date()}
                      className="form-control datetimestyle"
                      dateFormat="yyyy-MM-dd"
                      name="dateFrom"
                      id="dateFrom"
                      placeholderText="YYYY-MM-DD"
                      selected={this.state.dateFrom}
                      onChange={(newDate) => this.handleSelectDateFrom(newDate)}
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="dateTo" className="form-label">
                      Tanggal Sampai
                    </label>
                    <DatePicker
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      minDate={new Date("1/1/1900")}
                      maxDate={new Date()}
                      className="form-control datetimestyle"
                      dateFormat="yyyy-MM-dd"
                      name="dateTo"
                      id="dateTo"
                      placeholderText="YYYY-MM-DD"
                      selected={this.state.dateTo}
                      onChange={(newDate) => this.handleSelectDateTo(newDate)}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label htmlFor="polisNumber" className="form-label">
                  Nomor Polis
                </label>
                <input
                  type="text"
                  name="polisNumber"
                  id="polisNumber"
                  placeholder="123456789"
                  className="form-control"
                  onChange={this.onChang}
                  style={{ borderRadius: "7px" }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label htmlFor="cardNumber" className="form-label">
                  Nomor Rekening BCA
                </label>
                <input
                  type="text"
                  name="cardNumber"
                  id="cardNumber"
                  placeholder="52xxxxx90"
                  className="form-control"
                  onChange={this.onChang}
                  style={{ borderRadius: "7px" }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <label htmlFor="statusReg" className="form-label">
                Status Registrasi eSKDR BCA
              </label>
              <select
                className="form-control custom-select"
                id="statusReg"
                name="statusReg"
                onChange={this.onChang}
                style={{ borderRadius: "7px" }}
                // value={this.state.statusReg}
              >
                <option value="0">Silakan Pilih</option>
                <option value="1">Success</option>
                <option value="2">Failed</option>
                <option value="3">Pending</option>
              </select>
            </div>
          </div>

          <div className="row mx-0 justify-content-end align-items-end">
            <button
              type="submit"
              className="btn btn-block btn-primary"
              id="btnSearch"
              onClick={this.onSubmit}
              style={{
                backgroundColor: "#00008f",
                width: "125px",
                height: "47px",
                padding: "0",
                borderRadius: "7px",
              }}
            >
              Search{" "}
              <Spinner visible={this.state.loading === true} bgColor={"#fff"} />
            </button>
          </div>
          <br />
          {/* <div className="text-start mt-2">
            <button
              type="submit"
              className="btn btn-block btn-primary px-4"
              id="btnSearch"
              onClick={this.onSubmit}
            >
              Search <Spinner visible={this.state.loading === true} bgColor={"#fff"} />
            </button>
          </div> */}

          <div
            className="dashboard-table"
            id="tableData"
            style={{ display: "none" }}
          >
            <button
              type="submit"
              className="btn btn-block btn-outline-primary px-4"
              id="btnUnduh"
              onClick={this.downloadCSV}
              style={{
                borderRadius: "7px",
              }}
            >
              <FontAwesomeIcon icon={faDownload} className="me-2" />
              Unduh CSV
            </button>
            <div className="mt-2">
              <DashboardTable data={this.state.data} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Dashboard;
