import moment from "moment/moment";

export const formatNomorPolis = (e) => {
  let value = e?.target?.value;
  if (!value) return;
  let bUpdatedValue = false;
  value = value.replace(/-/g, "").replace(/\D/g, "");

  if (e.target.name === "polisnumber") {
    let sRegexTest = /^[0-9]*$/;
    if (sRegexTest.test(value)) {
      bUpdatedValue = true;
    } else if (value.trim() === "") {
      bUpdatedValue = true;
    }

    if (bUpdatedValue) {
      if (value.length === 10) {
        value = value?.substring(0, 3) + "-" + value?.substring(3, 10);
      } else if (value.length === 13) {
        value =
          value?.substring(0, 3) +
          "-" +
          value?.substring(3, 6) +
          "-" +
          value?.substring(6, 13);
      } else if (value.length >= 14) {
        value = value?.substring(0, 7) + "-" + value?.substring(7, 14);
      }
    }
  }

  return value;
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return;
  var split = string.split("/");
  var result = split[1]
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  if (split.length > 2) {
    if (split[2] === ":id") {
      result = `${
        split[1].charAt(0).toUpperCase() + split[1].slice(1)
      } - Detail`;
    } else {
      let child = split[2]
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      result = `${
        split[1].charAt(0).toUpperCase() + split[1].slice(1)
      } - ${child}`;
    }
  }
  return result;
};

const monthName = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

/** From: 20230204 yyyymmdd */
/** To: 2 Februari 2023 */
/** Dipakai di beranda dan metode pembayaran */
export const asDate = (date) => {
  if (!date) return;
  let year = parseInt(date?.substring(0, 4));
  let month = parseInt(date?.substring(4, 6));
  let day = parseInt(date?.substring(6, 8));
  let monthNm = month ? monthName[month - 1] : "";

  let res = `${!isNaN(day) ? day : ""} ${monthNm} ${
    !isNaN(year) ? year : ""
  }`.toString();

  if (res?.toLowerCase().includes("undefined") === true) {
    return "Tanggal Tidak Valid";
  }

  return res;
};

/** From: 02/04/2023 mmddyyyy */
/** To: 2 Februari 2023 */
/** Dipakai di bukti pembayaran UI WEB/ PDF */
export const asDateV2 = (date) => {
  if (!date || date === "//") return;
  let year = parseInt(date?.substring(6, 10));
  let day = parseInt(date?.substring(3, 5));
  let month = parseInt(date?.substring(0, 2));

  let monthNm = month ? monthName[month - 1] : "";

  let res = `${!isNaN(day) ? day : ""} ${monthNm} ${
    !isNaN(year) ? year : ""
  }`.toString();

  if (res?.toLowerCase().includes("undefined") === true) {
    return "Tanggal Tidak Valid";
  }

  return res;
};

/** From: 02/04/2023 mmddyyyy */
/** To: 2-Feb-2023 */
/** Dipakai di email pembayaran berhasil */
export const asDateV3 = (date) => {
  if (!date || date === "//") return;
  let year = parseInt(date?.substring(6, 10));
  let day = parseInt(date?.substring(3, 5));
  let month = parseInt(date?.substring(0, 2));

  let monthNm = month ? monthName[month - 1] : "";
  let montChar = monthNm?.substring(0, 3);

  let res = `${!isNaN(day) ? day : ""}-${montChar}-${
    !isNaN(year) ? year : ""
  }`.toString();

  if (res?.toLowerCase().includes("undefined") === true) {
    return "Tanggal Tidak Valid";
  }

  return res;
};

export const hubunganPemilikPolis = (val) => {
  if (parseInt(val) === 1) {
    return "Diri Sendiri";
  } else if (parseInt(val) === 2) {
    return "Suami/Istri";
  } else if (parseInt(val) === 3) {
    return "Orang Tua";
  } else if (parseInt(val) === 4) {
    return "Anak";
  } else if (parseInt(val) === 5) {
    return "Wali yang sah";
  }

  return;
};

export const maskString = (val, from = -4) => {
  if (!val) return;
  let valString = val.toString();
  let lastChar_ = lastChar(valString, from);
  let valWoLastChar = valString.replace(lastChar_, "");
  let mask = "";

  for (let index = 0; index < valWoLastChar.length; index++) {
    mask += "x";
  }

  return mask + lastChar_;
};

export const leadingZero = (val, size) => {
  if (val === null) val = 0;
  val = val?.toString();
  while (val?.length < size) val = "0" + val;
  return val;
};

export const asCurrency = (val) => {
  if (!val) return 0;
  val = val?.toString()?.replace(".", ",");
  return val?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const asNumber = (val) => {
  if (!val) return 0;
  return parseInt(val?.replace(/\./g, ""));
};

export const asTime = (duration, min = false, showDays = false) => {
  if (!duration) duration = 0;

  let seconds = Math.floor(duration % 60),
    minutes = Math.floor((duration / 60) % 60),
    hours = Math.floor((duration / (60 * 60)) % 24),
    days = Math.floor(duration / (60 * 60 * 24));

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  days = days < 10 ? "0" + days : days;

  if (min === true) {
    return minutes + ":" + seconds;
  } else if (showDays === true) {
    return days + ":" + hours + ":" + minutes + ":" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

export const lastChar = (val, from = -4) => {
  return val ? val.slice(from) : null;
};

export const separatorEach4Char = (val, each = 4) => {
  if (!val) return "";
  let valString = val.toString();
  const newVal = valString.replace(/\s/g, "").match(/.{1,4}/g);

  return newVal.join(" ");
};

export const generateRandomChar = (limit = 6, numberOnly = false) => {
  if (numberOnly === true) {
    return Math.floor(100000 + Math.random() * 900000);
  }
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < limit; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const generateTransactionId = (polisNumber) => {
  if (!polisNumber) return;
  let timestamps = parseInt(Math.abs(Date.now() - new Date("1/1/1970")) / 1000);
  return "PAYIF_" + polisNumber.replace(/-/g, "") + "_" + timestamps;
};

/** From: Epoch time */
export const dateTime = (time, showDay = true, showTime = true) => {
  if (!time) return;

  let format = `${showDay === true ? "dddd, " : ""}DD MMMM YYYY${
    showTime === true ? " hh:mm:ss A" : ""
  }`;
  let result = moment.unix(time).format(format);

  result = result
    .replace("Sunday", "Minggu")
    .replace("Monday", "Senin")
    .replace("Tuesday", "Selasa")
    .replace("Wednesday", "Rabu")
    .replace("Thursday", "Kamis")
    .replace("Friday", "Jumat")
    .replace("Saturday", "Sabtu")
    .replace("January", "Jan")
    .replace("February", "Feb")
    .replace("March", "Mar")
    .replace("April", "Apr")
    .replace("May", "Mei")
    .replace("June", "Jun")
    .replace("July", "Jul")
    .replace("August", "Agu")
    .replace("September", "Sep")
    .replace("October", "Okt")
    .replace("November", "Nov")
    .replace("December", "Des");

  return result;
};

/** From: Epoch time */
export const dateTimeFree = (time, format = null) => {
  if (!time) return;

  let result = moment.unix(time).format(format);

  result = result
    .replace("Sunday", "Minggu")
    .replace("Monday", "Senin")
    .replace("Tuesday", "Selasa")
    .replace("Wednesday", "Rabu")
    .replace("Thursday", "Kamis")
    .replace("Friday", "Jumat")
    .replace("Saturday", "Sabtu")
    .replace("January", "Jan")
    .replace("February", "Feb")
    .replace("March", "Mar")
    .replace("April", "Apr")
    .replace("May", "Mei")
    .replace("June", "Jun")
    .replace("July", "Jul")
    .replace("August", "Agu")
    .replace("September", "Sep")
    .replace("October", "Okt")
    .replace("November", "Nov")
    .replace("December", "Des");

  return result;
};

export const maskPhoneNumber = (val) => {
  if (!val) return;
  if (val?.toString() === "-" || val?.length < 3) return val;

  val = val?.replace(/\s/g, "");

  let length = val.length;
  let result = "";

  for (let i = 0; i < length; i++) {
    if (i < parseInt(length / 3) || i > length - (parseInt(length / 3) + 1)) {
      result += val[i];
    } else {
      result += "*";
    }
  }

  return result;
};

export const validateEffectiveDt = (sPaymentDueDate, sEffectiveDT) => {
  // define month
  let dueMonth = 0;
  let effMonth = 0;
  // retrieve due month
  dueMonth = sPaymentDueDate;
  dueMonth = parseInt(dueMonth.slice(0, 2));

  effMonth = sEffectiveDT;
  effMonth = parseInt(effMonth.slice(0, 2));

  // effective month - due month validation
  let diffMonth = effMonth - dueMonth;
  // validation due month 12 and eff month 1
  if (dueMonth === 12 && effMonth === 1) {
    diffMonth = 1;
  }

  let isNextMonthAnniversary = false;
  if (diffMonth === 1) {
    isNextMonthAnniversary = true;
  } else {
    isNextMonthAnniversary = false;
  }

  return isNextMonthAnniversary;
};

export const formatDate = (x) => {
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let month = x.slice(4, 6);
  let dateNew =
    x.slice(6, 8) + "-" + monthNames[month - 1] + "-" + x.slice(0, 4); // format dd-mmm-yyyy

  return dateNew;
};

export const timerCountdown = (val) => {
  let disp = "00:00";
  if (!val) return disp;

  return disp;
};

/** From: free date format */
/** To: dd-mmm-yyyy hh:mm:ss  */
/** Dipakai di Tabel CMS */
export const asDateV4 = (paramDate, withTime) => {
  if (!paramDate) return;

  let result,
    date,
    day,
    month,
    year,
    hour,
    minute,
    second = null;

  date = new Date(paramDate);
  day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth()+1).toString()
      : (date.getMonth() + 1).toString(); 

  year = date.getFullYear().toString();
  result = formatDate(year + month + day);

  if (withTime) {
    hour =
      date.getHours() < 10
        ? "0" + date.getHours().toString()
        : date.getHours().toString();
    minute =
      date.getMinutes() < 10
        ? "0" + date.getMinutes().toString()
        : date.getMinutes().toString();
    second =
      date.getSeconds() < 10
        ? "0" + date.getSeconds().toString()
        : date.getSeconds().toString();
    result =
      formatDate(year + month + day) + " " + hour + ":" + minute + ":" + second;
  }

  return result;
};
