import React, { useEffect, useState } from "react";
import "./dashboard.css";
import axios from "axios";
import DataTable from "./DataTable";
import { encryptDecript } from "../../helpers/encryptDecrypt.js";
import Header from "../../components/Layout/AuthLayout/Header";
import AfipayDesc from "../../components/Layout/AdminLayout/AfipayDesc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { asDateV4, formatDate } from "../../utils/formatter";
import { alert } from "../../utils/alert";

const UserLog = (props) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [moduleAccess, setModuleAccess] = useState([]);
  const [data, setData] = useState([]);
  const [totalEntries, setTotalEntries] = useState(10);
  const [filterData, setFilterData] = useState(data);

  useEffect(() => {
    if (
      typeof localStorage.getItem("AFIPAY02B01") !== "undefined" ||
      localStorage.getItem("AFIPAY02B01") !== ""
    ) {
      setModuleAccess(encryptDecript(localStorage.getItem("AFIPAY02B01"), 2));
      getListData();
    }
  }, []);

  useEffect(() => {
    data?.map((item, key) => {
      data[key].createdDate = asDateV4(item.createdDate, true);
    });
  }, [data]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const onSearch = async (e) => {
    e.preventDefault();

    if (search === null || search === "") {
      setData([]);
      setFilterData([]);
      getListData();
    } else {
      setLoading(true);
      setFilterData([]);

      const filteredData = data?.filter((item) => {
        if (search !== null || search !== "") {
          if (
            item?.createdDate?.toLowerCase().includes(search.toLowerCase()) ||
            item?.name?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userId?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userlogActivity
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.userlogId?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userlogPlatformAgent
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.userlogTarget?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userlogURL?.toLowerCase().includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });

      await delay(1000);

      if (typeof filteredData !== "undefined") {
        setFilterData(filteredData);
      }
      setLoading(false);
    }
  };

  const getListData = () => {
    setLoading(true);
    let token = "";
    if (
      typeof localStorage.getItem("AFIPAY02B02") !== "undefined" ||
      localStorage.getItem("AFIPAY02B02") !== "" ||
      localStorage.getItem("AFIPAY02B02") !== null
    ) {
      token = encryptDecript(localStorage.getItem("AFIPAY02B02"), 2);
    }

    const headers = {
      Authorization: "Bearer " + token,
    };

    const params = {
      search: search,
      page: page,
      size: 999999,
      sortBy: "userlogId",
      sortType: "ASC",
    };

    axios
      .get(process.env.REACT_APP_API_DASH + "/dashboard/get-all-userlogs", {
        params,
        headers,
      })
      .then(async (response) => {
        if (response?.data?.data?.Message === "Forbidden") {
          setData([]);
          setFilterData([]);
          setLoading(false);
          await alert({
            title: "Forbidden",
            html: "You don`t have access to read data or your access has been revoked in this menu",
          });
        } else {
          setData(response?.data?.data?._items);
          setFilterData(response.data.data._items);
          setLoading(false);
          localStorage.setItem(
            "AFIPAY01A36",
            encryptDecript(response?.data?.data?._items, 1)
          );
        }
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await alert({
            title: "Your session has ended",
            html: "Please login again",
          });

          props.history.push(process.env.REACT_APP_SUBDIR + "/sign-out-admin");
        }
        setLoading(false);
      });
  };

  const onChangeEntries = async (val) => {
    setTotalEntries(val);
    if (search === null || search === "") {
      setData([]);
      setFilterData([]);
      getListData();
    } else {
      setLoading(true);
      setFilterData([]);

      const filteredData = data?.filter((item) => {
        if (search !== null || search !== "") {
          if (
            item?.createdDate?.toLowerCase().includes(search.toLowerCase()) ||
            item?.name?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userId?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userlogActivity
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.userlogId?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userlogPlatformAgent
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.userlogTarget?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userlogURL?.toLowerCase().includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });

      await delay(1000);

      if (typeof filteredData !== "undefined") {
        setFilterData(filteredData);
      }
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Header
        url={process.env.REACT_APP_SUBDIR + "/sign-out-admin"}
        urlLabel="Keluar"
        title={"Dashboard"}
        confirm={true}
        icon={"ic-out"}
        confirmTitle={"Keluar?"}
        confirmDescription={"Apakah anda yakin ingin mengakhiri sesi anda?"}
        right={true}
        isAdmin={true}
        moduleAccess={moduleAccess}
        moduleName={"logs"}
      />
      <AfipayDesc title={"User Management / User Logs"} />

      <div className="container pt-4 mb-5 px-5 mt-4 dashboard-admin">
        <div className="row justify-content-between">
          <div className="col-lg-4">
            <span>Showing</span>
            <select
              className="form-select form-select-sm ms-2 me-2"
              style={{
                paddingRight: "2rem",
                width: "auto",
                height: "auto",
                display: "inline-block",
              }}
              value={totalEntries}
              onChange={(e) => onChangeEntries(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span>Entries</span>
          </div>
          <div className="col-lg-3">
            <div className="input-group mb-3">
              <input
                type="text"
                name="search"
                id="search"
                placeholder=""
                className="form-control"
                onChange={(e) => setSearch(e.target.value)}
                style={{
                  borderRadius: "7px",
                  height: "35px",
                }}
              />
              <div className="input-group-append">
                <button
                  type="submit"
                  className="btn btn-block btn-primary"
                  id="btnSearch"
                  onClick={onSearch}
                  style={{
                    borderRadius: "7px",
                    width: "35px",
                    height: "35px",
                    padding: "0",
                  }}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </div>
            </div>
          </div>
        </div>

        <DataTable
          data={filterData}
          tableName={"log"}
          totalEntries={totalEntries}
          loading={loading}
        />
      </div>
    </React.Fragment>
  );
};
export default UserLog;
