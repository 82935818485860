import React, { useState } from "react"
import IcSiku from "../../assets/new/img/icons/ic-siku.svg"

const CaraPembayaran = ({ title, show = true }) => {
    const [isShow, setIsShow] = useState(show)
    const [isFlip, setIsFlip] = useState(!show)

    const rotateToggle = () => {
        setIsShow(!isShow)
        setIsFlip(!isFlip)
    }

    return <React.Fragment>
        <div className="card">
            <div className="card-header accordion-item">
                <span className="fw-semibold">{title || "-"}</span>
                <span className={`force-block collapse-trigger ${isFlip === true ? 'flip-2' : ''}`} data-bs-toggle="collapse" aria-expanded="true"
                    aria-controls="collapseExample" href="#collapseCard" role="button"
                    onClick={() => rotateToggle()}
                >
                    <img src={IcSiku} width="100%" alt="siku" />
                </span>
            </div>
            <div className={`card-body accordion-collapse text-start collapse ${isShow === true ? 'show' : ''}`}>
                <div className="accordion-body">
                    <p>Apabila Anda menggunakan perangkat PC desktop/ Laptop/ Tablet maka anda akan diminta untuk melakukan scan QR pada aplikasi Gojek, dengan langkah sebagai berikut:</p>

                    <ol style={{ paddingLeft: "1.3rem" }}>
                        <li className="card-text-data">Buka aplikasi Gojek dan klik "Bayar"</li>
                        <li className="card-text-data">Scan kode QR yang terdapat di layar PC desktop/ Laptop/ Tablet Anda</li>
                        <li className="card-text-data">Pastikan jumlah pembayaran sudah sesuai</li>
                        <li className="card-text-data">Masukkan PIN Gopay Anda</li>
                        <li className="card-text-data">Transaksi sukses</li>
                    </ol>

                    <p>Apabila Anda menggunakan perangkat smartphone/ mobile phone maka layar Anda akan diarahkan masuk kedalam aplikasi Gojek, dengan langkah sebagai berikut:</p>

                    <ol style={{ paddingLeft: "1.3rem" }}>
                        <li className="card-text-data">Pastikan jumlah pembayaran sudah sesuai</li>
                        <li className="card-text-data">Masukkan PIN Gopay Anda</li>
                        <li className="card-text-data">Transaksi sukses</li>
                    </ol>
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default CaraPembayaran