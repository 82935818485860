import React, { useEffect, useRef, useState } from "react"
import SnK from "./SnK"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEye, faEyeSlash
} from "@fortawesome/free-solid-svg-icons";

const AutoDebitKartuKredit = ({ selected = false, autoNamaPemegangKartu, autoNomorKartuKredit, autoBulanBerlakuKartuKredit, autoTahunBerlakuKartuKredit, autoCvvKartuKredit, autoTncKartuKredit, autoAgreeKartuKredit, setAutoNamaPemegangKartu, setAutoNomorKartuKredit, setAutoBulanBerlakuKartuKredit, setAutoTahunBerlakuKartuKredit, setAutoCvvKartuKredit, setAutoTncKartuKredit, setAutoAgreeKartuKredit, errorAutoBulanBerlakuKartuKredit = null, errorAutoTahunBerlakuKartuKredit = null, errorAutoNomorkartuKredit = null, errorAutoNamaPemilikKartuKredit = null, setTrueAutoTncKartuKredit, errorAutoCvvKartuKredit = null }) => {
    const [show, setShow] = useState("v-hidden")
    const [tncClicked, seTncClicked] = useState(false)
    const refTnc = useRef(null)
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(faEyeSlash);

    useEffect(() => {
        if (selected === true) {
            setShow("v-hidden collapse-animate-lg")

            setTimeout(() => {
                setShow("collapse-animate-lg")
            }, 500)
        } else {
            setShow("v-hidden")
        }
    }, [selected])

    const handleToggle = () => {
        if (type === 'password') {
            setIcon(faEye);
            setType('text')
        } else {
            setIcon(faEyeSlash)
            setType('password')
        }
    }

    const onChangeTnc = () => {
        if (tncClicked === false) {
            refTnc.current.click()
        } else {
            setAutoTncKartuKredit()
        }
    }

    const acceptTnc = () => {
        setAutoTncKartuKredit()
        seTncClicked(true)
    }

    return <React.Fragment>
        <div className={"data-pembayaran " + show}>
            <div className="mb-3">
                <label htmlFor="holderName" className="form-label">Nama Pemegang Kartu</label>
                <input type="text" className="form-control" id="holderName"
                    placeholder="Nama Pemegang Kartu" value={autoNamaPemegangKartu} onChange={setAutoNamaPemegangKartu} maxLength={"50"} />

                {
                    errorAutoNamaPemilikKartuKredit ?
                        <p className="form-tip form-alert"><i>{errorAutoNamaPemilikKartuKredit}</i></p>
                        : null
                }
            </div>
            <div className="mb-3">
                <label htmlFor="cardNumber" className="form-label">Nomor Kartu</label>
                <input type="text" className="form-control" id="cardNumber"
                    placeholder="Contoh : 1234 5678 9012 4152" value={autoNomorKartuKredit} onChange={setAutoNomorKartuKredit} onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }} />

                {
                    errorAutoNomorkartuKredit ?
                        <p className="form-tip form-alert"><i>{errorAutoNomorkartuKredit}</i></p>
                        : null
                }
            </div>
            <div className="d-flex flex-row gap-3">
                <div className={errorAutoBulanBerlakuKartuKredit || errorAutoTahunBerlakuKartuKredit || errorAutoCvvKartuKredit ? "" : "mb-3"} style={{ width: "40%" }}>
                    <label htmlFor="" className="form-label">Masa Berlaku</label>
                    <div className="card-input">
                        <input type="text" className="form-control me-3" id="mmCard"
                            placeholder="mm" maxLength="2" value={autoBulanBerlakuKartuKredit} onChange={setAutoBulanBerlakuKartuKredit} onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                        <input type="text" className="form-control" id="yyCard"
                            placeholder="yy" maxLength="2" value={autoTahunBerlakuKartuKredit} onChange={setAutoTahunBerlakuKartuKredit} onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                    </div>
                </div>
                <div className={errorAutoBulanBerlakuKartuKredit || errorAutoTahunBerlakuKartuKredit || errorAutoCvvKartuKredit ? "" : "mb-3"} style={{ width: "60%" }}>
                    <label htmlFor="cvv" className="form-label">CVV</label>
                    <div className="card-input">
                        <input type={type} className="form-control" id="cvv"
                            placeholder="Contoh : 123" maxLength="4" value={autoCvvKartuKredit} onChange={setAutoCvvKartuKredit} style={{ width: "80%" }} onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                        <div>
                            <button
                                type="submit"
                                className="btn btn-block form-control"
                                onClick={handleToggle}
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    padding: "0",
                                }}
                            >
                                <FontAwesomeIcon icon={icon} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {
                errorAutoBulanBerlakuKartuKredit || errorAutoTahunBerlakuKartuKredit || errorAutoCvvKartuKredit
                    ? <div className="mb-3">
                        {errorAutoBulanBerlakuKartuKredit ? <p className="form-tip form-alert"><i>{errorAutoBulanBerlakuKartuKredit}</i></p> : null}
                        {errorAutoTahunBerlakuKartuKredit ? <p className="form-tip form-alert"><i>{errorAutoTahunBerlakuKartuKredit}</i></p> : null}
                        {errorAutoCvvKartuKredit ? <p className="form-tip form-alert"><i>{errorAutoCvvKartuKredit}</i></p> : null}
                    </div> : null
            }

            <div className="warning-box">
                <p>
                    Jaga Kerahasiaan data pribadi Anda, Jangan berikan data pribadi Anda
                    ke pihak lain.
                </p>
            </div>
            <div className="grey-box ketentuan-pembayaran">
                <div className="ps-3 mb-2">
                    <label className="form-check-label" htmlFor="agreeCard">
                        <small>
                            <strong>Catatan</strong> :
                        </small>
                    </label>
                </div>
                <ol className="ps-3">
                    <li>
                        Pastikan sinyal Anda dalam kondisi yang stabil untuk dapat melanjutkan transaksi.
                    </li>
                    <li>
                        Pastikan kartu debit/kredit yang digunakan sudah mendukung 3D Secure. Informasi lebih lanjut dapat menghubungi Bank Penerbit Kartu Anda.
                    </li>
                    <li>
                        Pastikan pulsa Anda mencukupi supaya dapat menerima OTP (One Time Password) dari Bank Penerbit Kartu.
                    </li>
                </ol>
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox" value=""
                        id="autoDebitCard" onChange={setAutoTncKartuKredit} checked={autoTncKartuKredit === true} />
                    <label className="form-check-label" htmlFor="autoDebitCard">
                        <small>
                            Saya setuju dan mengerti terhadap {" "}
                            <a ref={refTnc} href="." onClick={(e) => e.preventDefault()} data-bs-toggle="modal"
                                data-bs-target="#modalAutoTncKartuKredit">Syarat dan Ketentuan</a>
                        </small>
                    </label>
                </div>
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox" value="agreeCard" onChange={setAutoAgreeKartuKredit} checked={autoAgreeKartuKredit === true} />
                    <label className="form-check-label" htmlFor="agreeCard">
                        <small>
                            Simpan informasi kartu untuk transaksi selanjutnya
                        </small>
                    </label>
                </div>
            </div>
        </div>

        { /** <SnK id={"modalAutoTncKartuKredit"} acceptTnc={tncClicked ? null : acceptTnc} /> */}
        <SnK id={"modalAutoTncKartuKredit"} acceptTnc={setTrueAutoTncKartuKredit} opsi="AutoDebit" />
    </React.Fragment>
}

export default AutoDebitKartuKredit