import React, { useState } from "react";

import useTable from "./useTable";
import TableFooter from "./TableFooter";
import Spinner from "../../../components/Spinner";

const getHeader = () => {
  var keyHeader = ["Menu ID", "Menu Name", "Menu Description"];
  return keyHeader.map((key, index) => {
    return <th key={key}>{key}</th>;
  });
};

const mapRowsData = (data) => {
  return data.map((row, index) => {
    const { id, name, description } = row;

    return (
      <tr key={index}>
        <td>{id}</td>
        <td>{name}</td>
        <td>{description}</td>
      </tr>
    );
  });
};

const TableMenu = ({ data, rowsPerPage, loading }) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);

  return (
    <React.Fragment>
      <div
        className="dashboard-table table-responsive mb-4"
        id="tableData"
        style={{ display: "block" }}
      >
        <div className="mt-2">
          <table
            className="table"
            width="100%"
            style={{
              marginLeft: 0,
              backgroundColor: "white",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "rgb(0 0 143)",
                  marginLeft: "10px",
                  marginRight: "10px",
                  // borderCollapse: "collapse",
                  textAlign: "center",
                  color: "white",
                  verticalAlign: "center",
                }}
              >
                {getHeader()}
              </tr>
            </thead>
            <tbody
              style={{
                backgroundColor: "white",
                textAlign: "center",
              }}
            >
              {loading ? (
                <tr>
                  <td colSpan={3}>
                    <Spinner visible={loading} bgColor={"#00006e"} size={30} />
                  </td>
                </tr>
              ) : data !== null && data.length > 0 ? (
                mapRowsData(slice)
              ) : (
                <tr>
                  <td colSpan={3}>No Data Available in Table</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </React.Fragment>
  );
};

export default TableMenu;
