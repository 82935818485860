import React from "react";

const AfipayDesc = () => {
    return (
        <React.Fragment>
            <div className="row mx-0 sub-ribbon">
                <div className="d-flex align-items-center justify-content-center py-3">
                    <p className="small m-0 text-center">PT AXA Financial Indonesia 
                    tidak bertanggung jawab terhadap pembayaran premi selain melalui AFIPAY Portal.</p>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AfipayDesc;
