import React from "react";

const AfipayDesc = ({title, subTitle}) => {
    return (
        <React.Fragment>
            <div className="row mx-0 sub-ribbon">
                <div className="d-flex align-items-center justify-content-center py-3">
                    <div>
                        <p className="m-0 text-center">{title}</p>
                        {
                            subTitle !== "" ?
                                <p className="small m-0 text-center">{subTitle}</p>
                            :
                            null
                        }
                        
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AfipayDesc;
