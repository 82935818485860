import React, { useEffect, useState } from "react"
import Header from "../components/Layout/AuthLayout/Header"
import AfipayDesc from "../components/Layout/AuthLayout/AfipayDesc"
import IcTimeleft from "../assets/new/img/icons/ic-timeleft.svg"
import { asTime, dateTime } from "../utils/formatter"
import Preloader from "../components/Preloader/index.js"
import ImgFailed from "../assets/new/img/illustration-confirmation-failed.svg"
import { actionCreatePayment, actionResetPayment, actionInquiryPaymentStatus, actionSetLastSuccess, actionSetLastFailed } from "../store/payment/actions"
import { connect } from 'react-redux'
import { Link, withRouter } from "react-router-dom"
import ImgProcess from "../assets/new/img/illustration-confirmation-process.svg"

const AutoDebitKartuKredit = (props) => {
    let data = props?.history?.location?.data || null
    const { polis, payment, hubunganPembayar, actionCreatePayment, actionResetPayment, actionInquiryPaymentStatus, actionSetLastSuccess, actionSetLastFailed, buyRate } = props

    const [sisaWaktuPembayaran, setSiswaWaktuPembayaran] = useState(null)
    const [waktuMulai, setWaktuMulai] = useState(false)
    const [countCheck, setCountCheck] = useState(0)
    const [submit, setSubmit] = useState(false)

    const [frameSrc, setFrameSrc] = useState(null)
    const [loading, setLoading] = useState(false)

    const [pending, setPending] = useState(false)

    useEffect(() => {
        if (data === null && !payment?.result?.transaction_id) {
            props.history.replace({ pathname: process.env.REACT_APP_SUBDIR + "/registrasi-autodebit" })
        } else {
            createPayment()
        }
    }, [])

    const createPayment = () => {
        actionCreatePayment({
            polis, payment, hubunganPembayar, method: "AutoDebit", paymentType: "credit_card", dataCc: {
                "card_number": data?.card_number,
                "card_owner": data?.card_owner,
                "card_exp_month": data?.card_exp_month,
                "card_exp_year": data?.card_exp_year,
                "card_cvv": data?.card_cvv,
                "saveCard": data?.saveCard,
            }, kurs: buyRate
        })
    }

    useEffect(() => {
        setSubmit(true)
    }, [payment?.success])

    useEffect(() => {
        if (payment?.transaction_success === true) {
            setCountCheck(-1)
            actionResetPayment()
            actionSetLastSuccess({
                transactionId: payment?.payment?.trxRefference,
                noPolis: polis?.polisnumber,
                totalPembayaran: payment?.result?.gross_amount,
                tanggalTransaksi: payment?.result?.transaction_time,
                jenisTransaksi: "Pendaftaran Autodebit Kartu Kredit",
                metodePembayaran: "Kartu Kredit / Debit",
                namaLengkap: polis?.policyHolderNm,
                nomorKartu: data?.card_number,
                noHp: polis?.mobilePhoneNo,
                lanjutAutoDebet: true,
                notified: false
            })
            props.history.replace({ pathname: process.env.REACT_APP_SUBDIR + "/auto-debit-berhasil" })
        } else if (payment?.transaction_success === false) {
            setCountCheck(-1)
            actionResetPayment()
            actionSetLastFailed({
                transactionId: payment?.payment?.trxRefference,
                message: payment?.errMessage
            })
            props.history.replace({ pathname: process.env.REACT_APP_SUBDIR + "/auto-debit-gagal" })
        }
    }, [payment?.transaction_success])

    useEffect(() => {
        if (payment?.transaction_pending === true) {
            setPending(true)
        } else if (payment?.transaction_pending === false) {
            setPending(false)
        }
    }, [payment?.transaction_pending])

    useEffect(() => {
        if (payment?.transactionId) {
            actionInquiryPaymentStatus({
                polis: payment?.polis,
                payment: payment?.payment,
                transactionID: payment?.transactionId
            })
            setCountCheck(1)
        }
    }, [payment?.transactionId])

    useEffect(() => {
        if (countCheck <= 0) return

        const intervalId = setInterval(() => {
            setCountCheck(countCheck + 1)
            actionInquiryPaymentStatus({
                polis: payment?.polis,
                payment: payment?.payment,
                transactionID: payment?.transactionId
            })
        }, 5000)

        return () => clearInterval(intervalId)
    }, [countCheck])

    useEffect(() => {
        let now = parseInt((new Date).getTime() / 1000)
        let sisaWaktu = payment?.expiredAt - now
        if (sisaWaktu > 0) {
            setSiswaWaktuPembayaran(sisaWaktu)
            setWaktuMulai(true)
            setLoading(true)
            setFrameSrc(payment?.result?.redirect_url)
        }
    }, [payment?.expiredAt])

    useEffect(() => {
        if (sisaWaktuPembayaran === null) return
        if (!sisaWaktuPembayaran) return

        const intervalId = setInterval(() => {
            setSiswaWaktuPembayaran(sisaWaktuPembayaran - 1)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [sisaWaktuPembayaran])

    useEffect(() => {
        if (waktuMulai === true) {
            if (sisaWaktuPembayaran < 1) {
                actionResetPayment()
                actionSetLastFailed({
                    transactionId: payment?.payment?.trxRefference
                })
                props.history.replace({ pathname: process.env.REACT_APP_SUBDIR + "/auto-debit-gagal" })
            }
        }
    }, [waktuMulai, sisaWaktuPembayaran])

    const paymentFailedScreen = () => {
        return <React.Fragment>
            <div className="container metode-pembayaran konfirmasi text-center pt-4 mb-5">
                <img className="pt-4 pb-3" src={ImgFailed} alt="confirmation" />
                <div className="subtitle-page fw-bold pt-2 mb-2" style={{ color: "red", fontSize: "20px" }}>
                    Upps, Pendaftaran Autodebit Gagal
                </div>

                <div className="warning-box">
                    <p>
                        {payment?.message !== null ? payment?.message : "Transaksi Anda gagal"}
                    </p>
                </div>

                <p style={{ marginBottom: "2rem" }} className="px-3">
                    Silakan coba lagi.
                </p>
                <div className="inline-button pt-4">
                    <Link to={process.env.REACT_APP_SUBDIR + "/registrasi-autodebit"} type="button" className="btn btn-primary col mb-3">Ulangi Pendaftaran Auto Debit</Link>
                    <Link
                        to={process.env.REACT_APP_SUBDIR + "/beranda"}
                        type="button"
                        className="btn btn-primary-regis col mb-2"
                    >
                        Informasi Polis
                    </Link>
                </div>
            </div>
        </React.Fragment>
    }

    const onClickBack = async () => {
        actionResetPayment()
        props.history.replace({
            pathname: submit === true && payment?.success === true ? (process.env.REACT_APP_SUBDIR + "/beranda") : (process.env.REACT_APP_SUBDIR + "/registrasi-autodebit")
        })
    }

    const iframeLoaded = () => {
        setLoading(false)
    }

    const viewPending = () => {
        return <React.Fragment>
            <div className="container metode-pembayaran konfirmasi text-center pt-4 mb-5">
                <img className="pt-4 pb-3" src={ImgProcess} alt="confirmation" />
                <div className="subtitle-page fw-bold pt-2 mt-2 mb-2">
                    Pembayaran Anda Pending
                </div>
            </div>
        </React.Fragment>
    }

    const detailPembayaran = () => {
        return <React.Fragment>
            <Preloader visible={loading === true} />

            {
                sisaWaktuPembayaran > 0 && frameSrc ?
                    <div style={{ maxWidth: 500 }} className="mx-auto">
                        <iframe
                            title="Registrasi Kartu"
                            src={frameSrc}
                            scrolling="no"
                            style={{
                                height: 480,
                                overflow: "hidden"
                            }} className="ma-frame" onLoad={iframeLoaded} />
                    </div>
                    : null
            }
        </React.Fragment>
    }

    return <React.Fragment>
        <Header onClick={() => onClickBack()} urlLabel="Kembali" title={"Pembayaran"} confirm={submit === true && payment?.success === true} confirmTitle={"Kembali"} confirmDescription={"Batalkan pembayaran?"} homeConfirm={submit === true && payment?.success === true} />


        <div style={{ minHeight: "30vh" }}>
            <Preloader visible={payment?.loading === true} />
            {
                submit === true && payment?.loading === false ?
                    submit === true && payment?.success === false ?
                        paymentFailedScreen()
                        :
                        pending === true ?
                            viewPending()
                            :
                            detailPembayaran()
                    : null
            }
        </div>
        <AfipayDesc />
    </React.Fragment>
}

const mapStateToProps = state => {
    return {
        polis: state.auth?.polis,
        hubunganPembayar: state.auth?.hubunganPembayar,
        payment: state.payment,
        buyRate: state.currency?.buyRate
    }
}

export default withRouter(connect(mapStateToProps, { actionCreatePayment, actionResetPayment, actionInquiryPaymentStatus, actionSetLastSuccess, actionSetLastFailed })(AutoDebitKartuKredit))