import { combineReducers } from "redux"
import { connectRouter } from 'connected-react-router'
import history from '../utils/history'

import auth from "./auth/reducer"
import verification from "./verification/reducer"
import payment from "./payment/reducer"
import currency from "./currency/reducer"

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth,
  verification,
  payment,
  currency
})

export default rootReducer
