import React, { useState } from "react";

import useTable from "./useTable";
import TableFooter from "./TableFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPrint } from "@fortawesome/free-solid-svg-icons";
import { asDateV4 } from "../../../utils/formatter";

const getHeader = () => {
  var keyHeader = ["Charging Code", "Charging Time", "Action"];
  return keyHeader.map((key, index) => {
    return <th key={key}>{key}</th>;
  });
};

const mapRowsData = (data, onClickDownload) => {
  return data.map((row, index) => {
    const { chargeCode, createdDate } = row;

    return (
      <tr key={index}>
        <td>{chargeCode}</td>
        <td>{asDateV4(createdDate, true)}</td>
        <td>
          <button
            className="btn btn-sm btn-light"
            style={{
              width: "58px",
              height: "23px",
              fontSize: "10px",
              background: "#F1F3F4",
              borderRadius: "4px",
              color: "#00008f",
              padding: "0",
            }}
            onClick={(e) => onClickDownload(e, chargeCode)}
          >
            <FontAwesomeIcon icon={faPrint} className="me-1" /> Export
          </button>
        </td>
      </tr>
    );
  });
};

const TableCharge = ({ data, rowsPerPage, onClickDownload }) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);

  return (
    <div>
      <table
        className="table text-nowrap"
        width="100%"
        style={{
          marginLeft: 0,
          backgroundColor: "white",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: "rgb(0 0 143)",
              marginLeft: "10px",
              marginRight: "10px",
              // borderCollapse: "collapse",
              textAlign: "center",
              color: "white",
              verticalAlign: "center",
            }}
          >
            {getHeader()}
          </tr>
        </thead>
        <tbody
          style={{
            backgroundColor: "white",
            textAlign: "center",
          }}
        >
          {mapRowsData(slice, onClickDownload)}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </div>
  );
};

export default TableCharge;
