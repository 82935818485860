import Swal from 'sweetalert2'
import { PRIMARY_COLOR } from '../constants'

export const confirm = async ({ title, html, icon = "info" }) => {
    return new Promise((resolve) => {
        Swal.fire({
            title,
            html,
            icon,
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: PRIMARY_COLOR,
            cancelButtonColor: PRIMARY_COLOR,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((isConfirm) => {
            if (isConfirm && isConfirm.isConfirmed === true) {
                resolve(true)
            } else {
                resolve(false)
            }
        })
    })
}

export const alert = async ({ title, html, icon = "info" }) => {
    return new Promise((resolve) => {
        Swal.fire({
            title,
            html,
            icon,
            showCloseButton: false,
            showCancelButton: false,
            confirmButtonColor: PRIMARY_COLOR,
        }).then((isConfirm) => {
            if (isConfirm && isConfirm.isConfirmed === true) {
                resolve(true)
            } else {
                resolve(false)
            }
        })
    })
}