import React from "react";
import TablePage from "./hook/TablePage.js"
import { encryptDecript } from "../../helpers/encryptDecrypt.js";

export default class DashboardTable extends React.Component {
  constructor(props) {
    super(props);
    localStorage.setItem("AFIPAY01A36", encryptDecript(this.props.data, 1));
  }

  setData() {
    var data = this.props.data;
    localStorage.setItem("AFIPAY01A36", encryptDecript(data, 1));

  }

  getData() {
    var data = encryptDecript(localStorage.getItem("AFIPAY01A36"), 2);
    return this.props.data;
  }
  render() {
    return (
      <div>
        <TablePage data={this.getData()} rowsPerPage={10} />
      </div>
    );
  }

}