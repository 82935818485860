import React, { useEffect } from "react";
import footer from "./tableFooter.css";

const TableFooter = ({ range, slice, setPage, page }) => {
  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);
  let lastPage = range.length;

  // prev page
  let prevPage = 1;
  if (page !== 1) {
    prevPage = page - 1;
  }

  // next page
  let nextPage = lastPage;
  if (page !== lastPage) {
    nextPage = page + 1;
  }

  return (
    <div className={footer.tableFooter}>
      <div className="row">
        <div className="col-6">
          Page {page} of {lastPage}
          {/* <br />
      <br /> */}
        </div>
        <div className="col-6 d-flex justify-content-end align-items-end">
          <button
            className="btn btn-default"
            onClick={(e) => {
              e.preventDefault();
              setPage(1);
            }}
          >
            &lt;&lt;
          </button>
          <button
            className="btn btn-default"
            onClick={(e) => {
              e.preventDefault();
              setPage(prevPage);
            }}
          >
            &lt;
          </button>
          <button className="btn btn-default" disabled>
            <span className="text-black">{page}</span>
          </button>
          <button
            className="btn btn-default"
            onClick={(e) => {
              e.preventDefault();
              setPage(nextPage);
            }}
          >
            &gt;
          </button>
          <button
            className="btn btn-default"
            id="last-button"
            onClick={(e) => {
              e.preventDefault();
              setPage(lastPage);
            }}
          >
            &gt;&gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableFooter;
