import React, { useState } from "react";

import useTable from "./useTable";
import TableFooter from "./TableFooter";
import table from "./table.css"

const getHeader = () => {
    var keyHeader = [
        "Tanggal Registrasi",
        "Nomor Polis",
        "No Rekening BCA",
        "Status Registrasi eSKDR BCA",
        "Nama Pemilik Rekening",
        "Core System",
    ];
    return keyHeader.map((key, index) => {
        return <th key={key}>{key}</th>;
    });

}

const mapRowsData = (data) => {
    return data.map((row, index) => {
        const {
            policy_number,
            customer_name,
            db_account_no,
            status,
            created_dt,
            core_system,
        } = row;

        return (
            <tr key={index}>
                <td>{created_dt.split(".")[0]}</td>
                <td>{policy_number}</td>
                <td>{db_account_no}</td>
                <td>{status}</td>
                <td>{customer_name}</td>
                <td>{core_system}</td>
            </tr>
        );
    });

}

const TablePage = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);

    return (
        <div>
            <table className="table"
                width="100%"
                style={{
                    marginLeft: 0,
                    backgroundColor: "white",
                    borderCollapse: "collapse",
                }}>
                <thead >
                    <tr style={{
                        backgroundColor: "rgb(0 0 143)",
                        marginLeft: "10px",
                        marginRight: "10px",
                        // borderCollapse: "collapse",
                        textAlign: "center",
                        color: "white",
                        verticalAlign: "center",
                    }}>
                        {getHeader()}
                    </tr>
                </thead>
                <tbody style={{
                    backgroundColor: "white",
                    textAlign: "center",
                }}>
                    {mapRowsData(slice)}
                </tbody>
            </table>
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
        </div>
    );
};

export default TablePage;
