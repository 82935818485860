import React, { Component } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
// import https from "https";
import "./../components/Form/DatePicker/index.scss";
import Cookies from "js-cookie";
import { encryptDecript } from "../helpers/encryptDecrypt.js";
import IcPolis from "../assets/new/img/icons/ic-polis.svg";
import IcTtl from "../assets/new/img/icons/ic-ttl.svg";
import IcCalendar from "../assets/new/img/icons/ic-calendar.svg";
import Spinner from "../components/Spinner";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { PRIMARY_COLOR } from "../constants";
import { authExpiredTime, bypassCaptcha } from "../utils/config";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { actionSetVerificationPolis } from "../store/verification/actions";
import { setExpired, setPolis } from "../store/auth/actions";
import { actionCheckPaymentValidation } from "../store/payment/actions";
import { tokenExpiredTime } from "../helpers/accessToken";
import { alert, confirm } from "../utils/alert";
import ReCAPTCHA from "react-google-recaptcha";

class LandingPage extends Component {
  constructor() {
    super();
    this.state = {
      polisnumber: "",
      dateofbirth: null,
      isPolisNB: false,
      errors: {},
      csserrors: {},
      loading: false,

      data: null,
      hiddenCaptcha: "",
      captcha: "",
      captchaInserted: false,
      gcaptcha: "",
    };
    this.onChang = this.onChang.bind(this);
    this.validateOnBlur = this.validateOnBlur.bind(this);
    this.handleSelectDate = this.handleSelectDate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
    this.onChangeGcaptcha = this.onChangeGcaptcha.bind(this);
  }

  componentDidMount() {
    // const expiredTime = new Date().getTime()
    // Cookies.set("AFIPAY01A01", encryptDecript("", 1), { expires: expiredTime })
    localStorage.clear();
    // this.loadCaptcha();
    //this.loadCaptchaBE();
  }

  // loadCaptcha() {
  //   loadCaptchaEnginge(6, "white", PRIMARY_COLOR);
  //   this.configureCanvas();
  //   let error = this.state.errors;
  //   delete error["captcha"];
  //   this.setState({ captcha: "", errors: error });
  // }

  // loadCaptchaBE() {
  //   let headerGet = {
  //     "Content-Type": "application/json",
  //   };

  //   axios
  //     .get(process.env.REACT_APP_API_FE + "/get-captcha", {
  //       headers: headerGet,
  //     })
  //     .then((response) => {
  //       let captchaImage = document.getElementById("captchaImage");
  //       captchaImage.setAttribute(
  //         "src",
  //         "data:image/jpg;base64," + response?.data?.data?.image
  //       );
  //       this.setState({ hiddenCaptcha: response?.data?.data?.hidden });
  //     })
  //     .catch((response) => {
  //       let error = this.state.errors;
  //       error["generalerror"] = "*Gagal load captcha.";
  //       this.setState({
  //         errors: error,
  //       });
  //     });
  // }

  // configureCanvas() {
  //   document.getElementById("canv").setAttribute("style", "width: 100%");
  // }

  onChang(e) {
    let value = e.target.value;
    let csserror = {};
    let error = {};
    let bUpdatedValue = false;

    if (e.target.id === "polisnumber") {
      let sRegexTest = /^[0-9\-]/;
      if (sRegexTest.test(value)) {
        bUpdatedValue = true;
      } else if (value.trim() === "") {
        bUpdatedValue = true;
      }
      if (bUpdatedValue) {
        value = value.replace(/-/g, "");
        //RLS (10 char)
        if (value.length === 10) {
          value = value.substring(0, 3) + "-" + value.substring(3, 10);
          csserror["polisnumber"] = "notification-valid";
        }
        //DMTM (13 and 14 char)
        else if (value.length === 13) {
          value =
            value.substring(0, 3) +
            "-" +
            value.substring(3, 6) +
            "-" +
            value.substring(6, 13);
          csserror["polisnumber"] = "notification-valid";
        } else if (value.length === 14) {
          value = value.substring(0, 7) + "-" + value.substring(7, 14);
          csserror["polisnumber"] = "notification-valid";
        }
      }
    } else {
      bUpdatedValue = true;
    }

    if (bUpdatedValue) {
      this.setState({
        [e.target.id]: value,
        errors: error,
        csserrors: csserror,
      });
    }
  }

  validateOnBlur(e) {
    let value = e.target.value;
    let csserror = {};
    let error = {};
    let bUpdatedValue = false;

    if (e.target.id === "polisnumber") {
      let sRegexTest = /^[0-9\-]/;
      if (sRegexTest.test(value)) {
        bUpdatedValue = true;
      } else if (value.trim() === "") {
        bUpdatedValue = true;
      }

      if (bUpdatedValue) {
        value = value.replace(/-/g, "");
        if (value.indexOf("0") === 0) {
          error["polisnumber"] =
            "* Nomor polis yang Anda masukkan salah. Periksa kembali nomor polis Anda.";
          csserror["polisnumber"] = "notification-error";
        } else if (value.length === 10) {
          csserror["polisnumber"] = "notification-valid";
        } else if (value.length === 13) {
          csserror["polisnumber"] = "notification-valid";
        } else if (value.length === 14) {
          csserror["polisnumber"] = "notification-valid";
        } else {
          error["polisnumber"] =
            "* Nomor polis yang Anda masukkan salah. Periksa kembali nomor polis Anda.";
          csserror["polisnumber"] = "notification-error";
        }
      }
    }

    this.setState({
      errors: error,
      csserrors: csserror,
    });
  }

  handleSelectDate(tempDate) {
    let csserror = {};
    let error = {};

    if ("generalerror" in error) {
      delete error["generalerror"];
      delete csserror["generalerror"];
    }

    let minDate = new Date("1/1/1900");
    let bUpdatedDate = true;
    if (tempDate < minDate) {
      bUpdatedDate = false;
    }
    if (bUpdatedDate) {
      this.setState({
        dateofbirth: tempDate,
        csserrors: csserror,
        errors: error,
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({ loading: true });

    if (localStorage.getItem("AFIPAY02B03") != null) {

      alert({
        title: "Sesi Masih Tersedia",
        html: "Silahkan refresh browser untuk kembali ke sesi sebelumnya",
      });

      this.setState({ loading: false });
    } else {

      let bError = false;
      let bErrorUpdated = false;
      let error = this.state.errors;
      let csserror = this.state.csserrors;

      if (this.state.polisnumber === "") {
        error["polisnumber"] = "Nomor polis tidak boleh kosong.";
        csserror["polisnumber"] = "notification-error";
        bErrorUpdated = true;
      }

      if (this.state.dateofbirth === null) {
        error["dateofbirth"] = "Tanggal lahir tidak boleh kosong.";
        csserror["dateofbirth"] = "notification-error";
        bErrorUpdated = true;
      }

      // if (bypassCaptcha === false) {
      //   if (this.state.captcha === "") {
      //     error["captcha"] = "Captcha tidak boleh kosong.";
      //     bErrorUpdated = true;
      //   }
      // }

      if (this.state.gcaptcha === "") {
        error["g-recaptcha"] = "Mohon checklist untuk verifikasi";
        bErrorUpdated = true;
      } else {
        delete error["g-recaptcha"];
      }

      if (bErrorUpdated) {
        this.setState({
          errors: error,
          csserrors: csserror,
        });
      }

      if (Object.keys(this.state.errors).length > 0) {
        bError = true;
      }

      if (!bError) {
        let sCoreSystem = "";
        if (this.state.polisnumber.length === 11) {
          sCoreSystem = "RLS";
        } else if (this.state.polisnumber.length === 15) {
          sCoreSystem = "DMTM";
        }
        let transactionID = uuidv4();
        let payloadJson = {
          policyNo: this.state.polisnumber,
          dob:
            this.state.dateofbirth.getFullYear().toString() +
            ("0" + (this.state.dateofbirth.getMonth() + 1)).slice(-2).toString() +
            ("0" + this.state.dateofbirth.getDate()).slice(-2).toString(),
          captcha: this.state.gcaptcha,
        };
        // let payloadString = encryptDecript(JSON.stringify(payloadJson), 1);
        // payloadString =
        //   process.env.REACT_APP_PREFIX +
        //   payloadString.substring(0, 4) +
        //   process.env.REACT_APP_PREFIX +
        //   payloadString.substring(4, payloadString.length);
        // let payload = new FormData();
        // payload.append("validate", payloadString);

        let headerPost = {
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
        };
        // let agent = new https.Agent({
        //   rejectUnauthorized: false,
        //   requestCert: false,
        // });

        axios
          .post(process.env.REACT_APP_API_FE + "/auth", payloadJson, {
            headers: headerPost,
          })
          .then((resp) => {
            localStorage.setItem(
              "AFIPAY02B03",
              encryptDecript(resp?.data?.accessToken, 1)
            );
            localStorage.setItem(
              "AFIPAY02B04",
              encryptDecript(resp?.data?.expiresIn, 1)
            );
            tokenExpiredTime();
            this.getDetail(
              resp?.data?.accessToken,
              resp?.data?.expiresIn,
              error,
              csserror,
              bPolisNB,
              sCoreSystem
            );
          })
          .catch((err) => {
            this.grecaptcha.reset();
            let error = this.state.errors;
            error["generalerror"] =
              "*Nomor Polis atau Tanggal Lahir tidak terdaftar.";
            this.setState({
              errors: error,
              loading: false,
            });

          });

        let bPolisNB = false;
        this.state.gcaptcha = "";
        // axios
        //   .post(process.env.REACT_APP_API_POLICY, payload, {
        //     headers: headerPost,
        //   })
        //   .then((response) => {
        //     // let response = {
        //     //   data: {
        //     //     data: {
        //     //       "insuredDOB": "20000101",
        //     //       "insuredGender": "M",
        //     //       "birthDT": "20000101",
        //     //       "coresystem": "RLS",
        //     //       "insuranceProductRk": "AXA MAGNIFICENT ELITE LINK (AMEL)",
        //     //       "gender": "M",
        //     //       "policyNo": "501-1111222",
        //     //       "policyDueDt": "",
        //     //       "firstNM": "name 501-1111222",
        //     //       "emailAddress": "muhammad.ikhsan@indocyber.id",
        //     //       "sumInsured": "1000000.00",
        //     //       "planCd": "ASLRUDR",
        //     //       "insuredNm": "insured 501-1111222",
        //     //       "isSinglePremium": false,
        //     //       "insuredPhone": "081363118657",
        //     //       "applicationid": "AFIPAY",
        //     //       "mobilePhoneNo": "081363118657",
        //     //       "currencyCD": "IDR",
        //     //       "isSyariah": false,
        //     //       "paymentModeCD": "",
        //     //       "policyStatusCD": "",
        //     //       "isPaymentDisabled": false,
        //     //       "insuredEmail": "muhammad.ikhsan@indocyber.id",
        //     //       "premiumAMT": "100.00",
        //     //       "policyEffectiveDTTM": "",
        //     //       "issueDt": "0",
        //     //       "otpRequired": false
        //     //     }
        //     //   }
        //     // }
        //     if (response?.data?.data?.status === "FAILED") {
        //       if (
        //         response?.data?.data?.msg?.toLowerCase() === "policy not found"
        //       ) {
        //         error["polisnumber"] = "*Nomor polis tidak ditemukan.";
        //         error["dateofbirth"] = "*Tanggal lahir tidak sesuai.";
        //         this.setState({
        //           errors: error,
        //         });
        //       } else if (
        //         response?.data?.data?.msg?.toLowerCase() ===
        //         "incorrect dateofbirth"
        //       ) {
        //         error["dateofbirth"] = "*Tanggal lahir tidak sesuai.";
        //         this.setState({
        //           errors: error,
        //         });
        //       } else {
        //         error["generalerror"] =
        //           "*Nomor Polis atau Tanggal Lahir tidak terdaftar.";
        //         this.setState({
        //           errors: error,
        //         });
        //       }
        //     } else {
        //       if (
        //         response.data.data.issueDt === "0" ||
        //         response.data.data.policyStatusCD === "Y" ||
        //         response.data.data.policyStatusCD === "PE"
        //       ) {
        //         this.setState({
        //           isPolisNB: true,
        //         });
        //         bPolisNB = true;
        //       }

        //       let isPending = false;
        //       if (response.data.data.isPending === true) {
        //         isPending = true;
        //       }

        //       let isSplitPayment = false;
        //       if (response.data.data.isSplitPayment === true) {
        //         isSplitPayment = true;
        //       }

        //       let bValid = true;
        //       if (
        //         typeof response.data.data.birthDT !== "undefined" &&
        //         response.data.data.birthDT !== "" &&
        //         !this.state.isPolisNB
        //       ) {
        //         let oDOBResponse = response.data.data.birthDT.split("T");
        //         let sDOBResponse = oDOBResponse[0].replace(/[-]/g, "");
        //         if (
        //           sDOBResponse !==
        //           this.state.dateofbirth.getFullYear().toString() +
        //             ("0" + (this.state.dateofbirth.getMonth() + 1))
        //               .slice(-2)
        //               .toString() +
        //             ("0" + this.state.dateofbirth.getDate()).slice(-2).toString()
        //         ) {
        //           bValid = false;

        //           error["generalerror"] =
        //             "Nomor Polis atau Tanggal Lahir tidak terdaftar.";
        //           csserror["generalerror"] = "notification-error";
        //         }
        //       }

        //       //Negative response
        //       if (
        //         response.data.data.status !== null &&
        //         typeof response.data.data.status !== "undefined"
        //       ) {
        //         if (response.data.data.status !== null) {
        //           bValid = false;
        //           error["generalerror"] = response.data.data.msg;
        //           csserror["generalerror"] = "notification-error";
        //         } else {
        //           bValid = false;
        //           error["generalerror"] =
        //             "*Nomor Polis atau Tanggal Lahir tidak terdaftar.";
        //           csserror["generalerror"] = "notification-error";
        //         }
        //       }

        //       if (bValid) {
        //         let sEmailAddress = "";
        //         let sPremium = "";
        //         let sPremiumAmount = "";
        //         let sPolicyStatusCode = "";
        //         let sPaymentModeCD = "";
        //         let sPaymentDueDate = "";
        //         let sPaymentMethod = "";
        //         let sIssueDt = "";
        //         let sEffectiveDT = "";
        //         let sPolicyHolderNm = "";
        //         let sProductNm = "";
        //         let sCurrency = "RP"; // default
        //         let bIsPaymentDisabled = false;

        //         // Nama Tertanggung
        //         let sInsuredNm = "";
        //         // Email Tertanggung
        //         let sInsuredEmail = "";
        //         // No HP Tertanggung
        //         let sInsuredPhone = "";
        //         // Gender Tertanggung
        //         let sInsuredGender = "";
        //         // Tanggal Lahir Tertanggung
        //         let sInsuredDob = "";
        //         // Tanggal Lahir Pemegang
        //         let sBirthDT = "";
        //         // No HP Pemegang
        //         let sMobilePhoneNo = "";
        //         // Gender Pemegang
        //         let sGender = "";
        //         // Tanggal Jatuh Tempo
        //         let sPolicyDueDt = "";
        //         // Detail Split Payment
        //         let sSplitPaymentDetail = [];
        //         // Minimum Nominal Kondisi Pembayaran
        //         let sCanSplitPayment = "";
        //         // Minimum Nominal Pembayaran
        //         let sMinSplitPayment = "";
        //         // Inisial Premi
        //         let sFirstPremi = "";
        //         // Status split payment
        //         let sIsExpiry = false;
        //         // Jangka waktu max pembayaran split payment
        //         let sMaxPeriodPaySplit = "";

        //         //assign data
        //         if (typeof response.data.data.emailAddress !== "undefined") {
        //           sEmailAddress = response.data.data.emailAddress.trim();
        //         }
        //         if (typeof response.data.data.premiumAMT !== "undefined") {
        //           sPremium = response.data.data.premiumAMT
        //             .replace(".00", "")
        //             .trim();
        //           sPremiumAmount = response.data.data.premiumAMT
        //             .replace(".00", "")
        //             .trim();
        //         }
        //         if (typeof response.data.data.paymentModeCD !== "undefined") {
        //           sPaymentModeCD = response.data.data.paymentModeCD.trim();
        //         }
        //         if (typeof response.data.data.policyStatusCD !== "undefined") {
        //           sPolicyStatusCode = response.data.data.policyStatusCD.trim();
        //         }
        //         if (response.data.data.coresystem !== undefined) {
        //           if (response.data.data.coresystem === "DMTM") {
        //             // define due date
        //             if (typeof response.data.data.paidToDT !== "undefined") {
        //               let dueDate = response.data.data.paidToDT.trim();
        //               dueDate = dueDate.split("T");
        //               sPaymentDueDate = dueDate[0].replace(/[-]/g, "");
        //               sPaymentDueDate =
        //                 sPaymentDueDate.slice(4, 6) +
        //                 "/" +
        //                 sPaymentDueDate.slice(6, 8) +
        //                 "/" +
        //                 sPaymentDueDate.slice(0, 4); // format MM/dd/yyyy
        //             }

        //             // define effective date
        //             if (
        //               typeof response.data.data.policyEffectiveDTTM !==
        //               "undefined"
        //             ) {
        //               let effDT = response.data.data.policyEffectiveDTTM.trim();
        //               effDT = effDT.split("T");
        //               sEffectiveDT = effDT[0].replace(/[-]/g, "");
        //               sEffectiveDT =
        //                 sEffectiveDT.slice(4, 6) +
        //                 "/" +
        //                 sEffectiveDT.slice(6, 8) +
        //                 "/" +
        //                 sEffectiveDT.slice(0, 4); // format MM/dd/yyyy
        //             }
        //           } else {
        //             // define due date
        //             if (typeof response.data.data.policyDueDt !== "undefined") {
        //               sPaymentDueDate = response.data.data.policyDueDt.trim();
        //               sPaymentDueDate =
        //                 sPaymentDueDate.slice(4, 6) +
        //                 "/" +
        //                 sPaymentDueDate.slice(6, 8) +
        //                 "/" +
        //                 sPaymentDueDate.slice(0, 4); // format MM/dd/yyyy
        //             }

        //             // define effective date
        //             if (
        //               typeof response.data.data.policyEffectiveDTTM !==
        //               "undefined"
        //             ) {
        //               let effDT = response.data.data.policyEffectiveDTTM.trim();
        //               effDT = effDT.split("T");
        //               sEffectiveDT = effDT[0].replace(/[-]/g, "");
        //               sEffectiveDT =
        //                 sEffectiveDT.slice(4, 6) +
        //                 "/" +
        //                 sEffectiveDT.slice(6, 8) +
        //                 "/" +
        //                 sEffectiveDT.slice(0, 4); // format MM/dd/yyyy
        //             }
        //           }
        //         }
        //         if (typeof response.data.data.paymentMethodCD !== "undefined") {
        //           sPaymentMethod = response.data.data.paymentMethodCD.trim();
        //         }

        //         if (typeof response.data.data.issueDt !== "undefined") {
        //           sIssueDt = response.data.data.issueDt.trim();
        //         }

        //         if (typeof response.data.data.firstNM !== "undefined") {
        //           sPolicyHolderNm = response.data.data.firstNM.trim();
        //         }

        //         if (
        //           typeof response.data.data.insuranceProductRk !== "undefined"
        //         ) {
        //           sProductNm = response.data.data.insuranceProductRk.trim();
        //         }

        //         if (typeof response.data.data.currencyCD !== "undefined") {
        //           sCurrency = response.data.data.currencyCD.trim();
        //         }

        //         if (typeof response.data.data.insuredNm !== "undefined") {
        //           sInsuredNm = response.data.data.insuredNm.trim();
        //         }

        //         if (typeof response.data.data.insuredEmail !== "undefined") {
        //           sInsuredEmail = response.data.data.insuredEmail.trim();
        //         }

        //         if (typeof response.data.data.insuredPhone !== "undefined") {
        //           sInsuredPhone = response.data.data.insuredPhone.trim();
        //         }

        //         if (typeof response.data.data.insuredGender !== "undefined") {
        //           let insuredGenderTrim = response.data.data.insuredGender.trim();
        //           if (
        //             insuredGenderTrim === "M" ||
        //             insuredGenderTrim?.toLowerCase() === "male"
        //           ) {
        //             sInsuredGender = "Laki-laki";
        //           } else if (
        //             insuredGenderTrim === "F" ||
        //             insuredGenderTrim?.toLowerCase() === "female"
        //           ) {
        //             sInsuredGender = "Perempuan";
        //           }
        //         }

        //         if (typeof response.data.data.insuredDOB !== "undefined") {
        //           sInsuredDob = response.data.data.insuredDOB.trim();
        //         }

        //         if (typeof response.data.data.birthDT !== "undefined") {
        //           sBirthDT = response.data.data.birthDT.trim();
        //         }

        //         if (typeof response.data.data.mobilePhoneNo !== "undefined") {
        //           sMobilePhoneNo = response.data.data.mobilePhoneNo.trim();
        //         }

        //         if (typeof response.data.data.gender !== "undefined") {
        //           let genderTrim = response.data.data.gender.trim();
        //           if (
        //             genderTrim === "M" ||
        //             genderTrim?.toLowerCase() === "male"
        //           ) {
        //             sGender = "Laki-laki";
        //           } else if (
        //             genderTrim === "F" ||
        //             genderTrim?.toLowerCase() === "female"
        //           ) {
        //             sGender = "Perempuan";
        //           }
        //         }

        //         if (typeof response.data.data.policyDueDt !== "undefined") {
        //           sPolicyDueDt = response.data.data.policyDueDt.trim();
        //         }

        //         // bIsPaymentDisabled = response.data.data.isPaymentDisabled
        //         if (typeof response.data.data.isPaymentDisabled !== "undefined") {
        //           bIsPaymentDisabled = response.data.data.isPaymentDisabled;
        //         }

        //         if (!sPolicyHolderNm) {
        //           sPolicyHolderNm = sInsuredNm;
        //         }
        //         if (!sMobilePhoneNo) {
        //           sMobilePhoneNo = sInsuredPhone;
        //         }
        //         if (!sEmailAddress) {
        //           sEmailAddress = sInsuredEmail;
        //         }

        //         if (
        //           typeof response.data.data.splitPaymentDetail !== "undefined"
        //         ) {
        //           sSplitPaymentDetail = response.data.data.splitPaymentDetail;
        //         }

        //         if (typeof response.data.data.minPremiCanSplit !== "undefined") {
        //           sCanSplitPayment = response.data.data.minPremiCanSplit;
        //         }

        //         if (typeof response.data.data.minAmountPaySplit !== "undefined") {
        //           sMinSplitPayment = response.data.data.minAmountPaySplit;
        //         }

        //         if (typeof response.data.data.firstPremi !== "undefined") {
        //           sFirstPremi = response.data.data.firstPremi;
        //         }

        //         if (typeof response.data.data.isExpiry !== "undefined") {
        //           sIsExpiry = response.data.data.isExpiry;
        //         }

        //         if (typeof response.data.data.maxPeriodPaySplit !== "undefined") {
        //           sMaxPeriodPaySplit = response.data.data.maxPeriodPaySplit;
        //         }

        //         let data = {
        //           polisnumber: this.state.polisnumber,
        //           dateofbirth: this.state.dateofbirth,
        //           isPolisNB: this.state.isPolisNB,
        //           emailAddress: sEmailAddress,
        //           premium: sPremium,
        //           premiumAmount: sPremiumAmount,
        //           policyStatusCode: sPolicyStatusCode,
        //           paymentMethodCode: sPaymentModeCD,
        //           coresystem: sCoreSystem,
        //           paymentduedate: sPaymentDueDate,
        //           paymentmethod: sPaymentMethod,
        //           issueDt: sIssueDt,
        //           effectiveDt: sEffectiveDT,
        //           policyHolderNm: sPolicyHolderNm,
        //           productNm: sProductNm,
        //           currency: sCurrency,
        //           isPaymentDisabled: bIsPaymentDisabled,

        //           insuredNm: sInsuredNm,
        //           insuredEmail: sInsuredEmail,
        //           insuredPhone: sInsuredPhone,
        //           insuredGender: sInsuredGender,
        //           insuredDOB: sInsuredDob,
        //           birthDT: sBirthDT,
        //           mobilePhoneNo: sMobilePhoneNo,
        //           gender: sGender,
        //           policyDueDt: sPolicyDueDt,

        //           insuredRelation: response?.data?.data?.insuredRelation,
        //           isSyariah: response?.data?.data?.isSyariah,
        //           isSinglePremium: response?.data?.data?.isSinglePremium,
        //           sumInsured: response?.data?.data?.sumInsured
        //             ?.replace(".00", "")
        //             .trim(),

        //           isPending,
        //           isSplitPayment,
        //           splitPaymentDetail: sSplitPaymentDetail,
        //           minCanSplitPayment: sCanSplitPayment,
        //           minSplitPayment: sMinSplitPayment,
        //           firstPremium: sFirstPremi,
        //           isSplitExpired: sIsExpiry,
        //           maxPeriodPaySplit: sMaxPeriodPaySplit,
        //         };

        //         // this.props.history.push({
        //         //   pathname: process.env.REACT_APP_SUBDIR + "/beranda",
        //         //   data: data, // passing data to beranda
        //         // })

        //         data["nomorhp"] = response.data.data.mobilePhoneNo;

        //         let otpRequired =
        //           response.data.data.otpRequired === false ? false : true;
        //         // otpRequired = true

        //         if (bypassCaptcha === false) {
        //           if (validateCaptcha(this.state.captcha, false) == true) {
        //             if (otpRequired === true) {
        //               this.props.actionSetVerificationPolis({
        //                 polis: data,
        //               });
        //               delete error["captcha"];
        //               this.setState({ data, errors: error });

        //               this.props.history.push({
        //                 pathname: process.env.REACT_APP_SUBDIR + "/otp",
        //               });
        //             } else {
        //               let now = parseInt(new Date().getTime() / 1000);
        //               this.props.setPolis(data);
        //               this.props.actionCheckPaymentValidation(data);
        //               this.props.setExpired(now + authExpiredTime);
        //               this.props.history.push({
        //                 pathname: process.env.REACT_APP_SUBDIR + "/beranda",
        //                 data,
        //               });
        //             }
        //           } else {
        //             error["captcha"] = "Captcha salah";

        //             this.setState({
        //               errors: error,
        //             });
        //           }
        //         } else {
        //           if (otpRequired === true) {
        //             this.props.actionSetVerificationPolis({
        //               polis: data,
        //             });
        //             this.setState({ data });

        //             this.props.history.push({
        //               pathname: process.env.REACT_APP_SUBDIR + "/otp",
        //               data,
        //             });
        //           } else {
        //             let now = parseInt(new Date().getTime() / 1000);
        //             this.props.setPolis(data);
        //             this.props.actionCheckPaymentValidation(data);
        //             this.props.setExpired(now + authExpiredTime);
        //             this.props.history.push({
        //               pathname: process.env.REACT_APP_SUBDIR + "/beranda",
        //               data,
        //             });
        //           }
        //         }
        //       } else {
        //         this.setState({
        //           csserrors: csserror,
        //           errors: error,
        //         });
        //       }
        //     }
        //     this.setState({ loading: false });
        //   })
        //   .catch((response) => {
        //     error["generalerror"] =
        //       "*Nomor Polis atau Tanggal Lahir tidak terdaftar.";
        //     csserror["generalerror"] = "notification-error";
        //     this.setState({
        //       csserrors: csserror,
        //       errors: error,
        //     });
        //     this.setState({ loading: false });
        //   });
      } else {
        this.setState({ loading: false });
      }

    }
  }

  onChangeCaptcha(e) {
    this.setState({
      captcha: e.target.value,
    });

    let captchaInserted = false;
    if (e.target.value) {
      captchaInserted = true;
    }

    this.setState({
      captchaInserted,
    });
  }

  onChangeGcaptcha(g) {
    this.setState({
      gcaptcha: g,
    });
  }

  getDetail(token, expiresIn, error, csserror, bPolisNB, sCoreSystem) {
    const headerPost = {
      Authorization: "Bearer " + token,
    };
    const body = {};
    axios
      // .post("http://34.81.106.166:8080/afipay-frontend-service/api/v1/frontend/detail", {}, {headers: headerPost})
      .get(process.env.REACT_APP_API_FE + "/detail", { headers: headerPost })
      .then((response) => {
        if (response?.data?.data?.status === "FAILED") {
          if (response?.data?.data?.msg?.toLowerCase() === "policy not found") {
            error["polisnumber"] = "*Nomor polis tidak ditemukan.";
            error["dateofbirth"] = "*Tanggal lahir tidak sesuai.";
            this.setState({
              errors: error,
            });
          } else if (
            response?.data?.data?.msg?.toLowerCase() === "incorrect dateofbirth"
          ) {
            error["dateofbirth"] = "*Tanggal lahir tidak sesuai.";
            this.setState({
              errors: error,
            });
          } else {
            error["generalerror"] =
              "*Nomor Polis atau Tanggal Lahir tidak terdaftar.";
            this.setState({
              errors: error,
            });
          }
        } else {
          if (
            response.data.data.issueDt === "0" ||
            response.data.data.policyStatusCD === "Y" ||
            response.data.data.policyStatusCD === "PE"
          ) {
            this.setState({
              isPolisNB: true,
            });
            bPolisNB = true;
          }

          let isPending = false;
          if (response.data.data.isPending === true) {
            isPending = true;
          }

          let isSplitPayment = false;
          if (response.data.data.isSplitPayment === true) {
            isSplitPayment = true;
          }

          let bValid = true;
          if (
            typeof response.data.data.birthDT !== "undefined" &&
            response.data.data.birthDT !== "" &&
            !this.state.isPolisNB
          ) {
            let oDOBResponse = response.data.data.birthDT.split("T");
            let sDOBResponse = oDOBResponse[0].replace(/[-]/g, "");
            if (
              sDOBResponse !==
              this.state.dateofbirth.getFullYear().toString() +
              ("0" + (this.state.dateofbirth.getMonth() + 1))
                .slice(-2)
                .toString() +
              ("0" + this.state.dateofbirth.getDate()).slice(-2).toString()
            ) {
              bValid = false;

              error["generalerror"] =
                "Nomor Polis atau Tanggal Lahir tidak terdaftar.";
              csserror["generalerror"] = "notification-error";
            }
          }

          //Negative response
          if (
            response.data.data.status !== null &&
            typeof response.data.data.status !== "undefined"
          ) {
            if (response.data.data.status !== null) {
              bValid = false;
              error["generalerror"] = response.data.data.msg;
              csserror["generalerror"] = "notification-error";
            } else {
              bValid = false;
              error["generalerror"] =
                "*Nomor Polis atau Tanggal Lahir tidak terdaftar.";
              csserror["generalerror"] = "notification-error";
            }
          }

          if (bValid) {
            let sEmailAddress = "";
            let sPremium = "";
            let sPremiumAmount = "";
            let sPolicyStatusCode = "";
            let sPaymentModeCD = "";
            let sPaymentDueDate = "";
            let sPaymentMethod = "";
            let sIssueDt = "";
            let sEffectiveDT = "";
            let sPolicyHolderNm = "";
            let sProductNm = "";
            let sCurrency = "RP"; // default
            let bIsPaymentDisabled = false;
            let numPremium = "";
            let numFirstPremium = "";
            let registrationStatus = "";
            let paidPremium = "";
            let billingChannel = "";
            let bankName = "";
            let isPremiumHoliday = false;

            // Nama Tertanggung
            let sInsuredNm = "";
            // Email Tertanggung
            let sInsuredEmail = "";
            // No HP Tertanggung
            let sInsuredPhone = "";
            // Gender Tertanggung
            let sInsuredGender = "";
            // Tanggal Lahir Tertanggung
            let sInsuredDob = "";
            // Tanggal Lahir Pemegang
            let sBirthDT = "";
            // No HP Pemegang
            let sMobilePhoneNo = "";
            // Gender Pemegang
            let sGender = "";
            // Tanggal Jatuh Tempo
            let sPolicyDueDt = "";
            // Detail Split Payment
            let sSplitPaymentDetail = [];
            // Minimum Nominal Kondisi Pembayaran
            let sCanSplitPayment = "";
            // Minimum Nominal Pembayaran
            let sMinSplitPayment = "";
            // Inisial Premi
            let sFirstPremi = "";
            // Status split payment
            let sIsExpiry = false;
            // Jangka waktu max pembayaran split payment
            let sMaxPeriodPaySplit = "";

            //assign data
            if (typeof response.data.data.emailAddress !== "undefined") {
              sEmailAddress = response.data.data.emailAddress.trim();
            }
            if (typeof response.data.data.premiumAMT !== "undefined") {
              // if (response.data.data.currencyCD === "USD") {
              //   sPremium = response.data.data.premiumAMT
              //     .replace(".00", "")
              //     .trim();
              //   sPremiumAmount = response.data.data.premiumAMT
              //     .replace(".00", "")
              //     .trim();
              // } else {
              numPremium = Math.round(response.data.data.premiumAMT);
              numPremium = numPremium.toString();

              sPremium = numPremium
                .replace(".00", "")
                .trim();
              sPremiumAmount = numPremium
                .replace(".00", "")
                .trim();
              // }

            }
            if (typeof response.data.data.paymentModeCD !== "undefined") {
              sPaymentModeCD = response.data.data.paymentModeCD.trim();
            }
            if (typeof response.data.data.policyStatusCD !== "undefined") {
              sPolicyStatusCode = response.data.data.policyStatusCD.trim();
            }
            if (response.data.data.coresystem !== undefined) {
              if (response.data.data.coresystem === "DMTM") {
                // define due date
                if (typeof response.data.data.paidToDT !== "undefined") {
                  let dueDate = response.data.data.paidToDT.trim();
                  dueDate = dueDate.split("T");
                  sPaymentDueDate = dueDate[0].replace(/[-]/g, "");
                  sPaymentDueDate =
                    sPaymentDueDate.slice(4, 6) +
                    "/" +
                    sPaymentDueDate.slice(6, 8) +
                    "/" +
                    sPaymentDueDate.slice(0, 4); // format MM/dd/yyyy
                }

                // define effective date
                if (
                  typeof response.data.data.policyEffectiveDTTM !== "undefined"
                ) {
                  let effDT = response.data.data.policyEffectiveDTTM.trim();
                  effDT = effDT.split("T");
                  sEffectiveDT = effDT[0].replace(/[-]/g, "");
                  sEffectiveDT =
                    sEffectiveDT.slice(4, 6) +
                    "/" +
                    sEffectiveDT.slice(6, 8) +
                    "/" +
                    sEffectiveDT.slice(0, 4); // format MM/dd/yyyy
                }
              } else {
                // define due date
                if (typeof response.data.data.policyDueDt !== "undefined") {
                  sPaymentDueDate = response.data.data.policyDueDt.trim();
                  sPaymentDueDate =
                    sPaymentDueDate.slice(4, 6) +
                    "/" +
                    sPaymentDueDate.slice(6, 8) +
                    "/" +
                    sPaymentDueDate.slice(0, 4); // format MM/dd/yyyy
                }

                // define effective date
                if (
                  typeof response.data.data.policyEffectiveDTTM !== "undefined"
                ) {
                  let effDT = response.data.data.policyEffectiveDTTM.trim();
                  effDT = effDT.split("T");
                  sEffectiveDT = effDT[0].replace(/[-]/g, "");
                  sEffectiveDT =
                    sEffectiveDT.slice(4, 6) +
                    "/" +
                    sEffectiveDT.slice(6, 8) +
                    "/" +
                    sEffectiveDT.slice(0, 4); // format MM/dd/yyyy
                }
              }
            }
            if (typeof response.data.data.paymentMethodCD !== "undefined") {
              sPaymentMethod = response.data.data.paymentMethodCD.trim();
            }

            if (typeof response.data.data.issueDt !== "undefined") {
              sIssueDt = response.data.data.issueDt.trim();
            }

            if (typeof response.data.data.firstNM !== "undefined") {
              sPolicyHolderNm = response.data.data.firstNM.trim();
            }

            if (typeof response.data.data.insuranceProductRk !== "undefined") {
              sProductNm = response.data.data.insuranceProductRk.trim();
            }

            if (typeof response.data.data.currencyCD !== "undefined") {
              sCurrency = response.data.data.currencyCD.trim();
            }

            if (typeof response.data.data.insuredNm !== "undefined") {
              sInsuredNm = response.data.data.insuredNm.trim();
            }

            if (typeof response.data.data.insuredEmail !== "undefined") {
              sInsuredEmail = response.data.data.insuredEmail.trim();
            }

            if (typeof response.data.data.insuredPhone !== "undefined") {
              sInsuredPhone = response.data.data.insuredPhone.trim();
            }

            if (typeof response.data.data.insuredGender !== "undefined") {
              let insuredGenderTrim = response.data.data.insuredGender.trim();
              if (
                insuredGenderTrim === "M" ||
                insuredGenderTrim?.toLowerCase() === "male"
              ) {
                sInsuredGender = "Laki-laki";
              } else if (
                insuredGenderTrim === "F" ||
                insuredGenderTrim?.toLowerCase() === "female"
              ) {
                sInsuredGender = "Perempuan";
              }
            }

            if (typeof response.data.data.insuredDOB !== "undefined") {
              sInsuredDob = response.data.data.insuredDOB.trim();
            }

            if (typeof response.data.data.birthDT !== "undefined") {
              sBirthDT = response.data.data.birthDT.trim();
            }

            if (typeof response.data.data.mobilePhoneNo !== "undefined") {
              sMobilePhoneNo = response.data.data.mobilePhoneNo.trim();
            }

            if (typeof response.data.data.gender !== "undefined") {
              let genderTrim = response.data.data.gender.trim();
              if (genderTrim === "M" || genderTrim?.toLowerCase() === "male") {
                sGender = "Laki-laki";
              } else if (
                genderTrim === "F" ||
                genderTrim?.toLowerCase() === "female"
              ) {
                sGender = "Perempuan";
              }
            }

            if (typeof response.data.data.policyDueDt !== "undefined") {
              sPolicyDueDt = response.data.data.policyDueDt.trim();
            }

            // bIsPaymentDisabled = response.data.data.isPaymentDisabled
            if (typeof response.data.data.isPaymentDisabled !== "undefined") {
              bIsPaymentDisabled = response.data.data.isPaymentDisabled;
            }

            if (!sPolicyHolderNm) {
              sPolicyHolderNm = sInsuredNm;
            }
            if (!sMobilePhoneNo) {
              sMobilePhoneNo = sInsuredPhone;
            }
            if (!sEmailAddress) {
              sEmailAddress = sInsuredEmail;
            }

            if (typeof response.data.data.splitPaymentDetail !== "undefined") {
              sSplitPaymentDetail = response.data.data.splitPaymentDetail;
            }

            if (typeof response.data.data.minPremiCanSplit !== "undefined") {
              sCanSplitPayment = response.data.data.minPremiCanSplit;
            }

            if (typeof response.data.data.minAmountPaySplit !== "undefined") {
              sMinSplitPayment = response.data.data.minAmountPaySplit;
            }

            if (typeof response.data.data.firstPremi !== "undefined") {


              numFirstPremium = Math.round(response.data.data.firstPremi);
              numFirstPremium = numFirstPremium.toString();

              sFirstPremi = numFirstPremium;
            }

            if (typeof response.data.data.isExpiry !== "undefined") {
              sIsExpiry = response.data.data.isExpiry;
            }

            if (typeof response.data.data.maxPeriodPaySplit !== "undefined") {
              sMaxPeriodPaySplit = response.data.data.maxPeriodPaySplit;
            }

            if (typeof response.data.data.registrationStatus !== "undefined") {
              registrationStatus = response.data.data.registrationStatus;
            }

            if (typeof response.data.data.paidPremium !== "undefined") {
              paidPremium = response.data.data.paidPremium;
            }

            if (typeof response.data.data.billingChannel !== "undefined") {
              billingChannel = response.data.data.billingChannel;
            }

            if (typeof response.data.data.bankName !== "undefined") {
              bankName = response.data.data.bankName;
            }

            if (typeof response.data.data.isPremiumHoliday !== "undefined") {
              isPremiumHoliday = response.data.data.isPremiumHoliday
            }

            let data = {
              polisnumber: this.state.polisnumber,
              dateofbirth: this.state.dateofbirth,
              isPolisNB: this.state.isPolisNB,
              emailAddress: sEmailAddress,
              premium: sPremium,
              premiumAmount: sPremiumAmount,
              policyStatusCode: sPolicyStatusCode,
              paymentMethodCode: sPaymentModeCD,
              coresystem: sCoreSystem,
              paymentduedate: sPaymentDueDate,
              paymentmethod: sPaymentMethod,
              issueDt: sIssueDt,
              effectiveDt: sEffectiveDT,
              policyHolderNm: sPolicyHolderNm,
              productNm: sProductNm,
              currency: sCurrency,
              isPaymentDisabled: bIsPaymentDisabled,

              insuredNm: sInsuredNm,
              insuredEmail: sInsuredEmail,
              insuredPhone: sInsuredPhone,
              insuredGender: sInsuredGender,
              insuredDOB: sInsuredDob,
              birthDT: sBirthDT,
              mobilePhoneNo: sMobilePhoneNo,
              gender: sGender,
              policyDueDt: sPolicyDueDt,

              insuredRelation: response?.data?.data?.insuredRelation,
              isSyariah: response?.data?.data?.isSyariah,
              isSinglePremium: response?.data?.data?.isSinglePremium,
              sumInsured: response?.data?.data?.sumInsured
                ?.replace(".00", "")
                .trim(),

              isPending,
              isSplitPayment,
              splitPaymentDetail: sSplitPaymentDetail,
              minCanSplitPayment: sCanSplitPayment,
              minSplitPayment: sMinSplitPayment,
              firstPremium: sFirstPremi,
              isSplitExpired: sIsExpiry,
              maxPeriodPaySplit: sMaxPeriodPaySplit,
              isPresubmission: response?.data?.data?.isPresubmission,
              paymentType: response?.data?.data?.paymentType,
              registrationStatus: registrationStatus,
              paidPremium: paidPremium,
              billingChannel: billingChannel,
              bankName: bankName,
              isPremiumHoliday: isPremiumHoliday
            };

            // this.props.history.push({
            //   pathname: process.env.REACT_APP_SUBDIR + "/beranda",
            //   data: data, // passing data to beranda
            // })

            data["nomorhp"] = response.data.data.mobilePhoneNo;

            let otpRequired =
              response.data.data.otpRequired === false ? false : true;
            // otpRequired = true

            if (otpRequired === true) {
              this.props.actionSetVerificationPolis({
                polis: data,
              });
              this.setState({ data });

              this.props.history.push({
                pathname: process.env.REACT_APP_SUBDIR + "/otp",
                data,
              });
            } else {
              let now = parseInt(new Date().getTime() / 1000);
              this.props.setPolis(data);
              this.props.actionCheckPaymentValidation(data);
              // this.props.setExpired(now + authExpiredTime);
              this.props.setExpired(expiresIn / 1000);
              this.props.history.push({
                pathname: process.env.REACT_APP_SUBDIR + "/beranda",
                data,
              });
            }
          } else {
            this.setState({
              csserrors: csserror,
              errors: error,
            });
          }
        }
        this.setState({ loading: false });
      })
      .catch((response) => {
        error["generalerror"] =
          "*Nomor Polis atau Tanggal Lahir tidak terdaftar.";
        csserror["generalerror"] = "notification-error";
        this.setState({
          csserrors: csserror,
          errors: error,
        });
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="card login-form">
          <form onSubmit={this.onSubmit}>
            <div className="card-body">
              <div className="input-group-custom">
                <span className="input-group-icon" id="polis">
                  <img src={IcPolis} width="24px" height="24px" alt="" />
                </span>

                <input
                  type="text"
                  maxLength={"20"}
                  id="polisnumber"
                  className={
                    "form-control " +
                    (this.state.errors["polisnumber"] ? " border-danger " : "")
                  }
                  placeholder="Nomor Polis Anda"
                  aria-label="Nomor Polis"
                  aria-describedby="Nomor Polis"
                  name="polisnumber"
                  value={this.state.polisnumber}
                  onChange={this.onChang}
                  onBlur={this.validateOnBlur}
                />
              </div>
              {this.state.errors["polisnumber"] && (
                <p className="form-tip form-alert">
                  <i>{this.state.errors["polisnumber"]}</i>
                </p>
              )}

              <div className="input-group-custom">
                <span className="input-group-icon">
                  <img src={IcTtl} width="24px" height="24px" alt="" />
                </span>

                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  minDate={new Date("1/1/1900")}
                  maxDate={new Date()}
                  className={
                    "form-control " +
                    (this.state.errors["dateofbirth"] ? " border-danger " : "")
                  }
                  dateFormat="dd/MM/yyyy"
                  name="dateofbirth"
                  id="dateofbirth"
                  placeholderText="Tanggal Lahir Anda"
                  selected={this.state.dateofbirth}
                  onChange={(newDate) => this.handleSelectDate(newDate)}
                  autoComplete="off"
                />

                <span className="input-group-icon-right">
                  <img src={IcCalendar} width="24px" height="24px" alt="" />
                </span>
              </div>
              {this.state.errors["dateofbirth"] && (
                <p className="form-tip form-alert">
                  <i>{this.state.errors["dateofbirth"]}</i>
                </p>
              )}

              {/* <div className="mt-4 mb-4">
                <div className="d-flex gap-2">
                  <div style={{ width: "40%" }}>
                    <div className="captcha">
                      <LoadCanvasTemplateNoReload />
                      <img id="captchaImage"></img>
                    </div>
                  </div>
                  <div style={{ width: "10%" }} className="v-middle">
                    <a
                      href="."
                      onClick={(e) => {
                        e.preventDefault();
                        this.loadCaptchaBE();
                      }}
                      className="ic-refresh"
                    ></a>
                  </div>
                  <div style={{ width: "50%" }}>
                    <input
                      type="text"
                      maxLength={"6"}
                      id="captcha"
                      className={
                        "form-control input-captcha " +
                        (this.state.captchaInserted ? "lspace-2 " : "") +
                        (this.state.errors["captcha"] ? "border-danger " : "")
                      }
                      placeholder="Captcha"
                      aria-label="Captcha"
                      aria-describedby="Captcha"
                      name="captcha"
                      value={this.state.captcha}
                      onChange={this.onChangeCaptcha}
                      onBlur={this.validateOnBlur}
                    />
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <div style={{ width: "50%" }}></div>
                  <div style={{ width: "50%" }}>
                    {this.state.errors["captcha"] && (
                      <p className="form-tip form-alert">
                        <i>{this.state.errors["captcha"]}</i>
                      </p>
                    )}
                  </div>
                </div>
              </div> */}

              <div className="mt-4 mb-4">
                <div className="input-group-custom captcha-box">
                  <div className="g-recaptcha" >
                    <ReCAPTCHA
                      render="explicit"
                      hl="id"
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                      onChange={this.onChangeGcaptcha}
                      ref={e => (this.grecaptcha = e)}
                    />

                    <div className="d-flex gap-2">
                      {this.state.errors["g-recaptcha"] && (
                        <p className="form-tip form-alert">
                          <i>{this.state.errors["g-recaptcha"]}</i>
                        </p>
                      )}
                    </div>
                  </div>

                </div>
              </div>

              {this.state.errors["generalerror"] && (
                <p className="form-tip form-alert mb-3">
                  <i>{this.state.errors["generalerror"]}</i>
                </p>
              )}

              <div className="d-grid gap-2 col-12 mx-auto pb-2">
                <button
                  className="btn btn-lg btn-primary col-12"
                  type="submit"
                  onClick={this.onSubmit}
                  disabled={
                    !this.state.polisnumber ||
                    !this.state.dateofbirth ||
                    this.state.loading === true ||
                    !this.state.gcaptcha
                  }
                >
                  Masuk <Spinner visible={this.state.loading === true} />
                </button>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { otp, polis, requestedAt, expiredAt, nextRequestAt, success, failed } =
    state.verification;
  return { otp, polis, requestedAt, expiredAt, nextRequestAt, success, failed };
};

export default withRouter(
  connect(mapStateToProps, {
    actionSetVerificationPolis,
    setPolis,
    actionCheckPaymentValidation,
    setExpired,
  })(LandingPage)
);
