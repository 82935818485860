import React, { useEffect, useRef, useState } from "react"
import SnK from "./SnK"

const AutoDebitBcaPrivateCard = ({ selected = false, autoNamaPemegangBcaPrivateCard, autoNomorBcaPrivateCard, autoBulanBerlakuBcaPrivateCard, autoTahunBerlakuBcaPrivateCard, autoCvvBcaPrivateCard, autoTncBcaPrivateCard, autoAgreeBcaPrivateCard, setAutoNamaPemegangBcaPrivateCard, setAutoNomorBcaPrivateCard, setAutoBulanBerlakuBcaPrivateCard, setAutoTahunBerlakuBcaPrivateCard, setAutoCvvBcaPrivateCard, setAutoTncBcaPrivateCard, setAutoAgreeBcaPrivateCard, autoSudahDownload, autoHarusDownload, download, errorAutoBulanBerlakuBcaPrivateCard = null, errorAutoTahunBerlakuBcaPrivateCard = null, errorAutoNomorBcaPrivateCard = null, errorAutoNamaPemilikBcaPrivateCard = null, setTrueAutoTncBcaPrivateCard, errorAutoCvvBcaPrivateCard = null, submitBcaPrivateCard = false }) => {
    const [show, setShow] = useState("v-hidden")
    const [tncClicked, seTncClicked] = useState(false)
    const refTnc = useRef(null)

    useEffect(() => {
        if (selected === true) {
            setShow("v-hidden collapse-animate-xl")

            setTimeout(() => {
                setShow("collapse-animate-xl")
            }, 500)
        } else {
            setShow("v-hidden")
        }
    }, [selected])

    const onChangeTnc = () => {
        if (tncClicked === false) {
            refTnc.current.click()
        } else {
            setAutoTncBcaPrivateCard()
        }
    }

    const acceptTnc = () => {
        setAutoTncBcaPrivateCard()
        seTncClicked(true)
    }

    return <React.Fragment>
        <div className={"data-pembayaran " + show}>
            <div className="mb-3">
                <label htmlFor="holderName" className="form-label">Nama Pemegang Kartu</label>
                <input type="text" className="form-control" id="holderName"
                    placeholder="Nama Pemegang Kartu" value={autoNamaPemegangBcaPrivateCard} onChange={setAutoNamaPemegangBcaPrivateCard} maxLength={"50"} />

                {
                    errorAutoNamaPemilikBcaPrivateCard ?
                        <p className="form-tip form-alert"><i>{errorAutoNamaPemilikBcaPrivateCard}</i></p>
                        : null
                }
            </div>
            <div className="mb-3">
                <label htmlFor="cardNumber" className="form-label">Nomor Kartu</label>
                <input type="text" className="form-control" id="cardNumber"
                    placeholder="Contoh : 1234 5678 9012 4152" value={autoNomorBcaPrivateCard} onChange={setAutoNomorBcaPrivateCard} onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }} />

                {
                    errorAutoNomorBcaPrivateCard ?
                        <p className="form-tip form-alert"><i>{errorAutoNomorBcaPrivateCard}</i></p>
                        : null
                }
            </div>
            <div className="d-flex flex-row gap-3">
                <div className={errorAutoBulanBerlakuBcaPrivateCard || errorAutoTahunBerlakuBcaPrivateCard || errorAutoCvvBcaPrivateCard ? "" : "mb-3"} style={{ width: "40%" }}>
                    <label htmlFor="" className="form-label">Masa Berlaku</label>
                    <div className="card-input">
                        <input type="text" className="form-control me-3" id="mmCard"
                            placeholder="mm" maxLength="2" value={autoBulanBerlakuBcaPrivateCard} onChange={setAutoBulanBerlakuBcaPrivateCard} onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                        <input type="text" className="form-control" id="yyCard"
                            placeholder="yy" maxLength="2" value={autoTahunBerlakuBcaPrivateCard} onChange={setAutoTahunBerlakuBcaPrivateCard} onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                    </div>
                </div>
                <div className={errorAutoBulanBerlakuBcaPrivateCard || errorAutoTahunBerlakuBcaPrivateCard || errorAutoCvvBcaPrivateCard ? "" : "mb-3"} style={{ width: "60%" }}>
                    <label htmlFor="cvv" className="form-label">CVV</label>
                    <input type="text" className="form-control col" id="cvv"
                        placeholder="Contoh : 123" maxLength="3" value={autoCvvBcaPrivateCard} onChange={setAutoCvvBcaPrivateCard} style={{ width: "100%" }} onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }} />
                </div>
            </div>
            {
                errorAutoBulanBerlakuBcaPrivateCard || errorAutoTahunBerlakuBcaPrivateCard || errorAutoCvvBcaPrivateCard
                    ? <div className="mb-3">
                        {errorAutoBulanBerlakuBcaPrivateCard ? <p className="form-tip form-alert"><i>{errorAutoBulanBerlakuBcaPrivateCard}</i></p> : null}
                        {errorAutoTahunBerlakuBcaPrivateCard ? <p className="form-tip form-alert"><i>{errorAutoTahunBerlakuBcaPrivateCard}</i></p> : null}
                        {errorAutoCvvBcaPrivateCard ? <p className="form-tip form-alert"><i>{errorAutoCvvBcaPrivateCard}</i></p> : null}
                    </div> : null
            }
            <div className="text-center">
                <p>Silakan unduh dan lengkapi Formulir Surat Kuasa Debet KK (SKDKK) dibawah
                    ini</p>
                <a href="." onClick={download} className={"d-flex " + (submitBcaPrivateCard === true && autoHarusDownload === true ? "" : "mb-3")}>
                    <button className="btn btn-outline-primary px-5 mb-1 col">Download
                        Formulir</button>
                </a>
                {
                    submitBcaPrivateCard === true && autoHarusDownload === true ? <p className="form-tip text-danger mb-3"><i>Dokumen wajib di download</i></p> : null
                }

                <p>Kirimkan formulir tersebut dengan disertai salinan Identitas Diri
                    (KTP/SIM) dan Salinan Kartu Kredit bagian depan ke alamat email
                    customer@axa-financial.co.id</p>
            </div>
            <div className="grey-box ketentuan-pembayaran">
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox" value=""
                        id="autoDebitCard" onChange={setAutoTncBcaPrivateCard} checked={autoTncBcaPrivateCard === true} />
                    <label className="form-check-label" htmlFor="autoDebitCard">
                        <small>
                            Saya setuju dan mengerti terhadap {" "}
                            <a ref={refTnc} href="." onClick={(e) => e.preventDefault()} data-bs-toggle="modal"
                                data-bs-target="#modalAutoTncBcaPrivateCard">Syarat dan Ketentuan</a>
                        </small>
                    </label>
                </div>
            </div>
        </div>

        {/** <SnK id={"modalAutoTncBcaPrivateCard"} acceptTnc={tncClicked ? null : acceptTnc} /> */}
        <SnK id={"modalAutoTncBcaPrivateCard"} acceptTnc={setTrueAutoTncBcaPrivateCard} opsi="AutoDebit" />
    </React.Fragment>
}

export default AutoDebitBcaPrivateCard