import React from "react"
import Header from "../components/Layout/AuthLayout/Header"
import AfipayDesc from "../components/Layout/AuthLayout/AfipayDesc"
import ImgSuccess from "../assets/new/img/illustration-confirmation.svg"
import { Link } from "react-router-dom"
import { useEffect } from "react"
import { withRouter } from "react-router-dom"
import { connect } from 'react-redux'
import { actionSetDownload } from "../store/payment/actions"
import {
    asCurrency,
    asDateV3,
    dateTime,
    dateTimeFree,
} from "../utils/formatter";

const AutoDebitBerhasil = (props) => {
    const {
        data,
        polis,
        pemegangPolis,
        hubunganPembayar,
        emailPembayar,
        actionSetDownload,
        actionSetLastSuccessNotified,
        actionResetField,
    } = props;

    useEffect(() => {
        if (data === null) {
            props.history.replace({
                pathname: process.env.REACT_APP_SUBDIR + "/beranda"
            })
        }
    }, [data])

    let unduhBuktiPembayaran = () => {
        actionSetDownload(data)
        setTimeout(() => {
            window.open(process.env.REACT_APP_SUBDIR + "/unduh-bukti-pembayaran", "_blank")
        }, 500)
    }

    return <React.Fragment>
        <Header url={process.env.REACT_APP_SUBDIR + '/beranda'} title={"Pendaftaran Auto Debit Berhasil"} button={false} />


        <div className="container metode-pembayaran konfirmasi text-center pt-4 mb-5">
            <img className="pt-4 pb-3" src={ImgSuccess} alt="confirmation" />
            <div className="subtitle-page fw-bold pt-2 mb-2" style={{ color: "green", fontSize: "20px" }}>
                Pendaftaran Autodebit  Berhasil
            </div>
            <p style={{ marginBottom: "2rem" }} className="px-3">
                Selamat pendaftaran Auto Debit Anda Berhasil.
            </p>
            <div className="card mb-4">
                <div className="card-body py-2">
                    <p className="color-dark fw-semibold mb-2 text-start">
                        Detail Pendaftaran
                    </p>
                    <div className="mt-2">
                        <div className="gap-2 ps-0 d-md-grid" style={{ textAlign: "left" }}>
                            <div>
                                <span className="text-grey">Tanggal Transaksi</span>
                                <p className="color-dark">
                                    {dateTime(data?.tanggalTransaksi)}
                                </p>
                            </div>
                            <div>
                                <span className="text-grey">Transaksi id</span>
                                <p className="color-dark">{data?.transactionId}</p>
                            </div>
                            <div>
                                <span className="text-grey">No Polis</span>
                                <p className="color-dark">{data?.noPolis}</p>
                            </div>
                            {/* <div>
                                <span className="text-grey">Total Pembayaran</span>
                                <p className="color-dark">
                                    Rp {asCurrency(data?.totalPembayaran)}
                                </p>
                            </div> */}
                            <div>
                                <span className="text-grey">Metode Pembayaran</span>
                                <p className="color-dark">{data?.metodePembayaran}</p>
                            </div>
                            <div>
                                <span className="text-grey">Nama Lengkap</span>
                                <p className="color-dark">{data?.namaLengkap}</p>
                            </div>
                            <div>
                                <span className="text-grey">
                                    Mendaftar untuk premi lanjutan dengan autodebet
                                </span>
                                <p className="color-dark">
                                    {data?.lanjutAutoDebet === true ? "Ya" : "Tidak"}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <p>
            Hubungi <strong>Customer Care 1500 940</strong>, jika Anda membutuhkan informasi lebih lanjut
            </p>
            <p>
                Bukti pembayaran ini secara otomatis juga dikirim ke alamat email yang
                terdaftar.
            </p>
            <div className="inline-button pt-4">
                {/* <button type="button" onClick={() => unduhBuktiPembayaran()} className="btn btn-primary col mb-2">Unduh Bukti Pembayaran</button> */}
                <Link to={process.env.REACT_APP_SUBDIR + "/sign-out"} type="button" className="btn btn-outline-dark col mb-2">Keluar</Link>
            </div>
        </div>
        <AfipayDesc />
    </React.Fragment>
}

const mapStateToProps = (state) => {
    return {
        data: state.payment?.lastSuccess
    }
}

export default withRouter(connect(mapStateToProps, { actionSetDownload })(AutoDebitBerhasil))