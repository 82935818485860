import React, { Component } from "react";
import imgKetentuan from "../assets/images/ketentuan-image.svg";

class PopupBeranda extends Component {
  onClosePopup(e) {
    document.getElementById("popupBeranda").style.display = "none";
  }

  onRedirect(e) {
    window.location = process.env.REACT_APP_BASEREF + "/";
  }

  render() {
    return (
      <div className="modal" id="popupBeranda">
        <div className="container-popup-beranda">
          <img src={imgKetentuan} alt="ketentuan-image" width="50px" />
          <br />
          <h2>Perhatian</h2>
          <br />
          <p>
            Jika Anda memuat ulang halaman ini, maka akan menghapus data yang
            telah Anda isi.
          </p>
          <br />
          <div className="form-group periksa-kembali">
            <button
              type="submit"
              className="btn btn-block btn-primary-light"
              onClick={this.onClosePopup}
            >
              Saya tetap di halaman ini
            </button>
          </div>
          <div className="form-group">
            <button
              type="submit"
              className="btn btn-block btn-primary"
              onClick={this.onRedirect}
            >
              Saya ingin memuat ulang halaman ini
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default PopupBeranda;
