import React, { useEffect, useState } from "react";
import "./dashboard.css";
import axios from "axios";
import DataTable from "./DataTable";
import { encryptDecript } from "../../helpers/encryptDecrypt.js";
import Header from "../../components/Layout/AuthLayout/Header";
import AfipayDesc from "../../components/Layout/AdminLayout/AfipayDesc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const Menu = (props) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [moduleAccess, setModuleAccess] = useState([]);
  const [data, setData] = useState([]);
  const [totalEntries, setTotalEntries] = useState(10);

  useEffect(() => {
    if (
      typeof localStorage.getItem("AFIPAY02B01") !== "undefined" ||
      localStorage.getItem("AFIPAY02B01") !== ""
    ) {
      setModuleAccess(encryptDecript(localStorage.getItem("AFIPAY02B01"), 2));
      getListData();
    }
  }, []);

  const onSearch = (e) => {
    e.preventDefault();

    setData([]);
    getListData();
  };

  const getListData = () => {
    setLoading(true);
    let token = "";
    if (
      typeof localStorage.getItem("AFIPAY02B02") !== "undefined" ||
      localStorage.getItem("AFIPAY02B02") !== "" ||
      localStorage.getItem("AFIPAY02B02") !== null
    ) {
      token = encryptDecript(localStorage.getItem("AFIPAY02B02"), 2);
    }

    const headers = {
      Authorization: "Bearer " + token,
    };

    const params = {
      search: search,
      page: page,
      size: 999999,
      sortBy: "id",
      sortType: "ASC",
    };

    axios
      .get(process.env.REACT_APP_API_DASH + "/module/get-all", {
        params,
        headers,
      })
      .then(async (response) => {
        if (response?.data?.data?.Message === "Forbidden") {
          setData([]);
          setLoading(false);
          await alert({
            title: "Forbidden",
            html: "You don`t have access to read data or your access has been revoked in this menu",
          });
        } else {
          setData(response?.data?.data?._items);
          setLoading(false);
          localStorage.setItem(
            "AFIPAY01A36",
            encryptDecript(response?.data?.data?._items, 1)
          );
        }
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          await alert({
            title: "Your session has ended",
            html: "Please login again",
          });

          props.history.push(process.env.REACT_APP_SUBDIR + "/sign-out-admin");
        }
        setLoading(false);
      });
  };

  const onChangeEntries = (val) => {
    setTotalEntries(val);
    getListData();
  };

  return (
    <React.Fragment>
      <Header
        url={process.env.REACT_APP_SUBDIR + "/sign-out-admin"}
        urlLabel="Keluar"
        title={"Dashboard"}
        confirm={true}
        icon={"ic-out"}
        confirmTitle={"Keluar?"}
        confirmDescription={"Apakah anda yakin ingin mengakhiri sesi anda?"}
        right={true}
        isAdmin={true}
        moduleAccess={moduleAccess}
        moduleName={"menus"}
      />
      <AfipayDesc title={"User Management / Menu"} />

      <div className="container pt-4 mb-5 px-5 mt-4 dashboard-admin">
        <div className="row justify-content-between">
          <div className="col-lg-4">
            <span>Showing</span>
            <select
              className="form-select form-select-sm ms-2 me-2"
              style={{
                paddingRight: "2rem",
                width: "auto",
                height: "auto",
                display: "inline-block",
              }}
              value={totalEntries}
              onChange={(e) => onChangeEntries(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span>Entries</span>
          </div>
          <div className="col-lg-3">
            <div className="input-group mb-3">
              <input
                type="text"
                name="search"
                id="search"
                placeholder=""
                className="form-control"
                onChange={(e) => setSearch(e.target.value)}
                style={{
                  borderRadius: "7px",
                  height: "35px",
                }}
              />
              <div className="input-group-append">
                <button
                  type="submit"
                  className="btn btn-block btn-primary"
                  id="btnSearch"
                  onClick={onSearch}
                  style={{
                    borderRadius: "7px",
                    width: "35px",
                    height: "35px",
                    padding: "0",
                  }}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </div>
            </div>
          </div>
        </div>

        <DataTable
          data={data}
          tableName={"menu"}
          totalEntries={totalEntries}
          loading={loading}
        />
      </div>
    </React.Fragment>
  );
};
export default Menu;
