import React from "react"
import ImgFailed from "../../assets/new/img/illustration-confirmation-failed.svg"
import { Link } from "react-router-dom"

const Page404 = (props) => {
    return <React.Fragment>
        <div className="container metode-pembayaran konfirmasi text-center pt-4 mb-5">
            <img className="pt-4 pb-3 " src={ImgFailed} alt="confirmation" />
            <div className="subtitle-page fw-semibold pt-2 mb-2">
                404
            </div>
            <p style={{ marginBottom: "2rem" }} className="px-3"> Maaf, halaman tidak ditemukan.
            </p>
            <div className="inline-button pt-4">
                <Link to={process.env.REACT_APP_SUBDIR + "/"} type="button" className="btn btn-primary col mb-2">Kembali</Link>
            </div>
        </div>
    </React.Fragment>
}

export default Page404