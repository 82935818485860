import React from 'react'
import { withRouter } from 'react-router-dom'
import ScrollToTop from '../../ScrollToTop'
import Footer from '../Footer'

const AdminLayout = (props) => {
    const { children } = props

    return <React.Fragment>
        <ScrollToTop />
        {children}
        <Footer />
    </React.Fragment>
}

export default withRouter(AdminLayout)